import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

export default function ActiveSwitch({
  color,
  value,
  setValue,
  primaryText,
  secondaryText,
}) {
  return (
    <Stack
      display="inline-flex"
      direction="row"
      alignItems="center"
      sx={{
        padding: '2px',
        border: '1px solid grey',
        borderRadius: '20px',
      }}>
      <Box
        onClick={() => {
          setValue(true);
        }}
        sx={{
          cursor: 'pointer',
          padding: '4px 8px',
          borderRadius: '20px',
          background: (theme) =>
            value === true
              ? `${
                  color === 'primary'
                    ? theme.palette.primary.main
                    : theme.palette.success.main
                }`
              : 'transparent',
          color: value === true ? '#fff' : '#000',
        }}>
        <Typography sx={{ fontSize: 12 }}>{primaryText}</Typography>
      </Box>
      <Box
        onClick={() => {
          setValue(false);
        }}
        sx={{
          cursor: 'pointer',
          padding: '4px 8px',
          borderRadius: '20px',
          background: (theme) =>
            value === false
              ? `${
                  color === 'primary'
                    ? theme.palette.primary.main
                    : theme.palette.error.main
                }`
              : 'transparent',
          color: value === false ? '#fff' : '#000',
        }}>
        <Typography sx={{ fontSize: 12 }}>{secondaryText}</Typography>
      </Box>
    </Stack>
  );
}
