import HomeIcon from '@mui/icons-material/Home';
import UndoIcon from '@mui/icons-material/Undo';
import { Avatar, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import img from '../assets/images/missing.svg';
import { Link } from 'react-router-dom';

function Missing() {
  return (
    <Stack
      sx={{
        gap: 2,
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Avatar
        src={img}
        alt="missing page"
        sx={{
          width: { sm: 400, xs: 200 },
          height: { sm: 300, xs: 100 },
          borderRadius: 0,
          img: {
            objectFit: 'contain',
          },
        }}
      />

      <Typography variant="h6" color="text.secondary">
        Page Not Found
      </Typography>

      <Button
        onClick={() => window.history.back()}
        variant="text"
        color="inherit"
        endIcon={<UndoIcon />}>
        Go to previous page
      </Button>

      <Button component="a" href="/" variant="outlined" endIcon={<HomeIcon />}>
        Go to Homescreen
      </Button>
    </Stack>
  );
}

export default Missing;
