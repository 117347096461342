import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect({ data, state, setState }) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setState(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Box>
      <FormControl sx={{ width: { md: 300, xs: 270 } }} size="small">
        <InputLabel>Transaction Type</InputLabel>
        <Select
          multiple
          value={state}
          onChange={handleChange}
          input={<OutlinedInput label="Transaction Type" />}
          MenuProps={MenuProps}>
          {data?.map((info) => (
            <MenuItem key={info?.id} value={info?.id}>
              {info?.transactionType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
