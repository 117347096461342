import React, { createContext, useState } from 'react';

const LayoutContext = createContext({});

export const LayoutProvider = ({ children }) => {
  const [collapsemenu, setCollapseMenu] = useState(false);
  const [backDrop, setBackDrop] = React.useState(false);
  const [left, setLeft] = useState(-1000);
  const [toggleMobileNav, setToggleMobileNav] = useState(true);
  return (
    <LayoutContext.Provider
      value={{
        collapsemenu,
        setCollapseMenu,
        backDrop,
        setBackDrop,
        left,
        setLeft,
        toggleMobileNav,
        setToggleMobileNav,
      }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContext;
