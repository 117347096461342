import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HeadingTitle from '../../../component/HeadingTitle';

function ModifiedEmployeeRole() {
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const id = params?.employeeRoleID;

  const [isLoading, setIsLoading] = useState(false);
  // permission value start
  const [user, setUser] = useState('view');
  const [customerTypes, setCustomerTypes] = useState('view');
  const [customerClasses, setCustomerClasses] = useState('view');
  const [customers, setCustomers] = useState('view');
  const [employeeRoles, setEmployeeRoles] = useState('view');
  const [employees, setEmployees] = useState('view');
  const [territoryTypes, setTerritoryTypes] = useState('view');
  const [territories, setTerritories] = useState('view');
  const [productCategories, setProductCategories] = useState('view');
  const [products, setProducts] = useState('view');
  const [stocks, setStocks] = useState('view');
  const [transactions, setTransactions] = useState('view');
  const [transactionTypes, setTransactionTypes] = useState('view');
  const [reports, setReports] = useState('view');
  // permission value end
  // **************************************************************************//
  // **************************************************************************//
  // create Employee Role
  // form data
  const [checked, setChecked] = useState(true);
  const [employeeRole, setEmployeeRole] = useState('');
  const [codePrefix, setCodePrefix] = useState('');
  const [inTerritoryAllowance, setInTerritoryAllowance] = useState('');
  const [outTerritoryAllowance, setOutTerritoryAllowance] = useState('');
  const [outStationAllowance, setOutStationAllowance] = useState('');
  const [attendanceMethod, setAttendanceMethod] = useState('');
  const [description, setDescription] = useState('');
  const [territoryType, setTerritoryType] = useState('');
  const [territoyData, setTerritoyData] = useState([]);
  // form data

  // get edit territry data
  React.useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/employee/role/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          const p = data?.permissions;
          setChecked(data?.isActive);
          setEmployeeRole(data?.employeeRole);
          setCodePrefix(data?.codePrefix);
          setTerritoryType({
            territoryType: data?.territoryType?.territoryType,
          });
          setInTerritoryAllowance(data?.inTerritoryAllowance);
          setOutTerritoryAllowance(data?.outTerritoryAllowance);
          setOutStationAllowance(data?.outStationAllowance);
          setAttendanceMethod(data?.attendanceMethod);
          setDescription(data?.description);
          // permission set value
          setUser(p?.users);
          setCustomerTypes(p?.customerTypes);
          setCustomerClasses(p?.customerClasses);
          setCustomers(p?.customers);
          setEmployeeRoles(p?.employeeRoles);
          setEmployees(p?.employees);
          setTerritoryTypes(p?.territoryTypes);
          setTerritories(p?.territories);
          setProductCategories(p?.productCategories);
          setProducts(p?.products);
          setStocks(p?.stocks);
          setTransactions(p?.transactions);
          setTransactionTypes(p?.transactionTypes);
          setReports(p?.reports);
        })
        .catch((error) => console.warn(error))
        .finally(() => setIsLoading(false));
    } else {
      setChecked(true);
      setEmployeeRole('');
      setCodePrefix('');
      setTerritoryType({});
      setInTerritoryAllowance('');
      setOutTerritoryAllowance('');
      setOutStationAllowance('');
      setAttendanceMethod('');
      setDescription('');
      // permission set value
      setUser('view');
      setCustomerTypes('view');
      setCustomerClasses('view');
      setCustomers('view');
      setEmployeeRoles('view');
      setEmployees('view');
      setTerritoryTypes('view');
      setTerritories('view');
      setProductCategories('view');
      setProducts('view');
      setStocks('view');
      setTransactions('view');
      setTransactionTypes('view');
      setReports('view');
    }
  }, [id]);

  useEffect(() => {
    axiosPrivate
      .get('/api/v1/territory/type?sort=rank&isActive=true')
      .then((res) => {
        setTerritoyData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // get territory data

  // **************************************************************************//
  // **************************************************************************//
  const roleValues = ['restricted', 'view', 'contribute', 'manage'];

  const handleSubmit = () => {
    axiosPrivate({
      method: id ? 'patch' : 'post',
      url: id ? `/api/v1/employee/role/${id}` : '/api/v1/employee/role',
      data: {
        isActive: checked,
        employeeRole,
        codePrefix,
        territoryType: territoryType.id,
        inTerritoryAllowance,
        outTerritoryAllowance,
        outStationAllowance,
        attendanceMethod,
        description,
        permissions: {
          users: user,
          customerTypes: customerTypes,
          customerClasses: customerClasses,
          customers: customers,
          employeeRoles: employeeRoles,
          employees: employees,
          territoryTypes: territoryTypes,
          territories: territories,
          productCategories: productCategories,
          products: products,
          stocks: stocks,
          transactions: transactions,
          transactionTypes: transactionTypes,
          reports: reports,
        },
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate('/settings/employee-role');
        refetch();
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading ...</Typography>
      </Stack>
    );
  }

  return (
    <Box>
      <HeadingTitle>{id ? 'Edit' : 'Add'} Employee Role</HeadingTitle>
      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={12}>
          <FormControlLabel
            label={checked ? 'Active' : 'Inactive'}
            checked={checked}
            onChange={() => setChecked(!checked)}
            control={<Switch />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Employee Role"
            variant="outlined"
            fullWidth
            value={employeeRole}
            onChange={(e) => setEmployeeRole(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Code Prefix"
            variant="outlined"
            fullWidth
            value={codePrefix}
            onChange={(e) => setCodePrefix(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disableClearable
            autoHighlight
            fullWidth
            options={territoyData}
            getOptionLabel={(option) => option.territoryType || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={territoryType}
            onChange={(event, value) => setTerritoryType(value)}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Location Type" />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="outlined-basic"
            label="In HQ DA"
            variant="outlined"
            fullWidth
            value={inTerritoryAllowance}
            onChange={(e) => setInTerritoryAllowance(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="outlined-basic"
            label="Out HQ DA"
            variant="outlined"
            fullWidth
            value={outTerritoryAllowance}
            onChange={(e) => setOutTerritoryAllowance(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            id="outlined-basic"
            label="Out Station DA"
            variant="outlined"
            fullWidth
            value={outStationAllowance}
            onChange={(e) => setOutStationAllowance(e.target.value)}
            onWheel={(e) => e.target.blur()}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel id="attendance-method-label">
              Attendance Method
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="attendance-method-label"
              value={attendanceMethod}
              onChange={(e) => setAttendanceMethod(e.target.value)}>
              <FormControlLabel
                label="Sales Manager's"
                value="manager"
                control={<Radio />}
              />
              <FormControlLabel
                label="Sales Representative's"
                value="representative"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Permissions: </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Users</InputLabel>
            <Select
              value={user}
              label="Users"
              onChange={(event) => setUser(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Customer Types</InputLabel>
            <Select
              value={customerTypes}
              label="Customer Types"
              onChange={(event) => setCustomerTypes(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Customer Classes</InputLabel>
            <Select
              value={customerClasses}
              label="Customer Classes"
              onChange={(event) => setCustomerClasses(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Customers</InputLabel>
            <Select
              value={customers}
              label="Customers"
              onChange={(event) => setCustomers(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Employee Roles</InputLabel>
            <Select
              value={employeeRoles}
              label="Employee Roles"
              onChange={(event) => setEmployeeRoles(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Employees</InputLabel>
            <Select
              value={employees}
              label="Employee Roles"
              onChange={(event) => setEmployees(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Employees</InputLabel>
            <Select
              value={territoryTypes}
              label="Employee Roles"
              onChange={(event) => setTerritoryTypes(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Territories</InputLabel>
            <Select
              value={territories}
              label="Territories"
              onChange={(event) => setTerritories(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Product Categories</InputLabel>
            <Select
              value={productCategories}
              label="Product Categories"
              onChange={(event) => setProductCategories(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Products</InputLabel>
            <Select
              value={products}
              label="Products"
              onChange={(event) => setProducts(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Stocks</InputLabel>
            <Select
              value={stocks}
              label="Stocks"
              onChange={(event) => setStocks(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Transactions</InputLabel>
            <Select
              value={transactions}
              label="Transactions"
              onChange={(event) => setTransactions(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Transaction Types</InputLabel>
            <Select
              value={transactionTypes}
              label="Transaction Types"
              onChange={(event) => setTransactionTypes(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Reports</InputLabel>
            <Select
              value={reports}
              label="Reports"
              onChange={(event) => setReports(event.target.value)}>
              {roleValues.map((data) => (
                <MenuItem key={data} value={data}>
                  {data}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/settings/employee-role')}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ px: 3 }} onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ModifiedEmployeeRole;
