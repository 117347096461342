import { ListItem, styled } from '@mui/material';

export const CustomListItem = styled(ListItem)(({ view, theme }) => ({
  // display: view === 'true' ? 'none' : 'block',
  color: '#585858',
  '&.active': {
    color: `${theme.palette.primary.main}`,
    background: 'rgba(0, 0, 0, 0.04)',
  },
  '&.active .MuiListItemIcon-root': {
    color: `${theme.palette.primary.main}`,
  },
  '& .MuiListItemIcon-root': {
    minWidth: '35px',
  },
  '&.active .MuiTypography-root': {
    fontWeight: 500,
  },
  [theme.breakpoints.down('md')]: {},
}));
