import { Box, Icon, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import LastActive from '../LastActive';

export default function SessionsViews({ deviceInfo, listView, handleOpen }) {
  const today = Date.now();
  const sessions = deviceInfo?.sessions;

  if (listView) {
    return (
      <Stack
        onClick={() => handleOpen(deviceInfo)}
        spacing={0.5}
        sx={{ cursor: 'pointer' }}>
        {sessions?.slice(0, 4).map((s) => {
          let textColor = 'info.main';
          let isActive = false;
          let name = '';

          if (s?.user?.userType === 'customer') {
            textColor = 'success.main';
            isActive = s?.user?.customer?.isActive;
            name = s?.user?.customer?.name;
          } else {
            isActive = s?.user?.employee?.isActive;
            name = s?.user?.employee?.name;
          }

          return (
            <Stack
              key={s?.user?.id}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}>
                {isActive ? (
                  <Icon fontSize="small" color="success">
                    check_circle
                  </Icon>
                ) : (
                  <Icon fontSize="small" color="error">
                    highlight_off
                  </Icon>
                )}

                <Typography
                  title={name}
                  noWrap
                  variant="subtitle2"
                  color={textColor}>
                  {name}
                </Typography>
              </Stack>

              <LastActive
                lastActive={s?.lastSeenAt}
                noWrap
                color="text.secondary"
                sx={{ fontSize: 14 }}
              />
            </Stack>
          );
        })}
        {sessions?.length > 3 ? (
          <Typography align="right" variant="subtitle2" color="text.disabled">
            ...more
          </Typography>
        ) : null}
      </Stack>
    );
  } else {
    return (
      <Stack spacing={2}>
        {sessions?.map((s) => {
          let isEmployee = true;
          let isActive = false;
          let nameCode = '';
          let roleType = '';
          let territory = '';
          let dateOfClose = '';

          if (s?.user?.userType === 'customer') {
            isEmployee = false;
            isActive = s?.user?.customer?.isActive;
            nameCode = `${s?.user?.customer?.name} (${s?.user?.customer?.code})`;
            roleType = `${s?.user?.customer?.customerType?.customerType} (${s?.user?.customer?.customerClass?.customerClass})`;
            territory = `${s?.user?.customer?.territory?.territoryType}: ${s?.user?.customer?.territory?.name} (${s?.user?.customer?.territory?.code})`;
            dateOfClose = `Closing date: ${
              s?.user?.customer?.dateOfClosing
                ? dayjs(s?.user?.customer?.dateOfClosing).format('DD/MM/YYYY')
                : 'Not set'
            }`;
          } else {
            isActive = s?.user?.employee?.isActive;
            nameCode = `${s?.user?.employee?.name} (${s?.user?.employee?.code})`;
            roleType = s?.user?.employee?.role?.employeeRole;
            territory = `${s?.user?.employee?.territory?.territoryType}: ${s?.user?.employee?.territory?.name} (${s?.user?.employee?.territory?.code})`;
            dateOfClose = `Resigning date: ${
              s?.user?.employee?.dateOfResign
                ? dayjs(s?.user?.employee?.dateOfResign).format('DD/MM/YYYY')
                : 'Not set'
            }`;
          }

          return (
            <Box
              key={s?.user?.id}
              sx={{
                p: 0.5,
                background: isActive ? '#fafafa' : '#bdbdbd',
                border: '1px solid #80808040',
                borderRadius: '12px',
                position: 'relative',
                overflow: 'hidden',
                ':before': {
                  content: '""',
                  width: 50,
                  height: 50,
                  background: (theme) =>
                    isEmployee
                      ? theme.palette.info.main
                      : theme.palette.success.main,
                  position: 'absolute',
                  left: -25,
                  top: -25,
                  zIndex: 1,
                },
                ':after': {
                  content: '""',
                  width: 50,
                  height: 50,
                  background: (theme) =>
                    isEmployee
                      ? theme.palette.info.main
                      : theme.palette.success.main,
                  position: 'absolute',
                  right: -25,
                  bottom: -25,
                  zIndex: 1,
                },
              }}>
              <Box
                sx={{
                  p: 1,
                  background: 'white',
                  borderRadius: '8px',
                  zIndex: 2,
                  position: 'relative',
                }}>
                {today > new Date(s?.expiresAt).getTime() ? (
                  <Typography
                    variant="overline"
                    color="error"
                    sx={{
                      fontStyle: 'italic',
                      position: 'absolute',
                      top: 0,
                      right: 8,
                    }}>
                    Expired
                  </Typography>
                ) : null}

                <Typography variant="subtitle2" color="text.secondary">
                  Last online:
                  {s?.lastSeenAt
                    ? dayjs(s?.lastSeenAt).format(' DD/MM/YYYY hh:mm A')
                    : ' unknown'}
                </Typography>

                <Stack>
                  <Typography
                    title={nameCode}
                    noWrap
                    variant="body1"
                    sx={{
                      textDecoration: !isActive ? 'line-through' : 'none',
                      fontWeight: 'medium',
                      color: (theme) =>
                        isEmployee
                          ? theme.palette.info.main
                          : theme.palette.success.main,
                    }}>
                    {nameCode}
                  </Typography>

                  <Typography
                    title={roleType}
                    noWrap
                    variant="subtitle2"
                    color="black">
                    {roleType}
                  </Typography>

                  <Typography
                    title={territory}
                    noWrap
                    variant="body2"
                    color="text.primary">
                    {territory}
                  </Typography>

                  {!isActive ? (
                    <Typography
                      title={dateOfClose}
                      noWrap
                      variant="body2"
                      color="text.secondary">
                      {dateOfClose}
                    </Typography>
                  ) : null}
                </Stack>
              </Box>
            </Box>
          );
        })}
      </Stack>
    );
  }
}
