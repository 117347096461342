import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Tooltip, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LinkMain } from '../../assets/style/Report';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import { MultiSelectColumnFilter } from '../../component/Table/SelectColumnFilter';
import StickyTable from '../../component/Table/StickyTable';
import Filter from '../../component/report/Filter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import roundToDigit from '../../utils/roundToDigit';

export default function CustCollection() {
  const navigate = useNavigate();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();
  const [year, setYear] = useState(new Date());
  const [collectionType, setCollectionType] = useState('collection');

  // get Collection
  const { data, isLoading, isError, error } = useQuery(
    ['cust-collection-report', year, collectionType],

    () =>
      axiosPrivate.get(
        `/api/v1/report/customer-collection?year=${new Date(
          year,
        ).getFullYear()}&type=${collectionType}`,
      ),
  );

  const handleCustomerClick = (id) => {
    navigate(`/customers/report-ledger/${id}`);
  };

  const COLUMNS = useMemo(() => {
    const c = [
      {
        Header: 'Region',
        accessor: 'territories.rank-2.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
        sticky: 'left',
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
        sticky: 'left',
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 24 ? (
                <Tooltip title={value}>
                  <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                    {value.slice(0, 24)}..
                  </Typography>
                </Tooltip>
              ) : (
                value
              )}
            </>
          );
        },
        width: 200,
        sticky: 'left',
      },
      {
        Header: 'Route/Beat',
        accessor: 'territories.rank-5.name',
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
      },
      {
        Header: 'Customer Id',
        accessor: 'customer.code',
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
      },
      {
        Header: 'Customer Name',
        accessor: 'customer.name',
        Cell: ({ value, row }) => {
          let n = 24;
          if (!value) return '-';
          return (
            <>
              <LinkMain
                onClick={() =>
                  handleCustomerClick(row?.original?.customer?.id)
                }>
                {value.length > n ? (
                  <Tooltip title={value}>
                    <Typography className="text">
                      {value.slice(0, n)}.. <OpenInNewIcon fontSize="10px" />
                    </Typography>
                  </Tooltip>
                ) : (
                  <>
                    {value}
                    <OpenInNewIcon fontSize="10px" />
                  </>
                )}
              </LinkMain>
            </>
          );
        },
        width: 220,
      },
      {
        Header: 'Customer Type',
        accessor: 'customer.customerType.customerType',
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
    ];

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    months?.forEach((m, i) => {
      c.push({
        Header: m,
        accessor: `collections.${m}`,
        Cell: ({ value }) => {
          if (value) return roundToDigit(value);
          return '-';
        },
        width: 120,
        aggregate: 'sum',
        // disableFilters: true,
      });
    });

    return c;
  }, []);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <br />
      <Filter
        title="Customer Collection"
        collectionType={collectionType}
        setCollectionType={setCollectionType}
        year={year}
        setYear={setYear}
        custcollect
      />
      {!isLoading ? (
        <StickyTable
          columns={COLUMNS}
          data={data?.data?.data}
          filename={`Customer_Collection`}
          customerYear={new Date(year).getFullYear()}
          rowexpand="custCollect"
          // hidePlusIcon
        />
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
