import { Box, styled } from '@mui/material';

export const AuthContainer = styled(Box)(() => ({
  display: 'grid',
  height: '100vh',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const InnerContainer = styled(Box)(({ theme }) => ({
  width: 450,
  height: 'auto',
  padding: '30px 50px',
  background: 'rgba(25, 118, 210, 0.08)',
  borderRadius: 10,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  h2: {
    fontSize: 24,
    textAlign: 'center',
  },
  '& .forgot_text': {
    display: 'block',
    fontSize: 12,
    color: 'rgb(25, 118, 210)',
    textAlign: 'end',
    marginTop: 10,
    marginBottom: 20,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    // margin: '0px 20px',
  },
  // [theme.breakpoints.down('s_sm')]: {
  //   width: 'auto',
  //   maxWidth: '100%',
  //   margin: 20,
  //   padding: 30,
  // },
}));
