import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  Button,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { BankList, MobileBankList } from '../../staticData/Banks';

function EditPayment() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const { role, Admins } = useAuth();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const editId = params?.ledgerID;
  const isAllowed = ['Super Admin', ...Admins, 'Accounts', 'DO'].includes(role);

  const [isLoading, setIsLoading] = useState(false);
  const [isDateError, setIsDateError] = useState(false);
  const [asProduct, setAsProduct] = useState(false);
  const [customersList, setCustomersList] = useState([]);

  const [type, setType] = useState('');
  const [customer, setCustomer] = useState({});
  const [entryAmount, setEntryAmount] = useState('');
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [paymentMode, setPaymentMode] = useState('Bank');
  const [bankName, setBankName] = useState('');
  const [bankBranch, setBankBranch] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [reference, setReference] = useState('');
  const [remarks, setRemarks] = useState('');

  // get edit ladger data by id
  useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/ledger/${editId}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;

          if (data?.transactionRef) {
            setAsProduct(true);
            setEntryAmount(parseFloat(data?.entryAmount));
          } else {
            setAsProduct(false);
            setEntryAmount(parseFloat(data?.entryAmount) * -1);
          }

          setType(data?.type);
          setCustomer(data?.customer);
          setDateOfEntry(data?.dateOfEntry);
          setPaymentMode(data?.paymentMode);
          setBankName(data?.bankName);
          setBankBranch(data?.bankBranch);
          setAccountNumber(data?.accountNumber);
          setTransactionID(data?.transactionID);
          setReference(data?.reference);
          setRemarks(data?.remarks);
        })
        .catch((error) => expired(error))
        .finally(() => setIsLoading(false));
    } else {
      setType('');
      setCustomer({});
      setEntryAmount('');
      setDateOfEntry(new Date());
      setPaymentMode('Bank');
      setBankName('');
      setBankBranch('');
      setAccountNumber('');
      setTransactionID('');
      setReference('');
      setRemarks('');
    }
  }, [editId]);

  // get customer data
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer?populate=yes&limit=none&isActive=true')
      .then((res) => {
        setCustomersList(res?.data?.data);
      })
      .catch((error) => expired(error));
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    const xAmount = asProduct
      ? parseFloat(entryAmount)
      : parseFloat(entryAmount) * -1;

    axiosPrivate({
      method: 'patch',
      url: `/api/v1/ledger/${editId}`,
      data: {
        type: type,
        customer: customer?.id,
        entryAmount: xAmount,
        approvedAmount: xAmount,
        totalAmount: xAmount,
        dateOfEntry: dateOfEntry,
        dateOfDeposit: dateOfEntry,
        dateOfClearance: dateOfEntry,
        paymentMode: paymentMode,
        bankName: bankName,
        bankBranch: bankBranch,
        accountNumber: accountNumber,
        transactionID: transactionID,
        reference: reference,
        remarks: remarks,
      },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate('/payments');
      })
      .catch((error) => {
        expired(error);
      })
      .finally(() => setIsLoading(false));
  };

  const ledgerEntryTypes = [
    { value: '', label: 'None' },
    { value: 'salary', label: 'Salary' },
    { value: 'transport', label: 'Transport' },
    { value: 'damage', label: 'Damage' },
    { value: 'return', label: 'Return' },
    { value: 'bonus', label: 'Bonus' },
    { value: 'commission', label: 'Commission' },
    { value: 'other', label: 'Other funds' },
  ];

  return (
    <Container maxWidth="sm">
      <IconButton
        aria-label="Go back"
        sx={{ mb: 1 }}
        onClick={() => navigate(-1)}>
        <ArrowBackIcon />
      </IconButton>

      <Stack component={Paper} sx={{ p: 3, gap: 3 }}>
        <Autocomplete
          // disabled={isLoading || customersList.length < 1}
          disabled
          fullWidth
          options={customersList}
          value={customer}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setCustomer(value)}
          getOptionLabel={(option) => option?.nameCode || ''}
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Customer" />
          )}
        />

        <Divider flexItem>Payment info</Divider>

        <TextField
          // disabled={isLoading}
          disabled
          fullWidth
          select
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}>
          {ledgerEntryTypes.map((i) => (
            <MenuItem key={i.label} dense disabled={!i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          disabled={isLoading}
          fullWidth
          type="number"
          label="Amount"
          value={entryAmount}
          onChange={(e) => setEntryAmount(e.target.value)}
          onWheel={(e) => e.target.blur()}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={isLoading}
            // disabled
            disableFuture
            fullWidth
            label="Date"
            value={dateOfEntry}
            onError={(reason) => setIsDateError(Boolean(reason))}
            onChange={(newValue) => setDateOfEntry(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <Divider flexItem>Account info</Divider>

        <TextField
          disabled={isLoading}
          fullWidth
          select
          label="Payment Mode"
          value={paymentMode}
          onChange={(e) => setPaymentMode(e.target.value)}>
          <MenuItem value="Bank">Bank</MenuItem>
          <MenuItem value="Mobile Bank">Mobile Bank</MenuItem>
        </TextField>

        {paymentMode === 'Bank' ? (
          <>
            <Autocomplete
              disabled={isLoading}
              fullWidth
              options={BankList}
              value={bankName}
              onChange={(event, value) => setBankName(value)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Bank Name" />
              )}
            />

            <TextField
              disabled={isLoading}
              fullWidth
              type="text"
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />

            <TextField
              disabled={isLoading}
              fullWidth
              type="text"
              label="Bank Branch"
              value={bankBranch}
              onChange={(e) => setBankBranch(e.target.value)}
            />
          </>
        ) : (
          <>
            <Autocomplete
              disabled={isLoading}
              fullWidth
              options={MobileBankList}
              value={bankName}
              onChange={(event, value) => setBankName(value)}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Mobile Bank Name" />
              )}
            />

            <TextField
              disabled={isLoading}
              fullWidth
              type="text"
              label="Mobile Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
          </>
        )}

        <TextField
          disabled={isLoading}
          fullWidth
          type="text"
          label="Transaction Code"
          value={transactionID}
          onChange={(e) => setTransactionID(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          fullWidth
          type="text"
          label="Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
        />

        <TextField
          disabled={isLoading}
          fullWidth
          type="text"
          multiline
          rows={3}
          label="Remarks"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />

        <Button
          disabled={isLoading || !isAllowed || isDateError}
          fullWidth
          color="primary"
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Container>
  );
}

export default EditPayment;
