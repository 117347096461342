import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';
import Modals from '../../component/Modals';
import { toast } from 'react-toastify';
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function UpdatePhoneModal({ show, onSave, onHide }) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();
  const logout = useLogout();
  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    newPhone: '',
    password: '',
  });

  const [logInfo, setLogInfo] = React.useState(
    JSON.parse(localStorage.getItem('LoginInfo')) || '',
  );

  const handleLogout = async () => {
    const newLists = { ...logInfo };
    delete newLists[user?.phone];
    localStorage.setItem('LoginInfo', JSON.stringify(newLists));
    setLogInfo(newLists);
    await logout();
    navigate('/login');
  };
  //   update phone
  const handleSubmit = () => {
    axiosPrivate
      .patch('/api/v1/auth/update-phone', {
        newPhone: formState.newPhone,
        password: formState.password,
      })
      .then((res) => {
        if (res) {
          onSave();
          handleLogout();
          toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };
  return (
    <Modals
      title="Update Login Phone"
      fullWidth={true}
      width="xs"
      show={show}
      onSave={onSave}
      onHide={onHide}
      form>
      <TextField
        label="Phone"
        variant="standard"
        type="number"
        onWheel={(e) => e.target.blur()}
        fullWidth
        onChange={(e) =>
          setFormState({ ...formState, newPhone: e.target.value })
        }
      />
      <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
        <InputLabel>Password</InputLabel>
        <Input
          value={formState.password}
          onChange={(e) =>
            setFormState({ ...formState, password: e.target.value })
          }
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Stack mt={4}>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Modals>
  );
}
