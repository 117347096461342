import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import GridViewIcon from '@mui/icons-material/GridView';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import {
  COLORS,
  COLORS2,
  SKUCOLORS,
  TotalSKUColors,
} from '../../staticData/Colors';
import HeadingTitle from '../HeadingTitle';

export default function SkuAnalysis() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();

  const matches_sm = useMediaQuery('(max-width:600px)');

  const [rangeValue, setRangeValue] = useState([0, 5]);
  const [timeline, setTimeline] = useState(4);
  const [date, setDate] = useState(dayjs());
  const [territoryList, setTerritoryList] = useState([]);
  const [territory, setTerritory] = useState({});
  const [territoryType, setTerritoryType] = useState({
    name: 'Region',
    id: '63aaa3c505e4dc7daa1a85ce',
  });
  const [largeView, setLargeView] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState();

  useEffect(() => {
    const t = territory.name
      ? territoryType.name + ': ' + territory.name
      : territoryType.name;
    setHeader({ title: `SKU - ${t}` });
  }, [territoryType.name, territory.name]);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/territory?limit=none&territoryType=${territoryType?.id}`)
      .then((res) => {
        setTerritoryList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [axiosPrivate, territoryType]);

  const { data, isLoading, isError, error } = useQuery(
    ['sku-dashboard', territory?.id, date.format('YYYY-MM-DD'), timeline],
    ({ queryKey }) => {
      const [key, territory, month, compareMonths] = queryKey;
      if (Object.keys(territory).length !== 0) {
        return axiosPrivate
          .get(
            `/api/v1/report/dashboard-sku?territory=${territory}&month=${month}&compareMonths=${compareMonths}&compareProducts=7`,
          )
          .then((d) => d?.data?.data);
      }
    },
  );

  useEffect(() => {
    setRangeValue([
      data?.totalSaleCurrentMonth?.prices[0],
      data?.totalSaleCurrentMonth?.prices[0],
    ]);
  }, [data]);

  const productDataList = data?.totalSaleCurrentMonth?.data.filter((item) => {
    return item?.price >= rangeValue[0] && item?.price <= rangeValue[1];
  });

  const renderCustomizedLabel = (props) => {
    const { x, y, width, index, height, value } = props;
    const radius = 10;

    if (value) {
      return (
        <g>
          <circle
            cx={x + width / 2}
            cy={y - radius}
            r={radius}
            fill="#8884d8"
          />
          <text
            x={x + width / 2}
            y={y - radius}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={10}>
            {value}
          </text>
        </g>
      );
    }
  };
  // console.log('SKU DASHBOARD DATA', data);

  const toggleFullScreen = () => {
    const element = document.getElementById('container');
    const isFullScreenView = document.fullscreenElement;
    console.log(isFullScreenView);
    if (isFullScreenView) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      element.requestFullscreen();
      setIsFullScreen(true);
    }
  };

  if (isError) {
    expired(error);
  }

  return (
    <Stack
      gap={2}
      id="container"
      sx={{
        overflowY: isFullScreen ? 'scroll' : 'initial',
        p: isFullScreen ? 2 : 0,
        pt: isFullScreen ? 4 : 0,
        background: '#e3e7ef',
      }}>
      <Stack
        component={Paper}
        gap={2}
        alignItems="center"
        sx={{ p: 3 }}
        position="relative">
        <HeadingTitle variant={0}> SKU Analysis</HeadingTitle>
        {/* filter */}
        <Stack direction={{ md: 'row' }} gap={2}>
          <Autocomplete
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={[
              { name: 'Region', id: '63aaa3c505e4dc7daa1a85ce' },
              { name: 'HQ', id: '63aaa46405e4dc7daa1a8648' },
            ]}
            getOptionLabel={(option) => option.name || ''}
            value={territoryType}
            onChange={(event, value) => {
              setTerritoryType(value);
              setTerritory({});
            }}
            renderInput={(params) => (
              <TextField {...params} label="Location Type" />
            )}
          />
          <Autocomplete
            loading
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={territoryList}
            getOptionLabel={(option) => option.name || ''}
            value={territory}
            onChange={(event, value) => setTerritory(value)}
            renderInput={(params) => (
              <TextField {...params} label={territoryType.name} />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              inputFormat="MMM YYYY"
              views={['month', 'year']}
              value={date}
              onChange={(newValue) => setDate(newValue || null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: { md: 160 } }}
                />
              )}
            />
          </LocalizationProvider>
          <Autocomplete
            loading
            sx={{ width: { md: 170 } }}
            disableClearable
            size="small"
            disablePortal
            options={[4, 6, 8, 12]}
            getOptionLabel={(option) => 'Last ' + option + ' months' || ''}
            value={timeline}
            onChange={(event, value) => setTimeline(value)}
            renderInput={(params) => (
              <TextField {...params} label="Choose Timeline" />
            )}
          />
          {/* <FormControl size="small">
            <InputLabel>Choose Timeline</InputLabel>
            <Select
              value={timeline}
              label="Choose Timeline"
              onChange={(e) => setTimeline(e.target.value)}>
              <MenuItem value={4}>Last 4 months</MenuItem>
              <MenuItem value={6}>Last 6 months</MenuItem>
              <MenuItem value={8}>Last 8 months</MenuItem>
              <MenuItem value={12}>Last 12 months</MenuItem>
            </Select>
          </FormControl> */}
          <ToggleButton
            sx={{ display: { lg: 'flex', xs: 'none' } }}
            size="small"
            value="check"
            selected={largeView}
            onChange={() => {
              setLargeView(!largeView);
            }}>
            <GridViewIcon />
          </ToggleButton>
          <IconButton
            onClick={toggleFullScreen}
            sx={{
              display: { lg: 'flex', xs: 'none' },
              position: 'absolute',
              top: 5,
              right: 5,
            }}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          component={Paper}
          justifyContent="center"
          alignItems="center"
          height={{ xs: '30vh', md: '50vh' }}
          gap={2}>
          <CircularProgress />
          <Typography color="text.disabled">
            Loading{' '}
            <b>
              {territory.name} {territoryType.name}
            </b>{' '}
            Data
          </Typography>
        </Stack>
      ) : (
        <>
          {Object.keys(territory).length === 0 ? (
            <Stack
              component={Paper}
              height={{ xs: '30vh', md: '50vh' }}
              justifyContent="center"
              alignItems="center">
              <Typography fontSize={{ xs: 18, md: 35 }} fontWeight={800}>
                Please Select a {territoryType.name}
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack direction={{ lg: largeView ? 'column' : 'row' }} gap={2}>
                {/* TOTAL SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOTAL SKUS (ctn) */}
                    {data?.hqTotalSKU?.message} <sub>(ctn)</sub>
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTotalSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTotalSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTotalSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
                {/* TOTAL COLLECTION */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOTAL COLLECTION (tk) */}
                    {data?.hqTotalCollection?.message} <sub>(ctn)</sub>
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTotalCollection?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTotalCollection?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 20,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTotalCollection?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              <Stack direction={{ lg: largeView ? 'column' : 'row' }} gap={2}>
                {/* TOP 7 SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOP 7 SKUS (ctn) */}
                    {data?.topSKU?.message} <sub>(ctn)</sub>
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.topSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.topSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="productCode"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.topSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={SKUCOLORS[index % SKUCOLORS.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
                {/* BOTTOM 7 SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* BOTTOM 7 SKUS (ctn) */}
                    {data?.bottomSKU?.message} <sub>(ctn)</sub>
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.bottomSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.bottomSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="productCode"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.bottomSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              {/* SKU PIE CHART RUNNING MONTH */}
              <Paper sx={{ p: 2 }}>
                <Stack direction={{ md: 'row' }} justifyContent="space-between">
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* SKU PIE CHART RUNNING MONTH */}
                    {data?.totalSaleCurrentMonth.message}
                  </Typography>
                  <Box sx={{ width: { md: 250 } }}>
                    {data?.totalSaleCurrentMonth?.data.length === 0 ? null : (
                      <Slider
                        color="secondary"
                        value={rangeValue}
                        onChange={(event, newValue) => {
                          setRangeValue(newValue);
                        }}
                        valueLabelDisplay="on"
                        // marks={pieInfo}
                        max={
                          data?.totalSaleCurrentMonth?.prices[
                            data?.totalSaleCurrentMonth?.prices.length - 1
                          ]
                        }
                      />
                    )}
                  </Box>
                </Stack>
                <ResponsiveContainer width="100%" height={400}>
                  {data?.totalSaleCurrentMonth?.data.length === 0 ? (
                    <NoData />
                  ) : (
                    <PieChart width={400} height={300}>
                      <Pie
                        data={productDataList}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={(item) => {
                          return `${item.payload.productCode} ${item.payload.price}tk ${item.payload.totalSale} ctn ${item?.payload?.percent}% `;
                        }}
                        outerRadius={matches_sm ? 50 : 150}
                        fill="#8884d8"
                        dataKey="totalSale">
                        {data?.totalSaleCurrentMonth?.data.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ),
                        )}
                      </Pie>
                    </PieChart>
                  )}
                </ResponsiveContainer>
              </Paper>

              <Stack direction={{ xl: largeView ? 'column' : 'row' }} gap={2}>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography fontSize={18} fontWeight={700} textAlign="center">
                    NUMBER OF TRANSACTIONS {timeline} MONTHS
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTransactionQuantity?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTransactionQuantity?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="month"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTransactionQuantity?.allHq.map(
                          (item, index) => (
                            <Bar
                              dataKey={item}
                              fill={COLORS2[index % COLORS2.length]}>
                              <LabelList
                                dataKey={item}
                                position="top"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                          ),
                        )}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>

                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography fontSize={18} fontWeight={700} textAlign="center">
                    TRANSACTIONS AMOUNT (tk)
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTransactionAmount?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTransactionAmount?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 20,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTransactionAmount?.months?.map(
                          (item, index) => (
                            <Bar
                              dataKey={item}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ),
                        )}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              <Stack gap={2}>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase"
                    pb={1}>
                    {/* {data?.totalSKUCurrentMonth?.message} */}
                    TOTAL SKU {dayjs(date).format('MMMM YYYY')}
                  </Typography>
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>HQ</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product) => (
                              <TableCell>{product}</TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      {/* ---------- */}
                      <TableBody>
                        {data?.totalSKUCurrentMonth?.data.length === 0 ? (
                          <TableRow>
                            <TableCell style={{ padding: 40 }}>
                              <NoData />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data?.totalSKUCurrentMonth?.data?.map((item) => (
                              <TableRow>
                                <TableCell>{item?.location}</TableCell>
                                {data?.totalSKUCurrentMonth?.products?.map(
                                  (product) => (
                                    <TableCell>{item?.[product]} ctn</TableCell>
                                  ),
                                )}
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                      <TableFooter sx={{ td: { fontWeight: 'bold' } }}>
                        <TableRow>
                          <TableCell>Total Quantity</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .totalSale
                                }
                                {' ctn'}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Value</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .totalAmount
                                }
                                {' tk'}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Contribution</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .percent
                                }
                                %
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase"
                    pb={1}>
                    COLLECTION OVERVIEW {dayjs(date).format('MMMM YYYY')}
                  </Typography>
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>HQ</TableCell>
                          <TableCell>Lifting Quantity</TableCell>
                          <TableCell>Lifting Value</TableCell>
                          <TableCell>Collection Entry</TableCell>
                          <TableCell>Approved Collection</TableCell>
                          <TableCell>Online Charge</TableCell>
                          <TableCell>Incentive</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.collectionTableData?.data.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7} height={150} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data?.collectionTableData?.data?.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    {item.location}
                                  </TableCell>
                                  <TableCell>
                                    {item.liftingQuantity} ctn
                                  </TableCell>
                                  <TableCell>{item.liftingValue} tk</TableCell>
                                  <TableCell>
                                    {item.collectionEntry} tk
                                  </TableCell>
                                  <TableCell>
                                    {item.approvedCollection} tk
                                  </TableCell>
                                  <TableCell>{item.OnlineCharge} tk</TableCell>
                                  <TableCell>{item.Incentive} tk</TableCell>
                                </TableRow>
                              ),
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
}

const tableStyle = {
  table: {
    tr: {
      th: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
        '&:first-child': {
          fontSize: 16,
          fontWeight: 600,
        },
      },

      td: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
      },
    },
    thead: {
      'tr:last-child': {
        th: {
          background: (theme) => theme.palette.primary.main,
          color: '#fff',
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
};

function NoData() {
  return (
    <Stack justifyContent="center" alignItems="center" height={1} width={1}>
      <Typography>No Data Found</Typography>
    </Stack>
  );
}
