import React from 'react';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { Box, Stack, Tooltip } from '@mui/material';
import { offerTypeLabels, unitLabels } from '../../../staticData/ProductUnits';
import roundToDigit from '../../../utils/roundToDigit';
import { keepKeys, deleteKeys } from '../../../utils/trimObject';

const commonKeys = [
  'depotPrice',
  'dealerPrice',
  'dsrPrice',
  'tradePrice',
  'maxRetailPrice',
  'offerType',
  'freeItemsPerCarton',
  'minimumOrderQuantityForOffer',
  'giftItemDescription',
];

function ProductExpandRow({ data, isMarketPrice }) {
  let product = data;

  if (isMarketPrice) {
    product = {
      ...deleteKeys(data?.product, commonKeys),
      ...keepKeys(data, commonKeys),
    };
  }

  const qpc = parseFloat(product?.quantityPerCarton);
  const dsrp = parseFloat(product?.dsrPrice);
  const dp = parseFloat(product?.dealerPrice);
  const tp = parseFloat(product?.tradePrice);

  return (
    <>
      <Box sx={{ width: { md: '50%', xs: '100%' } }}>
        <div className="ExpandTableMain">
          <caption style={caption}>Product Details</caption>
          <table className="details-table" cellSpacing="0">
            <tr>
              <td>
                <b>Active status</b>
              </td>
              <td>{product?.isActive ? 'Active' : 'Inactive'}</td>
            </tr>
            <tr>
              <td>
                <b>Company</b>
              </td>
              <td>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                  {product?.productCompany?.productCompany}
                  &ensp;
                  {product?.isOwn ? (
                    <Tooltip title="Own company" placement="bottom-end" arrow>
                      <FileDownloadDoneIcon fontSize="small" color="success" />
                    </Tooltip>
                  ) : null}
                </Stack>
              </td>
            </tr>
            <tr>
              <td>
                <b>Category</b>
              </td>
              <td>{product?.productCategory?.productCategory}</td>
            </tr>
            <tr>
              <td>
                <b>Name</b>
              </td>
              <td>{product?.name}</td>
            </tr>
            <tr>
              <td>
                <b>Code</b>
              </td>
              <td>{product?.code}</td>
            </tr>
            <tr>
              <td>
                <b>Quantity per carton</b>
              </td>
              <td>{product?.quantityPerCarton}</td>
            </tr>
            <tr>
              <td>
                <b>Unit of measurement</b>
              </td>
              <td>
                {unitLabels[product?.unitOfMeasurement]} (
                {product?.unitOfMeasurement})
              </td>
            </tr>
            <tr>
              <td>
                <b>
                  {unitLabels[product?.unitOfMeasurement] || 'Units'} per packet
                </b>
              </td>
              <td>{product?.unitPerPacket}</td>
            </tr>
            <tr>
              <td>
                <b>Net weight per packet</b>
              </td>
              <td>{product?.weightPerPacket}</td>
            </tr>
            <tr>
              <td>
                <b>DSR price per carton</b>
              </td>
              <td>{roundToDigit(dsrp * qpc)}</td>
            </tr>
            <tr>
              <td>
                <b>Dealer price per carton</b>
              </td>
              <td>{roundToDigit(dp * qpc)}</td>
            </tr>
            <tr>
              <td>
                <b>Trade price per carton</b>
              </td>
              <td>{roundToDigit(tp * qpc)}</td>
            </tr>
            <tr>
              <td>
                <b>Max retail price per packet</b>
              </td>
              <td>{product?.maxRetailPrice}</td>
            </tr>
            <tr>
              <td>
                <b>Offer type</b>
              </td>
              <td>{offerTypeLabels[product?.offerType]}</td>
            </tr>
            {product.offerType === 'free' && (
              <>
                <tr>
                  <td>
                    <b>Free items per carton</b>
                  </td>
                  <td>{product?.freeItemsPerCarton}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Minimum order quantity
                      <br />
                      for 1 ctn free
                    </b>
                  </td>
                  <td>{product?.minimumOrderQuantityForOffer} ctn</td>
                </tr>
              </>
            )}
            {product.offerType === 'gift' && (
              <>
                <tr>
                  <td>
                    <b>Gift item description</b>
                  </td>
                  <td>{product?.giftItemDescription}</td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Minimum order quantity
                      <br />
                      for 1 gift item
                    </b>
                  </td>
                  <td>{product?.minimumOrderQuantityForOffer} ctn</td>
                </tr>
              </>
            )}
            <tr>
              <td>
                <b>Product description</b>
              </td>
              <td>{product?.description}</td>
            </tr>
            {product?.photos?.length ? (
              <tr>
                <td colSpan={2}>
                  <b>Photos</b>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 10,
                      gap: 10,
                    }}>
                    {product?.photos.map((photo, index) => (
                      <div
                        key={`${product?.id}-${index}`}
                        style={{ maxWidth: '250px', maxHeight: '200px' }}>
                        <img
                          src={photo}
                          alt={`${product?.name} photo ${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <b>Photos</b>
                </td>
                <td>No photos</td>
              </tr>
            )}
          </table>
        </div>
      </Box>
    </>
  );
}

export default ProductExpandRow;

const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: '#1976d2',
  padding: '5px',
};
