import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import styles from '../../assets/css/profile.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useAuth from '../../hooks/useAuth';
// import profileImg from '../../assets/images/person.png';

function EditProfile({ setPage, user, refetch }) {
  const { role } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const data = new FormData();
  const matches = useMediaQuery('(min-width:900px)');
  const [image, setImage] = useState();
  // form data states
  const [name, setName] = useState(user?.employee?.name);
  const [dob, setDob] = useState(user?.employee?.dateOfBirth);
  const [nid, setNid] = useState(user?.employee?.nationalID);
  const [edu, setEdu] = useState(user?.employee?.educationalQualification);
  const [presentAddress, setPresentAddress] = useState(
    user?.employee?.presentAddress,
  );
  const [permanentAddress, setPermanentAddress] = useState(
    user?.employee?.permanentAddress,
  );
  const [bankName, setBankName] = useState(user?.employee?.bankName);
  const [accNo, setAccNo] = useState(user?.employee?.bankAccountNumber);
  // const [facebook, setFacebook] = useState();
  // const [twitter, setTwitter] = useState('');
  // const [linkedIn, setLinkedIn] = useState('');
  // const [whatsApp, setWhatsApp] = useState('');
  // const total = parseInt(salary) + parseInt(allowance);
  const [isDateError, setIsDateError] = useState(false);
  // form data states

  const handleSubmit = () => {
    window.scrollTo(0, 0);
    setPage(1);
    data.append('name', name);
    if (dob) data.append('dateOfBirth', dob);
    data.append('nationalID', nid);
    data.append('educationalQualification', edu);
    data.append('presentAddress', presentAddress);
    data.append('permanentAddress', permanentAddress);
    data.append('bankName', bankName);
    data.append('bankAccountNumber', accNo);
    if (image instanceof File) data.append('avatar', image);

    axiosPrivate
      .post(`/api/v1/user/me`, data)
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        // window.location.reload(false);
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Box sx={{ width: { md: '600px' } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={5}>
            <div className={styles.avatarImg}>
              <img
                src={
                  image ? URL.createObjectURL(image) : user?.employee?.avatar
                }
                alt={user?.employee?.name}
                width="100%"
                height="100%"
              />

              <Paper className={styles.editIcon} sx={{ borderRadius: '50%' }}>
                <div style={{ position: 'relative' }}>
                  <IconButton sx={{ cursor: 'pointer' }}>
                    <EditIcon />
                  </IconButton>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(event) => setImage(event.target.files[0])}
                  />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item sm={7} xs={12}>
            <Box>
              <Typography
                sx={{ typography: { sm: 'h4', xs: 'h5' } }}
                color="grey.800">
                UPLOAD PROFILE IMAGE
              </Typography>
              <p className={styles.profileTitle}>prefered size 400 x 400</p>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ margin: '40px 0px' }} />
        {role === 'Super Admin' && (
          <>
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ width: { md: '600px' } }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ typography: { sm: 'h4', xs: 'h5' } }}
                  color="primary.main">
                  Basic Information
                </Typography>
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Name</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Name' : ''}
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              {/* <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Email</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Email' : ''}
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid> */}
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>NID</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'NID' : ''}
                  fullWidth
                  value={nid}
                  onChange={(e) => setNid(e.target.value)}
                />
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Date Of Birth</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={!matches ? 'Date Of Birth' : ''}
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    value={dob}
                    onError={(reason) => setIsDateError(Boolean(reason))}
                    onChange={(newValue) => setDob(newValue)}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Education Qualification</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Education Qualification' : ''}
                  fullWidth
                  value={edu}
                  onChange={(e) => setEdu(e.target.value)}
                />
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Present Address</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Present Address' : ''}
                  fullWidth
                  value={presentAddress}
                  onChange={(e) => setPresentAddress(e.target.value)}
                />
              </Grid>

              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Permanent Address</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Permanent Address' : ''}
                  fullWidth
                  value={permanentAddress}
                  onChange={(e) => setPermanentAddress(e.target.value)}
                />
              </Grid>
            </Grid>

            <Divider sx={{ margin: '40px 0px' }} />
            <Grid
              container
              spacing={2}
              alignItems="center"
              sx={{ width: { md: '600px' } }}>
              <Grid item xs={12}>
                <Typography
                  sx={{ typography: { sm: 'h4', xs: 'h5' } }}
                  color="primary.main">
                  Salary Information
                </Typography>
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Bank Name</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Bank Name' : ''}
                  fullWidth
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </Grid>
              <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <p className={styles.levelName}>Account No</p>
              </Grid>
              <Grid item md={7} sm={12}>
                <TextField
                  size="small"
                  label={!matches ? 'Account No' : ''}
                  fullWidth
                  value={accNo}
                  onChange={(e) => setAccNo(e.target.value)}
                />
              </Grid>
            </Grid>
            <Divider sx={{ margin: '40px 0px' }} />
            {/* <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ width: { md: '600px' } }}>
          <Grid item xs={12}>
            <Typography
              sx={{ typography: { sm: 'h4', xs: 'h5' } }}
              color="primary.main">
              Social Media Information
            </Typography>
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Facebook</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Facebook' : ''}
              fullWidth
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Twitter</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Twitter' : ''}
              fullWidth
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>LinkedIn</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'LinkedIn' : ''}
              fullWidth
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>What's App</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? "What's App" : ''}
              fullWidth
              value={whatsApp}
              onChange={(e) => setWhatsApp(e.target.value)}
            />
          </Grid>
        </Grid> */}
          </>
        )}
        <Stack justifyContent="end" direction="row" mt={5}>
          <Button
            disabled={isDateError}
            variant="contained"
            onClick={handleSubmit}>
            Save
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default EditProfile;
