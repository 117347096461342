import { Box, styled } from '@mui/material';

export const SettingsContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  gap: '20px',
  background: '#fff',
  '& .SettingsLeft': {
    borderRight: '1px solid #8080803b',
    background: 'white',
    overflow: 'hidden',
    minWidth: 'fit-content',
    padding: '20px 0px',
    '& .MuiList-root': {
      padding: 0,
      paddingLeft: '10px',
      paddingRight: '10px',

      '& .MuiListItem-root': {
        marginTop: '5px',
        padding: 0,
        color: '#585858',
        overflow: 'hidden',
        '&:hover': {
          background: `#1976d221`,
          color: `${theme.palette.primary.main}`,
          borderRadius: '7px',
        },

        '&.active': {
          color: `${theme.palette.primary.main}`,
          background: `#1976d221`,
          borderRadius: '7px',
        },
        '&.active .MuiListItemIcon-root': {
          color: `${theme.palette.primary.main}`,
        },
        '& .MuiListItemIcon-root': {
          minWidth: '35px',
        },
        '& .MuiTypography-root': {
          fontWeight: 500,
        },
        // '& .MuiButtonBase-root': {
        //   margin: '0px 10px',
        // },
      },
    },
  },
  '& .SettingsRight': {
    padding: '20px',
    width: '100%',
    overflow: 'hidden',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& .SettingsLeft': {
      minWidth: 'initial',
      '& .MuiList-root': {
        paddingBottom: '10px',
        display: 'flex',
        overflow: 'auto',
        '& .MuiListItem-root': {
          minWidth: 'fit-content',
        },
      },
    },
  },
}));
