import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function TadaInformation() {
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location?.pathname === '/tada-summary') {
      setValue(0);
    }
    if (location?.pathname === '/tada-details') {
      setValue(1);
    }
  }, [location?.pathname]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value}>
          <Tab label="Summary" component={Link} to={'/tada-summary'} />
          <Tab label="Details" component={Link} to={'/tada-details'} />
        </Tabs>
      </Box>
      <Box pt={3}>
        <Outlet />
      </Box>
    </Box>
  );
}

// import { useState } from 'react';
// import { Box, Stack } from '@mui/material';
// import ActiveSwitch from '../../../../component/input/ActiveSwitch';

// export default function TadaInformation() {
//   const [tab, setTab] = useState(true);
//   return (
//     <Box>
//       <Stack direction="row" justifyContent="center" pb={2}>
//         <ActiveSwitch
//           primaryText="Details"
//           secondaryText="Summary"
//           color="primary"
//           value={tab}
//           setValue={setTab}
//         />
//       </Stack>
//       {tab ? <TadaDetails /> : <TadaSummary />}
//     </Box>
//   );
// }
