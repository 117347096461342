import React from 'react';
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import useAuth from '../hooks/useAuth';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

export default function ProfileCard({ login }) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [logInfo, setLogInfo] = React.useState(
    JSON.parse(localStorage.getItem('LoginInfo')) || '',
  );
  const refreshToken = localStorage.getItem('refreshToken');
  const today = Date.now();

  // get profiles
  const handleProfileInfo = (item) => {
    localStorage.setItem('refreshToken', item?.refreshToken);
    navigate('/');
    window.location.reload(true);
  };

  // delete profile
  const handleDeleteProfile = (key) => {
    const newLists = { ...logInfo };
    delete newLists[key];
    localStorage.setItem('LoginInfo', JSON.stringify(newLists));
    setLogInfo(newLists);
  };

  return (
    <>
      {Object.entries(logInfo).map(([key, item]) => {
        // console.log(item.refreshExpire);
        if (user?.phone === key && !login) {
          return null;
        }
        return (
          <ListItem
            key={key}
            disablePadding
            secondaryAction={
              <>
                {login && refreshToken === item?.refreshToken ? null : (
                  <IconButton
                    disabled={today > item.refreshExpire}
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteProfile(key)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </>
            }>
            <ListItemButton
              disabled={today > item.refreshExpire}
              onClick={() => handleProfileInfo(item)}>
              <ListItemIcon>
                <Box position="relative">
                  <Avatar src={item?.avatar} />
                  {refreshToken === item?.refreshToken && (
                    <Box sx={styles.activeDot}></Box>
                  )}
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {item?.name}{' '}
                    <Typography sx={{ display: 'inline', fontSize: 12 }}>
                      ({item?.role})
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography sx={{ fontSize: 12 }}>
                      <LocationOnIcon
                        sx={{
                          position: 'relative',
                          top: 1,
                          fontSize: 13,
                          marginRight: 1,
                        }}
                      />
                      {item?.territoryType}: {item?.territory}
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      <EmailIcon
                        sx={{
                          position: 'relative',
                          top: 2,
                          fontSize: 13,
                          marginRight: 1,
                        }}
                      />
                      {item?.email}
                    </Typography>
                  </>
                }
              />
            </ListItemButton>
            {today > item.refreshExpire && (
              <Typography sx={styles.expired}>Session Expired</Typography>
            )}
          </ListItem>
        );
      })}
    </>
  );
}

const styles = {
  expired: {
    color: 'grey',
    background: '#D3D3D3',
    fontSize: 12,
    borderRadius: 10,
    p: 0.5,
    width: 'auto',
    position: 'absolute',
    left: 2,
    bottom: 2,
    opacity: 0.6,
  },
  active: {
    color: '#fff',
    background: '#008000a3',
    fontSize: 12,
    borderRadius: 10,
    p: 0.5,
    width: 'auto',
    position: 'absolute',
    left: 2,
    bottom: 2,
  },
  activeDot: {
    width: 10,
    height: 10,
    background: 'green',
    borderRadius: '50%',
    position: 'absolute',
    right: 0,
    bottom: -2,
    border: '2px solid #fff',
  },
};
