import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import img from '../assets/images/delete.svg';

function ConfirmModal(props) {
  return (
    <Dialog open={props.show} onClose={props.onHide} maxWidth="xs">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent sx={{ textAlign: 'center' }}>
        {props.actionButtonLabel.toLocaleLowerCase() === 'delete' ? (
          <picture>
            <img src={img} alt="" style={{ maxWidth: '150px' }} />
          </picture>
        ) : null}

        <Typography>{props.subtitle}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={props.onSave}
          color={props.actionButtonColor}>
          {props.actionButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
