import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContainer, InnerContainer } from '../../assets/style/auth';
import FloatCIrcle from '../../component/FloatCIrcle';
import Logo from '../../component/auth/Logo';
import useAxios from '../../hooks/useAxios';

function SignUp() {
  const axios = useAxios();
  const navigate = useNavigate();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    if (showPassword === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };

  const pwd = watch('password');

  const onSubmit = (data) => {
    axios
      .post('/api/v1/auth/signup', data)
      .then((response) => {
        toast.success(response?.data?.message);
        navigate('/login');
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  return (
    <>
      <AuthContainer>
        <FloatCIrcle />

        <Box mx={2}>
          <Logo />
          <InnerContainer>
            <Typography variant="h2">Create New Account</Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                sx={{ mt: 2 }}
                id="standard-basic1"
                type="text"
                label="Full Name"
                variant="standard"
                fullWidth
                {...register('name', {
                  required: 'Required field',
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
              />
              <TextField
                sx={{ mt: 2 }}
                id="standard-basic1"
                type="email"
                label="Email Address"
                variant="standard"
                fullWidth
                {...register('email', {
                  required: 'Required field',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Inavild email address',
                  },
                })}
                error={!!errors?.email}
                helperText={errors?.email ? errors.email.message : null}
              />
              <TextField
                sx={{ mt: 2 }}
                id="standard-basic2"
                type="number"
                label="Phone Number"
                variant="standard"
                fullWidth
                {...register('phone', {
                  required: 'Required field',
                  maxLength: {
                    value: 11,
                    message: 'Phone number should be 11 digit',
                  },
                  minLength: {
                    value: 11,
                    message: 'Phone number should be 11 digit',
                  },
                })}
                error={!!errors?.phone}
                helperText={errors?.phone ? errors.phone.message : null}
              />
              <FormControl
                variant="standard"
                sx={{ mt: 2 }}
                fullWidth
                error={!!errors.password}>
                <InputLabel>Password</InputLabel>
                <Input
                  name="password"
                  id="standard-adornment-password1"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password', {
                    required: 'Required field',
                    pattern: {
                      value:
                        /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/,
                      message:
                        'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                    },
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityOff
                            color={!!errors.password ? 'error' : 'grey'}
                          />
                        ) : (
                          <Visibility
                            color={!!errors.password ? 'error' : 'grey'}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!errors.password ? (
                  <FormHelperText>{errors.password.message}</FormHelperText>
                ) : null}
              </FormControl>

              <FormControl
                variant="standard"
                sx={{ mt: 2 }}
                fullWidth
                error={!!errors.confirmPassword}>
                <InputLabel>Confirm Password</InputLabel>
                <Input
                  id="standard-adornment-password2"
                  type={showPassword ? 'text' : 'password'}
                  {...register('confirmPassword', {
                    required: 'Required field',
                    validate: (value) =>
                      value === pwd || 'password do not match',
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <VisibilityOff
                            color={!!errors.confirmPassword ? 'error' : 'grey'}
                          />
                        ) : (
                          <Visibility
                            color={!!errors.confirmPassword ? 'error' : 'grey'}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!errors.confirmPassword ? (
                  <FormHelperText>
                    {errors.confirmPassword.message}
                  </FormHelperText>
                ) : null}
              </FormControl>

              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: '40px' }}>
                  Sign Up
                </Button>
              </Box>
            </form>

            <Typography
              component={Link}
              to="/login"
              color="primary"
              display="block"
              pt={2}>
              Already have an account? Login here
            </Typography>
          </InnerContainer>
        </Box>
      </AuthContainer>
    </>
  );
}

export default SignUp;
