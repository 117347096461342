import { Box, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export default function Card(props) {
  const { title, icon, url, unaccessible } = props.info;
  const { role } = useAuth();
  return (
    <Stack
      style={{
        display: unaccessible?.includes(role) ? 'none' : 'flex',
      }}
      component={Link}
      to={url}
      state={{ title: title }}
      sx={cardStyles}>
      <Typography
        variant="body1"
        textTransform={'uppercase'}
        sx={{ fontSize: '14px' }}>
        {title}
      </Typography>
      <Box sx={{ color: 'primary.main', fontSize: '40px' }}>{icon}</Box>
    </Stack>
  );
}

// style
const cardStyles = {
  flexDirection: 'row',
  bgcolor: '#fff',
  color: '#000',
  p: 2,
  textDecoration: 'none',
  borderRadius: 2,
  width: {
    sm: '200px',
    xs: '100%',
  },
  justifyContent: 'space-between',
  boxShadow: 1,
  cursor: 'pointer',
  transition: '.3s',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: '#fff',
    '& svg': { color: '#fff' },
  },
};
