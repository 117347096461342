import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import roundToDigit from '../../utils/roundToDigit';
import dayjs from 'dayjs';

const typeOptions = [
  'basic',
  'travel',
  'mobile',
  'internet',
  'incentive',
  'commission',
  'bonus',
  'cost',
  'non compliance',
  'absence',
  'salary',
];

export default function ModifyMultipleParollTicket() {
  const matches_sm = useMediaQuery('(max-width:600px)');
  const axiosPrivate = useAxiosPrivate();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const employeeList = location.state.employees;
  const [navigateUrl, setNavigateUrl] = useState('');

  const employeeArray = [];
  employeeList.forEach((employee) =>
    employeeArray.push({
      name: employee?.name,
      role: employee?.role?.employeeRole,
      territoryType: employee?.territory?.territoryType?.territoryType,
      territoryName: employee?.territory?.name,
      salaryBasic: employee?.salaryBasic,
      salaryMobile: employee?.salaryMobile,
      salaryInternet: employee?.salaryInternet,
      attendanceMethod: employee?.role?.attendanceMethod,
      minimumCollection: employee?.minimumCollection,
      salaryPercent: employee?.salaryPercent,
      travelAllowancePercent: employee?.travelAllowancePercent,
      elements: [{ type: 'basic', entryAmount: 0, remarks: '' }],
    }),
  );

  // form state
  const [isDateError, setIsDateError] = useState(false);
  const [dateOfEntry, setDateOfEntry] = useState(new Date());
  const [form, setForm] = useState(employeeArray);

  const addElement = (employeeIndex) => {
    const updatedEmployes = [...form];
    updatedEmployes[employeeIndex].elements.push({
      type: '',
      entryAmount: 0,
      remarks: '',
    });
    setForm(updatedEmployes);
  };

  const removeElement = (employeeIndex, elementIndex) => {
    const updatedEmployee = [...form];
    updatedEmployee[employeeIndex].elements.splice(elementIndex, 1);
    setForm(updatedEmployee);
  };

  const handleElementChange = (employeeIndex, elementIndex, field, value) => {
    const updatedEmployee = [...form];
    updatedEmployee[employeeIndex].elements[elementIndex][field] = value;
    setForm(updatedEmployee);
  };

  useEffect(() => {
    const d = query.get('dateOfEntry');
    const redirect = query.get('redirect');

    if (d) {
      setDateOfEntry(dayjs(d, 'YYYY-MM-DD'));
    }
    if (redirect) {
      setNavigateUrl(redirect);
    } else {
      setNavigateUrl('/payroll-report');
    }
  }, [query]);

  const handleSubmit = () => {
    const data = [];
    form.forEach((employee) =>
      employee.elements.forEach((element) =>
        data.push({
          dateOfEntry: dateOfEntry,
          employee: employee.employee,
          ...element,
        }),
      ),
    );

    console.log(data);
    navigate(navigateUrl);

    // axiosPrivate
    //   .post('/api/v1/employee-ledger')
    //   .then((res) => {
    //     toast.success(res?.data?.message);
    //   })
    //   .catch((error) => toast.warn(error?.response?.data?.message));
    // .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" pb={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            // disabled={isLoading}
            disableFuture
            label="Issue Date"
            fullWidth
            inputFormat="DD/MM/YYYY"
            value={dateOfEntry}
            onError={(reason) => setIsDateError(Boolean(reason))}
            onChange={(e) => setDateOfEntry(e.target.value)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Stack>

      <Stack direction="column" gap={2}>
        {form?.map((employee, employeeIndex) => (
          <Stack
            component={Paper}
            p={{ md: 4, xs: 2 }}
            direction={{ lg: 'row' }}
            alignItems="start"
            gap={2}
            fullWidth>
            {/* left part */}
            <Stack gap={2}>
              <Typography
                sx={{
                  width: { md: 500, xs: 1 },
                  textAlign: { md: 'start', xs: 'center' },
                }}
                fontWeight={700}
                fontSize={17}>
                {employee.name}
              </Typography>
              <table>
                <tr>
                  <td>Role:</td>
                  <td>
                    <b>{employee?.role}</b>
                  </td>
                  <td>{employee?.territoryType}:</td>
                  <td>
                    <b>{employee?.territoryName}</b>
                  </td>
                </tr>
                <tr>
                  <td>Basic Salary:</td>
                  <td>
                    <b>{employee?.salaryBasic}</b>
                  </td>
                  <td>Per Day Basic:</td>
                  <td>
                    <b>
                      {roundToDigit(
                        parseFloat(employee?.salaryBasic) /
                          dayjs(dateOfEntry).daysInMonth(),
                        3,
                      )}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>Mobile Allowance:</td>
                  <td>
                    <b>{employee?.salaryMobile}</b>
                  </td>
                  <td>Internet Allowance:</td>
                  <td>
                    <b>{employee?.salaryInternet}</b>
                  </td>
                </tr>
                {employee?.attendanceMethod === 'representative' ? (
                  <tr>
                    <td>Minimum Collection:</td>
                    <td>
                      <b>{employee?.minimumCollection}</b>
                    </td>
                    <td>Salary & TA Percent:</td>
                    <td>
                      <b>
                        {employee?.salaryPercent}%,{' '}
                        {employee?.travelAllowancePercent}%
                      </b>
                    </td>
                  </tr>
                ) : null}
              </table>
            </Stack>
            {/* right part */}
            <Stack gap={2} sx={{ width: '100%' }}>
              {employee.elements.map((element, elementIndex) => (
                <Stack
                  direction={'row'}
                  gap={2}
                  sx={{ width: '100%' }}
                  flexWrap={{ sm: 'initial', xs: 'wrap' }}>
                  <FormControl
                    sx={{ width: { sm: 1, xs: 'calc(50% - 8px)' } }}
                    size="small">
                    <InputLabel>Select Type</InputLabel>
                    <Select
                      value={element?.type}
                      label="Select Type"
                      onChange={(e) =>
                        handleElementChange(
                          employeeIndex,
                          elementIndex,
                          'type',
                          e.target.value,
                        )
                      }>
                      {typeOptions?.map((type, typeIndex) => (
                        <MenuItem key={typeIndex} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Issue Amount"
                    size="small"
                    sx={{ width: { sm: 1, xs: 'calc(50% - 8px)' } }}
                    value={element.entryAmount}
                    onChange={(e) =>
                      handleElementChange(
                        employeeIndex,
                        elementIndex,
                        'entryAmount',
                        e.target.value,
                      )
                    }
                  />
                  <TextField
                    fullWidth
                    label="Remarks"
                    size="small"
                    value={element.remarks}
                    onChange={(e) =>
                      handleElementChange(
                        employeeIndex,
                        elementIndex,
                        'remarks',
                        e.target.value,
                      )
                    }
                  />
                  {!matches_sm ? (
                    <IconButton
                      onClick={() =>
                        removeElement(employeeIndex, elementIndex)
                      }>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  ) : (
                    <>
                      <Button
                        fullWidth
                        color="error"
                        variant="outlined"
                        startIcon={<DeleteForeverIcon color="error" />}
                        onClick={() =>
                          removeElement(employeeIndex, elementIndex)
                        }>
                        Remove
                      </Button>
                      <Divider sx={{ width: 1 }} />
                    </>
                  )}
                </Stack>
              ))}

              <Stack direction={{ sm: 'row' }} justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => addElement(employeeIndex)}>
                  Add
                </Button>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>

      <Stack direction="row" justifyContent="center" py={4}>
        <Button
          disabled={isDateError}
          variant="contained"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </>
  );
}
