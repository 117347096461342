import { useEffect, useState } from 'react';

const useGetCurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    async function fetchData() {
      // You can await here
      await fetch('http://worldtimeapi.org/api/timezone/Asia/Dhaka')
        .then((response) => response.json())
        .then((json) => {
          setCurrentDate(json?.datetime);
        });
    }
    fetchData();
  }, []);

  return { currentDate };
};

export default useGetCurrentDate;
