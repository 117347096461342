import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Card,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modals from '../../component/Modals';
import ProductSkeleton from '../../component/Skeleton/ProductSkeleton';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import roundToDigit from '../../utils/roundToDigit';
import CustomerProfileHeader from '../Customer/AllCustomer/CustomerProfileHeader/CustomerProfileHeader';

function DamClaimModal({ showmodal, setshowmodal, editinfo, refetch }) {
  const param = useParams();
  const cID = param?.customerID;
  const axiosPrivate = useAxiosPrivate();
  const { userId } = useAuth();
  const edit = editinfo;

  const [productsList, setProductsList] = React.useState([]);
  const [claimedAmount, setClaimedAmount] = useState(0);

  const [trigger, setTrigger] = React.useState(0);

  const handleSubmit = () => {
    const products = [];

    productsList?.forEach((p) => {
      if (p.intactQuantity > 0 || p.damagedQuantity > 0) {
        products.push({
          product: p.product.id,
          damagedQuantity: p.damagedQuantity,
          damageDescription: p.damageDescription,
        });
      }
    });

    console.log(products?.length);

    if (!products?.length) {
      return toast.warn('Nothing to update!');
    }

    const data = {
      customer: cID,
      recordedBy: userId,
      claimedAmount,
      claimedAt: new Date(),
      products,
    };

    axiosPrivate({
      method: edit ? 'patch' : 'post',
      url: edit ? `/api/v1/damage/${edit?.id}` : '/api/v1/damage',
      data,
    })
      .then((res) => {
        toast.success(res?.data?.message);
        setshowmodal(false);
        refetch();
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };

  const handleChange = (id, type, value) => {
    if (!value) return;

    const products = [];
    productsList?.forEach((p) => {
      if (p.product.id === id) {
        switch (type) {
          case 'damagedQuantity':
            if (value > 0) p.damagedQuantity = value;
            else p.damagedQuantity = 0;
            break;
          case 'damageDescription':
            if (value) p.damageDescription = value;
            break;
          default:
            break;
        }
      }
      products.push(p);
    });
    setProductsList(products);
    setTrigger(Date.now());
  };

  // Get Customer's stock by ID
  React.useEffect(() => {
    if (edit?.products?.length > 0) {
      setProductsList(edit.products);
      setTrigger(Date.now());
    } else {
      axiosPrivate
        .get(
          `/api/v1/customer/${cID}/stock?type=opening&type=physical&type=system`,
        )
        .then((res) => {
          setProductsList(res?.data?.data?.products);
          setTrigger(Date.now());
        })
        .catch((err) => toast.warn(err?.response?.data?.message));
    }
  }, [cID, edit?.products?.length]);

  const productsControl = React.useMemo(() => {
    if (productsList?.length <= 0) {
      return <ProductSkeleton />;
    }
    let v = 0;
    const p = productsList?.map((p, i) => {
      v += p?.damagedQuantity * p?.product?.dealerPrice;
      return (
        <Stack
          key={p.product.id}
          direction="column"
          gap={1}
          p={2}
          component={Card}
          variant="outlined">
          <Typography fontWeight="bold">{`${i + 1}. ${
            p.product?.nameCode
          }`}</Typography>

          <Divider />

          <Stack direction="row" gap={0.5} alignItems="center">
            <Button
              color="error"
              size="small"
              sx={btnWidth}
              onClick={() => {
                const q = p.damagedQuantity - p.product.quantityPerCarton;
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}>
              -1 carton
            </Button>
            <IconButton
              color="error"
              aria-label="remove one"
              onClick={() => {
                const q = p.damagedQuantity - 1;
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}>
              <RemoveIcon />
            </IconButton>
            <TextField
              type="number"
              size="small"
              label="Packets"
              color="error"
              fullWidth
              value={p.damagedQuantity}
              onChange={(e) => {
                const q = parseInt(e.target.value, 10);
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}
            />
            <IconButton
              color="error"
              aria-label="add one"
              onClick={() => {
                const q = p.damagedQuantity + 1;
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}>
              <AddIcon />
            </IconButton>
            <Button
              color="error"
              size="small"
              sx={btnWidth}
              onClick={() => {
                const q = p.damagedQuantity + p.product.quantityPerCarton;
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}>
              +1 carton
            </Button>
            <Button
              color="error"
              size="small"
              sx={btnWidth}
              onClick={() => {
                const q = p.damagedQuantity + p.product.quantityPerCarton * 10;
                handleChange(p.product?.id, 'damagedQuantity', q);
              }}>
              +10 cartons
            </Button>
          </Stack>

          <TextField
            type="text"
            size="small"
            label="Damage description"
            color="error"
            fullWidth
            multiline
            rows={2}
            value={p.damageDescription}
            onChange={(e) => {
              handleChange(p.product?.id, 'damageDescription', e.target.value);
            }}
          />
        </Stack>
      );
    });
    setClaimedAmount(roundToDigit(v));
    return p;
  }, [trigger]);

  return (
    <Modals
      title={edit ? 'Edit Damaged Claim' : 'Create Damaged Claim'}
      show={showmodal}
      onHide={() => setshowmodal(false)}
      form
      width="lg">
      <Stack direction={'column'} gap={2}>
        <CustomerProfileHeader
          cID={edit ? edit?.customer?.id : cID}
          dam_claim
        />

        {productsControl}
        <TextField
          type="number"
          label="Claimed Amount"
          onWheel={(e) => e.target.blur()}
          value={claimedAmount}
          onChange={(e) => setClaimedAmount(e.target.value)}
        />
      </Stack>

      <Stack direction="row" justifyContent="center" pt={3}>
        <Button variant="contained" size="large" onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Modals>
  );
}

export default DamClaimModal;

const btnWidth = { minWidth: 64 };
