import jsPDF from 'jspdf';
import { toast } from 'react-toastify';

const useDownload = () => {
  const downloadPdf = async (id, filename, pagewidth) => {
    const source = document.getElementById(id);
    const windowWidth = document.getElementById(id).offsetWidth;

    const pdf = new jsPDF();

    const addPageNumbers = (pdf, totalPages) => {
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.text(
          5,
          pdf.internal.pageSize.height - 5,
          `Page ${i} of ${totalPages}`,
        );
      }
    };

    await pdf.html(source, {
      callback: function (doc) {
        addPageNumbers(doc, doc.getNumberOfPages());
        // send natigator to share
        doc.save(filename);
        toast.success('File Downloaded Successfully !');
      },
      x: 0,
      y: 0,
      margin: [10, 10, 10, 10],
      autoPaging: 'text',
      width: 190,
      windowWidth: pagewidth
        ? pagewidth
        : windowWidth < 900
        ? windowWidth + 100
        : windowWidth + 400,
    });
  };

  return { downloadPdf };
};

export default useDownload;
