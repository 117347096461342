import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
// import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import roundToDigit from '../../utils/roundToDigit';
import StickyTable from '../../component/Table/StickyTable';

export default function SKUContribution() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [regionList, setRegionList] = useState([]);
  const [hqList, setHqList] = useState([]);
  const [pointList, setPointList] = useState([]);

  const [region, setRegion] = useState({});
  const [hq, setHq] = useState({});
  const [point, setPoint] = useState({});

  const [territory, setTerritory] = useState('63aaa53505e4dc7daa1a86b7');
  const [year, setYear] = useState(new Date());
  const [show, setShow] = useState('percent');

  useEffect(() => {
    axiosPrivate
      .get('/api/v1/territory?limit=none&sort=name')
      .then((res) => {
        const t1 = [];
        const t2 = [];
        const t3 = [];

        res?.data?.data?.forEach((t) => {
          switch (t?.territoryType) {
            case '63aaa3c505e4dc7daa1a85ce':
              t1.push(t);
              break;

            case '63aaa46405e4dc7daa1a8648':
              t2.push(t);
              break;

            case '63aaa48905e4dc7daa1a864e':
              t3.push(t);
              break;

            default:
              break;
          }
        });

        setRegionList(t1);
        setHqList(t2);
        setPointList(t3);
      })
      .catch((error) => {
        expired(error);
      });
  }, []);

  useEffect(() => {
    if (region?.id) {
      setTerritory(region?.id);
    } else if (hq?.id) {
      setTerritory(hq?.id);
    } else if (point?.id) {
      setTerritory(point?.id);
    } else {
      setTerritory('63aaa53505e4dc7daa1a86b7');
    }
  }, [region?.id, hq?.id, point?.id]);

  async function fetchSKUContributionReport({
    queryKey: [key, territory, year],
  }) {
    const data = [];

    const res = await axiosPrivate.get(
      `/api/v1/report/sku-contribution?year=${year}&territory=${territory}`,
    );

    const total = res?.data?.data?.total;
    const products = res?.data?.data?.products;

    products.forEach((p) => {
      const product = { ...p };
      product.product.nameCode = `${p?.product?.name} (${p?.product?.code})`;

      Object.entries(p.months).forEach(([month, quantity]) => {
        const tq = total?.[month]?.quantity || 0;
        const q = quantity?.quantity || 0;

        const percent = roundToDigit((q * 100) / tq);

        const displayQuantity = `${quantity?.quantityC} ctn ${quantity?.quantityP} pcs`;

        product.months[month].displayQuantity = displayQuantity;
        product.months[month].percent = `${percent}%`;
        product.months[month].display = `${displayQuantity} (${percent}%)`;
      });

      data.push(product);
    });

    return data;
  }

  const { data, isLoading, isError, error } = useQuery(
    ['sku-contribution', territory, year.getFullYear()],
    fetchSKUContributionReport,
  );

  const renderCell = useCallback(
    (data) => {
      let display = '-';

      switch (show) {
        case 'quantity':
          display = data?.displayQuantity || '-';
          break;

        case 'both':
          display = data?.display || '-';
          break;

        default:
          display = data?.percent || '-';
          break;
      }

      return (
        <Typography align="right" variant="body2">
          {display}
        </Typography>
      );
    },
    [show],
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Product',
        accessor: 'product.nameCode',
        width: 250,
        sticky: 'left',
      },
      {
        Header: 'January',
        accessor: 'months.Jan.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Jan),
      },
      {
        Header: 'February',
        accessor: 'months.Feb.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Feb),
      },
      {
        Header: 'March',
        accessor: 'months.Mar.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Mar),
      },
      {
        Header: 'April',
        accessor: 'months.Apr.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Apr),
      },
      {
        Header: 'May',
        accessor: 'months.May.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.May),
      },
      {
        Header: 'June',
        accessor: 'months.Jun.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Jun),
      },
      {
        Header: 'July',
        accessor: 'months.Jul.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Jul),
      },
      {
        Header: 'August',
        accessor: 'months.Aug.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Aug),
      },
      {
        Header: 'September',
        accessor: 'months.Sep.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Sep),
      },
      {
        Header: 'October',
        accessor: 'months.Oct.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Oct),
      },
      {
        Header: 'November',
        accessor: 'months.Nov.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Nov),
      },
      {
        Header: 'December',
        accessor: 'months.Dec.display',
        aggregateCtn: 'sum',
        width: 200,
        Cell: ({ row }) => renderCell(row?.original?.months?.Dec),
      },
    ],
    [renderCell],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="SKU Contribution">
        <Stack
          direction="row"
          spacing={2}
          useFlexGap
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Autocomplete
            disabled={regionList.length < 1}
            options={regionList}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPoint({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Region"
                size="small"
                sx={{ width: 200 }}
              />
            )}
          />
          <Autocomplete
            disabled={hqList.length < 1}
            options={hqList}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion({});
              setHq(value);
              setPoint({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="HQ"
                size="small"
                sx={{ width: 200 }}
              />
            )}
          />
          <Autocomplete
            disabled={pointList.length < 1}
            options={pointList}
            value={point}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion({});
              setHq({});
              setPoint(value);
            }}
            getOptionLabel={(option) => option?.code || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Point"
                size="small"
                sx={{ width: 200 }}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year']}
              label="Year"
              value={year}
              onChange={(newValue) => setYear(newValue.toDate())}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Year"
                  size="small"
                  sx={{ width: 200 }}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
      </Title>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <>
          <FormControl sx={{ pt: 2 }}>
            <FormLabel id="sku-con-view-type">Show</FormLabel>
            <RadioGroup
              row
              aria-labelledby="sku-con-view-type"
              value={show}
              onChange={(e) => setShow(e.target.value)}>
              <FormControlLabel
                disableTypography
                control={<Radio size="small" />}
                value="percent"
                label="Percent"
              />
              <FormControlLabel
                disableTypography
                control={<Radio size="small" />}
                value="quantity"
                label="Quantity"
              />
              <FormControlLabel
                disableTypography
                control={<Radio size="small" />}
                value="both"
                label="Both"
              />
            </RadioGroup>
          </FormControl>

          <StickyTable
            columns={COLUMNS}
            data={data || []}
            hideAddIcon
            hidePagination
            noWrap
          />
        </>
      )}
    </>
  );
}
