import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  Grid,
  Switch,
  Stack,
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HeadingTitle from '../../../component/HeadingTitle';

export default function ModifiedCustomerType() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const params = useParams();
  const id = params.customerTypeID;

  const [isLoading, setIsLoading] = useState(false);
  // form state
  const [customerType, setCustomerType] = useState('');
  const [codePrefix, setCodePrefix] = useState('');
  const [territoryType, setTerritoryType] = useState('');
  const [hasLedger, setHasLedger] = useState(false);
  const [hasStock, setHasStock] = useState(false);
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [territoryTypes, setTerritoryTypes] = useState([]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/customer/type/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          setCustomerType(data?.customerType);
          setCodePrefix(data?.codePrefix);
          setTerritoryType(data?.territoryType?.id);
          setHasLedger(data?.hasLedger);
          setHasStock(data?.hasStock);
          setDescription(data?.description);
          setIsActive(data?.isActive);
        })
        .catch((error) => console.warn(error))
        .finally(() => setIsLoading(false));
    } else {
      setCustomerType('');
      setCodePrefix('');
      setTerritoryType('');
      setHasLedger(false);
      setHasStock(false);
      setDescription('');
      setIsActive(true);
    }
  }, [axiosPrivate, id]);

  const handleSubmit = () => {
    axiosPrivate({
      method: id ? 'patch' : 'post',
      url: id ? `/api/v1/customer/type/${id}` : '/api/v1/customer/type',
      data: {
        customerType,
        codePrefix,
        territoryType,
        hasLedger,
        hasStock,
        description,
        isActive,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate('/settings/customer-type');
        refetch();
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  };

  useEffect(() => {
    axiosPrivate
      .get('/api/v1/territory/type?sort=rank&isActive=true')
      .then((res) => {
        setTerritoryTypes(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, []);

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading ...</Typography>
      </Stack>
    );
  }

  return (
    <>
      <HeadingTitle>{id ? 'Edit' : 'Add'} Customer Type</HeadingTitle>
      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            label="Customer Type"
            variant="outlined"
            fullWidth
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            label="Code Prefix"
            variant="outlined"
            fullWidth
            value={codePrefix}
            onChange={(e) => setCodePrefix(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Location Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={territoryType}
              label="Location Type"
              onChange={(event) => setTerritoryType(event.target.value)}>
              {territoryTypes.map((data, index) => (
                <MenuItem key={data.id} value={data.id}>
                  {data.territoryType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            type="text"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label="Has stock"
            control={
              <Checkbox
                checked={hasStock}
                onChange={() => setHasStock(!hasStock)}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            label="Has ledger"
            control={
              <Checkbox
                checked={hasLedger}
                onChange={() => setHasLedger(!hasLedger)}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label={isActive ? 'Active' : 'Inactive'}
            control={
              <Switch
                color="primary"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            }
          />
        </Grid>
      </Grid>
      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/settings/customer-type')}>
          Cancel
        </Button>
        <Button variant="contained" sx={{ px: 3 }} onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
