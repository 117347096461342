import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useEffect, useState } from 'react';

function Pagination(props) {
  const totalRow = props?.preGlobalFilteredRows?.length;
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    // pageTopRef,
    handleScroll,
  } = props;
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (totalRow > 10 && totalRow <= 25) {
      setItems([10, 25]);
    } else if (totalRow > 25 && totalRow <= 50) {
      setItems([10, 25, 50]);
    } else if (totalRow > 50 && totalRow <= 75) {
      setItems([10, 25, 50, 75]);
    } else if (totalRow > 75 && totalRow <= 100) {
      setItems([10, 25, 50, 75, 100]);
    } else {
      setItems([10, 25, 50, 75, 100]);
    }
  }, [totalRow]);

  if (totalRow <= 10) {
    return;
  }

  return (
    <Stack
      spacing={2}
      my={2}
      direction="row"
      alignItems="center"
      justifyContent="end">
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          sx={{
            borderRadius: '50%',
            minWidth: '0px',
            width: '30px',
            height: '30px',
          }}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}>
          {<KeyboardDoubleArrowLeftIcon />}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: '50%',
            minWidth: '0px',
            width: '30px',
            height: '30px',
          }}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}>
          {<KeyboardArrowLeftIcon />}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: '50%',
            minWidth: '0px',
            width: '30px',
            height: '30px',
          }}
          onClick={() => {
            nextPage();
            handleScroll();
          }}
          disabled={!canNextPage}>
          {<KeyboardArrowRightIcon />}
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: '50%',
            minWidth: '0px',
            width: '30px',
            height: '30px',
          }}
          onClick={() => {
            gotoPage(pageCount - 1);
            handleScroll();
          }}
          disabled={!canNextPage}>
          {<KeyboardDoubleArrowRightIcon />}
        </Button>
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography>
          {'Page '}
          <strong>{`${pageIndex + 1} of ${pageOptions.length}`}</strong>
        </Typography>
        <Typography>| Go to page:</Typography>
        <TextField
          id="go-to-page"
          variant="outlined"
          type="number"
          InputProps={{
            inputProps: {
              max: pageOptions.length,
              min: 1,
            },
          }}
          size="small"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          sx={{ width: 100 }}
        />
      </Stack>
      <FormControl sx={{ width: 100 }} size="small">
        <InputLabel id="show-rows-number">Show</InputLabel>
        <Select
          labelId="show-rows-number"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          label="Show">
          {items.map((item, i) => (
            <MenuItem key={i} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default Pagination;
