import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, IconButton, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import useHeader from '../../../hooks/useHeader';
import getRandomColorName from '../../../utils/getRandomColorName';

function AllTeritories() {
  const navigate = useNavigate();

  const location = useLocation();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  // modifed states

  const [deleteId, setDeleteId] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setHeader({ title: 'Locations' });
  }, []);

  const handleAdd = () => {
    navigate('/territories/add');
  };

  const handleEdit = (id) => {
    navigate(`/territories/edit/${id}`);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/territory/${deleteId}`)
      .then((res) => {
        console.log(res);
        if (res.status === 204) {
          toast.success('Location deleted Successfully');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setShowDelete(false);
      });
  };

  // get all territry data
  const {
    data: res,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery('all_territory', () => {
    return axiosPrivate.get(
      '/api/v1/territory?populate=yes&limit=none&sort=-createdAt',
    );
  });

  const countItems = useMemo(() => {
    const items = {};
    if (Array.isArray(res?.data?.data)) {
      res.data.data.forEach((d) => {
        const id = `${d?.parentTerritory?.name}-${d?.name}`;

        if (items[id]?.count) {
          items[id].count += 1;
        } else {
          items[id] = {
            count: 1,
            color: getRandomColorName(),
          };
        }
      });
    }
    return items;
  }, [res]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => {
          const id = `${row?.original?.parentTerritory?.name}-${row?.original?.name}`;
          const item = countItems[id];
          if (item?.count > 1) {
            return (
              <span style={{ color: item.color }}>
                {value}&nbsp;
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: item.color,
                  }}>
                  &nbsp;{item.count}&nbsp;
                </span>
              </span>
            );
          }
          return value;
        },
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Parent Location',
        accessor: 'parentTerritory.name',
        Cell: ({ value, row }) => {
          if (value)
            return `${value} (${row?.original?.parentTerritory?.code})`;
        },
      },
      {
        Header: 'Location Hierarchy',
        accessor: 'territoryType.territoryType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        // filter: 'includesSome',
        Cell: ({ value, row }) => {
          return `${value} (${row?.original?.territoryType?.codePrefix})`;
        },
      },
      {
        Header: 'Address',
        accessor: 'address',
        Cell: ({ row }) => (
          <div>
            {row?.original?.address?.length >= 20 ? (
              <div>{row?.original?.address.slice(0, 20)}...</div>
            ) : (
              <div>{row?.original?.address}</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEdit(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
                <Box>
                  <IconButton
                    onClick={() => {
                      setDeleteId(row?.original?.id);
                      setShowDelete(true);
                    }}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Stack>
            </>
          );
        },
        disableFilters: true,
      },
    ],
    [countItems],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/territories' ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={res?.data?.data || []}
            filename="All_Location"
            addButtonTooltip="Add Location"
            onAddButtonClick={handleAdd}
            isLoading={isLoading || isFetching}
            fullHeight={true}
          />
          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={showDelete}
            onSave={() => handleDelete()}
            onHide={() => setShowDelete(false)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}
    </>
  );
}

export default AllTeritories;
