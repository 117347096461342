import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import { Box, CircularProgress, Stack } from '@mui/material';
import logo from '../assets/images/logo_blue.svg';
import useRequestId from '../hooks/useRequestId';
import useExpiredSession from '../hooks/useExpiredSession';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth } = useAuth();

  const { requestId } = useRequestId();
  const expired = useExpiredSession();

  const refreshToken = localStorage.getItem('refreshToken');

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        if (requestId) await refresh();
      } catch (err) {
        console.error(err?.response?.data?.message);
        expired(err);
      } finally {
        setIsLoading(true);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);
  }, [auth?.accessToken, requestId]);

  return (
    <>
      {isLoading && refreshToken ? (
        <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
          <Stack direction={'column'} alignItems="center" gap={2}>
            <img src={logo} alt="" />
            <CircularProgress />
          </Stack>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default PersistLogin;
