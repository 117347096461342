import React, { useEffect, useState } from 'react';
import useExpiredSession from '../../hooks/useExpiredSession';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

function FilterViewLayout({
  userTypeFilter,
  setUserTypeFilter,
  searchFilter,
  setSearchFilter,
  setTerritoryFilter,
  children,
}) {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [regionList, setRegionList] = useState([]);
  const [hqList, setHqList] = useState([]);
  const [pointList, setPointList] = useState([]);

  const [region, setRegion] = useState({});
  const [hq, setHq] = useState({});
  const [point, setPoint] = useState({});

  useEffect(() => {
    axiosPrivate
      .get('/api/v1/territory?limit=none&sort=name')
      .then((res) => {
        const t1 = [];
        const t2 = [];
        const t3 = [];

        res?.data?.data?.forEach((t) => {
          switch (t?.territoryType) {
            case '63aaa3c505e4dc7daa1a85ce':
              t1.push(t);
              break;

            case '63aaa46405e4dc7daa1a8648':
              t2.push(t);
              break;

            case '63aaa48905e4dc7daa1a864e':
              t3.push(t);
              break;

            default:
              break;
          }
        });

        setRegionList(t1);
        setHqList(t2);
        setPointList(t3);
      })
      .catch((error) => {
        expired(error);
      });
  }, []);

  useEffect(() => {
    if (region?.code) {
      setTerritoryFilter(`Region: ${region?.code}`);
    } else if (hq?.code) {
      setTerritoryFilter(`HQ: ${hq?.code}`);
    } else if (point?.code) {
      setTerritoryFilter(`Point/Town: ${point?.code}`);
    } else {
      setTerritoryFilter('');
    }
  }, [hq?.code, point?.code, region?.code]);

  return (
    <Stack direction="column" spacing={2} useFlexGap>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        useFlexGap
        sx={(theme) => ({
          p: 1.5,
          flexGrow: 1,
          flexWrap: 'wrap',
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.shape.borderRadius,
        })}>
        <TextField
          type="search"
          placeholder="Search..."
          size="small"
          value={searchFilter}
          onChange={(e) => setSearchFilter(e.target.value)}
          sx={{ width: 200 }}
        />
        <Autocomplete
          disabled={regionList.length < 1}
          options={regionList}
          value={region}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => {
            setRegion(value);
            setHq({});
            setPoint({});
          }}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              size="small"
              sx={{ width: 200 }}
            />
          )}
        />
        <Autocomplete
          disabled={hqList.length < 1}
          options={hqList}
          value={hq}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => {
            setRegion({});
            setHq(value);
            setPoint({});
          }}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="HQ"
              size="small"
              sx={{ width: 200 }}
            />
          )}
        />
        <Autocomplete
          disabled={pointList.length < 1}
          options={pointList}
          value={point}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => {
            setRegion({});
            setHq({});
            setPoint(value);
          }}
          getOptionLabel={(option) => option?.code || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Point"
              size="small"
              sx={{ width: 200 }}
            />
          )}
        />
        <ToggleButtonGroup
          value={userTypeFilter}
          exclusive
          size="small"
          onChange={(e, v) => setUserTypeFilter(v)}>
          <ToggleButton value="Employee" color="info">
            Employee
          </ToggleButton>
          <ToggleButton value="Customer" color="success">
            Customer
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Box>{children}</Box>
    </Stack>
  );
}

export default FilterViewLayout;
