import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import './assets/css/style.css';
import ScrollToTop from './component/ScrollToTop';
import Router from './router/Router';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import useHeader from './hooks/useHeader';

function App() {
  const { header } = useHeader();

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        // refetchOnmount: false,
        // refetchOnReconnect: false,
        retry: false,
        // staleTime: twentyFourHoursInMs,
      },
    },
  });

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{header?.title}</title>
        </Helmet>
      </HelmetProvider>

      <QueryClientProvider client={client}>
        <Router />
      </QueryClientProvider>
      <ToastContainer autoClose={3000} draggable pauseOnHover />
      <ScrollToTop />
    </>
  );
}

export default App;
