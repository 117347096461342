import { TableContainer, styled } from '@mui/material';

export const TableContain = styled(TableContainer)(({ theme }) => ({
  table: {
    th: {
      fontSize: 15,
      fontWeight: 400,
      color: '#fff',
      textAlign: 'start',
      background: `${theme.palette.primary.main}`,
      padding: '0.5rem',
      border: '1px solid #fff',
    },
    td: {
      fontSize: 14,
      textAlign: 'start',
      padding: '0.2rem 0.1rem 0.2rem 0.3rem',
      /* white-space: nowrap; */
    },

    'tr:nth-of-type(even)': {
      background: `${theme.palette.primary.main}11`,
      fontWeight: 400,
    },
    'tr:nth-of-type(odd)': {
      background: `${theme.palette.white.main}32`,
      fontWeight: 400,
    },
    'tr:hover': {
      color: `${theme.palette.primary.main}`,
      boxShadow: '0px 0px 3px 1px #9ab5d0 inset',
      //   td: {
      //     color: `${theme.palette.primary.main}`,
      //   },
    },
  },
}));
