import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  CustomBox,
  CustomMenu,
  CustomMobileNavBox,
} from '../assets/style/navigation/MobileNavigation';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import useContainerDimensions from '../hooks/useContainerDimensions';

export default function MobileNavigation({ options }) {
  const [show, setShow] = useState(false);
  const [tabsNo, setTabsNo] = useState(4);
  const [currentTabsNo, setCurrentTabsNo] = useState(4);
  const [backDrop, setBackDrop] = useState(false);

  // options
  const showText = true;

  const handleClick = () => {
    setTabsNo(options?.length);
    setShow(true);
    setBackDrop(true);
  };

  const handleClose = () => {
    // console.log('close');
    setTabsNo(currentTabsNo);
    setShow(false);
    setBackDrop(false);
  };

  const ref = useRef(null);
  const { width } = useContainerDimensions(ref);

  useMemo(() => {
    for (let i = 4; i <= options?.length; i++) {
      if (width > 70 * i) {
        setTabsNo(i);
        setCurrentTabsNo(i);
      }
    }

    // if (width > 70 * 5) {
    //   setTabsNo(5);
    //   setCurrentTabsNo(5);
    // }
  }, [width, options]);

  if (options?.length < 2) {
    return;
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: 199, display: { md: 'none' } }}
        open={backDrop}
        onClick={() => {
          setBackDrop(false);
          setShow(false);
          setTabsNo(currentTabsNo);
        }}
      />
      <CustomMobileNavBox ref={ref}>
        <List sx={{ flexWrap: show ? 'wrap' : 'initial' }}>
          {options?.length > tabsNo ? (
            <>
              {options?.slice(0, tabsNo)?.map((info, index) => (
                <ListItems key={index} data={info} showText={showText} />
              ))}
              {!show && (
                <ListItem>
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon>
                      <MoreHorizIcon />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )}
            </>
          ) : (
            <>
              {options?.map((info, index) => (
                <ListItems
                  key={index}
                  data={info}
                  showText={showText}
                  close={handleClose}
                />
              ))}
            </>
          )}
        </List>
        {show && (
          <Stack
            py={1}
            px={2}
            direction="row"
            justifyContent="flex-end"
            onClick={handleClose}>
            <IconButton>
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
      </CustomMobileNavBox>
    </>
  );
}

function ListItems({ data, showText, close }) {
  return (
    <ListItem
      onClick={close}
      disablePadding
      component={NavLink}
      state={{
        title: data?.title,
      }}
      to={data?.url}>
      <ListItemButton>
        <ListItemIcon>{data?.icon}</ListItemIcon>
        {showText && (
          <ListItemText primary={data?.title} sx={{ whiteSpace: 'nowrap' }} />
        )}
      </ListItemButton>
    </ListItem>
  );
}
