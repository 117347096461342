import React, { useMemo } from 'react';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, IconButton, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import { SelectColumnFilterBool } from '../../../component/Table/SelectColumnFilter';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function ViewProdcutCompany() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAddProductCat = () => {
    navigate('/settings/product-company/add');
  };
  const handleEditProductCat = (id) => {
    navigate(`/settings/product-company/edit/${id}`);
  };

  // get product company
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'productCat',
    () => {
      return axiosPrivate.get(
        '/api/v1/product/company?populate=yes&limit=none&sort=-createdAt',
      );
    },
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Product Company',
        accessor: 'productCompany',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ row }) => (
          <div>
            {row?.original?.description?.length >= 30 ? (
              <div>{row?.original?.description?.slice(0, 30)}...</div>
            ) : (
              <div>{row?.original?.description?.slice(0, 30)}</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          const [deleteShow, setDeleteShow] = React.useState(false);
          // edit

          // delete
          const handleDelete = (id) => {
            setDeleteShow(false);
            axiosPrivate
              .delete(`/api/v1/product/company/${id}`)
              .then((res) => {
                if (res.status === 204) {
                  toast.success('Document was deleted');
                  refetch();
                }
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEditProductCat(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>

                <Box>
                  <IconButton
                    onClick={() => setDeleteShow(true)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                    title="Are you sure?"
                    subtitle="You won't be able to revert this!"
                    show={deleteShow}
                    onSave={() => handleDelete(row?.original?.id)}
                    onHide={() => setDeleteShow(false)}
                    actionButtonLabel="Delete"
                    actionButtonColor="error"
                  />
                </Box>
              </Stack>
            </>
          );
        },
      },
    ],
    [axiosPrivate],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location?.pathname !== '/settings/product-company' ? (
        <Outlet context={[refetch]} />
      ) : (
        <Table
          columns={COLUMNS}
          data={data?.data?.data || []}
          filename="Product_Company"
          addButtonTooltip="Add Product_Company"
          isLoading={isLoading || isFetching}
          onAddButtonClick={handleAddProductCat}
        />
      )}
    </>
  );
}
