import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Box, Stack } from '@mui/material';
import React from 'react';
import Card from './Card';
import DashTitle from './DashTitle';

const iconStyle = {
  fontSize: '50px',
};

const CardInfo = [
  {
    title: 'Outlet Entry Info',
    url: '/outletinfo',
    icon: <StorefrontIcon sx={iconStyle} />,
  },
  {
    title: 'Dealer Search Visit Info',
    url: '/dealerinfo',
    icon: <TravelExploreIcon sx={iconStyle} />,
    unaccessible: ['SO/SR'],
  },
  {
    title: 'Existing Dealer Info',
    url: '/exdealerinfo',
    icon: <WarehouseIcon sx={iconStyle} />,
    unaccessible: ['SO/SR'],
  },
  {
    title: 'TA DA Info',
    url: '/tada-summary',
    icon: <LocalTaxiIcon sx={iconStyle} />,
    unaccessible: ['SO/SR'],
  },
];

export default function InfoCard() {
  return (
    <Box
      mt={3}
      p={2}
      pb={5}
      sx={{
        background: (theme) => `${theme.palette.success.main}20`,
        borderRadius: 3,
      }}>
      <DashTitle title={'Information'} />
      <Stack
        direction="row"
        justifyContent="center"
        mt={3}
        sx={{ flexWrap: 'wrap', gap: '20px' }}>
        {/* <Card /> */}
        {CardInfo.map((info, index) => (
          <Card key={index} info={info} />
        ))}
      </Stack>
    </Box>
  );
}
