const TotalSKUColors = [
  '#028391',
  '#344C64',
  '#344444',
  '#C80036',
  '#3572EF',
  '#AF47D2',
  '#FFD0D0',
  '#83B4FF',
];

const SKUCOLORS = ['#83B4FF', '#6295A2', '#3572EF', '#C80036'];

const COLORS = [
  '#83B4FF',
  '#6295A2',
  '#3572EF',
  '#C80036',
  '#028391',
  '#344C64',
  '#344444',
  '#6295A2',
  '#3572EF',
  '#AF47D2',
  '#FFD0D0',
  '#83B4FF',
];

const ActiveCOLORS = [
  '#FDFFE2',
  '#538392',
  '#050C9C',
  '#FFFDB5',
  '#3572EF',
  '#FFD0D0',
];

const COLORS2 = ['#667BC6', '#F19ED2', '#E0A75E', '#402E7A', '#4C3BCF'];
const COLORS3 = [
  '#83B4FF',
  '#6295A2',
  '#3572EF',
  '#C80036',
  '#028391',
  '#344C64',
  '#344444',
  '#6295A2',
  '#3572EF',
  '#AF47D2',
  '#FFD0D0',
  '#83B4FF',
];

export { SKUCOLORS, TotalSKUColors, COLORS, ActiveCOLORS, COLORS2, COLORS3 };
