import React, { useEffect, useMemo, useState } from 'react';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterStatus,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import useHeader from '../../../hooks/useHeader';
import getRandomColorName from '../../../utils/getRandomColorName';
import roundToDigit from '../../../utils/roundToDigit';
import ExpandRowInfo from './ExpandRowInfo';
import shortMongoID from '../../../utils/shortMongoID';

function ViewTransactions() {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, role, user, Admins } = useAuth();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  useEffect(() => {
    setHeader({ title: 'Transactions' });
  }, []);

  const customerName = location?.state?.name;
  const customerId = location?.state?.id;
  const cID = param?.customerID || customerId;

  const [selectedItem, setSelectedItem] = useState({});
  const [deleteShow, setDeleteShow] = useState(false);
  const [undoConfirmShow, setUndoConfirmShow] = useState(false);

  // filter states ------------------
  const [orderStartDate, setOrderStartDate] = useState(
    dayjs().subtract(1, 'week').startOf('month'),
  );
  const [orderEndDate, setOrderEndDate] = useState(dayjs().endOf('month'));

  const [deliveryStartDate, setDeliveryStartDate] = useState(null);
  const [deliveryEndDate, setDeliveryEndDate] = useState(null);

  // get All transaction data
  const { data, isLoading, isError, error, refetch } = useQuery(
    [
      'transactions',
      orderStartDate?.valueOf(),
      orderEndDate?.valueOf(),
      deliveryStartDate?.valueOf(),
      deliveryEndDate?.valueOf(),
    ],
    () => {
      let url1 = '/api/v1/transaction?populate=yes&limit=none&sort=-orderedAt';
      let url2 = '/api/v1/ledger?limit=none';

      if (!cID) {
        if (['SD'].includes(role)) {
          url1 += `&parentTerritory=${user?.employee?.territory?.parentTerritory?.id}`;
        } else if (
          !['Super Admin', ...Admins, 'Accounts', 'DO', 'Factory'].includes(
            role,
          )
        ) {
          url1 += `&parentTerritory=${user?.employee?.territory?.id}`;
        }
        if (orderStartDate?.isValid()) {
          url1 += `&orderedAt[gte]=${orderStartDate.toISOString()}`;
          url2 += `&dateOfDeposit[gte]=${orderStartDate.toISOString()}`;
        }
        if (orderEndDate?.isValid()) {
          url1 += `&orderedAt[lte]=${orderEndDate.toISOString()}`;
          url2 += `&dateOfDeposit[lte]=${orderEndDate
            .add(1, 'month')
            .endOf('month')
            .toISOString()}`;
        }
        if (deliveryStartDate?.isValid()) {
          url1 += `&deliveredAt[gte]=${deliveryStartDate.toISOString()}`;
          url2 += `&dateOfDeposit[gte]=${deliveryStartDate
            .subtract(1, 'month')
            .startOf('month')
            .toISOString()}`;
        }
        if (deliveryEndDate?.isValid()) {
          url1 += `&deliveredAt[lte]=${deliveryEndDate.toISOString()}`;
          url2 += `&dateOfDeposit[lte]=${deliveryEndDate.toISOString()}`;
        }
      } else {
        url1 = `/api/v1/customer/${cID}/transaction`;
        url2 = `/api/v1/customer/${cID}/ledger`;
      }

      const promises = [axiosPrivate.get(url1), axiosPrivate.get(url2)];

      return Promise.all(promises).then((results) => {
        const ledgerValues = {};
        const collectionValues = {};

        results[1]?.data?.data?.forEach((item) => {
          const refID = item?.transactionRef;
          const type = item?.type;

          if (refID && type) {
            if (!ledgerValues[refID]) {
              ledgerValues[refID] = {};
            }

            if (!ledgerValues[refID][type]) {
              ledgerValues[refID][type] = 0;
            }

            ledgerValues[refID][type] += item?.totalAmount;
          }

          if (type === 'collection') {
          }
        });

        const d = [];
        const doCount = {};
        const receiverCount = {};

        results[0]?.data?.data?.forEach((item) => {
          const tID = item?.id;
          const doNumber = item?.doNumber;
          const receiverCode = item?.receiver?.code;

          if (doNumber) {
            if (doCount[doNumber]?.count) {
              doCount[doNumber].count += 1;
            } else {
              doCount[doNumber] = {
                count: 1,
                color: getRandomColorName(),
              };
            }
          }

          if (receiverCode) {
            if (receiverCount[receiverCode]?.count) {
              receiverCount[receiverCode].count += 1;
            } else {
              receiverCount[receiverCode] = {
                count: 1,
                color: getRandomColorName(),
              };
            }
          }

          let location = '';
          if (
            item.receiver?.territory?.territoryType ===
            '63aaa48905e4dc7daa1a864e'
          ) {
            location = item.receiver?.territory?.code;
          } else if (
            item.receiver?.territory?.parentTerritory?.territoryType ===
            '63aaa48905e4dc7daa1a864e'
          ) {
            location = item.receiver?.territory?.parentTerritory?.code;
          } else if (
            item.sender?.territory?.territoryType === '63aaa48905e4dc7daa1a864e'
          ) {
            location = item.sender?.territory?.code;
          } else if (
            item.sender?.territory?.parentTerritory?.territoryType ===
            '63aaa48905e4dc7daa1a864e'
          ) {
            location = item.sender?.territory?.parentTerritory?.code;
          } else {
            location = item.receiver?.territory?.code;
          }

          item.location = location;

          let cartonsO = 0;
          let packetsO = 0;

          let cartonsF = 0;
          let packetsF = 0;

          let cartonsT = 0;
          let packetsT = 0;

          item?.products?.forEach((p) => {
            cartonsO += Math.floor(p?.quantity / p?.quantityPerCarton);
            packetsO += p?.quantity % p?.quantityPerCarton;

            cartonsF += Math.floor(p?.freeQuantity / p?.quantityPerCarton);
            packetsF += p?.freeQuantity % p?.quantityPerCarton;

            const tq = p?.quantity + p?.freeQuantity;
            cartonsT += Math.floor(tq / p?.quantityPerCarton);
            packetsT += tq % p?.quantityPerCarton;
          });

          let orderedQuantity = `${cartonsO} ctn`;
          if (packetsO) orderedQuantity += ` ${packetsO} pcs`;

          let freeQuantity = `${cartonsF} ctn`;
          if (packetsF) freeQuantity += ` ${packetsF} pcs`;

          let totalQuantity = `${cartonsT} ctn`;
          if (packetsT) totalQuantity += ` ${packetsT} pcs`;

          item.orderedQuantity = orderedQuantity;
          item.freeQuantity = freeQuantity;
          item.totalQuantity = totalQuantity;

          item.avgPricePerCtn = roundToDigit(item?.invoiceAmount / cartonsO);

          item.applicableCommission =
            item?.isDelivered && item?.commissionPercent
              ? roundToDigit(
                  (item?.invoiceAmount * item?.commissionPercent) / 100,
                )
              : 0;

          let collectionIds = '';
          item?.collectionRefs?.forEach((i, index) => {
            if (index !== 0) collectionIds += ', ';
            collectionIds += shortMongoID(i);
          });

          item.collectionIds = collectionIds;

          let netPayableAmount = item?.payableAmount
            ? parseFloat(item?.payableAmount)
            : parseFloat(item?.invoiceAmount);
          if (ledgerValues[tID]) {
            Object.entries(ledgerValues[tID]).forEach(([key, amt]) => {
              if (
                [
                  'damage',
                  'return',
                  'transport',
                  'salary',
                  'bonus',
                  'commission',
                  'other',
                ].includes(key)
              ) {
                netPayableAmount -= amt;
              }
            });
          }

          item.netPayableAmount = roundToDigit(netPayableAmount);
          item.ledgerValues = ledgerValues[tID];

          d.push(item);
        });

        return { data: d, doCount, receiverCount };
      });
    },
    { refetchInterval: 60000 },
  );

  const handleCloseDelete = () => {
    setSelectedItem({});
    setDeleteShow(false);
  };

  const handleCloseUndoConfirm = () => {
    setSelectedItem({});
    setUndoConfirmShow(false);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/transaction/${selectedItem?.id}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        handleCloseDelete();
      });
  };

  const handleUndoConfirm = () => {
    axiosPrivate
      .patch(`/api/v1/transaction/${selectedItem?.id}/unconfirm`)
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        handleCloseUndoConfirm();
      });
  };

  const handleAddTransaction = () => {
    if (['SD'].includes(role)) {
      navigate('/transactions/add-depot-order');
    } else {
      navigate('/transactions/add');
    }
  };

  const handleCustomerInfo = (id) => {
    navigate(`/customers/transaction/${id}`, {
      state: { title: 'Customers' },
    });
  };

  const orderDateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={orderStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setOrderStartDate(dayjs(e.target.value).startOf('date'));
            setDeliveryStartDate(null);
            setDeliveryEndDate(null);
          }}
        />
        <input
          type="date"
          value={orderEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setOrderEndDate(dayjs(e.target.value).endOf('date'));
            setDeliveryStartDate(null);
            setDeliveryEndDate(null);
          }}
        />
      </Stack>
    );
  }, [orderEndDate, orderStartDate]);

  const deliveryDateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={deliveryStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setDeliveryStartDate(dayjs(e.target.value).startOf('date'));
            setOrderStartDate(null);
            setOrderEndDate(null);
          }}
        />
        <input
          type="date"
          value={deliveryEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setDeliveryEndDate(dayjs(e.target.value).endOf('date'));
            setOrderStartDate(null);
            setOrderEndDate(null);
          }}
        />
      </Stack>
    );
  }, [deliveryEndDate, deliveryStartDate]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        hidePrint: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        id: 'ID',
        Header: 'ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Order Time',
        accessor: 'orderedAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: cID ? DateRangeColumnFilter : orderDateRangeFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Deliver Time',
        accessor: 'deliveredAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: cID ? DateRangeColumnFilter : deliveryDateRangeFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Transaction Type',
        accessor: 'transactionType.transactionType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Sender',
        accessor: `sender.nameCode`,
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <Box
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleCustomerInfo(row?.original?.sender?.id)}>
                {value}
              </Box>
            );
          }
          return '-';
        },
      },
      {
        Header: 'Receiver',
        accessor: 'receiver.nameCode',
        Cell: ({ value, row }) => {
          if (value) {
            const code = row?.original?.receiver?.code;
            const count = data?.receiverCount[code]?.count;
            const color = data?.receiverCount[code]?.color;

            return (
              <Box
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                onClick={() => handleCustomerInfo(row?.original?.receiver?.id)}>
                {!cID && count > 1 ? (
                  <span style={{ color: color }}>
                    {`${value} `}
                    <span
                      style={{
                        borderRadius: 8,
                        color: 'white',
                        backgroundColor: color,
                      }}>
                      &nbsp;{count}&nbsp;
                    </span>
                  </span>
                ) : (
                  value
                )}
              </Box>
            );
          }
          return '-';
        },
      },
      {
        Header: 'Ordered Qty.',
        accessor: 'orderedQuantity',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Free Qty.',
        accessor: 'freeQuantity',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Total Qty.',
        accessor: 'totalQuantity',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Ave Ctn Amt',
        accessor: 'avgPricePerCtn',
      },
      {
        Header: 'Invoice Amount',
        accessor: 'invoiceAmount',
        aggregate: 'sum',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Damage ৳',
        accessor: 'ledgerValues.damage',
        aggregate: 'sum',
      },
      {
        Header: 'Return ৳',
        accessor: 'ledgerValues.return',
        aggregate: 'sum',
      },
      {
        Header: 'Transport ৳',
        accessor: 'ledgerValues.transport',
        aggregate: 'sum',
      },
      {
        Header: 'Salary ৳',
        accessor: 'ledgerValues.salary',
        aggregate: 'sum',
      },
      {
        Header: 'Bonus ৳',
        accessor: 'ledgerValues.bonus',
        aggregate: 'sum',
      },
      {
        Header: 'Commission ৳',
        accessor: 'ledgerValues.commission',
        aggregate: 'sum',
      },
      {
        Header: 'Other funds ৳',
        accessor: 'ledgerValues.other',
        aggregate: 'sum',
      },
      {
        Header: 'Net Payable Amount',
        accessor: 'netPayableAmount',
        aggregate: 'sum',
      },
      {
        Header: 'SD Commission',
        accessor: 'applicableCommission',
        aggregate: 'sum',
      },
      {
        Header: 'Trx. ID',
        accessor: 'shortId',
      },
      {
        Header: 'Linked Collections',
        accessor: 'collectionIds',
      },
      {
        Header: 'DO No.',
        accessor: 'doNumber',
        Cell: ({ value }) => {
          const count = data?.doCount[value]?.count;
          const color = data?.doCount[value]?.color;

          if (value && count > 1) {
            return (
              <span style={{ color: color }}>
                {`${value} `}
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: color,
                  }}>
                  &nbsp;{count}&nbsp;
                </span>
              </span>
            );
          }
          return value;
        },
      },
      {
        Header: 'Check Status',
        accessor: 'statusBinary',
        Filter: SelectColumnFilterStatus,
        Cell: ({ value, row }) => {
          return (
            <>
              {row?.original?.isProductsConfirmed ? (
                <Tooltip title="Products Confirmed">
                  <CheckCircleIcon color="primary" />
                </Tooltip>
              ) : (
                <Tooltip title="Products Pending">
                  <RadioButtonUncheckedIcon color="primary" />
                </Tooltip>
              )}

              {row?.original?.isPaymentsConfirmed ? (
                <Tooltip title="Payments Confirmed">
                  <CheckCircleIcon color="secondary" />
                </Tooltip>
              ) : (
                <Tooltip title="Payments Pending">
                  <RadioButtonUncheckedIcon color="secondary" />
                </Tooltip>
              )}

              {row?.original?.isDelivered ? (
                <Tooltip title="Delivery Confirmed">
                  <CheckCircleIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Delivery Pending">
                  <RadioButtonUncheckedIcon color="success" />
                </Tooltip>
              )}
            </>
          );
        },
      },
      {
        Header: 'Recorded By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          const showDeleteButton = Boolean(
            !row?.original?.isProductsConfirmed &&
              !row?.original?.isPaymentsConfirmed &&
              !row?.original?.isDelivered &&
              (['Super Admin', ...Admins, 'DO'].includes(role) ||
                row?.original?.recordedBy?.id === userId),
          );

          const showUndoConfirmButton = Boolean(
            row?.original?.isProductsConfirmed &&
              ['Super Admin'].includes(role),
          );

          return (
            <>
              <Stack direction="row" sx={{ gap: 1 }}>
                <Tooltip title="Link Collections">
                  <IconButton
                    component={Link}
                    to="/transactions/link-collections"
                    state={{ data: row?.original }}
                    disabled={row?.original?.isPaymentsConfirmed}
                    className="Table_icon_btn"
                    color="primary">
                    <AddLinkIcon />
                  </IconButton>
                </Tooltip>

                {showDeleteButton && (
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => {
                        setSelectedItem(row?.original);
                        setDeleteShow(true);
                      }}
                      className="Table_icon_btn delete">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}

                {showUndoConfirmButton && (
                  <Tooltip title="Remove confirmations">
                    <IconButton
                      onClick={() => {
                        setSelectedItem(row?.original);
                        setUndoConfirmShow(true);
                      }}
                      className="Table_icon_btn">
                      <RemoveCircleIcon color="warning" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </>
          );
        },
      },
    ],
    [
      orderDateRangeFilter,
      deliveryDateRangeFilter,
      data?.doCount,
      data?.receiverCount,
      cID,
      userId,
      role,
    ],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/transactions' && !cID ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            customerName={customerName}
            columns={COLUMNS}
            data={data?.data || []}
            filename="transactions"
            addButtonTooltip="Add new transaction"
            onAddButtonClick={handleAddTransaction}
            expandRow={ExpandRowInfo}
            expandRowProps={{ refetch }}
            isLoading={isLoading}
            fullHeight={true}
            hasDrawer
            drawerWidth={'1000px'}
          />

          <Typography sx={{ py: 1 }}>Status color meaning:</Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            <Chip
              icon={<CheckCircleIcon />}
              label="Products Confirmed"
              color="primary"
              variant="outlined"
              size="small"
            />
            <Chip
              icon={<CheckCircleIcon />}
              label="Payments Confirmed"
              color="secondary"
              variant="outlined"
              size="small"
            />
            <Chip
              icon={<CheckCircleIcon />}
              label="Delivery Confirmed"
              color="success"
              variant="outlined"
              size="small"
            />
          </Stack>

          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={Boolean(deleteShow && selectedItem?.id)}
            onSave={handleDelete}
            onHide={handleCloseDelete}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />

          <ConfirmModal
            title="Do you want to remove confirmations?"
            subtitle="Ledger entries related to this transaction will be removed and stock will be reset."
            show={Boolean(undoConfirmShow && selectedItem?.id)}
            onSave={handleUndoConfirm}
            onHide={handleCloseUndoConfirm}
            actionButtonLabel="Remove"
            actionButtonColor="warning"
          />
        </>
      )}
    </>
  );
}

export default ViewTransactions;
