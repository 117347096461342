import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import GridViewIcon from '@mui/icons-material/GridView';
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import {
  COLORS,
  COLORS2,
  SKUCOLORS,
  TotalSKUColors,
} from '../../staticData/Colors';
import HeadingTitle from '../HeadingTitle';

const data = {
  totalSaleCurrentMonth: {
    data: [
      {
        percent: 1.56,
        productName: 'Boost Vita',
        productCode: 'BV',
        productNameCode: 'Boost Vita (BV)',
        price: 25.31,
        totalSale: 9,
        totalAmount: 1528.8,
      },
      {
        percent: 6.07,
        productName: 'Bouncy Standard',
        productCode: 'BCS-S',
        productNameCode: 'Bouncy Standard (BCS-S)',
        price: 9.11,
        totalSale: 35,
        totalAmount: 6189.6,
      },
      {
        percent: 3.12,
        productName: 'Cha- Time',
        productCode: 'CH-T',
        productNameCode: 'Cha- Time (CH-T)',
        price: 4.17,
        totalSale: 18,
        totalAmount: 3396.24,
      },
      {
        percent: 10.4,
        productName: 'FRUITELY MANGO JUICE',
        productCode: 'FMJ250',
        productNameCode: 'FRUITELY MANGO JUICE (FMJ250)',
        price: 17.24,
        totalSale: 60,
        totalAmount: 23421.6,
      },
      {
        percent: 1.21,
        productName: 'Gold Marie',
        productCode: 'GM',
        productNameCode: 'Gold Marie (GM)',
        price: 32,
        totalSale: 7,
        totalAmount: 1690.5,
      },
      {
        percent: 5.89,
        productName: 'KOOKIEJAR ENERGY STANDARD (24)',
        productCode: '24ESTD',
        productNameCode: 'KOOKIEJAR ENERGY STANDARD (24) (24ESTD)',
        price: 8.33,
        totalSale: 34,
        totalAmount: 6038.4,
      },
      {
        percent: 3.81,
        productName: 'KOOKIEJAR FRIED PEAS 48',
        productCode: 'FP48',
        productNameCode: 'KOOKIEJAR FRIED PEAS 48 (FP48)',
        price: 4,
        totalSale: 22,
        totalAmount: 3984.82,
      },
      {
        percent: 13.52,
        productName: 'KOOKIEJAR TANGY ORANGE (24)',
        productCode: '24TO',
        productNameCode: 'KOOKIEJAR TANGY ORANGE (24) (24TO)',
        price: 8.33,
        totalSale: 78,
        totalAmount: 13586.4,
      },
      {
        percent: 0.87,
        productName: 'KOOKIEJAR TIFFIN CAKE',
        productCode: 'VTC',
        productNameCode: 'KOOKIEJAR TIFFIN CAKE (VTC)',
        price: 4,
        totalSale: 5,
        totalAmount: 1358.5,
      },
      {
        percent: 11.27,
        productName: 'King Kookie',
        productCode: 'KK-S',
        productNameCode: 'King Kookie (KK-S)',
        price: 8.33,
        totalSale: 65,
        totalAmount: 11320.8,
      },
      {
        percent: 5.72,
        productName: "Malai'5",
        productCode: "MAL'5",
        productNameCode: "Malai'5 (MAL'5)",
        price: 4.4,
        totalSale: 33,
        totalAmount: 4983,
      },
      {
        percent: 2.25,
        productName: 'Milk Marie B',
        productCode: 'MM-B',
        productNameCode: 'Milk Marie B (MM-B)',
        price: 40,
        totalSale: 13,
        totalAmount: 2943.41,
      },
      {
        percent: 0.87,
        productName: 'Pure Ghee',
        productCode: 'PGH',
        productNameCode: 'Pure Ghee (PGH)',
        price: 40,
        totalSale: 5,
        totalAmount: 1132.1,
      },
      {
        percent: 5.89,
        productName: 'Choco Momo s',
        productCode: 'CM- S48',
        productNameCode: 'Choco Momo s (CM- S48)',
        price: 4.3,
        totalSale: 34,
        totalAmount: 6240,
      },
      {
        percent: 13,
        productName: 'Fruitly',
        productCode: 'PN -S',
        productNameCode: 'Fruitly (PN -S)',
        price: 4.27,
        totalSale: 75,
        totalAmount: 13927.68,
      },
      {
        percent: 2.6,
        productName: 'Lemon Puff',
        productCode: 'LP',
        productNameCode: 'Lemon Puff (LP)',
        price: 12,
        totalSale: 15,
        totalAmount: 4075.5,
      },
      {
        percent: 0.35,
        productName: 'VERMICELLI',
        productCode: 'VR',
        productNameCode: 'VERMICELLI (VR)',
        price: 30.07,
        totalSale: 2,
        totalAmount: 2723.72,
      },
      {
        percent: 5.89,
        productName: 'FRUITLEY MANGO JUICE',
        productCode: 'FMJ500',
        productNameCode: 'FRUITLEY MANGO JUICE (FMJ500)',
        price: 30.3,
        totalSale: 34,
        totalAmount: 23327.81,
      },
      {
        percent: 0.17,
        productName: 'Lachcha Shemai',
        productCode: 'LS',
        productNameCode: 'Lachcha Shemai (LS)',
        price: 37.88,
        totalSale: 1,
        totalAmount: 893.35,
      },
      {
        percent: 1.56,
        productName: 'Dry Cake',
        productCode: 'DC',
        productNameCode: 'Dry Cake (DC)',
        price: 8.5,
        totalSale: 9,
        totalAmount: 3079.68,
      },
      {
        percent: 1.73,
        productName: 'KOOKIEJAR ENERGY FAMILY',
        productCode: 'EFM',
        productNameCode: 'KOOKIEJAR ENERGY FAMILY (EFM)',
        price: 42,
        totalSale: 10,
        totalAmount: 3209.22,
      },
      {
        percent: 2.25,
        productName: 'Lexus',
        productCode: 'LX',
        productNameCode: 'Lexus (LX)',
        price: 4.25,
        totalSale: 13,
        totalAmount: 2309.4,
      },
    ],
    prices: [
      4, 4.17, 4.25, 4.27, 4.3, 4.4, 8.33, 8.5, 9.11, 12, 17.24, 25.31, 30.07,
      30.3, 32, 37.88, 40, 42,
    ],
    percents: [
      0.17, 0.35, 0.87, 1.21, 1.56, 1.73, 2.25, 2.6, 3.12, 3.81, 5.72, 5.89,
      6.07, 10.4, 11.27, 13, 13.52,
    ],
    message: 'Total sale of Apr 2024',
  },
  totalSKUCurrentMonth: {
    data: [
      {
        location: 'Gazipur',
        BV: 9,
        'BCS-S': 35,
        'CH-T': 18,
        FMJ250: 60,
        GM: 7,
        '24ESTD': 34,
        FP48: 22,
        '24TO': 78,
        VTC: 5,
        'KK-S': 65,
        "MAL'5": 33,
        'MM-B': 13,
        PGH: 5,
        'CM- S48': 34,
        'PN -S': 75,
        LP: 15,
        VR: 2,
        FMJ500: 34,
        LS: 1,
        DC: 9,
        EFM: 10,
        LX: 13,
      },
    ],
    total: {
      BV: {
        percent: 1.56,
        productName: 'Boost Vita',
        productCode: 'BV',
        productNameCode: 'Boost Vita (BV)',
        price: 25.31,
        totalSale: 9,
        totalAmount: 1528.8,
      },
      'BCS-S': {
        percent: 6.07,
        productName: 'Bouncy Standard',
        productCode: 'BCS-S',
        productNameCode: 'Bouncy Standard (BCS-S)',
        price: 9.11,
        totalSale: 35,
        totalAmount: 6189.6,
      },
      'CH-T': {
        percent: 3.12,
        productName: 'Cha- Time',
        productCode: 'CH-T',
        productNameCode: 'Cha- Time (CH-T)',
        price: 4.17,
        totalSale: 18,
        totalAmount: 3396.24,
      },
      FMJ250: {
        percent: 10.4,
        productName: 'FRUITELY MANGO JUICE',
        productCode: 'FMJ250',
        productNameCode: 'FRUITELY MANGO JUICE (FMJ250)',
        price: 17.24,
        totalSale: 60,
        totalAmount: 23421.6,
      },
      GM: {
        percent: 1.21,
        productName: 'Gold Marie',
        productCode: 'GM',
        productNameCode: 'Gold Marie (GM)',
        price: 32,
        totalSale: 7,
        totalAmount: 1690.5,
      },
      '24ESTD': {
        percent: 5.89,
        productName: 'KOOKIEJAR ENERGY STANDARD (24)',
        productCode: '24ESTD',
        productNameCode: 'KOOKIEJAR ENERGY STANDARD (24) (24ESTD)',
        price: 8.33,
        totalSale: 34,
        totalAmount: 6038.4,
      },
      FP48: {
        percent: 3.81,
        productName: 'KOOKIEJAR FRIED PEAS 48',
        productCode: 'FP48',
        productNameCode: 'KOOKIEJAR FRIED PEAS 48 (FP48)',
        price: 4,
        totalSale: 22,
        totalAmount: 3984.82,
      },
      '24TO': {
        percent: 13.52,
        productName: 'KOOKIEJAR TANGY ORANGE (24)',
        productCode: '24TO',
        productNameCode: 'KOOKIEJAR TANGY ORANGE (24) (24TO)',
        price: 8.33,
        totalSale: 78,
        totalAmount: 13586.4,
      },
      VTC: {
        percent: 0.87,
        productName: 'KOOKIEJAR TIFFIN CAKE',
        productCode: 'VTC',
        productNameCode: 'KOOKIEJAR TIFFIN CAKE (VTC)',
        price: 4,
        totalSale: 5,
        totalAmount: 1358.5,
      },
      'KK-S': {
        percent: 11.27,
        productName: 'King Kookie',
        productCode: 'KK-S',
        productNameCode: 'King Kookie (KK-S)',
        price: 8.33,
        totalSale: 65,
        totalAmount: 11320.8,
      },
      "MAL'5": {
        percent: 5.72,
        productName: "Malai'5",
        productCode: "MAL'5",
        productNameCode: "Malai'5 (MAL'5)",
        price: 4.4,
        totalSale: 33,
        totalAmount: 4983,
      },
      'MM-B': {
        percent: 2.25,
        productName: 'Milk Marie B',
        productCode: 'MM-B',
        productNameCode: 'Milk Marie B (MM-B)',
        price: 40,
        totalSale: 13,
        totalAmount: 2943.41,
      },
      PGH: {
        percent: 0.87,
        productName: 'Pure Ghee',
        productCode: 'PGH',
        productNameCode: 'Pure Ghee (PGH)',
        price: 40,
        totalSale: 5,
        totalAmount: 1132.1,
      },
      'CM- S48': {
        percent: 5.89,
        productName: 'Choco Momo s',
        productCode: 'CM- S48',
        productNameCode: 'Choco Momo s (CM- S48)',
        price: 4.3,
        totalSale: 34,
        totalAmount: 6240,
      },
      'PN -S': {
        percent: 13,
        productName: 'Fruitly',
        productCode: 'PN -S',
        productNameCode: 'Fruitly (PN -S)',
        price: 4.27,
        totalSale: 75,
        totalAmount: 13927.68,
      },
      LP: {
        percent: 2.6,
        productName: 'Lemon Puff',
        productCode: 'LP',
        productNameCode: 'Lemon Puff (LP)',
        price: 12,
        totalSale: 15,
        totalAmount: 4075.5,
      },
      VR: {
        percent: 0.35,
        productName: 'VERMICELLI',
        productCode: 'VR',
        productNameCode: 'VERMICELLI (VR)',
        price: 30.07,
        totalSale: 2,
        totalAmount: 2723.72,
      },
      FMJ500: {
        percent: 5.89,
        productName: 'FRUITLEY MANGO JUICE',
        productCode: 'FMJ500',
        productNameCode: 'FRUITLEY MANGO JUICE (FMJ500)',
        price: 30.3,
        totalSale: 34,
        totalAmount: 23327.81,
      },
      LS: {
        percent: 0.17,
        productName: 'Lachcha Shemai',
        productCode: 'LS',
        productNameCode: 'Lachcha Shemai (LS)',
        price: 37.88,
        totalSale: 1,
        totalAmount: 893.35,
      },
      DC: {
        percent: 1.56,
        productName: 'Dry Cake',
        productCode: 'DC',
        productNameCode: 'Dry Cake (DC)',
        price: 8.5,
        totalSale: 9,
        totalAmount: 3079.68,
      },
      EFM: {
        percent: 1.73,
        productName: 'KOOKIEJAR ENERGY FAMILY',
        productCode: 'EFM',
        productNameCode: 'KOOKIEJAR ENERGY FAMILY (EFM)',
        price: 42,
        totalSale: 10,
        totalAmount: 3209.22,
      },
      LX: {
        percent: 2.25,
        productName: 'Lexus',
        productCode: 'LX',
        productNameCode: 'Lexus (LX)',
        price: 4.25,
        totalSale: 13,
        totalAmount: 2309.4,
      },
    },
    products: [
      '24ESTD',
      '24TO',
      'BCS-S',
      'BV',
      'CH-T',
      'CM- S48',
      'DC',
      'EFM',
      'FMJ250',
      'FMJ500',
      'FP48',
      'GM',
      'KK-S',
      'LP',
      'LS',
      'LX',
      "MAL'5",
      'MM-B',
      'PGH',
      'PN -S',
      'VR',
      'VTC',
    ],
  },
  topSKU: {
    data: [
      {
        productName: 'Choco Momo s',
        productCode: 'CM- S48',
        productNameCode: 'Choco Momo s (CM- S48)',
        totalQuantity: 1870,
        'Sep 2023': 6,
        'Oct 2023': 51,
        'Nov 2023': 119,
        'Dec 2023': 368,
        'Jan 2024': 833,
        'Feb 2024': 289,
        'Mar 2024': 170,
        'Apr 2024': 34,
      },
      {
        productName: 'Fruitly',
        productCode: 'PN -S',
        productNameCode: 'Fruitly (PN -S)',
        totalQuantity: 1125,
        'Sep 2023': 25,
        'Oct 2023': 50,
        'Nov 2023': 225,
        'Dec 2023': 250,
        'Jan 2024': 250,
        'Mar 2024': 75,
        'Apr 2024': 75,
        'Feb 2024': 175,
      },
      {
        productName: 'KOOKIEJAR TANGY ORANGE (24)',
        productCode: '24TO',
        productNameCode: 'KOOKIEJAR TANGY ORANGE (24) (24TO)',
        totalQuantity: 1027,
        'Sep 2023': 26,
        'Oct 2023': 78,
        'Nov 2023': 182,
        'Dec 2023': 286,
        'Jan 2024': 234,
        'Feb 2024': 104,
        'Mar 2024': 39,
        'Apr 2024': 78,
      },
      {
        productName: 'Cha- Time',
        productCode: 'CH-T',
        productNameCode: 'Cha- Time (CH-T)',
        totalQuantity: 876,
        'Sep 2023': 8,
        'Nov 2023': 64,
        'Dec 2023': 164,
        'Jan 2024': 385,
        'Feb 2024': 125,
        'Mar 2024': 112,
        'Apr 2024': 18,
      },
      {
        productName: 'King Kookie',
        productCode: 'KK-S',
        productNameCode: 'King Kookie (KK-S)',
        totalQuantity: 611,
        'Sep 2023': 26,
        'Oct 2023': 52,
        'Nov 2023': 130,
        'Jan 2024': 130,
        'Feb 2024': 117,
        'Dec 2023': 39,
        'Mar 2024': 52,
        'Apr 2024': 65,
      },
      {
        productName: 'Bouncy Standard',
        productCode: 'BCS-S',
        productNameCode: 'Bouncy Standard (BCS-S)',
        totalQuantity: 588,
        'Sep 2023': 28,
        'Oct 2023': 21,
        'Nov 2023': 98,
        'Dec 2023': 112,
        'Jan 2024': 140,
        'Mar 2024': 63,
        'Apr 2024': 35,
        'Feb 2024': 91,
      },
      {
        productName: 'Kookie Time',
        productCode: 'KT',
        productNameCode: 'Kookie Time (KT)',
        totalQuantity: 461,
        'Sep 2023': 17,
        'Oct 2023': 68,
        'Nov 2023': 103,
        'Dec 2023': 104,
        'Jan 2024': 130,
        'Feb 2024': 39,
      },
    ],
    months: [
      'Sep 2023',
      'Oct 2023',
      'Nov 2023',
      'Dec 2023',
      'Jan 2024',
      'Feb 2024',
      'Mar 2024',
      'Apr 2024',
    ],
    message: 'Top 7 SKU of 12 months',
  },
  bottomSKU: {
    data: [
      {
        productName: 'FRUITELY MANGO JUICE',
        productCode: 'FMJ250',
        productNameCode: 'FRUITELY MANGO JUICE (FMJ250)',
        totalQuantity: 78,
        'Apr 2024': 60,
        'Mar 2024': 18,
      },
      {
        productName: 'Chocotina',
        productCode: 'MCT',
        productNameCode: 'Chocotina (MCT)',
        totalQuantity: 53,
        'Feb 2024': 35,
        'Mar 2024': 18,
      },
      {
        productName: 'FRUITLEY MANGO JUICE',
        productCode: 'FMJ500',
        productNameCode: 'FRUITLEY MANGO JUICE (FMJ500)',
        totalQuantity: 49,
        'Mar 2024': 15,
        'Apr 2024': 34,
      },
      {
        productName: 'Bouncy Mini',
        productCode: 'BCS-M',
        productNameCode: 'Bouncy Mini (BCS-M)',
        totalQuantity: 34,
        'Sep 2023': 17,
        'Dec 2023': 17,
      },
      {
        productName: 'STICK NOODLES',
        productCode: 'NDCH120',
        productNameCode: 'STICK NOODLES (NDCH120)',
        totalQuantity: 33,
        'Mar 2024': 33,
      },
      {
        productName: 'VERMICELLI',
        productCode: 'VR',
        productNameCode: 'VERMICELLI (VR)',
        totalQuantity: 12,
        'Mar 2024': 10,
        'Apr 2024': 2,
      },
      {
        productName: 'Lachcha Shemai',
        productCode: 'LS',
        productNameCode: 'Lachcha Shemai (LS)',
        totalQuantity: 1,
        'Apr 2024': 1,
      },
    ],
    months: [
      'Sep 2023',
      'Oct 2023',
      'Nov 2023',
      'Dec 2023',
      'Jan 2024',
      'Feb 2024',
      'Mar 2024',
      'Apr 2024',
    ],
    message: 'Bottom 7 SKU of 12 months',
  },
  hqTotalSKU: {
    data: [
      {
        location: 'Tongi',
        'Sep 2023': 222,
        'Oct 2023': 317,
        'Nov 2023': 221,
        'Dec 2023': 159,
        'Jan 2024': 325,
        'Feb 2024': 409,
        'Mar 2024': 58,
      },
      {
        location: 'Gazipur',
        'Oct 2023': 214,
        'Nov 2023': 865,
        'Dec 2023': 1815,
        'Jan 2024': 2180,
        'Feb 2024': 1289,
        'Mar 2024': 840,
        'Apr 2024': 577,
      },
      {
        location: 'Savar',
        'Nov 2023': 272,
        'Jan 2024': 281,
      },
    ],
    months: [
      'Sep 2023',
      'Oct 2023',
      'Nov 2023',
      'Dec 2023',
      'Jan 2024',
      'Feb 2024',
      'Mar 2024',
      'Apr 2024',
    ],
    message: 'HQ wise total SKU',
  },
  hqTransactionAmount: {
    data: [
      {
        location: 'Tongi',
        'Sep 2023': 40360.83,
        'Oct 2023': 59982.41,
        'Nov 2023': 40337.78,
        'Dec 2023': 30043.18,
        'Jan 2024': 62918.84,
        'Feb 2024': 76363.51,
        'Mar 2024': 10023.76,
      },
      {
        location: 'Gazipur',
        'Oct 2023': 39938.63,
        'Nov 2023': 170323.45,
        'Dec 2023': 347673.43,
        'Jan 2024': 417594.32,
        'Feb 2024': 248754.7,
        'Mar 2024': 188972.42,
        'Apr 2024': 141360.51,
      },
      {
        location: 'Savar',
        'Nov 2023': 50449.91,
        'Jan 2024': 57084.23,
      },
    ],
    months: [
      'Sep 2023',
      'Oct 2023',
      'Nov 2023',
      'Dec 2023',
      'Jan 2024',
      'Feb 2024',
      'Mar 2024',
      'Apr 2024',
    ],
    message: 'All HQ invoice amount of 12 months',
  },
  hqTransactionQuantity: {
    data: [
      {
        month: 'Sep 2023',
        Tongi: 1,
      },
      {
        month: 'Oct 2023',
        Tongi: 2,
        Gazipur: 1,
      },
      {
        month: 'Nov 2023',
        Tongi: 1,
        Gazipur: 3,
        Savar: 1,
      },
      {
        month: 'Dec 2023',
        Tongi: 1,
        Gazipur: 9,
      },
      {
        month: 'Jan 2024',
        Gazipur: 13,
        Savar: 2,
        Tongi: 1,
      },
      {
        month: 'Feb 2024',
        Gazipur: 10,
        Tongi: 2,
      },
      {
        month: 'Mar 2024',
        Gazipur: 7,
        Tongi: 1,
      },
      {
        month: 'Apr 2024',
        Gazipur: 6,
      },
    ],
    allHq: ['Gazipur', 'Savar', 'Tongi'],
    message: 'HQ wise memo count',
  },
  hqTotalCollection: {
    data: [
      {
        location: 'Tongi',
        'Sep 2023': 40000,
        'Oct 2023': 60000,
        'Nov 2023': 70000,
      },
      {
        location: 'Gazipur',
        'Oct 2023': 71190,
        'Nov 2023': 188220,
        'Dec 2023': 319520,
        'Jan 2024': 282000,
        'Mar 2024': 206500,
        'Apr 2024': 156000,
      },
      {
        location: 'Savar',
        'Nov 2023': 50002,
        'Jan 2024': 27000,
      },
    ],
    months: [
      'Sep 2023',
      'Oct 2023',
      'Nov 2023',
      'Dec 2023',
      'Jan 2024',
      'Mar 2024',
      'Apr 2024',
    ],
    message: 'HQ wise total collections',
  },
  collectionTableData: {
    data: [
      {
        location: 'Gazipur',
        monthKey: 'Apr 2024',
        liftingValue: 141360.51,
        liftingQuantity: 577,
        collectionEntry: 156000,
        approvedCollection: 155880,
        OnlineCharge: 120,
        Incentive: 900,
      },
    ],
    message: 'Collections of Apr 2024',
  },
};

export default function CollectionAnalysis() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();

  const matches_sm = useMediaQuery('(max-width:600px)');

  const [rangeValue, setRangeValue] = useState([0, 5]);
  const [timeline, setTimeline] = useState(4);
  const [date, setDate] = useState(dayjs());
  const [territoryList, setTerritoryList] = useState([]);
  const [territory, setTerritory] = useState({});
  const [territoryType, setTerritoryType] = useState({
    name: 'Region',
    id: '63aaa3c505e4dc7daa1a85ce',
  });
  const [largeView, setLargeView] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState();

  useEffect(() => {
    const t = territory.name
      ? territoryType.name + ': ' + territory.name
      : territoryType.name;
    setHeader({ title: `Collections - ${t}` });
  }, [territoryType.name, territory.name]);

  useEffect(() => {
    axiosPrivate
      .get(`/api/v1/territory?limit=none&territoryType=${territoryType?.id}`)
      .then((res) => {
        setTerritoryList(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [axiosPrivate, territoryType]);

  const {
    data: dataX,
    isLoading,
    isError,
    error,
  } = useQuery(
    [
      'collection-dashboard',
      territory?.id,
      date.format('YYYY-MM-DD'),
      timeline,
    ],
    ({ queryKey }) => {
      const [key, territory, month, compareMonths] = queryKey;
      if (Object.keys(territory).length !== 0) {
        return axiosPrivate
          .get(
            `/api/v1/report/dashboard-collection?territory=${territory}&month=${month}&compareMonths=${compareMonths}&compareProducts=7`,
          )
          .then((d) => d?.data?.data);
      }
    },
  );

  useEffect(() => {
    setRangeValue([
      data?.totalSaleCurrentMonth?.prices[0],
      data?.totalSaleCurrentMonth?.prices[0],
    ]);
  }, [data]);

  const productDataList = data?.totalSaleCurrentMonth?.data.filter((item) => {
    return item?.price >= rangeValue[0] && item?.price <= rangeValue[1];
  });

  const renderCustomizedLabel = (props) => {
    const { x, y, width, index, height, value } = props;
    const radius = 10;

    if (value) {
      return (
        <g>
          <circle
            cx={x + width / 2}
            cy={y - radius}
            r={radius}
            fill="#8884d8"
          />
          <text
            x={x + width / 2}
            y={y - radius}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={10}>
            {value}
          </text>
        </g>
      );
    }
  };
  // console.log('SKU DASHBOARD DATA', data);

  const toggleFullScreen = () => {
    const element = document.getElementById('container');
    const isFullScreenView = document.fullscreenElement;
    console.log(isFullScreenView);
    if (isFullScreenView) {
      document.exitFullscreen();
      setIsFullScreen(false);
    } else {
      element.requestFullscreen();
      setIsFullScreen(true);
    }
  };

  if (isError) {
    expired(error);
  }

  return (
    <Stack
      gap={2}
      id="container"
      sx={{
        overflowY: isFullScreen ? 'scroll' : 'initial',
        p: isFullScreen ? 2 : 0,
        pt: isFullScreen ? 4 : 0,
        background: '#e3e7ef',
      }}>
      <Stack
        component={Paper}
        gap={2}
        alignItems="center"
        sx={{ p: 3 }}
        position="relative">
        <HeadingTitle variant={0}>Collection Analysis</HeadingTitle>
        {/* filter */}
        <Stack direction={{ md: 'row' }} gap={2}>
          <Autocomplete
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={[
              { name: 'Region', id: '63aaa3c505e4dc7daa1a85ce' },
              { name: 'HQ', id: '63aaa46405e4dc7daa1a8648' },
            ]}
            getOptionLabel={(option) => option.name || ''}
            value={territoryType}
            onChange={(event, value) => {
              setTerritoryType(value);
              setTerritory({});
            }}
            renderInput={(params) => (
              <TextField {...params} label="Location Type" />
            )}
          />
          <Autocomplete
            loading
            sx={{ width: { md: 150 } }}
            disableClearable
            size="small"
            disablePortal
            options={territoryList}
            getOptionLabel={(option) => option.name || ''}
            value={territory}
            onChange={(event, value) => setTerritory(value)}
            renderInput={(params) => (
              <TextField {...params} label={territoryType.name} />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Select Month"
              inputFormat="MMM YYYY"
              views={['month', 'year']}
              value={date}
              onChange={(newValue) => setDate(newValue || null)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: { md: 160 } }}
                />
              )}
            />
          </LocalizationProvider>
          <Autocomplete
            loading
            sx={{ width: { md: 170 } }}
            disableClearable
            size="small"
            disablePortal
            options={[4, 6, 8, 12]}
            getOptionLabel={(option) => 'Last ' + option + ' months' || ''}
            value={timeline}
            onChange={(event, value) => setTimeline(value)}
            renderInput={(params) => (
              <TextField {...params} label="Choose Timeline" />
            )}
          />
          {/* <FormControl size="small">
            <InputLabel>Choose Timeline</InputLabel>
            <Select
              value={timeline}
              label="Choose Timeline"
              onChange={(e) => setTimeline(e.target.value)}>
              <MenuItem value={4}>Last 4 months</MenuItem>
              <MenuItem value={6}>Last 6 months</MenuItem>
              <MenuItem value={8}>Last 8 months</MenuItem>
              <MenuItem value={12}>Last 12 months</MenuItem>
            </Select>
          </FormControl> */}
          <ToggleButton
            sx={{ display: { lg: 'flex', xs: 'none' } }}
            size="small"
            value="check"
            selected={largeView}
            onChange={() => {
              setLargeView(!largeView);
            }}>
            <GridViewIcon />
          </ToggleButton>
          <IconButton
            onClick={toggleFullScreen}
            sx={{
              display: { lg: 'flex', xs: 'none' },
              position: 'absolute',
              top: 5,
              right: 5,
            }}>
            {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          component={Paper}
          justifyContent="center"
          alignItems="center"
          height={{ xs: '30vh', md: '50vh' }}
          gap={2}>
          <CircularProgress />
          <Typography color="text.disabled">
            Loading{' '}
            <b>
              {territory.name} {territoryType.name}
            </b>{' '}
            Data
          </Typography>
        </Stack>
      ) : (
        <>
          {Object.keys(territory).length === 0 ? (
            <Stack
              component={Paper}
              height={{ xs: '30vh', md: '50vh' }}
              justifyContent="center"
              alignItems="center">
              <Typography fontSize={{ xs: 18, md: 35 }} fontWeight={800}>
                Please Select a {territoryType.name}
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack direction={{ lg: largeView ? 'column' : 'row' }} gap={2}>
                {/* TOTAL SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOTAL SKUS (ctn) */}
                    {data?.hqTotalSKU?.message}
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTotalSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTotalSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTotalSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
                {/* TOTAL COLLECTION */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOTAL COLLECTION (tk) */}
                    {data?.hqTotalCollection?.message}
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTotalCollection?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTotalCollection?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 20,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTotalCollection?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={TotalSKUColors[index % TotalSKUColors.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              <Stack direction={{ lg: largeView ? 'column' : 'row' }} gap={2}>
                {/* TOP 7 SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* TOP 7 SKUS (ctn) */}
                    {data?.topSKU?.message}
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.topSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.topSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="productCode"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.topSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={SKUCOLORS[index % SKUCOLORS.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
                {/* BOTTOM 7 SKUS */}
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* BOTTOM 7 SKUS (ctn) */}
                    {data?.bottomSKU?.message}
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.bottomSKU?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.bottomSKU?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="productCode"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.bottomSKU?.months?.map((item, index) => (
                          <Bar
                            dataKey={item}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              {/* SKU PIE CHART RUNNING MONTH */}
              <Paper sx={{ p: 2 }}>
                <Stack direction={{ md: 'row' }} justifyContent="space-between">
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase">
                    {/* SKU PIE CHART RUNNING MONTH */}
                    {data?.totalSaleCurrentMonth.message}
                  </Typography>
                  <Box sx={{ width: { md: 250 } }}>
                    {data?.totalSaleCurrentMonth?.data.length === 0 ? null : (
                      <Slider
                        color="secondary"
                        value={rangeValue}
                        onChange={(event, newValue) => {
                          setRangeValue(newValue);
                        }}
                        valueLabelDisplay="on"
                        // marks={pieInfo}
                        max={
                          data?.totalSaleCurrentMonth?.prices[
                            data?.totalSaleCurrentMonth?.prices.length - 1
                          ]
                        }
                      />
                    )}
                  </Box>
                </Stack>
                <ResponsiveContainer width="100%" height={400}>
                  {data?.totalSaleCurrentMonth?.data.length === 0 ? (
                    <NoData />
                  ) : (
                    <PieChart width={400} height={300}>
                      <Pie
                        data={productDataList}
                        cx="50%"
                        cy="50%"
                        labelLine={true}
                        label={(item) => {
                          return `${item.payload.productCode} ${item.payload.price}tk ${item.payload.totalSale} ctn ${item?.payload?.percent}% `;
                        }}
                        outerRadius={matches_sm ? 50 : 150}
                        fill="#8884d8"
                        dataKey="totalSale">
                        {data?.totalSaleCurrentMonth?.data.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ),
                        )}
                      </Pie>
                    </PieChart>
                  )}
                </ResponsiveContainer>
              </Paper>

              <Stack direction={{ xl: largeView ? 'column' : 'row' }} gap={2}>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography fontSize={18} fontWeight={700} textAlign="center">
                    NUMBER OF TRANSACTIONS {timeline} MONTHS
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTransactionQuantity?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTransactionQuantity?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 5,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="month"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTransactionQuantity?.allHq.map(
                          (item, index) => (
                            <Bar
                              dataKey={item}
                              fill={COLORS2[index % COLORS2.length]}>
                              <LabelList
                                dataKey={item}
                                position="top"
                                content={renderCustomizedLabel}
                              />
                            </Bar>
                          ),
                        )}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>

                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography fontSize={18} fontWeight={700} textAlign="center">
                    TRANSACTIONS AMOUNT (tk)
                  </Typography>
                  <ResponsiveContainer width="100%" height={300}>
                    {data?.hqTransactionAmount?.data.length === 0 ? (
                      <NoData />
                    ) : (
                      <BarChart
                        width={500}
                        height={250}
                        data={data?.hqTransactionAmount?.data}
                        margin={{
                          top: 20,
                          right: 0,
                          left: 20,
                          bottom: 0,
                        }}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="location"
                          tickLine={false}
                          axisLine={true}
                        />
                        <YAxis tickLine={false} axisLine={false} />
                        <Tooltip allowEscapeViewBox={{ y: true }} />
                        <Legend verticalAlign="bottom" height={36} />

                        {data?.hqTransactionAmount?.months?.map(
                          (item, index) => (
                            <Bar
                              dataKey={item}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ),
                        )}
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                </Paper>
              </Stack>

              <Stack gap={2}>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase"
                    pb={1}>
                    {/* {data?.totalSKUCurrentMonth?.message} */}
                    TOTAL SKU {dayjs(date).format('MMMM YYYY')}
                  </Typography>
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>HQ</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product) => (
                              <TableCell>{product}</TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableHead>
                      {/* ---------- */}
                      <TableBody>
                        {data?.totalSKUCurrentMonth?.data.length === 0 ? (
                          <TableRow>
                            <TableCell style={{ padding: 40 }}>
                              <NoData />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data?.totalSKUCurrentMonth?.data?.map((item) => (
                              <TableRow>
                                <TableCell>{item?.location}</TableCell>
                                {data?.totalSKUCurrentMonth?.products?.map(
                                  (product) => (
                                    <TableCell>{item?.[product]} ctn</TableCell>
                                  ),
                                )}
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                      <TableFooter sx={{ td: { fontWeight: 'bold' } }}>
                        <TableRow>
                          <TableCell>Total Quantity</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .totalSale
                                }
                                {' ctn'}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Total Value</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .totalAmount
                                }
                                {' tk'}
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell>Contribution</TableCell>
                          {data?.totalSKUCurrentMonth?.products?.map(
                            (product, index) => (
                              <TableCell key={index}>
                                {
                                  data?.totalSKUCurrentMonth?.total[product]
                                    .percent
                                }
                                %
                              </TableCell>
                            ),
                          )}
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Paper>
                <Paper sx={{ p: 2, width: '100%' }}>
                  <Typography
                    fontSize={18}
                    fontWeight={700}
                    textAlign="center"
                    textTransform="uppercase"
                    pb={1}>
                    COLLECTION OVERVIEW {dayjs(date).format('MMMM YYYY')}
                  </Typography>
                  <TableContainer sx={tableStyle}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>HQ</TableCell>
                          <TableCell>Lifting Quantity</TableCell>
                          <TableCell>Lifting Value</TableCell>
                          <TableCell>Collection Entry</TableCell>
                          <TableCell>Approved Collection</TableCell>
                          <TableCell>Online Charge</TableCell>
                          <TableCell>Incentive</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.collectionTableData?.data.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7} height={150} align="center">
                              No Data Found
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {data?.collectionTableData?.data?.map(
                              (item, index) => (
                                <TableRow>
                                  <TableCell sx={{ fontWeight: 600 }}>
                                    {item.location}
                                  </TableCell>
                                  <TableCell>
                                    {item.liftingQuantity} ctn
                                  </TableCell>
                                  <TableCell>{item.liftingValue} tk</TableCell>
                                  <TableCell>
                                    {item.collectionEntry} tk
                                  </TableCell>
                                  <TableCell>
                                    {item.approvedCollection} tk
                                  </TableCell>
                                  <TableCell>{item.OnlineCharge} tk</TableCell>
                                  <TableCell>{item.Incentive} tk</TableCell>
                                </TableRow>
                              ),
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Stack>
            </>
          )}
        </>
      )}
    </Stack>
  );
}

const tableStyle = {
  table: {
    tr: {
      th: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
        '&:first-child': {
          fontSize: 16,
          fontWeight: 600,
        },
      },

      td: {
        fontSize: 14,
        padding: 1,
        whiteSpace: 'nowrap',
      },
    },
    thead: {
      'tr:last-child': {
        th: {
          background: (theme) => theme.palette.primary.main,
          color: '#fff',
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
  },
};

function NoData() {
  return (
    <Stack justifyContent="center" alignItems="center" height={1} width={1}>
      <Typography>No Data Found</Typography>
    </Stack>
  );
}
