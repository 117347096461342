import { Stack } from '@mui/material';
import React from 'react';

import DashTitle from '../dashBoard/DashTitle';

function Title({ title = 'Title', children }) {
  return (
    <Stack
      direction={{ md: 'row' }}
      gap={2}
      mb={2}
      alignItems="center"
      justifyContent="space-between">
      <DashTitle title={title} />

      <Stack
        direction={{ sm: 'row' }}
        gap={2}
        alignItems="center"
        justifyContent="flex-end">
        {children}
      </Stack>
    </Stack>
  );
}

export default Title;
