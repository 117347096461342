// import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import customstyles from '../../../src/assets/css/dynamicForm.module.css';
import CompanyNames from '../../../src/staticData/CompanyNames';
import ProductCategories from '../../../src/staticData/ProductCategories';

function DynamicForm({ companies, setCompanies, setError }) {
  const handleChange = (index, field, value) => {
    const updatedCompanies = [...companies];
    updatedCompanies[index][field] = value;
    setCompanies(updatedCompanies);
  };

  const handleProductChange = (companyIndex, productIndex, field, value) => {
    const updatedCompanies = [...companies];
    updatedCompanies[companyIndex].categorySales[productIndex][field] = value;
    setCompanies(updatedCompanies);
  };

  const addCompany = () => {
    setCompanies([
      ...companies,
      { companyName: '', categorySales: [{ title: '', amount: '' }] },
    ]);
  };

  const addProduct = (companyIndex) => {
    const updatedCompanies = [...companies];
    updatedCompanies[companyIndex].categorySales.push({
      title: '',
      amount: '',
    });
    setCompanies(updatedCompanies);
  };

  const removeCompany = (index) => {
    const updatedCompanies = [...companies];
    updatedCompanies.splice(index, 1);
    setCompanies(updatedCompanies);
  };

  const removeProduct = (companyIndex, productIndex) => {
    const updatedCompanies = [...companies];
    updatedCompanies[companyIndex].categorySales.splice(productIndex, 1);
    setCompanies(updatedCompanies);
  };

  // useEffect(() => {
  //   const formData = [...companies];

  //   formData.forEach((company, companyIndex) => {
  //     if (company.companyName === '') {
  //       company.nameError = 'Company name is required';
  //       // formData[index].nameCheck = 'Company name is required';
  //     } else {
  //       delete company.nameError;
  //     }

  //     company.categorySales.forEach((cat, catIndex) => {
  //       if (cat.title === '') {
  //         cat.titleError = 'error';
  //       } else {
  //         delete cat.titleError;
  //       }
  //       if (cat.amount === '') {
  //         cat.amountError = 'error';
  //       } else {
  //         delete cat.amountError;
  //       }
  //     });
  //   });

  //   setCompanies(formData);
  // }, [companies]);

  const handleSubmit = () => {
    // const formattedData = companies.filter(
    //   (company) =>
    //     company.companyName !== '' && company.categorySales.length > 0,
    // );

    const formData = [...companies];

    // formData.forEach((company, companyIndex) => {
    //   if (company.companyName === '') {
    //     company.nameError = 'Company name is required';
    //     // formData[index].nameCheck = 'Company name is required';
    //   } else {
    //     delete company.nameError;
    //   }

    //   company.categorySales.forEach((cat, catIndex) => {
    //     if (cat.title === '') {
    //       cat.titleError = 'error';
    //     } else {
    //       delete cat.titleError;
    //     }
    //     if (cat.amount === '') {
    //       cat.amountError = 'error';
    //     } else {
    //       delete cat.amountError;
    //     }
    //   });
    // });

    formData.forEach((company, companyIndex) => {
      if (company.companyName === '') {
        company.nameError = 'Company name is required';
        setError(true);
      } else {
        delete company.nameError;
        setError(false);
      }

      company.categorySales.forEach((cat, catIndex) => {
        if (cat.title === '') {
          cat.titleError = 'Category is required';
          setError(true);
        } else {
          delete cat.titleError;
          setError(false);
        }
        if (cat.amount === '') {
          cat.amountError = 'Sales amount is required';
          setError(true);
        } else {
          delete cat.amountError;
          setError(false);
        }
      });
    });

    setCompanies(formData);
  };

  return (
    <>
      {companies.map((company, companyIndex) => (
        <TableContainer key={companyIndex} sx={{ border: '1px solid gray' }}>
          <Table
            sx={{
              th: {
                p: 0.5,
                textAlign: 'center',
                bgcolor: (theme) => `${theme.palette.primary.main}c0`,
                color: '#ffffff',
              },
              td: { p: 0.5, fontSize: 12, whiteSpace: 'nowrap' },
            }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Box>
                      <select
                        style={{
                          // color: company.nameError ? 'red' : '#000000',
                          outline: '2px solid #ffffff',
                          border: company.nameError
                            ? '2px solid #ff0000c0'
                            : '2px solid #bdbdbdc0',
                        }}
                        placeholder="Company Name"
                        value={company.companyName}
                        onChange={(e) =>
                          handleChange(
                            companyIndex,
                            'companyName',
                            e.target.value,
                          )
                        }
                        className={customstyles.companySelect}>
                        <option value="" disabled>
                          Select company
                        </option>
                        {CompanyNames?.sort()?.map(
                          (companyName, companyNameIndex) => {
                            const isDisabled = companies
                              .map((item) => item.companyName)
                              .includes(companyName);
                            return (
                              <option
                                disabled={isDisabled}
                                key={companyNameIndex}
                                value={companyName}>
                                {companyName}
                              </option>
                            );
                          },
                        )}
                      </select>
                      {/* {company.nameError ? (
                        <Typography
                          color="error"
                          fontSize={14}
                          sx={{ background: '#ffffff', borderRadius: 1 }}
                          // sx={{ textShadow: '0px 1px 0px #ffffff' }}
                        >
                          {company?.nameError}
                        </Typography>
                      ) : null} */}
                    </Box>
                    {companies.length <= 1 ? null : (
                      <IconButton
                        onClick={() => removeCompany(companyIndex)}
                        disableRipple
                        sx={{
                          p: 0,
                          width: 25,
                          height: 25,
                          background: 'white',
                        }}>
                        <DeleteForeverIcon
                          sx={{ fontSize: 18 }}
                          color="error"
                        />
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {company.categorySales.map((product, productIndex) => (
                <TableRow key={productIndex}>
                  <TableCell>
                    <select
                      style={{
                        border: product?.titleError
                          ? '2px solid #ff0000c0'
                          : '2px solid #bdbdbdc0',
                      }}
                      placeholder="Product Title"
                      className={customstyles.productCatSelect}
                      value={product.title}
                      onChange={(e) =>
                        handleProductChange(
                          companyIndex,
                          productIndex,
                          'title',
                          e.target.value,
                        )
                      }>
                      <option value="" disabled>
                        Select category
                      </option>
                      {ProductCategories?.sort()?.map((cat, indx) => {
                        const isDisabled = companies[
                          companyIndex
                        ].categorySales.some(
                          (prod, prodIndex) =>
                            prod.title === cat && prodIndex !== productIndex,
                        );
                        return (
                          <option key={indx} value={cat} disabled={isDisabled}>
                            {cat}
                          </option>
                        );
                      })}
                    </select>
                    {/* {product?.titleError ? (
                      <p style={{ color: 'red' }}>{product?.titleError}</p>
                    ) : null} */}
                  </TableCell>
                  <TableCell>
                    <InputBase
                      fullWidth
                      name="approxSale"
                      sx={{
                        '.MuiInputBase-input': { p: '5px' },
                        fontSize: 12,
                        border: product?.amountError
                          ? '2px solid #ff0000c0'
                          : '2px solid #bdbdbdc0',
                      }}
                      placeholder="Approx Sales (Tk)"
                      type="number"
                      value={product.amount}
                      inputProps={{ min: 0 }}
                      onChange={(e) =>
                        handleProductChange(
                          companyIndex,
                          productIndex,
                          'amount',
                          e.target.value,
                        )
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                    {/* {product?.amountError ? (
                      <p style={{ color: 'red' }}>{product?.amountError}</p>
                    ) : null} */}
                  </TableCell>
                  <TableCell>
                    {company.categorySales.length <= 1 ? null : (
                      <IconButton
                        onClick={() =>
                          removeProduct(companyIndex, productIndex)
                        }
                        disableRipple
                        sx={{ p: 0 }}>
                        <DeleteForeverIcon
                          sx={{ fontSize: 18 }}
                          color="error"
                        />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} sx={{ textAlign: 'center' }}>
                  <Button
                    fullWidth
                    onClick={() => addProduct(companyIndex)}
                    variant="contained"
                    disableRipple
                    sx={{ py: 0.5, fontSize: 12 }}>
                    Add&nbsp;
                    <b>
                      {company.companyName ? company.companyName : 'Company'}'s
                    </b>
                    &nbsp;Category
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}

      <Button onClick={addCompany} variant="contained">
        Add Company
      </Button>

      {/* {error ? (
        <Typography
          sx={{
            bgcolor: 'error.main',
            color: '#ffffff',
            p: 1,
            borderRadius: 1,
            textAlign: 'center',
            fontSize: 12,
          }}>
          Please fill all the company's field
        </Typography>
      ) : null} */}
      {/* <button onClick={handleSubmit}>Submit</button> */}
    </>
  );
}

export default DynamicForm;
