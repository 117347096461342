import React, { useEffect, useMemo, useState } from 'react';
import ReportIcon from '@mui/icons-material/TableChartSharp';
import SeparateIcon from '@mui/icons-material/ViewListSharp';
import {
  Autocomplete,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { DateRangeColumnFilter } from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import Title from '../../../../component/report/Title';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import roundToDigit from '../../../../utils/roundToDigit';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';
import Ledger from '../Ledger/Ledger';

function LedgerReport() {
  const params = useParams();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [customer, setCustomer] = useState({ id: params?.customerID });
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);

  const [tab, setTab] = useState('vertical');

  const isReport = useMemo(
    () => Boolean(location.pathname.startsWith('/customer-ledger-report')),
    [location.pathname],
  );

  // get all customers
  useEffect(() => {
    if (isReport) {
      setIsLoadingCustomers(true);
      axiosPrivate
        .get('/api/v1/customer?populate=yes&limit=none&sort=name')
        .then((res) => setAllCustomers(res?.data?.data))
        .catch((error) => expired(error))
        .finally(() => setIsLoadingCustomers(false));
    }
  }, [isReport]);

  // get report data
  async function getLedgerReport({ queryKey: [key, id] }) {
    if (!id) return [];

    const res = await axiosPrivate.get(
      `/api/v1/report/customer-ledger?customerId=${id}`,
    );

    return res?.data?.data;
  }

  const { data, isLoading, isError, error } = useQuery(
    ['customer-ledger-report', customer?.id],
    getLedgerReport,
  );

  if (isError) {
    expired(error);
  }

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'date',
        Cell: ({ row }) => {
          return row?.original?.dateF;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Opening',
        accessor: 'opening',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Sales',
        accessor: 'sales',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Deposit',
        accessor: 'deposit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Transport',
        accessor: 'transport',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Return',
        accessor: 'return',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Comm',
        accessor: 'commission',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Salary',
        accessor: 'salary',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Bank Ch.',
        accessor: 'cost',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Damage',
        accessor: 'damage',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Total Debit',
        accessor: 'totalDebit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Total Credit',
        accessor: 'totalCredit',
        Cell: ({ value }) => roundToDigit(value),
      },
      {
        Header: 'Closing',
        accessor: 'closing',
        Cell: ({ value }) => roundToDigit(value),
      },
    ],
    [],
  );

  const DataView = useMemo(
    () => (
      <>
        {customer?.name ? (
          <Typography variant="h6" sx={{ pt: 1 }}>
            {`${customer?.nameCode} - ${customer?.customerType?.customerType} - ${customer?.territory?.name}`}
          </Typography>
        ) : null}

        {!customer?.id ? (
          <Paper variant="outlined" sx={{ mt: 4, p: 2 }}>
            <Typography color="text.disabled" align="center" fontSize={28}>
              Select a customer from above to see their ledger
            </Typography>
          </Paper>
        ) : (
          <>
            {tab === 'vertical' ? (
              <Ledger customerID={customer?.id} LedgerReport />
            ) : (
              <Table
                columns={COLUMNS}
                data={data || []}
                isLoading={isLoading}
                filename="Customer Ledger"
                hideAddIcon
                noWrap
              />
            )}
          </>
        )}
      </>
    ),
    [isLoading, tab],
  );

  if (isReport) {
    return (
      <>
        <Title title="Customer Ledger">
          <Autocomplete
            size="small"
            loading={isLoadingCustomers}
            options={allCustomers}
            getOptionLabel={(option) =>
              option?.name
                ? `${option?.nameCode} - ${option?.customerType?.customerType} - ${option?.territory?.name}`
                : ''
            }
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            value={customer}
            onChange={(event, value) => setCustomer(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select customer"
                sx={{ width: { md: 300 } }}
              />
            )}
          />

          <ToggleButtonGroup
            aria-label="Ledger view format"
            color="success"
            size="small"
            exclusive={true}
            value={tab}
            onChange={(event, value) => setTab(value)}>
            <ToggleButton
              value="vertical"
              aria-label="Vertical view"
              title="Vertical view">
              <SeparateIcon />
            </ToggleButton>
            <ToggleButton
              value="horizontal"
              aria-label="Horizontal view"
              title="Horizontal view">
              <ReportIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Title>

        {DataView}
      </>
    );
  } else {
    return (
      <>
        <CustomerProfileHeader cID={customer?.id} />

        <Table
          columns={COLUMNS}
          data={data || []}
          isLoading={isLoading}
          filename="Customer Ledger"
          hideAddIcon
          noWrap
        />
      </>
    );
  }
}

export default LedgerReport;
