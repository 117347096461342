import React, { useEffect } from 'react';
import DevicesIcon from '@mui/icons-material/Devices';
import UsersIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link, Outlet, useLocation } from 'react-router-dom';
import Layout from '../../component/Layout';

export default function AuthSetup() {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  useEffect(() => {
    if (location?.pathname === '/auth/users') {
      setValue(0);
    }
    if (location?.pathname === '/auth/devices') {
      setValue(1);
    }
  }, [location?.pathname]);
  return (
    <Layout>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={value}>
            <Tab
              label="Users"
              icon={<UsersIcon />}
              component={Link}
              to={'/auth/users'}
            />
            <Tab
              label="Devices"
              icon={<DevicesIcon />}
              component={Link}
              to={'/auth/devices'}
            />
          </Tabs>
        </Box>
        <Outlet />
      </Box>
    </Layout>
  );
}
