import React, { useMemo } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { toast } from 'react-toastify';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

function EmployeeRole() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const location = useLocation();

  // get Employee Role Data
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'employeeRole',
    () => {
      return axiosPrivate.get('/api/v1/employee/role?populate=yes&sort=rank');
    },
  );

  const handleUpRank = (id) => {
    axiosPrivate
      .patch(`/api/v1/employee/role/${id}/rank-up`)
      .then((res) => {
        toast.success(res.data.message);
        refetch();
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };
  const handleDownRank = (id) => {
    axiosPrivate
      .patch(`/api/v1/employee/role/${id}/rank-down`)
      .then((res) => {
        toast.success(res.data.message);
        refetch();
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Rank',
        id: 'selection2',
        Cell: ({ row }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2">
                  {row?.original?.rank}
                </Typography>
                <IconButton
                  onClick={() => handleUpRank(row?.original?.id)}
                  className="Table_icon_btn">
                  <ArrowUpwardIcon color="success" />
                </IconButton>
                <IconButton
                  onClick={() => handleDownRank(row?.original?.id)}
                  className="Table_icon_btn">
                  <ArrowDownwardIcon color="error" />
                </IconButton>
              </Stack>
            </>
          );
        },
      },
      {
        Header: 'Employee Role',
        accessor: 'employeeRole',
      },
      {
        Header: 'CodePrefix',
        accessor: 'codePrefix',
      },
      {
        Header: 'Location Type',
        accessor: 'territoryType.territoryType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'In HQ DA',
        accessor: 'inTerritoryAllowance',
      },
      {
        Header: 'Out HQ DA',
        accessor: 'outTerritoryAllowance',
      },
      {
        Header: 'Out Station DA',
        accessor: 'outStationAllowance',
      },
      {
        Header: 'Attendance method',
        accessor: 'attendanceMethod',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value, row }) => {
          if (value.length > 14) {
            return (
              <span title={value}>
                {row?.original?.description?.slice(0, 15)}...
              </span>
            );
          }
          return row?.original?.description;
        },
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() =>
                    navigate(
                      `/settings/employee-role/edit/${row?.original?.id}`,
                    )
                  }
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
              </Stack>
            </>
          );
        },
        disableFilters: true,
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/settings/employee-role' ? (
        <Outlet context={[refetch]} />
      ) : (
        <Table
          columns={COLUMNS}
          data={data?.data?.data || []}
          filename="EmployeeRole_table"
          addButtonTooltip="Add Employee Role"
          onAddButtonClick={() => navigate('/settings/employee-role/add')}
          noWrap
          isLoading={isLoading || isFetching}
        />
      )}
    </>
  );
}

export default EmployeeRole;
