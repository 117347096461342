import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [user, setUser] = useState({});
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [avatar, setAvatar] = useState({});

  const Admins = [
    'Managing Director',
    'Director Operations',
    'Director Finance',
    'Admin',
    'Assistant Admin',
  ];
  const SalesManagers = ['NSM', 'DSM', 'RSM', 'ASM'];
  const SalesOfficers = ['SO/SR', 'DSR'];

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        user,
        setUser,
        role,
        setRole,
        avatar,
        setAvatar,
        userId,
        setUserId,
        name,
        setName,
        Admins,
        SalesManagers,
        SalesOfficers,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
