import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import {
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import shortMongoID from '../../utils/shortMongoID';

function Payments() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, role, Admins } = useAuth();
  const { setHeader } = useHeader();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  // filter states
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'week').startOf('month'),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));

  useEffect(() => {
    setHeader({ title: 'Payments' });
  }, []);

  const { data, isError, error, isLoading, refetch } = useQuery(
    ['customer-payments', startDate?.valueOf(), endDate?.valueOf()],
    () => {
      let url = `/api/v1/ledger?populate=yes&limit=none&type[nin][0]=collection&type[nin][1]=sale&type[nin][2]=transfer&sort=-dateOfEntry`;
      if (!['Super Admin', ...Admins, 'Accounts', 'DO'].includes(role)) {
        url += `&recordedBy=${userId}`;
      }
      if (startDate?.isValid()) {
        url += `&dateOfEntry[gte]=${startDate.toISOString()}`;
      }
      if (endDate?.isValid()) {
        url += `&dateOfEntry[lte]=${endDate.toISOString()}`;
      }

      return axiosPrivate.get(url).then((res) =>
        res?.data?.data?.map((item) => {
          item.location = item?.customer?.territory?.code;

          if (item?.transactionRef) {
            item.transactionRefShort = shortMongoID(item?.transactionRef);
            item.asProduct = true;
          } else {
            item.asProduct = false;
          }

          if (item?.asProduct) {
            item.viewAmount = parseFloat(item?.totalAmount);
          } else {
            item.viewAmount = parseFloat(item?.totalAmount) * -1;
          }

          if (
            typeof item?.bankName === 'string' &&
            item?.bankName?.length > 10
          ) {
            item.bankNameX = item?.bankName?.match(/\b([A-Z])/g).join('');
          }

          return item;
        }),
      );
    },
  );

  if (isError) {
    expired(error);
  }

  const handleAdd = () => {
    navigate('/payments/add');
  };

  const handleEdit = (id) => {
    navigate(`/payments/edit/${id}`);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/ledger/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const dateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={startDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setStartDate(dayjs(e.target.value).startOf('date'));
          }}
        />
        <input
          type="date"
          value={endDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEndDate(dayjs(e.target.value).endOf('date'));
          }}
        />
      </Stack>
    );
  }, [endDate, startDate]);

  const COLUMNS = useMemo(() => {
    const columns = [
      {
        id: 'ID',
        Header: 'Full ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: dateRangeFilter,
      },
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Amount',
        accessor: 'viewAmount',
        aggregate: 'sum',
      },
      {
        Header: 'As Product',
        accessor: 'asProduct',
        Cell: ({ value }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="disabled" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Linked Transaction',
        accessor: 'transactionRefShort',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
        Cell: ({ value, row }) => {
          if (!value) {
            return row?.original?.paymentMode;
          }
          return <span title={value}>{row?.original?.bankNameX || value}</span>;
        },
      },
      {
        Header: 'Trx. Code',
        accessor: 'transactionID',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
    ];

    if (['Super Admin', ...Admins, 'Accounts' /* 'DO' */].includes(role)) {
      columns.push({
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (role === 'Accounts' && row?.original?.asProduct) {
            return null;
          }
          /* if (role === 'DO' && !row?.original?.asProduct) {
            return null;
          } */
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  onClick={() => handleEdit(row?.original?.id)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>

                <IconButton
                  onClick={() => handleDeleteShow(row?.original?.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            </>
          );
        },
      });
    }

    return columns;
  }, [dateRangeFilter, role]);

  if (location.pathname !== '/payments') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        fullHeight
        noWrap
        filename="All_Payments"
        onAddButtonClick={handleAdd}
        hideAddIcon={Boolean(
          !['Super Admin', ...Admins, 'Accounts'].includes(role),
        )}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default Payments;
