import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import ExpandRowInfo from './ExpandRowInfo';
import useHeader from '../../../hooks/useHeader';

export default function AllCustomer() {
  const location = useLocation();
  const { setHeader } = useHeader();
  const { user, role, Admins } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const navigate = useNavigate();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  // ************___form state__****************//

  const [lowGuy, setLowGuy] = useState({
    territory: {
      territoryType: {
        rank: 0,
      },
    },
  });

  //  get all customer
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'all-customer',
    () => {
      let url = '/api/v1/customer?populate=yes&limit=none&sort=-createdAt';

      if (!['Super Admin', ...Admins, 'Accounts', 'DO'].includes(role)) {
        url += `&parentTerritory=${user?.employee?.territory?.id}`;
      }

      return axiosPrivate.get(url);
    },
  );

  useEffect(() => {
    setHeader({ title: 'Customers' });
  }, []);

  // get customer type data
  // useEffect(() => {
  //   axiosPrivate
  //     .get('/api/v1/customer/type?populate=yes&sort=rank&isActive=true')
  //     .then((res) => {
  //       setCustomerTypeData(res?.data?.data);
  //     })
  //     .catch((error) => console.warn(error));
  // }, []);

  // get All territory data
  // useEffect(() => {
  //   axiosPrivate
  //     .get(
  //       `/api/v1/territory?populate=yes&limit=none&territoryType=${customerType?.territoryType?.id}`,
  //     )
  //     .then((res) => {
  //       setTerritoyData(res?.data?.data);
  //     })
  //     .catch((error) => console.warn(error));
  // }, [customerType?.territoryType?.id]);

  const handleInfo = (id) => {
    navigate(`/customers/transaction/${id}`, {
      state: { title: 'Customers' },
    });
  };

  // add customer
  const handleAdd = () => {
    navigate('/customers/add');
  };
  // edit customer
  const handleEdit = (id) => {
    navigate(`/customers/edit/${id}`);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/customer/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  function getTerritories(territory) {
    const territories = {};

    function getParent(t) {
      const rank = t?.territoryType?.rank;

      if (rank > 1) {
        territories[`rank${rank}`] = {
          type: t?.territoryType?.territoryType,
          name: t?.name,
        };
      }

      if (
        Object.prototype.toString.call(t?.parentTerritory) === '[object Object]'
      ) {
        getParent(t.parentTerritory);
      }
    }

    getParent(territory);
    return territories;
  }

  data?.data?.data?.map((item) => {
    item.territories = getTerritories(item?.territory);

    if (
      item?.territory?.territoryType?.rank >
      lowGuy?.territory?.territoryType?.rank
    ) {
      setLowGuy(item);
    }
    return item;
  });

  const COLUMNS = useMemo(() => {
    const columns = [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Name & Code',
        accessor: 'nameCode',
        Cell: ({ value, row }) => {
          return (
            <>
              <Box
                sx={{
                  '&:hover': { textDecoration: 'underline', cursor: 'pointer' },
                }}
                onClick={() => handleInfo(row?.original?.id)}>
                {value}
              </Box>
            </>
          );
        },
      },
      /* {
        Header: 'Code',
        accessor: 'code',
      }, */
      {
        Header: 'Starting Date',
        accessor: 'dateOfStarting',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Closing Date',
        accessor: 'dateOfClosing',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      /* {
        Header: 'Location',
        accessor: 'territory.name',
        Cell: ({ value, row }) => {
          return `${row?.original?.territory?.territoryType?.territoryType} : ${value} (${row?.original?.territory?.code})`;
        },
      }, */
    ];

    let i = 1;
    while (i <= lowGuy?.territory?.territoryType?.rank) {
      const header = lowGuy?.territories[`rank${i}`]?.type;
      if (header) {
        columns.push({
          Header: header,
          accessor: `territories.rank${i}.name`,
          Filter: MultiSelectColumnFilter,
          filter: 'includesSome',
        });
      }
      i++;
    }

    columns.push(
      {
        Header: 'Type',
        accessor: 'customerType.customerType',
        Cell: ({ value, row }) => {
          if (value) {
            return `${value}${
              row?.original?.commissionPercent
                ? ` (${row?.original?.commissionPercent}%)`
                : ''
            }`;
          }
          return '';
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        // filter: 'includes',
      },
      {
        Header: 'Class',
        accessor: 'customerClass.customerClass',
        Cell: ({ value, row }) => {
          if (row?.original?.customerClass?.customerClass) {
            return `${row?.original?.customerClass?.customerClass}${
              row?.original?.customerClass?.discountPercent
                ? ` (${row?.original?.customerClass?.discountPercent}%)`
                : ''
            }`;
          }
          return '';
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        show: false,
        Cell: ({ row }) => {
          return (
            <>
              {['Super Admin', ...Admins].includes(role) ? (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={() => handleEdit(row?.original?.id)}
                    className="Table_icon_btn">
                    <BorderColorIcon />
                  </IconButton>

                  {role !== 'Admin' ? (
                    <IconButton
                      onClick={() => handleDeleteShow(row?.original?.id)}
                      className="Table_icon_btn delete">
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                </Stack>
              ) : null}
            </>
          );
        },
      },
    );

    return columns;
  }, [lowGuy, role]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/customers' ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={data?.data?.data || []}
            filename="All_Customer"
            addButtonTooltip="Add Customer"
            expandRow={ExpandRowInfo}
            onAddButtonClick={handleAdd}
            isLoading={isLoading || isFetching}
            noWrap
            fullHeight={true}
          />

          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={deleteShow}
            onSave={() => handleDelete()}
            onHide={() => setDeleteShow(false)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}
    </>
  );
}
