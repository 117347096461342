import React, { createContext, useState } from 'react';

const HelmetContext = createContext({});

export const HelmetProvider = ({ children }) => {
  const [header, setHeader] = useState({ title: '', backUrl: '' });
  return (
    <HelmetContext.Provider value={{ header, setHeader }}>
      {children}
    </HelmetContext.Provider>
  );
};

export default HelmetContext;
