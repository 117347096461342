import { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import TargetExpandRow from './ExpandRow';
import roundToDigit from '../../utils/roundToDigit';

export default function Targets() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { role, user, userId, Admins } = useAuth();
  const expired = useExpiredSession();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    setHeader({ title: 'Target' });
  }, []);

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/target/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  // get targets
  const {
    data: allTargets,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery('targets', () => {
    let url = '/api/v1/target?populate=yes&limit=none&sort=-selectedAt';
    if (!['Super Admin', ...Admins].includes(role)) {
      url += `&parentTerritory=${user?.employee?.territory?.id}`;
    }

    return axiosPrivate.get(url).then((res) => {
      const dt = [];
      res?.data?.data?.forEach((d) => {
        const item = { ...d };

        let quantityT = 0;
        let cartonsT = 0;
        let packetsT = 0;
        let quantityS = 0;
        let cartonsS = 0;
        let packetsS = 0;

        d?.products?.forEach((p) => {
          quantityT += p?.targetQuantity;
          cartonsT += Math.floor(
            p?.targetQuantity / p?.product?.quantityPerCarton,
          );
          packetsT += p?.targetQuantity % p?.product?.quantityPerCarton;

          quantityS += p?.saleQuantity;
          cartonsS += Math.floor(
            p?.saleQuantity / p?.product?.quantityPerCarton,
          );
          packetsS += p?.saleQuantity % p?.product?.quantityPerCarton;
        });

        let targetQuantityDisplay = `${cartonsT} ctn`;
        if (packetsT) targetQuantityDisplay += ` ${packetsT} pcs`;

        let saleQuantityDisplay = `${cartonsS} ctn`;
        if (packetsS) saleQuantityDisplay += ` ${packetsS} pcs`;

        item.targetSum = targetQuantityDisplay;
        item.salesSum = saleQuantityDisplay;
        item.selectedAt = dayjs(d?.selectedAt)
          .startOf('month')
          .format('MMM YYYY');
        // item.selectedAt = d?.selectedAt;

        if (quantityS > 0) {
          item.increase = `${roundToDigit(
            (quantityT * 100) / quantityS - 100,
          )}%`;
        } else {
          item.increase = '-';
        }

        dt.push(item);
      });

      return dt;
    });
  });

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Target Month',
        accessor: 'selectedAt',
        // Cell: ({ value }) => {
        //   if (value) return dayjs(value).format('MMM YYYY');
        //   return value;
        // },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Employee',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Location',
        accessor: 'customer.territory.code',
      },
      {
        Header: "Last Month's IMS",
        accessor: 'salesSum',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Target Quantity',
        accessor: 'targetSum',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Increase %',
        accessor: 'increase',
      },
      {
        Header: 'Action',
        id: 'PhysicalStock_Action',
        Cell: ({ row }) => {
          if (
            (role !== 'Admin' && ['Super Admin', ...Admins].includes(role)) ||
            row?.original?.employee?.id === userId
          ) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <Tooltip title="Edit">
                  <IconButton
                    component={Link}
                    to={`/target/edit/${row?.original?.id}`}
                    aria-label="Edit target entry"
                    className="Table_icon_btn">
                    <BorderColorIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDeleteShow(row?.original?.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            );
          }
        },
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  if (location.pathname !== '/target') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={allTargets || []}
        filename="Targets"
        addButtonTooltip="Add Target"
        onAddButtonClick={() => navigate('/target/add')}
        isLoading={isLoading || isFetching}
        expandRow={TargetExpandRow}
        fullHeight={true}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}
