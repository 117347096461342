import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  // TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  // Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useRowSelect,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { ColumnFilter } from './ColumnFilter';
import { GlobalFilter } from './GlobalFilter';
// import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import NoData from '../Skeleton/NoData';
import matchSorter from 'match-sorter';
import ReactToPrint from 'react-to-print';
import { TableContain } from '../../assets/style/table/mainTable';
import useAuth from '../../hooks/useAuth';
import DamClaimModal from '../../pages/Damages/DamClaimModal';
import roundToDigit from '../../utils/roundToDigit';
import CustomPopover from '../CustomPopover';
import CustomDrawer from '../Drawer/CustomDrawer';
import { exportExcelFile } from './ExportData';
import Pagination from './Pagination';
import PrintTbody from './PrintTbody';
import useShare from '../../hooks/useShare';
import TableBodySkeleton from '../Skeleton/TableBodySkeleton';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const IndeterminateCheckbox = React.forwardRef(
  ({ color, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
        <Checkbox
          ref={resolvedRef}
          {...rest}
          indeterminate={indeterminate}
          color={color || 'primary'}
          checkedIcon={<CheckBoxIcon />}
          icon={<CheckBoxOutlineBlankIcon color={color || 'primary'} />}
          disableRipple
        />
      </>
    );
  },
);

export default function CustomTable({
  hidePagination,
  isLoading,
  customerName,
  columns,
  data,
  filename,
  hasDrawer,
  drawerWidth,
  expandRow,
  expandRowProps,
  hideAddIcon,
  addButtonTooltip,
  onAddButtonClick,
  customerYear,
  pendingCount,
  fullHeight,
  noWrap,
  rowSelection,
  setSelectionValues,
  setFilteredRows,
}) {
  const { sharePdf, isLoadingPdf, getFile, navigatorShare } = useShare();
  const macthes_s_md = useMediaQuery('(min-width:769px)');
  const machtes_md = useMediaQuery('(max-width:900px)');
  const componentRef = useRef();
  const tableRef = useRef(null);
  const { role, SalesManagers, SalesOfficers } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [expandRowData, setExpandRowData] = useState([]);
  const [toggoleFilter, setToggoleFilter] = useState(true);
  const [showModal, setShowModal] = useState(false); // damage claim modal handles

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      // fuzzyText: fuzzyTextFilterFn,
      dateBetween: dateBetweenFilterFn,
      includesSome: includeSomeFn,
      includesNumber: includeNumberFn,
      hasValue: hasValueFn,
      // text: (rows, id, filterValue) => {
      //   return rows.filter((row) => {
      //     const rowValue = row.values[id];
      //     return rowValue !== undefined
      //       ? String(rowValue)
      //           .toLowerCase()
      //           .startsWith(String(filterValue).toLowerCase())
      //       : true;
      //   });
      // },
    }),
    [],
  );

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    visibleColumns,
    selectedFlatRows,
    // pagination----------------------
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    // pagination----------------------------------
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns: columns,
      data: data,
      defaultColumn: defaultColumn,
      filterTypes,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      initialState: {
        pageSize: 10,
        hiddenColumns: [...SalesManagers, 'SD']?.includes(role) ? 'Action' : '',
        expanded: {},
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      rowSelection &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  color="white"
                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    },
  );

  useEffect(() => {
    if (rowSelection) {
      setSelectionValues(selectedFlatRows);
    }
  }, [rowSelection, selectedFlatRows]);

  const { pageIndex, pageSize, globalFilter } = state;

  const toggleRowExpanded = (row) => {
    const isExpanded = row.isExpanded;
    toggleAllRowsExpanded(false); // Collapse all rows
    if (!isExpanded) {
      row.toggleRowExpanded(); // Expand the clicked row if it was collapsed
    }
  };

  // filtered Data start -----------------------------------------------
  const [filteredData, setFilteredData] = useState([{}]);

  useEffect(() => {
    const rowData = rows.map((info) => {
      return info.original;
    });
    setFilteredData(rowData);
  }, [rows]);
  // filtered Data end -----------------------------------------------

  useEffect(() => {
    if (typeof setFilteredRows === 'function') {
      setFilteredRows(filteredData);
    }
  }, [filteredData]);

  const pages = hidePagination ? rows : page;

  function Tbody() {
    if (isLoading) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={visibleColumns?.length}>
              <TableBodySkeleton />
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    if (rows?.length === 0) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={visibleColumns?.length}>
              <Typography color="text.disabled" p={{ md: 10, xs: 3 }}>
                Data Not Found...
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    return (
      <TableBody {...getTableBodyProps()}>
        {pages.map((row, i) => {
          prepareRow(row);
          if (row?.isExpanded && hasDrawer) {
            setExpandRowData(row);
          }
          if (row.isExpanded && !macthes_s_md) {
            setExpandRowData(row);
          }
          return (
            <React.Fragment key={i}>
              <TableRow
                key={i}
                style={{ width: 'max-content' }}
                onClick={() => {
                  if (expandRow || hasDrawer || !macthes_s_md) {
                    toggleRowExpanded(row);
                  }
                  if (hasDrawer || !macthes_s_md) {
                    setIsDrawerOpen(true);
                  }
                }}>
                {row.cells.map((cell) => {
                  const colhide = cell?.column?.hidden;
                  return (
                    <TableCell
                      key={cell.id}
                      {...cell.getCellProps()}
                      sx={{
                        display: colhide ? 'none' : 'table-cell',
                        color: (theme) =>
                          row.isExpanded && theme.palette.primary.main,
                        whiteSpace: noWrap ? 'nowrap' : 'normal',
                      }}>
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>

              {row.isExpanded && !hasDrawer && macthes_s_md && (
                <TableRow id="expand_row">
                  <TableCell colSpan={visibleColumns.length}>
                    {React.createElement(expandRow, {
                      data: row.original,
                      ...expandRowProps,
                    })}
                  </TableCell>
                </TableRow>
              )}
            </React.Fragment>
          );
        })}
      </TableBody>
    );
  }

  const handleScroll = () => {
    const table = tableRef.current;
    table.scrollTo({ top: 0 });
  };

  const handleAddClaimShow = () => {
    setShowModal(true);
  };

  const sumAggregator = (rows, columnId) => {
    return roundToDigit(
      rows?.reduce(
        (acc, row) =>
          acc + (row.values[columnId] === undefined ? 0 : row.values[columnId]),
        0,
      ),
    );
  };

  const ctnSumAggregator = (rows, columnId) => {
    let cartons = 0;
    let packets = 0;

    rows?.forEach((row) => {
      const txt = row?.values[columnId];
      if (txt?.includes(' ctn')) {
        const t = txt.split(' ctn');

        const carton = parseInt(t[0], 10);
        cartons += carton;

        if (t[1] && t[1].includes(' pcs')) {
          const packet = parseInt(t[1].split(' pcs')[0], 10);
          packets += packet;
        }
      }
    });

    let sumDisplay = `${cartons} ctn`;
    if (packets) {
      sumDisplay += ` ${packets} pcs`;
    }

    return sumDisplay;
  };

  const printFileNames = [
    'All_Collection',
    'Lifting_Plan_Details',
    'Sr_Report',
    'payroll_report',
  ];

  return (
    <>
      <Stack
        justifyContent="space-between"
        spacing={1}
        direction="row"
        alignItems={'flex-end'}
        mb={1}>
        <GlobalFilter
          customerName={customerName}
          rows={rows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />

        <Stack direction="row" alignItems="center" gap={1}>
          {filename === 'Stock' && (
            <>
              {data?.length ? (
                <Button
                  variant="contained"
                  sx={{ borderRadius: '20px' }}
                  onClick={handleAddClaimShow}>
                  {macthes_s_md ? 'Claim Damage' : 'CD'}
                </Button>
              ) : null}
              {/* // ********damage claim modal************ */}
              {showModal && (
                <DamClaimModal
                  showmodal={showModal}
                  setshowmodal={setShowModal}
                />
              )}
            </>
          )}
          {filename === 'Damages' &&
          (role === 'Super Admin' || role === 'DO') ? (
            <Chip
              label={`${machtes_md ? 'P' : 'Pending'}: ${pendingCount || 0}`}
              color="primary"
              variant="outlined"
              sx={{ mx: 0.5, cursor: 'pointer' }}
            />
          ) : null}
          {filename === 'All_Collection' && (
            <Chip
              label={`${machtes_md ? 'P' : 'Pending'}: ${pendingCount || 0}`}
              color="primary"
              variant="outlined"
              sx={{ mx: 0.5, cursor: 'pointer' }}
            />
          )}
          {/* Download and Share  options start here */}

          <CustomPopover
            onOpen={() => {
              if (printFileNames.includes(filename)) {
                setToggoleFilter(false);
                toggleAllRowsExpanded(false);
              }
            }}
            onClose={() => {
              if (printFileNames.includes(filename)) {
                setToggoleFilter(true);
              }
            }}>
            <List>
              {role === 'Super Admin' ||
              (role === 'Accounts' && filename === 'All_Collection') ? (
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() =>
                      exportExcelFile(
                        filename,
                        filteredData,
                        customerYear,
                        columns,
                      )
                    }>
                    <ListItemIcon sx={{ minWidth: 32 }}>
                      <ListAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Export Excel" />
                  </ListItemButton>
                </ListItem>
              ) : null}

              <ListItem disablePadding>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <ListItemButton>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <PrintIcon />
                        </ListItemIcon>
                        <ListItemText primary="Print" />
                      </ListItemButton>
                    );
                  }}
                  content={() => componentRef.current}
                />
              </ListItem>
              {printFileNames.includes(filename) ? (
                <>
                  <ListItem disablePadding>
                    {!getFile ? (
                      <ListItemButton
                        disabled={isLoadingPdf}
                        onClick={() => sharePdf('tableDom', filename)}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <PictureAsPdfIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${
                            isLoadingPdf ? 'Preparing ....' : 'Prepare PDF'
                          }`}
                        />
                      </ListItemButton>
                    ) : (
                      <ListItemButton onClick={() => navigatorShare(getFile)}>
                        <ListItemIcon sx={{ minWidth: 32 }}>
                          <ShareIcon />
                        </ListItemIcon>
                        <ListItemText primary="Share PDF" />
                      </ListItemButton>
                    )}
                  </ListItem>
                </>
              ) : null}
            </List>
          </CustomPopover>

          {/* Download and Share options end here */}

          <AddModalIcon
            role={role}
            filename={filename}
            hideAddIcon={hideAddIcon}
            addButtonTooltip={addButtonTooltip}
            onAddButtonClick={onAddButtonClick}
            restrictedRoles={[...SalesManagers, ...SalesOfficers]}
          />
        </Stack>
      </Stack>

      <>
        <TableContain
          ref={tableRef}
          sx={{
            maxHeight: {
              xs: toggoleFilter
                ? fullHeight
                  ? 'calc(100vh - 13rem)'
                  : 'calc(100vh - 14rem)'
                : null,
              md: toggoleFilter
                ? fullHeight
                  ? 'calc(100vh - 13rem)'
                  : 'calc(100vh - 18rem)'
                : null,
            },
          }}>
          <Table
            cellSpacing="0"
            cellPadding="0"
            border={toggoleFilter ? '0' : '1'}
            ref={componentRef}
            id="tableDom"
            sx={{
              position: 'relative',
              zIndex: 1,
            }}
            {...getTableProps()}>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
              }}>
              {headerGroups.map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <TableCell
                        style={{
                          verticalAlign: 'top',
                          display:
                            column?.hidden ||
                            (column?.hidePrint && !toggoleFilter)
                              ? 'none'
                              : 'table-cell',
                        }}
                        {...column.getHeaderProps()}
                        key={column.id}
                        className="prevent-select v-top">
                        <Stack>
                          {column?.Header === 'Active' ||
                          column?.Header === 'Action' ||
                          column?.Header === 'Edu' ||
                          column?.id === 'expander' ? (
                            <Stack
                              direction="row"
                              sx={{ width: 'max-content' }}>
                              {column.render('Header')}
                            </Stack>
                          ) : (
                            <Stack
                              direction="row"
                              {...column.getSortByToggleProps()}
                              sx={{ width: 'max-content' }}>
                              {column.render('Header')}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <ArrowDownwardIcon fontSize="small" />
                                  ) : (
                                    <ArrowUpwardIcon fontSize="small" />
                                  )
                                ) : toggoleFilter ? (
                                  <SwapVertIcon fontSize="small" />
                                ) : null}
                              </span>
                            </Stack>
                          )}
                          {column.canFilter ? column.render('Filter') : null}
                        </Stack>
                        {column.aggregate === 'sum' && (
                          <Box mt={0.75} style={{ whiteSpace: 'nowrap' }}>
                            {sumAggregator(rows, column.id)}
                          </Box>
                        )}
                        {column.aggregateCtn === 'sum' && (
                          <Box mt={0.75} style={{ whiteSpace: 'nowrap' }}>
                            {ctnSumAggregator(rows, column.id)}
                          </Box>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            {toggoleFilter ? (
              <Tbody />
            ) : (
              <PrintTbody filename={`${filename}`} data={filteredData} />
            )}
          </Table>
        </TableContain>

        {hidePagination ? null : (
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageSize={pageSize}
            preGlobalFilteredRows={preGlobalFilteredRows}
            handleScroll={handleScroll}
          />
        )}
      </>

      <CustomDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        expandRowData={expandRowData}
        expandRow={expandRow}
        expandRowProps={expandRowProps}
        drawerWidth={drawerWidth}
      />
    </>
  );
}

function AddModalIcon({
  role,
  filename,
  hideAddIcon,
  addButtonTooltip,
  onAddButtonClick,
  restrictedRoles,
}) {
  if (restrictedRoles?.includes(role) && filename === 'All_Customer') return;
  // else if (role === 'Accounts') return;
  else if (
    role === 'DO' &&
    !['transactions', 'All_Collection'].includes(filename)
  )
    return;
  // else if (role === 'DO' && filename !== 'All_Collection') return;
  else if (role === 'Admin' && filename === 'All_Employee_table') return;
  else
    return (
      <>
        {!hideAddIcon && (
          <Tooltip title={addButtonTooltip || ''}>
            <IconButton
              color="primary"
              className="Table_icon_btn"
              onClick={onAddButtonClick}>
              <AddCircleIcon style={{ fontSize: '36px' }} />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
}

// custom filters functionality start here
// -------------------------------------------
// -------------------------------------------

function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;

  if (ed || sd) {
    return rows?.filter((r) => {
      const cellDate = new Date(r.values[id]);

      if (ed && sd) {
        return cellDate >= sd && cellDate <= ed;
      } else if (sd) {
        return cellDate >= sd;
      } else if (ed) {
        return cellDate <= ed;
      }
    });
  } else {
    return rows;
  }
}

dateBetweenFilterFn.autoRemove = (val) => !val;

function includeSomeFn(rows, id, filterValues) {
  if (filterValues.length === 0) {
    return rows;
  } else {
    return rows.filter((row) => filterValues.includes(row.values[id]));
  }
}

includeSomeFn.autoRemove = (val) => !val;

function includeNumberFn(rows, id, filterValues) {
  if (filterValues.length === 0) {
    return rows;
  } else {
    return rows.filter((row) => filterValues.includes(row.values[id]));
  }
}

includeNumberFn.autoRemove = (val) => !val;

function hasValueFn(rows, columnId, filterValue) {
  // console.log(filterValue);
  if (filterValue === 'all') {
    return rows;
  } else if (filterValue === 'hasValue') {
    return rows.filter(
      (row) =>
        row.values[columnId] !== null &&
        row.values[columnId] !== undefined &&
        row.values[columnId] !== '-',
    );
  } else {
    return rows.filter((row) => row.values[columnId] === '-');
  }
}

// function fuzzyTextFilterFn(rows, id, filterValue) {
//   return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
// }

// Let the table remove the filter if the string is empty
// fuzzyTextFilterFn.autoRemove = (val) => !val;
