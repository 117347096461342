import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

function TableSkeleton() {
  return (
    <Box mt={4}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" height={35} sx={{ width: '50%' }} />
          <Stack
            justifyContent="flex-end"
            spacing={2}
            direction="row"
            sx={{ width: '50%' }}>
            <Skeleton variant="circular" width={35} height={35} />
            <Skeleton variant="circular" width={35} height={35} />
          </Stack>
        </Stack>
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
      </Stack>
    </Box>
  );
}

export default TableSkeleton;
