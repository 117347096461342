import { Stack, styled } from '@mui/material';

export const LinkMain = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: 0,
  alignItems: 'center',
  width: 'max-content',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  '& .text': {
    fontSize: '14px',
    lineHeight: 'normal',
  },
}));
