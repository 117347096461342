import { Outlet } from 'react-router-dom';
import Layout from '../../component/Layout';

function Stock() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Stock;
