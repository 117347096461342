import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ActiveSwitch from '../input/ActiveSwitch';

export default function DeviceViews({
  device,
  findDeviceName,
  setDeviceId,
  noEdit,
  updateStatus,
  index,
  isActive,
  setIsActive,
  toggleAccordion,
  activeIndex,
  setActiveIndex,
}) {
  return (
    <Box p={1} sx={styles.main}>
      <Box sx={styles.absOne}>
        <Stack
          gap={0.5}
          direction="row"
          alignItems="center"
          sx={{
            position: 'relative',
            overflow: 'hidden',
            border: '1px solid #80808042',
            borderRadius: '24px',
            width: '80px',
            padding: '4px 8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ':hover': {
              div: {
                right: '0',
              },
            },
          }}>
          <CircleIcon
            sx={{ fontSize: 12 }}
            color={device?.isActive ? 'success' : 'error'}
          />
          <Typography sx={{ fontSize: 12 }} variant="subtitle2">
            {device?.isActive ? 'Allowed' : 'Blocked'}
          </Typography>
          {!noEdit && (
            <Box
              sx={{
                background: (theme) => theme.palette.primary.main,
                position: 'absolute',
                top: 0,
                right: '-70%',
                height: '100%',
                width: '70%',
                borderRadius: '24px',
                transition: 'right 0.3s',
                cursor: 'pointer',
                userSelect: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                setDeviceId(device?.id);
                setIsActive(device?.isActive);
                toggleAccordion(index);
              }}>
              <Typography sx={{ fontSize: 12, color: '#fff' }}>Edit</Typography>
            </Box>
          )}
        </Stack>
      </Box>

      <Box
        sx={{
          ...styles.absTwo,
          right: activeIndex === index && !noEdit ? '0%' : '-100%',
        }}>
        <Box p={2}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 3,
            }}>
            <Typography sx={{ fontWeight: 500 }}>Device Status</Typography>
            <ActiveSwitch
              primaryText="Allowed"
              secondaryText="Blocked"
              value={isActive}
              setValue={setIsActive}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button
              color="error"
              onClick={() => {
                setActiveIndex(-1);
              }}
              size="small"
              variant="contained">
              Cancel
            </Button>
            <Button
              onClick={() => {
                updateStatus();
                setActiveIndex(-1);
              }}
              size="small"
              variant="contained">
              Save
            </Button>
          </Stack>
        </Box>
      </Box>

      <Avatar
        src={findDeviceName(device?.clientName)}
        sx={{
          width: 50,
          height: 50,
          margin: '0 auto',
          img: { transform: 'rotate(0deg)', transition: 'all 1s' },
        }}>
        <Typography sx={{ fontSize: 10 }}>{device?.clientName}</Typography>
      </Avatar>

      <Box>
        <Typography mt={1.5} fontSize={12} color="text.primary">
          {device?.clientName} {device?.clientVersion} on {device?.deviceBrand}{' '}
          {device?.deviceModel} {device?.osName} {device?.osVersion}{' '}
          {device?.osPlatform}
        </Typography>
        <Typography mt={0.5} fontSize={12} color="text.secondary">
          {device?.fingerprint}
        </Typography>
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    border: '1px solid #80808080',
    // boxShadow: '1px 1px 6px 1px #80808047',
    borderRadius: '8px',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  absOne: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  absTwo: {
    position: 'absolute',
    top: 0,
    width: 1,
    height: 1,
    background: '#f5f5f5',
    zIndex: 1,
    transition: 'right 0.5s',
  },
};
