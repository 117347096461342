import React from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Modals from '../../component/Modals';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

/**
 * @typedef {Object} Props
 * @property {Boolean} open
 * @property {Object} info
 * @property {Function} onClose
 * @property {Function} refetch
 */

/**
 * @param {Props} props
 * @returns
 */
function ConfirmPaymentModal({ open, info, onClose, refetch }) {
  const axiosPrivate = useAxiosPrivate();
  const { userId } = useAuth();

  const [paymentAmount, setPaymentAmount] = React.useState(0);
  const [paymentRemarks, setPaymentRemarks] = React.useState('');

  React.useEffect(() => {
    setPaymentAmount(info?.totalDue > 0 ? info?.totalDue : 0);
  }, [info?.totalDue]);

  // generate employee ledger entries for payment
  const paymentDate = dayjs(info?.endDate).subtract(12, 'hour');
  const ledgerEntries = React.useMemo(() => {
    const entries = [];

    if (
      info?.salaryBasic &&
      !info?.foundLedgerTypes?.includes('BASIC SALARY')
    ) {
      entries.push({
        type: 'BASIC SALARY',
        entryAmount: info?.salaryBasic,
        approvedAmount: info?.salaryBasic,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (
      info?.salaryMobile &&
      !info?.foundLedgerTypes?.includes('MOBILE BILL')
    ) {
      entries.push({
        type: 'MOBILE BILL',
        entryAmount: info?.salaryMobile,
        approvedAmount: info?.salaryMobile,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (
      info?.salaryInternet &&
      !info?.foundLedgerTypes?.includes('INTERNET BILL')
    ) {
      entries.push({
        type: 'INTERNET BILL',
        entryAmount: info?.salaryInternet,
        approvedAmount: info?.salaryInternet,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (
      info?.totalDearnessAllowance &&
      !info?.foundLedgerTypes?.includes('DA')
    ) {
      entries.push({
        type: 'DA',
        entryAmount: info?.totalDearnessAllowance,
        approvedAmount: info?.totalDearnessAllowance,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (info?.totalTravelAllowance && !info?.foundLedgerTypes?.includes('TA')) {
      entries.push({
        type: 'TA',
        entryAmount: info?.totalTravelAllowance,
        approvedAmount: info?.totalTravelAllowance,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (
      info?.totalHotelBill &&
      !info?.foundLedgerTypes?.includes('HOTEL BILL')
    ) {
      entries.push({
        type: 'HOTEL BILL',
        entryAmount: info?.totalHotelBill,
        approvedAmount: info?.totalHotelBill,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    if (
      info?.totalOtherBill &&
      !info?.foundLedgerTypes?.includes('OTHER BILL')
    ) {
      entries.push({
        type: 'OTHER BILL',
        entryAmount: info?.totalOtherBill,
        approvedAmount: info?.totalOtherBill,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
      });
    }

    return entries;
  }, [info, paymentDate, userId]);

  const handleModalClose = React.useCallback(() => {
    setPaymentAmount(0);
    setPaymentRemarks('');
    onClose();
  }, [onClose]);

  const handleModalSumbit = React.useCallback(() => {
    const amount = paymentAmount * -1;
    const entries = [
      ...ledgerEntries,
      {
        type: 'NET PAID',
        entryAmount: amount,
        approvedAmount: amount,
        employee: info?.employee?.id,
        recordedBy: userId,
        approvedBy: userId,
        dateOfEntry: paymentDate,
        dateOfApprove: paymentDate,
        remarks: paymentRemarks,
      },
    ];

    axiosPrivate
      .post('/api/v1/employee-ledger/insert', entries)
      .then(() => {
        toast.success('Payment added.');
        handleModalClose();
        refetch();
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, [ledgerEntries, paymentAmount, handleModalClose, refetch]);

  return (
    <Modals title="Confirm Payment" show={open} onHide={handleModalClose} form>
      <Stack sx={{ gap: 0.5 }}>
        <Stack direction="row" sx={rowStyle}>
          <Typography>Employee:</Typography>
          <Typography fontWeight="bold">{info?.employee?.nameCode}</Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Basic Salary:</Typography>
          <Typography
            color={info?.salaryBasic > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.salaryBasic}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Total DA:</Typography>
          <Typography
            color={
              info?.totalDearnessAllowance > 0 ? 'success.main' : 'error.main'
            }
            fontWeight="bold">
            {info?.totalDearnessAllowance}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Total TA:</Typography>
          <Typography
            color={
              info?.totalTravelAllowance > 0 ? 'success.main' : 'error.main'
            }
            fontWeight="bold">
            {info?.totalTravelAllowance}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Hotel Bill:</Typography>
          <Typography
            color={info?.totalHotelBill > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalHotelBill}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Other Bill:</Typography>
          <Typography
            color={info?.totalOtherBill > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalOtherBill}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Mobile Bill:</Typography>
          <Typography
            color={info?.salaryMobile > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.salaryMobile}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Internet Bill:</Typography>
          <Typography
            color={info?.salaryInternet > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.salaryInternet}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Tickets+:</Typography>
          <Typography
            color={info?.totalTicketsPlus > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalTicketsPlus}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Tickets-:</Typography>
          <Typography
            color={info?.totalTicketsMinus > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalTicketsMinus}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Previous Dues:</Typography>
          <Typography
            color={info?.previousDues > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.previousDues}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Net Payable:</Typography>
          <Typography
            color={info?.totalPayable > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalPayable}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Net Paid:</Typography>
          <Typography
            color={info?.totalPaid > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalPaid}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography sx={{ whiteSpace: 'nowrap' }}>
            Payment Remarks:
          </Typography>
          <Typography sx={{ whiteSpace: 'pre-line' }}>
            {info?.totalPaidRemarks}
          </Typography>
        </Stack>

        <Stack direction="row" sx={rowStyle}>
          <Typography>Payable Due:</Typography>
          <Typography
            color={info?.totalDue > 0 ? 'success.main' : 'error.main'}
            fontWeight="bold">
            {info?.totalDue}
          </Typography>
        </Stack>
      </Stack>

      <TextField
        label="Payment Amount"
        type="number"
        fullWidth
        variant="outlined"
        value={paymentAmount}
        onChange={(e) => setPaymentAmount(e.target.value)}
        onWheel={(e) => e.target.blur()}
        sx={{ mt: 3 }}
      />

      <TextField
        label="Remarks"
        type="text"
        fullWidth
        variant="outlined"
        value={paymentRemarks}
        onChange={(e) => setPaymentRemarks(e.target.value)}
        sx={{ mt: 3 }}
      />

      <Button
        fullWidth
        color="primary"
        variant="contained"
        size="large"
        onClick={handleModalSumbit}
        sx={{ mt: 4 }}>
        Confirm
      </Button>
    </Modals>
  );
}

export default ConfirmPaymentModal;

const rowStyle = {
  gap: 2,
  alignItems: 'flex-start',
  justifyContent: 'space-between',
};
