import chrome from '../assets/images/browserLogo/chrome.svg';
import edge from '../assets/images/browserLogo/edge.svg';
import firefox from '../assets/images/browserLogo/firefox.svg';
import opera from '../assets/images/browserLogo/opera.svg';
import postman from '../assets/images/browserLogo/postman.svg';
import uc from '../assets/images/browserLogo/uc.svg';
import brave from '../assets/images/browserLogo/brave.svg';
import safari from '../assets/images/browserLogo/safari.svg';
import internetExplorer from '../assets/images/browserLogo/internet-explorer.svg';
import samsungInternet from '../assets/images/browserLogo/samsung-internet.svg';
import vivo from '../assets/images/browserLogo/vivo.webp';

const obj = {
  Chrome: chrome,
  'Chrome Mobile': chrome,
  'Chrome Webview': chrome,
  Firefox: firefox,
  'Microsoft Edge': edge,
  Opera: opera,
  'Opera Mobile': opera,
  'Postman Desktop': postman,
  'UC Browser': uc,
  Brave: brave,
  Safari: safari,
  'Mobile Safari': safari,
  'Internet Explorer': internetExplorer,
  'Samsung Browser': samsungInternet,
  'vivo Browser': vivo,
};

const useFindBrowserLogos = () => {
  const findDeviceName = (name) => {
    for (const key in obj) {
      if (key === name) {
        return obj[key];
      }
    }
    // If name is not found, you can return a default value or null.
    return null;
  };
  return findDeviceName;
};

export default useFindBrowserLogos;
