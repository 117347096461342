const ProductCategories = [
  'BISCUIT',
  'CHIPS',
  'CHANACHUR SNACKS',
  'DRINKS',
  'JUICE',
  'CHOCOLATE CANDY',
  'HOME CARE',
  'CAKE',
  'WAFER',
  'FRUIT GEL',
  'ICE CREAM',
  'MURI',
  'PICKLE CHUTNEY',
  'BREAD',
  'COMMODITY PRODUCT',
  'SAUCE',
  'SHEMAI',
  'INSTANT NOODLES',
  'CUP NOODLES',
  'STICK NOODLES',
  'BATTERY',
  'STATIONERY',
];

/* 
  "CREAM",
  "NON CREAM",
  "SWEET",
  "SALTED",
  "CRACKER",
  "HARD BISCUIT",
  "CHOCO FILLING",
  "SUGAR COATED",
  "CHOCOLATE CREAM",
  "DRY CAKE",
  "TOAST",
  "COOKIES",
  "ASSORTED",
  "DIABETIC",
  "DIGESTIVE",
  "CAKE",
  "INSTANT NOODLES CHICKEN",
  "INSTANT NOODLE MASALA OR CURRY",
  "WAFER",
  "CHIPS",
  "CHOCO SNACKS",
  "FRUITS GEL",
  "JUICE/WATER",
  "CANDY",
  "CHUTNEY",
  "STICK NOODLES",
  "CUP NOODLES",
  "INSTANT NOODLES",
  "CREAM ROLL",
  "SUGAR SPRINKLED",
  "LACHCHA SHEMAI",
  "SNACKS",
  "CHOCOLATE",
  "BEVERAGE",
  "VERMICEILLI",
  "CONFECTIONERY",
  "IPD",
  "CHOCOLATE SPREAD",
  "BAR CHOCOLATE",
  "MINI CHOCOLATE",
  "BISC CUP CHOCO",
  "BISC BALL CHCO",
  "SUCK MINI",
  "LOLLY",
  "MINERAL WATER",
  "BALLS",
  "CHANACHUR",
  "POWDER MASALA",
  "RICE",
  "SPICES",
  "FLOUR",
  "OIL",
  "PUFFED RICE",
  "AMS",
  "SALT",
  "CHOCOLATE INJECTED",
  "SAUCE",
  "SOAP",
  "BATTERY",
  "SUJI",
  "FARMENTED HARD SALTED",
  "FARMENTED HARD SWEET",
  "FARMENTED HARD SALT + SUGAR SPRINKLED SLIGHT",
  "HONEY",
  "SALINE",
  "CLEANER",
  "BUBBLE GUM",
*/

export default ProductCategories;
