import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { RequestIdProvider } from './context/RequestIdProvider';
import { HelmetProvider } from './context/HelmetProvider';
import { LayoutProvider } from './context/LayoutProvider';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import theme from './assets/style/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RequestIdProvider>
        <ThemeProvider theme={theme}>
          <LayoutProvider>
            <AuthProvider>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </AuthProvider>
          </LayoutProvider>
        </ThemeProvider>
      </RequestIdProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
reportWebVitals();
