import { toast } from 'react-toastify';
import useLogout from './useLogout';

const useExpiredSession = () => {
  const logout = useLogout();

  const expired = (error) => {
    if (error?.code === 'ERR_NETWORK') {
      toast.error(error.code);
    }
    if (error?.code === 'ERR_BAD_RESPONSE') {
      toast.warn(error?.response?.data?.message);
    }
    if (error?.response?.data?.status === 'failed') {
      toast.warn(error?.response?.data?.message);
    }
    if (error?.response?.data?.status === 'error') {
      toast.error(error?.response?.data?.message);
    }
    if (error?.response?.status === 401) {
      toast.error(error?.response?.data?.message);
      logout();
    }
  };
  return expired;
};

export default useExpiredSession;
