import { Typography, SxProps, Theme } from '@mui/material';
import React from 'react';

/**
 * @typedef {Object} Parameters
 * @property {React.ElementType} component
 * @property {Number} count
 * @property {Number} error
 * @property {Number} warning
 * @property {Number} normal
 * @property {Number} info
 * @property {Number} success
 * @property {( 'center' | 'inherit' | 'justify' | 'left' | 'right' )} align
 * @property {( 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2' )} variant
 * @property {Boolean} gutterBottom
 * @property {Boolean} noWrap
 * @property {SxProps<Theme>} sx
 */

/**
 * @param {Parameters} parameters
 */
function CountColorTypography({
  component,
  count = 0,
  error,
  warning,
  normal,
  info,
  success,
  align,
  variant,
  gutterBottom,
  noWrap,
  sx,
  children,
  ...props
}) {
  let color = 'inherit';
  if (typeof warning === 'number' && count <= warning) color = 'warning.main';
  if (typeof error === 'number' && count <= error) color = 'error.main';
  if (typeof normal === 'number' && count >= normal) color = 'inherit';
  if (typeof info === 'number' && count >= info) color = 'info.main';
  if (typeof success === 'number' && count >= success) color = 'success.main';

  return (
    <Typography
      component={component}
      color={color}
      align={align}
      variant={variant}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      sx={sx}
      {...props}>
      {children}
    </Typography>
  );
}

export default CountColorTypography;
