import { useParams } from 'react-router-dom';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';
import ViewTransactions from '../../../Transactions/Transactions/ViewTransactions';

function Transactions() {
  const param = useParams();
  const cID = param?.customerID;

  return (
    <>
      <CustomerProfileHeader cID={cID} />
      <ViewTransactions />
    </>
  );
}

export default Transactions;
