export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows } = column;
  const selectStyle = {
    border: '1px solid #80808078',
  };
  return (
    <input
      type="search"
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value || undefined)}
      placeholder={`${preFilteredRows.length} records...`}
      className="w-100"
      style={selectStyle}
    />
  );
};
