import useAuth from './useAuth';
import useAxios from './useAxios';

const REFRESH_URL = '/api/v1/auth/refresh';

const useRefreshToken = () => {
  const axios = useAxios();
  const { setAuth, setRole, setAvatar, setUserId, setName, setUser } =
    useAuth();
  const refreshToken = localStorage.getItem('refreshToken');

  const refresh = async () => {
    const response = await axios.post(
      REFRESH_URL,
      { refreshToken: refreshToken },
      {
        headers: { 'Content-Type': 'application/json' },
      },
      {
        withCredentials: true,
      },
    );

    if (response.data.accessToken) {
      axios
        .get('/api/v1/user/me', {
          headers: {
            Authorization: `Bearer ${response.data.accessToken}`,
          },
        })
        .then((response) => {
          const userRole = response?.data?.data?.employee?.role?.employeeRole;
          const avatar = response?.data?.data?.employee?.avatar;
          const id = response?.data?.data?.employee?.id;
          const name = response?.data?.data?.employee?.name;

          setUser(response?.data?.data);
          setRole(userRole);
          setAvatar(avatar);
          setUserId(id);
          setName(name);
        });
    }
    setAuth((prev) => {
      // console.log('prev', prev);
      // console.log('newaccessToken: ', response.data.accessToken);
      return {
        ...prev,
        accessToken: response.data.accessToken,
      };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
