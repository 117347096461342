import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProductQuantityInput from '../../../component/ProductQuantityInput';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useDownload from '../../../hooks/useDownload';
import useExpiredSession from '../../../hooks/useExpiredSession';
import useShare from '../../../hooks/useShare';
import { BankList, MobileBankList } from '../../../staticData/Banks';
import roundToDigit from '../../../utils/roundToDigit';

function AddDepotOrder() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const { downloadPdf } = useDownload();
  const { sharePdf, isLoadingPdf, getFile, navigatorShare } = useShare();
  const { user, userId } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const editId = params?.transactionId;

  const headerRef = React.useRef(null);

  // Form data states
  const [orderType, setOrderType] = React.useState({});
  const [returnType, setReturnType] = React.useState({});
  const [damageType, setDamageType] = React.useState({});
  const [sender, setSender] = React.useState({});
  const [receiver, setReceiver] = React.useState({});
  const [orderedAt, setOrderedAt] = React.useState(dayjs());
  const [doNumber, setDoNumber] = React.useState('');
  const [coNumber, setCoNumber] = React.useState('');
  const [deliverymanName, setDeliverymanName] = React.useState('');
  const [deliverymanContact, setDeliverymanContact] = React.useState('');
  const [vehicleNumber, setVehicleNumber] = React.useState('');
  const [invoiceAmount, setInvoiceAmount] = React.useState(0);
  const [discountAmount, setDiscountAmount] = React.useState(0);
  const [discountPercent, setDiscountPercent] = React.useState(0);
  const [returnAmount, setReturnAmount] = React.useState(0);
  const [damageAmount, setDamageAmount] = React.useState(0);
  const [collectionAmount, setCollectionAmount] = React.useState(0);
  const [bonusAmount, setBonusAmount] = React.useState(0);
  const [costAmount, setCostAmount] = React.useState(0);
  const [paymentMode, setPaymentMode] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [bankBranch, setBankBranch] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [transactionID, setTransactionID] = React.useState('');
  const [reference, setReference] = React.useState('');
  const [remarks, setRemarks] = React.useState('');

  // Selection states
  const [customerList, setCustomerList] = React.useState([]);
  const [trxTypeList, setTrxTypeList] = React.useState([]);
  const [orderProductsList, setOrderProductsList] = React.useState([]);
  const [returnProductsList, setReturnProductsList] = React.useState([]);
  const [damageProductsList, setDamageProductsList] = React.useState([]);
  const [priceType, setPriceType] = React.useState('dealerPrice');
  const [submitReturn, setSubmitReturn] = React.useState('not_set');
  const [returnPrice, setReturnPrice] = React.useState('EDP');
  const [isDSR, setIsDSR] = React.useState(false);

  // Render states
  const [orderProductFilter, setOrderProductFilter] = React.useState('');
  const [returnProductFilter, setReturnProductFilter] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError, setIsDateError] = React.useState(false);
  const [triggerSt, setTriggerSt] = React.useState(0);
  const [triggerOQt, setTriggerOQt] = React.useState(0);
  const [triggerOPr, setTriggerOPr] = React.useState(0);
  const [triggerRQt, setTriggerRQt] = React.useState(0);
  const [triggerRPr, setTriggerRPr] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(0);

  // Get transaction type list
  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/transaction/type?limit=none&isActive=true')
      .then((res) => setTrxTypeList(res?.data?.data))
      .catch((error) => expired(error));
  }, []);

  // Get customer list
  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/customer?populate=yes&limit=none&isActive=true&sort=name&parentTerritory=${user?.employee?.territory?.parentTerritory?.id}`,
      )
      .then((res) => {
        const receivers = [];

        res?.data?.data?.forEach((c) => {
          const rank = c?.customerType?.rank;

          if (
            rank === 2 &&
            c?.territory?.parentTerritory?.id === user?.employee?.territory?.id
          ) {
            setSender(c);
          }

          if (rank > 2 && rank < 5) {
            receivers.push(c);
          }
        });

        setCustomerList(receivers);
      })
      .catch((error) => expired(error));
  }, []);

  // Set price type for DSR
  React.useEffect(() => {
    if (isDSR) {
      setPriceType('dsrPrice');
    } else {
      setPriceType('dealerPrice');
    }
  }, [isDSR]);

  // Set transaction type
  React.useEffect(() => {
    let oType = null;
    let rType = null;

    trxTypeList?.forEach((value) => {
      if (
        value?.senderType === sender?.customerType?.id &&
        value?.receiverType === receiver?.customerType?.id &&
        value?.function === 'sale' &&
        value?.priceType === priceType
      ) {
        oType = value;
      }

      if (
        value?.senderType === receiver?.customerType?.id &&
        value?.receiverType === sender?.customerType?.id &&
        value?.function === 'transfer' &&
        value?.priceType === priceType
      ) {
        rType = value;
      }
    });

    if (oType?.id) {
      setOrderType(oType);
      setDiscountPercent(
        receiver?.customerClass?.discountPercent + oType?.discountPercent,
      );
    }

    if (rType?.id) {
      setReturnType(rType);
    }
  }, [
    trxTypeList?.length,
    sender?.customerType?.id,
    receiver?.customerType?.id,
    priceType,
  ]);

  // Get all products and sender stock
  React.useEffect(() => {
    setIsLoading(true);
    async function fetchProducts() {
      let allProducts = [];
      let senderStock = {};
      let receiverStock = {};

      try {
        if (
          orderType?.productCondition &&
          returnType?.productCondition &&
          sender?.id &&
          receiver?.id
        ) {
          const resP = await axiosPrivate.get(
            '/api/v1/product?populate=yes&limit=none&isActive=true&isOwn=true&sort=name',
          );
          const resC1 = await axiosPrivate.get(
            `/api/v1/customer/${sender?.id}/stock`,
          );
          const resC2 = await axiosPrivate.get(
            `/api/v1/customer/${receiver?.id}/stock`,
          );

          allProducts = resP?.data?.data;

          resC1?.data?.data?.products?.forEach((p) => {
            if (orderType?.productCondition === 'intact') {
              senderStock[p?.product?.id] = p.intactQuantity;
            } else if (orderType?.productCondition === 'damaged') {
              senderStock[p?.product?.id] = p.damagedQuantity;
            }
          });

          resC2?.data?.data?.products?.forEach((p) => {
            if (returnType?.productCondition === 'intact') {
              receiverStock[p?.product?.id] = p.intactQuantity;
            } else if (returnType?.productCondition === 'damaged') {
              receiverStock[p?.product?.id] = p.damagedQuantity;
            }
          });
        }
      } catch (error) {
        expired(error);
      } finally {
        const spList = [];
        const rpList = [];

        allProducts?.forEach((p) => {
          const spCtn = Math.floor(senderStock[p.id] / p?.quantityPerCarton);
          const spPcs = senderStock[p.id] % p?.quantityPerCarton;

          let spDisplay = `Stock: ${spCtn || 0} ctn`;
          if (spPcs > 0) {
            spDisplay += ` ${spPcs} pcs`;
          }

          const rpCtn = Math.floor(receiverStock[p.id] / p?.quantityPerCarton);
          const rpPcs = receiverStock[p.id] % p?.quantityPerCarton;

          let rpDisplay = `Stock: ${rpCtn || 0} ctn`;
          if (rpPcs > 0) {
            rpDisplay += ` ${rpPcs} pcs`;
          }

          let sp = {
            ...p,
            product: p.id,
            quantity: 0,
            freeQuantity: 0,
            giftQuantity: 0,
            priceDisplay: '',
            quantityDisplay: '',
            totalPrice: 0,
            // stock: sender?.customerType?.hasStock
            //   ? senderStock[p.id] || 0
            //   : 999999,
            stock: 999999,
            stockDisplay: spDisplay,
          };

          let rp = {
            ...p,
            product: p.id,
            quantity: 0,
            freeQuantity: 0,
            giftQuantity: 0,
            priceDisplay: '',
            quantityDisplay: '',
            totalPrice: 0,
            // stock: receiver?.customerType?.hasStock
            //   ? receiverStock[p.id] || 0
            //   : 999999,
            stock: 999999,
            stockDisplay: rpDisplay,
          };

          /* if (oldProducts[p.id]?.quantity) {
            sp = { ...sp, ...oldProducts[p.id] };
          } */

          spList.push(sp);
          rpList.push(rp);
        });

        setOrderProductsList(spList);
        setReturnProductsList(rpList);
        setTriggerSt(Date.now());
      }
    }
    fetchProducts();
  }, [
    sender?.id,
    receiver?.id,
    orderType?.productCondition,
    returnType?.productCondition,
  ]);

  // Calculate order price
  React.useEffect(() => {
    let amount = 0;
    const products = [];
    orderProductsList?.forEach((p) => {
      const product = { ...p };
      if (p?.quantity > 0) {
        const price = parseFloat(p[orderType?.priceType]);
        const quantity = parseFloat(p.quantity);
        const quantityPerCarton = parseInt(p?.quantityPerCarton, 10);

        const quantityCartons = Math.floor(quantity / quantityPerCarton);
        const quantityPieces = quantity % quantityPerCarton;

        product.quantityDisplay = `Ordered: ${quantityCartons} ctn`;
        if (quantityPieces > 0) {
          product.quantityDisplay += ` ${quantityPieces} pcs`;
        }

        if (orderType?.applyOffer) {
          if (p?.offerType === 'free') {
            const freeItemsPerCarton = parseFloat(p?.freeItemsPerCarton);

            const freeQuantity = Math.floor(
              quantity / (quantityPerCarton / freeItemsPerCarton),
            );

            const freeCartons = Math.floor(freeQuantity / quantityPerCarton);

            product.freeQuantity = freeCartons * quantityPerCarton;

            const freeQuantityCartons = Math.floor(
              product.freeQuantity / quantityPerCarton,
            );
            const freeQuantityPieces = product.freeQuantity % quantityPerCarton;

            let freeDisplay = `${freeQuantityCartons} ctn`;
            if (freeQuantityPieces > 0) {
              freeDisplay += ` ${freeQuantityPieces} pcs`;
            }

            const totalCarton = Math.floor(
              (quantity + product.freeQuantity) / quantityPerCarton,
            );
            const totalPieces =
              (quantity + product.freeQuantity) % quantityPerCarton;

            let totalDisplay = `${totalCarton} ctn`;
            if (totalPieces > 0) {
              totalDisplay += ` ${totalPieces} pcs`;
            }

            product.quantityDisplay += `, Free: ${freeDisplay}, Total: ${totalDisplay}`;
          } else if (
            p?.offerType === 'gift' &&
            p?.minimumOrderQuantityForOffer > 0
          ) {
            const giftQuantity = Math.floor(
              quantity / (p.minimumOrderQuantityForOffer * quantityPerCarton),
            );

            product.giftQuantity = giftQuantity;
            product.quantityDisplay += `, Gift items: ${p?.giftItemDescription} (x${giftQuantity})`;
          }
        } else {
          product.freeQuantity = 0;
          product.giftQuantity = 0;
        }

        if (price) {
          let total = price * quantity;
          if (discountPercent > 0) {
            total -= (total * discountPercent) / 100;
          }

          product.totalPrice = roundToDigit(total);
          amount += total;

          product.priceDisplay = `Price: (${quantity} x ${roundToDigit(
            price,
            3,
          )}) - ${discountPercent}% = ${roundToDigit(
            product.totalPrice,
            3,
          )} Tk`;
        }
      } else {
        product.freeQuantity = 0;
        product.giftQuantity = 0;
        product.totalPrice = 0;
        product.priceDisplay = '';
        product.quantityDisplay = '';
      }
      products.push(product);
    });

    setOrderProductsList(products);
    setInvoiceAmount(roundToDigit(amount));
    setTriggerOPr(Date.now());
    setIsLoading(false);
  }, [orderType?.priceType, discountPercent, triggerSt, triggerOQt]);

  // Calculate return price
  React.useEffect(() => {
    let amount = 0;
    const products = [];
    returnProductsList?.forEach((p) => {
      const product = { ...p };
      if (p?.quantity > 0) {
        const originalPrice = parseFloat(p[returnType?.priceType]);
        const quantity = parseFloat(p.quantity);
        const quantityPerCarton = parseInt(p?.quantityPerCarton, 10);

        let price = originalPrice;
        if (returnPrice === 'EDP' && p?.offerType === 'free') {
          const freeItemsPerCarton = parseFloat(p?.freeItemsPerCarton);

          price =
            (originalPrice * quantityPerCarton) /
            (freeItemsPerCarton + quantityPerCarton);
        }

        const quantityCartons = Math.floor(quantity / quantityPerCarton);
        const quantityPieces = quantity % quantityPerCarton;

        product.quantityDisplay = `Returned: ${quantityCartons} ctn`;
        if (quantityPieces > 0) {
          product.quantityDisplay += ` ${quantityPieces} pcs`;
        }

        product.freeQuantity = 0;
        product.giftQuantity = 0;

        if (price) {
          let total = price * quantity;
          if (discountPercent > 0) {
            total -= (total * discountPercent) / 100;
          }

          product.totalPrice = roundToDigit(total);
          amount += total;

          product.priceDisplay = `Value: (${quantity} x ${roundToDigit(
            price,
            3,
          )}) - ${discountPercent}% = ${roundToDigit(
            product.totalPrice,
            3,
          )} Tk`;
        }
      } else {
        product.freeQuantity = 0;
        product.giftQuantity = 0;
        product.totalPrice = 0;
        product.priceDisplay = '';
        product.quantityDisplay = '';
      }
      products.push(product);
    });

    setReturnProductsList(products);
    setReturnAmount(roundToDigit(amount));
    setTriggerRPr(Date.now());
    setIsLoading(false);
  }, [
    returnType?.priceType,
    discountPercent,
    returnPrice,
    triggerSt,
    triggerRQt,
  ]);

  // Set bonus amount
  React.useEffect(() => {
    const amount = parseFloat(collectionAmount) + parseFloat(costAmount);

    if (amount >= 50000) {
      setBonusAmount(roundToDigit(amount / 100));
    } else {
      setBonusAmount(0);
    }
  }, [collectionAmount, costAmount]);

  const handleOrderQuantityChange = (id, quantity) => {
    setIsLoading(true);
    const products = [];
    orderProductsList?.forEach((p) => {
      if (p?.id === id) {
        if (quantity > p?.stock) {
          quantity = p.stock;
        }
        if (quantity > 0) {
          p.quantity = quantity;
        } else {
          p.quantity = 0;
        }
      }
      products.push(p);
    });

    setOrderProductsList(products);
    setTriggerOQt(Date.now());
  };

  const handleReturnQuantityChange = (id, quantity) => {
    setIsLoading(true);
    const products = [];
    returnProductsList?.forEach((p) => {
      if (p?.id === id) {
        if (quantity > p?.stock) {
          quantity = p.stock;
        }
        if (quantity > 0) {
          p.quantity = quantity;
        } else {
          p.quantity = 0;
        }
      }
      products.push(p);
    });

    setReturnProductsList(products);
    setTriggerRQt(Date.now());
  };

  const handleSubmit = () => {
    setIsLoading(true);

    const orderProducts = [];
    orderProductsList?.forEach((p) => {
      if (p?.quantity > 0) orderProducts.push(p);
    });

    const returnProducts = [];
    returnProductsList?.forEach((p) => {
      if (p?.quantity > 0) returnProducts.push(p);
    });

    const damageProducts = [];
    damageProductsList?.forEach((p) => {
      if (p?.quantity > 0) damageProducts.push(p);
    });

    axiosPrivate
      .post('/api/v1/transaction/depot-order', {
        orderType: orderType?.id,
        returnType: returnType?.id,
        damageType: damageType?.id,
        sender: sender?.id,
        receiver: receiver?.id,
        recordedBy: userId,
        orderedAt: orderedAt?.toDate(),
        doNumber,
        coNumber,
        deliverymanName,
        deliverymanContact,
        vehicleNumber,
        invoiceAmount,
        discountAmount,
        discountPercent,
        returnAmount,
        damageAmount,
        collectionAmount,
        bonusAmount,
        costAmount,
        paymentMode,
        bankName,
        bankBranch,
        accountNumber,
        transactionID,
        reference,
        remarks,
        orderProducts,
        returnProducts,
        damageProducts,
      })
      .then((res) => {
        refetch();
        toast.success(res?.data?.message);
        navigate('/transactions');
      })
      .catch((error) => expired(error))
      .finally(() => setIsLoading(false));
  };

  const OrderProductsControl = React.useMemo(() => {
    return orderProductsList.map((p, i) => {
      if (
        !p?.nameCode?.toLowerCase()?.includes(orderProductFilter?.toLowerCase())
      ) {
        return null;
      }

      let offer;
      switch (p?.offerType) {
        case 'free':
          offer = `Free ${roundToDigit(p?.freeItemsPerCarton, 5)} pcs / ctn`;
          break;
        case 'gift':
          offer = `Gift ${p?.giftItemDescription}`;
          break;
        default:
          offer = '';
          break;
      }

      const isEmptyStock = false; // Boolean(p?.stock <= 0);
      const isSelected = Boolean(p?.quantity > 0);
      const isStockExceeded = Boolean(p?.stock < p?.quantity);

      return (
        <Paper
          key={p?.id}
          variant="outlined"
          sx={(theme) => ({
            boxShadow: isSelected
              ? `inset 8px 0px ${
                  isStockExceeded
                    ? theme.palette.error.main
                    : theme.palette.primary.main
                }`
              : '',
          })}>
          <Stack
            direction="column"
            sx={{ padding: '16px 8px 4px 16px', gap: 2 }}>
            <Stack direction="column">
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                {`${i + 1}. ${p?.nameCode}`}
              </Typography>
              <Typography
                variant="caption"
                fontWeight="bold"
                color="success.main"
                gutterBottom>
                {offer}
              </Typography>
              {p?.stock < 999999 ? (
                <Typography
                  variant="subtitle2"
                  color={isStockExceeded ? 'error.main' : 'primary.main'}
                  gutterBottom>
                  {p?.stockDisplay}
                </Typography>
              ) : null}
              <Typography variant="subtitle2" color="text.secondary">
                {p?.priceDisplay || 'Price: 0'}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {p?.quantityDisplay || 'Ordered: 0'}
              </Typography>
            </Stack>

            <ProductQuantityInput
              size="small"
              inputLabel="Quantity (pcs)"
              color="primary"
              disabled={isEmptyStock}
              error={isStockExceeded}
              cartonUnits={p?.quantityPerCarton}
              value={p?.quantity}
              onChange={(q) => handleOrderQuantityChange(p?.id, q)}
            />
          </Stack>
        </Paper>
      );
    });
  }, [orderProductsList?.length, orderProductFilter, triggerOPr]);

  const ReturnProductsControl = React.useMemo(() => {
    return returnProductsList.map((p, i) => {
      if (
        !p?.nameCode
          ?.toLowerCase()
          ?.includes(returnProductFilter?.toLowerCase())
      ) {
        return null;
      }

      const isEmptyStock = false; // Boolean(p?.stock <= 0);
      const isSelected = Boolean(p?.quantity > 0);
      const isStockExceeded = Boolean(p?.stock < p?.quantity);

      return (
        <Paper
          key={p?.id}
          variant="outlined"
          sx={(theme) => ({
            boxShadow: isSelected
              ? `inset 8px 0px ${
                  isStockExceeded
                    ? theme.palette.error.main
                    : theme.palette.secondary.main
                }`
              : '',
          })}>
          <Stack
            direction="column"
            sx={{ padding: '16px 8px 4px 16px', gap: 2 }}>
            <Stack direction="column">
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                {`${i + 1}. ${p?.nameCode}`}
              </Typography>
              {p?.stock < 999999 ? (
                <Typography
                  variant="subtitle2"
                  color={isStockExceeded ? 'error.main' : 'secondary.main'}
                  gutterBottom>
                  {p?.stockDisplay}
                </Typography>
              ) : null}
              <Typography variant="subtitle2" color="text.secondary">
                {p?.priceDisplay || 'Value: 0'}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {p?.quantityDisplay || 'Returned: 0'}
              </Typography>
            </Stack>

            <ProductQuantityInput
              size="small"
              inputLabel="Quantity (pcs)"
              color="secondary"
              disabled={isEmptyStock}
              error={isStockExceeded}
              cartonUnits={p?.quantityPerCarton}
              value={p?.quantity}
              onChange={(q) => handleReturnQuantityChange(p?.id, q)}
            />
          </Stack>
        </Paper>
      );
    });
  }, [returnProductsList?.length, returnProductFilter, triggerRPr]);

  return (
    <Container maxWidth="sm">
      <Box ref={headerRef}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
          <Step disabled>
            <StepButton>Order</StepButton>
          </Step>
          <Step disabled>
            <StepButton>Return</StepButton>
          </Step>
          <Step disabled>
            <StepButton>Confirmation</StepButton>
          </Step>
        </Stepper>
      </Box>

      {activeStep === 0 ? (
        <>
          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <Typography variant="subtitle1" color="primary.main">
              Order Details
            </Typography>

            <Typography variant="subtitle2" align="center">
              {sender?.name
                ? `${sender?.name} - ${sender?.territory?.name}`
                : 'Loading...'}
            </Typography>

            <Autocomplete
              fullWidth
              size="small"
              disabled={customerList?.length < 1}
              options={customerList}
              value={receiver}
              isOptionEqualToValue={(option, value) => value?.id === option?.id}
              onChange={(event, value) => {
                setReceiver(value);
                setIsDSR(value?.customerType?.customerType === 'DSR');
              }}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option?.name} - ${option?.territory?.name}`
                  : ''
              }
              renderInput={(params) => (
                <TextField {...params} label="Customer" />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disableFuture
                label="Order Time"
                inputFormat="DD/MM/YYYY hh:mm A"
                views={['month', 'year', 'day', 'hours', 'minutes']}
                value={orderedAt}
                onChange={(value) => setOrderedAt(value)}
                onError={(reason) => setIsDateError(Boolean(reason))}
                renderInput={(params) => (
                  <TextField {...params} fullWidth size="small" />
                )}
              />
            </LocalizationProvider>

            {isDSR ? (
              <FormControl>
                <FormLabel id="depot-order-price-type">Price Type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="depot-order-price-type"
                  value={priceType}
                  onChange={(e) => setPriceType(e.target.value)}>
                  <FormControlLabel
                    value="dsrPrice"
                    label="DSR Price"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    value="dealerPrice"
                    label="Dealer Price"
                    control={<Radio />}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}

            {orderType?.id ? (
              <Typography variant="caption" color="text.disabled">
                {orderType?.transactionType}
              </Typography>
            ) : null}
          </Stack>

          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Typography variant="subtitle1" color="primary.main">
                Products
              </Typography>

              <TextField
                size="small"
                type="search"
                placeholder="Search products"
                value={orderProductFilter}
                onChange={(e) => setOrderProductFilter(e.target.value)}
              />
            </Stack>

            {OrderProductsControl?.length < 1 ? (
              <Typography
                variant="subtitle2"
                color="text.disabled"
                align="center">
                Select a customer to load products
              </Typography>
            ) : (
              OrderProductsControl
            )}
          </Stack>

          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <TextField
              fullWidth
              size="small"
              type="number"
              label="Discount Percent"
              value={discountPercent}
              onChange={(e) =>
                setDiscountPercent(
                  e?.target?.value ? parseFloat(e?.target?.value) : 0,
                )
              }
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />

            <TextField
              fullWidth
              size="small"
              type="number"
              label="Discount Amount"
              value={discountAmount}
              onChange={(e) =>
                setDiscountAmount(
                  e?.target?.value ? parseFloat(e?.target?.value) : 0,
                )
              }
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              size="small"
              type="number"
              label="Invoice Amount"
              value={invoiceAmount}
              onChange={(e) =>
                setInvoiceAmount(
                  e?.target?.value ? parseFloat(e?.target?.value) : 0,
                )
              }
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button
              size="large"
              variant="contained"
              endIcon={<KeyboardArrowRightIcon />}
              onClick={() => {
                if (submitReturn === 'no') {
                  setActiveStep(2);
                } else {
                  setActiveStep(1);
                }
                headerRef?.current?.scrollIntoView();
              }}>
              Next
            </Button>
          </Stack>
        </>
      ) : null}

      {activeStep === 1 ? (
        submitReturn === 'not_set' ? (
          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <Typography>
              Do you want to add damage return information?
            </Typography>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => {
                setSubmitReturn('no');
                setActiveStep(2);
                headerRef?.current?.scrollIntoView();
              }}
              sx={{ textTransform: 'none' }}>
              No, skip this step
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={() => setSubmitReturn('yes')}
              sx={{ textTransform: 'none' }}>
              Yes, enter damage return
            </Button>
          </Stack>
        ) : (
          <>
            <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
              <Typography variant="subtitle1" color="primary.main">
                Return Details
              </Typography>

              <Typography variant="subtitle2" align="center">
                {sender?.name
                  ? `${sender?.name} - ${sender?.territory?.name}`
                  : 'Loading...'}
              </Typography>

              <Typography variant="subtitle2" align="center">
                {receiver?.name
                  ? `${receiver?.name} - ${receiver?.territory?.name}`
                  : 'Loading...'}
              </Typography>

              <Stack>
                <Typography variant="body1" color="text.secondary">
                  Return value calculation based on
                </Typography>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <Radio
                      onChange={() => setReturnPrice('DP')}
                      checked={returnPrice === 'DP'}
                      inputProps={{ 'aria-labelledby': 'return-price-dp' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="return-price-dp"
                    primary="DP"
                    secondary="Original dealer price"
                  />
                </ListItem>

                <ListItem disablePadding>
                  <ListItemIcon>
                    <Radio
                      onChange={() => setReturnPrice('EDP')}
                      checked={returnPrice === 'EDP'}
                      inputProps={{ 'aria-labelledby': 'return-price-edp' }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id="return-price-edp"
                    primary="EDP"
                    secondary="Dealer price including free offer"
                  />
                </ListItem>
              </Stack>

              {returnType?.id ? (
                <Typography variant="caption" color="text.disabled">
                  {returnType?.transactionType}
                </Typography>
              ) : null}
            </Stack>

            <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
              <Stack
                direction="row"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Typography variant="subtitle1" color="primary.main">
                  Products
                </Typography>

                <TextField
                  size="small"
                  type="search"
                  placeholder="Search products"
                  value={returnProductFilter}
                  onChange={(e) => setReturnProductFilter(e.target.value)}
                />
              </Stack>

              {ReturnProductsControl?.length < 1 ? (
                <Typography
                  variant="subtitle2"
                  color="text.disabled"
                  align="center">
                  Select a customer to load products
                </Typography>
              ) : (
                ReturnProductsControl
              )}
            </Stack>

            <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
              <TextField
                fullWidth
                size="small"
                type="number"
                label="Return Amount"
                value={returnAmount}
                onChange={(e) =>
                  setReturnAmount(
                    e?.target?.value ? parseFloat(e?.target?.value) : 0,
                  )
                }
                onWheel={(e) => e.target.blur()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Tk</InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Button
                size="large"
                variant="outlined"
                startIcon={<KeyboardArrowLeftIcon />}
                onClick={() => {
                  setActiveStep(0);
                  headerRef?.current?.scrollIntoView();
                }}>
                Back
              </Button>

              <Button
                size="large"
                variant="contained"
                endIcon={<KeyboardArrowRightIcon />}
                onClick={() => {
                  setActiveStep(2);
                  headerRef?.current?.scrollIntoView();
                }}>
                Next
              </Button>
            </Stack>
          </>
        )
      ) : null}

      {activeStep === 2 ? (
        <>
          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <Typography variant="subtitle1" color="primary.main">
              Delivery Details
            </Typography>

            <TextField
              fullWidth
              size="small"
              type="text"
              label="DO Number"
              value={doNumber}
              onChange={(e) => setDoNumber(e.target.value)}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              label="CO Number"
              value={coNumber}
              onChange={(e) => setCoNumber(e.target.value)}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              label="Delivery Man Name"
              value={deliverymanName}
              onChange={(e) => setDeliverymanName(e.target.value)}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              label="Delivery Man Contact"
              value={deliverymanContact}
              onChange={(e) => setDeliverymanContact(e.target.value)}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              label="Vehicle Number"
              value={vehicleNumber}
              onChange={(e) => setVehicleNumber(e.target.value)}
            />
          </Stack>

          <Stack component={Paper} sx={{ p: 2, mb: 2, gap: 2 }}>
            <Typography variant="subtitle1" color="primary.main">
              Payment Collection Details
            </Typography>

            <Typography variant="subtitle2" color="text.disabled" gutterBottom>
              Expected collection: {roundToDigit(invoiceAmount - returnAmount)}
            </Typography>

            <TextField
              fullWidth
              size="small"
              type="number"
              label="Collection Amount"
              value={collectionAmount}
              onChange={(e) =>
                setCollectionAmount(
                  e?.target?.value ? parseFloat(e?.target?.value) : 0,
                )
              }
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
            />

            <TextField
              select
              fullWidth
              size="small"
              label="Payment Mode"
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}>
              <MenuItem value="Cash">Cash</MenuItem>
              <MenuItem value="Bank">Bank</MenuItem>
              <MenuItem value="Mobile Bank">Mobile Bank</MenuItem>
            </TextField>

            {paymentMode === 'Bank' ? (
              <>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={BankList}
                  value={bankName}
                  onChange={(event, value) => setBankName(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Bank Name" />
                  )}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Bank Branch"
                  value={bankBranch}
                  onChange={(e) => setBankBranch(e.target.value)}
                />
              </>
            ) : null}

            {paymentMode === 'Mobile Bank' ? (
              <>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={MobileBankList}
                  value={bankName}
                  onChange={(event, value) => setBankName(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Mobile Bank Name" />
                  )}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Mobile Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </>
            ) : null}

            {['Bank', 'Mobile Bank']?.includes(paymentMode) ? (
              <>
                <TextField
                  fullWidth
                  size="small"
                  type="text"
                  label="Transaction Code"
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                />

                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  label="Bank Charge"
                  value={costAmount}
                  onChange={(e) =>
                    setCostAmount(
                      e?.target?.value ? parseFloat(e?.target?.value) : 0,
                    )
                  }
                  onWheel={(e) => e.target.blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tk</InputAdornment>
                    ),
                  }}
                />
              </>
            ) : null}

            <TextField
              fullWidth
              size="small"
              type="number"
              label="Incentive Amount"
              value={bonusAmount}
              onChange={(e) =>
                setBonusAmount(
                  e?.target?.value ? parseFloat(e?.target?.value) : 0,
                )
              }
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              label="Reference"
              value={reference}
              onChange={(e) => setReference(e.target.value)}
            />

            <TextField
              fullWidth
              size="small"
              type="text"
              multiline
              rows={3}
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Button
              size="large"
              variant="outlined"
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={() => {
                if (submitReturn === 'no') {
                  setActiveStep(0);
                } else {
                  setActiveStep(1);
                }
                headerRef?.current?.scrollIntoView();
              }}>
              Back
            </Button>

            <Button
              disabled={isLoading || isDateError}
              size="large"
              variant="contained"
              endIcon={<FileUploadOutlinedIcon />}
              onClick={handleSubmit}>
              Submit
            </Button>
          </Stack>
        </>
      ) : null}
    </Container>
  );
}

export default AddDepotOrder;
