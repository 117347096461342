import React from 'react';
import { useQuery } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import useAuth from '../../hooks/useAuth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function ViewTransfers() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId, role, user } = useAuth();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  React.useEffect(() => {
    setHeader({ title: 'Transfers' });
  }, []);

  useQuery('', () => {});

  if (location.pathname !== '') {
    return (
      <Outlet
        context={
          [
            /* refetch */
          ]
        }
      />
    );
  }

  return <div>Transfers</div>;
}
