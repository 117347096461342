import { useMemo, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Filter from '../../component/report/Filter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useQuery } from 'react-query';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import useExpiredSession from '../../hooks/useExpiredSession';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import StickyTable from '../../component/Table/StickyTable';
import { useNavigate } from 'react-router-dom';
import { MultiSelectColumnFilter } from '../../component/Table/SelectColumnFilter';

export default function CustStock() {
  const [year, setYear] = useState(new Date());
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const navigate = useNavigate();

  //   get Stock
  const { data, isLoading, isError, error } = useQuery(
    ['cust-stock-report', year],
    () =>
      axiosPrivate.get(
        `/api/v1/report/customer-stock?year=${new Date(year).getFullYear()}`,
      ),
  );

  const handleInfo = (id) => {
    navigate(`/customers/stock/${id}`, {
      state: { title: 'Customers Stocks' },
    });
  };

  const COLUMNS = useMemo(() => {
    const c = [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon fontSize="8px" />
            ) : (
              <KeyboardArrowRightIcon fontSize="3px" />
            )}
          </>
        ),
        width: 20,
        sticky: 'left',
      },
      {
        Header: 'Region',
        accessor: 'territories.rank-2.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value) return '-';
          else return value;
        },
        sticky: 'left',
        width: 130,
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value) return '-';
          else return value;
        },
        sticky: 'left',
        width: 130,
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                  textAlign: 'center',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 20 ? (
                <Tooltip title={value}>
                  <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                    {value.slice(0, 20)}..
                  </Typography>
                </Tooltip>
              ) : (
                value
              )}
            </>
          );
        },
        width: 150,
        sticky: 'left',
      },
      {
        Header: 'Cust Id',
        accessor: 'customer.code',
        // sticky: 'left',
      },
      {
        Header: 'Cust Name',
        accessor: 'customer.name',
        Cell: ({ value, row }) => {
          if (!value)
            return (
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: 'normal',
                }}>
                -
              </Typography>
            );
          return (
            <>
              {value.length > 24 ? (
                <Stack
                  direction={'row'}
                  gap={0}
                  alignItems={'center'}
                  sx={{
                    width: 'max-content',
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleInfo(row?.original?.customer?.id)}>
                  <Tooltip title={value}>
                    <Typography sx={{ fontSize: '14px', lineHeight: 'normal' }}>
                      {value.slice(0, 24)}.. <OpenInNewIcon fontSize="10px" />
                    </Typography>
                  </Tooltip>
                </Stack>
              ) : (
                <Stack
                  direction={'row'}
                  gap={0}
                  alignItems={'center'}
                  sx={{
                    width: 'max-content',
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleInfo(row?.original?.customer?.id)}>
                  {value}
                  <OpenInNewIcon fontSize="10px" />
                </Stack>
              )}
            </>
          );
        },
        width: 200,
      },
      {
        Header: 'Cust Type',
        accessor: 'customer.customerType',
        // sticky: 'left',
      },
    ];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    months?.forEach((m) => {
      c.push({
        Header: m,
        accessor: `stocks.${m}.totalIntact`,
        Cell: ({ value }) => {
          if (value) return value;
          return '-';
        },
        width: 100,
        aggregate: 'sum',
      });
    });
    return c;
  }, []);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Filter
        title="Customer Stock"
        year={year}
        setYear={setYear}
        custcollect
        CustStock
      />

      {!isLoading ? (
        <StickyTable
          columns={COLUMNS}
          data={data?.data?.data}
          filename="Cust_Stock"
          customerYear={new Date(year).getFullYear()}
          rowexpand="custCollect"
          hideAddIcon
          expandRow={ExpandRow}
        />
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}

function ExpandRow({ data }) {
  const s = data?.stocks;
  const [month, setMonth] = useState(
    new Date().toLocaleString('default', { month: 'short' }),
  );
  const [info, setInfo] = useState(s[month]);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <>
      <Stack direction={'row'} gap={1} p={2}>
        {months.map((m) => (
          <Button
            size="small"
            variant={m === month ? 'contained' : 'outlined'}
            disabled={!s[m] && true}
            onClick={() => {
              setMonth(m);
              setInfo(s[m]);
            }}
            sx={{ borderRadius: 8 }}>
            {m}
          </Button>
        ))}
      </Stack>
      <table style={{ width: '800px' }}>
        <tr>
          <th>Name(Code)</th>
          <th>intactQuantity</th>
          <th>damagedQuantity</th>
          <th>damageDescription</th>
        </tr>

        {info?.products?.map((p) => (
          <tr>
            <td>
              {p.name}({p.code})
            </td>
            <td>{p.intactQuantity}</td>
            <td>{p.damagedQuantity}</td>
            <td>{p.damageDescription}</td>
          </tr>
        ))}
      </table>
      {!info && (
        <Typography p={3}>
          Haven't found any data for the current month.
        </Typography>
      )}
    </>
  );
}
