import React, { useState } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import usePayroll from '../../hooks/usePayroll';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import CustomSpeedDial from '../../component/SpeedDial/CustomSpeedDial';
import AddCardIcon from '@mui/icons-material/AddCard';

function PayrollReport() {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();

  React.useEffect(() => {
    setHeader({ title: 'Payroll Report' });
  }, []);

  const [month, setMonth] = React.useState(dayjs().subtract(1, 'month'));
  const [paymentInfo, setPaymentInfo] = React.useState(null);
  const [trigger, setTrigger] = React.useState(Date.now());
  const [selectedPayrollRow, setSelectedPayrollRow] = useState([]);

  React.useEffect(() => {
    const m = query.get('month');
    if (m) {
      setMonth(dayjs(m, 'MMM_YYYY'));
    }
  }, [query]);

  const { data, isLoading, isError, error, getData } = usePayroll();

  React.useEffect(() => {
    if (trigger) {
      getData(dayjs(month).startOf('month'), dayjs(month).endOf('month'));
    }
  }, [month, trigger, getData]);

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'employee.nameCode',
        Cell: ({ value }) => (
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Role',
        accessor: 'employee.role.employeeRole',
        Filter: MultiSelectColumnFilter,
      },
      {
        Header: 'Region',
        accessor: 'employee.territories.rank-2.name',
        Cell: ({ value }) => (
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Box>
        ),
      },
      {
        Header: 'HQ',
        accessor: 'employee.territories.rank-3.name',
        Cell: ({ value }) => (
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Point/Town',
        accessor: 'employee.territories.rank-4.name',
        Cell: ({ value }) => (
          <Box component="span" sx={{ whiteSpace: 'nowrap' }}>
            {value}
          </Box>
        ),
      },
      {
        Header: 'Date of Join',
        accessor: 'employee.dateOfJoin',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Date of Leave',
        accessor: 'employee.dateOfResign',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Attendance',
        accessor: 'totalAttendance',
      },
      {
        Header: 'Work With',
        accessor: 'totalWorkWith',
      },
      {
        Header: 'Total Collection',
        accessor: 'totalCollection',
      },
      {
        Header: 'Total Lifting',
        accessor: 'totalLifting',
      },
      {
        Header: 'IMS Quantity',
        accessor: 'totalInMarketSalesQuantity',
      },
      {
        Header: 'IMS Value',
        accessor: 'totalInMarketSalesValue',
      },
      {
        Header: 'Basic Salary',
        accessor: 'salaryBasic',
      },
      {
        Header: 'Total DA',
        accessor: 'totalDearnessAllowance',
      },
      {
        Header: 'Total TA',
        accessor: 'totalTravelAllowance',
      },
      {
        Header: 'Hotel Bill',
        accessor: 'totalHotelBill',
      },
      {
        Header: 'Other Bill',
        accessor: 'totalOtherBill',
      },
      {
        Header: 'Mobile Bill',
        accessor: 'salaryMobile',
      },
      {
        Header: 'Internet Bill',
        accessor: 'salaryInternet',
      },
      {
        Header: 'Tickets +',
        accessor: 'totalTicketsPlus',
      },
      {
        Header: 'Tickets -',
        accessor: 'totalTicketsMinus',
      },
      {
        Header: 'Previous Dues',
        accessor: 'previousDues',
      },
      {
        Header: 'Net Payable',
        accessor: 'totalPayable',
      },
      {
        Header: 'Net Paid',
        accessor: 'totalPaid',
      },
      {
        Header: 'Payment Remarks',
        accessor: 'totalPaidRemarks',

        Cell: ({ value }) => (
          <Box component="span" sx={{ whiteSpace: 'pre-line' }}>
            {value}
          </Box>
        ),
        hidePrint: true,
      },
      {
        Header: 'Payable Due',
        accessor: 'totalDue',
        hidePrint: true,
      },
      {
        Header: 'Basic ÷ Coll.',
        accessor: 'salaryCollectionPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'Basic ÷ IMS',
        accessor: 'salaryInMarketSalesPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'TA ÷ Coll.',
        accessor: 'travelAllowanceCollectionPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'TA ÷ IMS',
        accessor: 'travelAllowanceInMarketSalesPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'Net Pay ÷ Coll.',
        accessor: 'totalPayCollectionPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'Net Pay ÷ IMS',
        accessor: 'totalPayInMarketSalesPercent',
        Cell: ({ value }) => `${value}%`,
        hidePrint: true,
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => (
          <Stack direction="row" spacing={1} sx={{ whiteSpace: 'nowrap' }}>
            <Button
              component={Link}
              to={`/payroll-payslip?employee=${
                row?.original?.employee?.id
              }&month=${dayjs(month).format('MMM_YYYY')}`}
              color="primary"
              variant="outlined"
              size="small">
              Payslip
            </Button>

            <Button
              component={Link}
              to={`/payroll-ticket/add?${stringify({
                employee: row?.original?.employee?.id,
                dateOfEntry: month?.format('YYYY-MM-DD'),
                redirect: `/payroll-report?month=${month.format('MMM_YYYY')}`,
              })}`}
              color="secondary"
              variant="outlined"
              size="small">
              Add ticket
            </Button>

            <Button
              color="success"
              variant="outlined"
              size="small"
              onClick={() => setPaymentInfo(row?.original)}>
              Confirm Payment
            </Button>
          </Stack>
        ),
      },
    ],
    [month],
  );

  const handleMultipleTickets = () => {
    // const employeeInfo = [];
    console.log('selectedPayrollRow: ', selectedPayrollRow);
    const employeeInfo = selectedPayrollRow.map(
      (item) => item.original.employee,
    );

    navigate(
      `/payroll-ticket/add-multiple?${stringify({
        dateOfEntry: month?.format('YYYY-MM-DD'),
        redirect: `/payroll-report?month=${month.format('MMM_YYYY')}`,
      })}`,
      {
        state: { employees: employeeInfo },
      },
    );
  };

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'end' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Month"
            inputFormat="MMM YYYY"
            views={['month', 'year']}
            value={month}
            onChange={(newValue) => setMonth(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Box>

      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        hideAddIcon
        filename="payroll_report"
        rowSelection
        setSelectionValues={setSelectedPayrollRow}
      />

      {selectedPayrollRow?.length > 0 ? (
        <CustomSpeedDial
          actions={[
            {
              icon: <AddCardIcon />,
              name: 'Add Tickets',
              onEvent: handleMultipleTickets,
            },
          ]}
        />
      ) : null}

      <ConfirmPaymentModal
        info={paymentInfo}
        open={Boolean(paymentInfo !== null)}
        onClose={() => setPaymentInfo(null)}
        refetch={() => setTrigger(Date.now())}
      />
    </>
  );
}

export default PayrollReport;
