import qs from 'qs';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  MultiSelectColumnFilter,
  SelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import StickyTable from '../../component/Table/StickyTable';
import Filter from '../../component/report/Filter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';

function HqWiseSku() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  // filter states
  const [customerType, setCustomerType] = useState([
    '63d9ef98f3507d5b693cc0e0',
  ]);
  const [territoryType, setTerritoryType] = useState(
    '63aaa46405e4dc7daa1a8648',
  );
  const [year, setYear] = useState(new Date());

  // selection States
  const [customerTypeData, setCustomerTypeData] = useState([]);
  const [territoryTypeData, setTerritoryTypeData] = useState([]);

  //get territoryType
  React.useEffect(() => {
    axiosPrivate.get('/api/v1/territory/type?sort=rank').then((res) => {
      setTerritoryTypeData(res?.data?.data);
    });
  }, [axiosPrivate]);

  const query = qs.stringify({
    territoryType: territoryType,
    customerType: customerType,
    year: new Date(year).getFullYear(),
  });

  //   get hq-sku
  const { data, isLoading, isError, error } = useQuery(
    ['hq-wise-sku', query],
    () => axiosPrivate.get(`/api/v1/report/hq-sku?${query}`),
  );

  console.log(data?.data?.data);

  //get customerType
  React.useEffect(() => {
    axiosPrivate.get('/api/v1/customer/type?sort=-updatedAt').then((res) => {
      setCustomerTypeData(res?.data?.data);
    });
  }, [axiosPrivate]);
  const COLUMNS = useMemo(() => {
    // get categories
    const list = {};
    const catList = [];

    data?.data?.data?.forEach((item, index) => {
      Object.keys(item?.categories)?.forEach((value) => {
        list[value] = {
          Header: value,
          accessor: `categories.${value}`,
          aggregate: 'sum',
        };
      });
    });

    Object.values(list)?.forEach((item) => catList.push(item));

    // get products
    const productList = {};
    const getproducts = [];
    data?.data?.data.forEach((item, index) => {
      Object.keys(item?.products)?.forEach((value) => {
        productList[value] = {
          Header: value,
          accessor: `products.${value}.cartonQuantity`,
          aggregate: 'sum',
        };
      });
    });

    Object.values(productList)?.forEach((item) => getproducts.push(item));

    const c = [
      {
        Header: 'Time',
        columns: [
          { Header: 'Month', accessor: 'month', Filter: SelectColumnFilter },
        ],
        sticky: 'left',
      },
      {
        Header: 'Territorries',
        columns: [
          {
            Header: 'Region',
            accessor: 'territories.Region',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
            Cell: ({ value }) => {
              if (value) {
                return value;
              }
              return '-';
            },
          },
          {
            Header: 'HQ',
            accessor: 'territories.HQ',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
            Cell: ({ value }) => {
              if (value) {
                return value;
              }
              return '-';
            },
          },
          {
            Header: 'Point/Town',
            accessor: 'territories.Point/Town',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
            Cell: ({ value }) => {
              if (value) {
                return value;
              }
              return '-';
            },
          },
        ],
        sticky: 'left',
      },

      {
        Header: 'Categories',
        columns: [...catList],
      },
      {
        Header: 'Products',
        columns: [...getproducts],
      },
    ];
    return c;
  }, [data?.data?.data]);

  if (isError) {
    return (
      <>
        <Filter
          title="HQ-WISE-SKU"
          customerType={customerType}
          setCustomerType={setCustomerType}
          territoryType={territoryType}
          setTerritoryType={setTerritoryType}
          year={year}
          setYear={setYear}
          customerTypeData={customerTypeData}
          territoryTypeData={territoryTypeData}
          hq_sku
        />
        {expired(error)}
      </>
    );
  }

  return (
    <>
      <Filter
        title="HQ-WISE-SKU"
        customerType={customerType}
        setCustomerType={setCustomerType}
        territoryType={territoryType}
        setTerritoryType={setTerritoryType}
        year={year}
        setYear={setYear}
        customerTypeData={customerTypeData}
        territoryTypeData={territoryTypeData}
        hq_sku
      />
      {!isLoading ? (
        <StickyTable
          columns={COLUMNS}
          data={data?.data?.data}
          filename={`Cust_Stock`}
          customerYear={new Date(year).getFullYear()}
          rowexpand="custCollect"
          hideAddIcon
        />
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}

export default HqWiseSku;
