import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/HighlightOff';
import StoreIcon from '@mui/icons-material/Store';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import roundToDigit from '../../utils/roundToDigit';
import { MultiSelectColumnFilter } from '../../component/Table/SelectColumnFilter';

function SrReportSummary() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { role, userId, Admins } = useAuth();
  const [filterDate, setFilterDate] = useState(dayjs());

  const [columns, setColumns] = useState([]);
  const [detailView, setDetailView] = useState(null);
  const handleViewClose = () => setDetailView(null);

  const { data, error, isError, isLoading } = useQuery(
    ['employee-attendance', filterDate, role],

    ({ queryKey: [key, filter] }) => {
      let start = dayjs().startOf('month').toISOString();
      let end = dayjs().endOf('month').toISOString();

      const reportDate = dayjs(filter);
      if (reportDate.isValid) {
        const col = [
          {
            Header: 'Region',
            accessor: 'territories.rank-2.name',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
          },
          {
            Header: 'HQ',
            accessor: 'territories.rank-3.name',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
          },
          {
            Header: 'Point/Town',
            accessor: 'territories.rank-4.name',
            Filter: MultiSelectColumnFilter,
            filter: 'includesSome',
          },
          {
            Header: 'SO/SR',
            accessor: 'employee.nameCode',
          },
          {
            Header: 'Attendance',
            accessor: 'calc.totalAttendance',
          },
          {
            Header: 'Worked With',
            accessor: 'calc.totalWorkWith',
          },
          {
            Header: 'Order Qty',
            accessor: 'calc.orderQuantity',
            Cell: ({ value }) => (value ? `${value} ctn` : null),
          },
          {
            Header: 'Order Value',
            accessor: 'calc.orderValue',
            Cell: ({ value }) => (value ? `${value} Tk` : null),
          },
          {
            Header: 'Memos',
            accessor: 'calc.memoCount',
          },
          {
            Header: 'Delivery Qty',
            accessor: 'calc.deliveryQuantity',
            Cell: ({ value }) => (value ? `${value} ctn` : null),
          },
          {
            Header: 'Delivery Value',
            accessor: 'calc.deliveryValue',
            Cell: ({ value }) => (value ? `${value} Tk` : null),
          },
          {
            Header: 'Lifting Qty',
            accessor: 'calc.liftingQuantity',
            Cell: ({ value }) => (value ? `${value} ctn` : null),
          },
          {
            Header: 'Ave. SKU Per Memo',
            accessor: 'calc.aveSkuValuePerMemo',
          },
          {
            Header: 'Order Value Per Memo',
            accessor: 'calc.orderValuePerMemo',
          },
          {
            Header: 'Line Per Call',
            accessor: 'calc.linePerCall',
          },
          {
            Header: 'Strike Rate',
            accessor: 'calc.strikeRate',
            Cell: ({ value }) => (value ? `${value}%` : null),
          },
          {
            Header: 'Order Execution Rate',
            accessor: 'calc.orderExecutionRate',
            Cell: ({ value }) => (value ? `${value}%` : null),
          },
        ];

        const daysInMonth = reportDate.daysInMonth();
        let i = 1;
        while (i <= daysInMonth) {
          const day = reportDate.date(i);
          const dayKey = day.format('YYYY-MM-DD');

          col.push({
            Header: `Day ${day.format('D')}`,
            accessor: `dates.${dayKey}.routes`,
            Filter: () => <span>{day.format('ddd')}</span>,
            Cell: ({ row, value }) => {
              const info = row?.original?.dates?.[dayKey];

              const rcValue = info?.activities?.reduce(
                (a, c) => {
                  a.deliverQnty += c?.deliveryQuantity;
                  a.outletNumber += c?.routeOutlets?.length;
                  return a;
                },
                { deliverQnty: 0, outletNumber: 0 },
              );

              const mergedRouteOutlets = info?.activities?.reduce(
                (acc, cur) => {
                  return acc.concat(cur.routeOutlets);
                },
                [],
              );

              if (!value) {
                return (
                  <Box title="No SR report found" sx={{ textAlign: 'center' }}>
                    <CancelIcon color="error" />
                  </Box>
                );
              }

              return (
                <Box
                  sx={{
                    textAlign: 'center',
                    backgroundColor:
                      info?.workWith?.length > 0
                        ? 'lightgoldenrodyellow'
                        : 'inherit',
                  }}>
                  <Stack
                    title={`Delivered: ${
                      rcValue?.deliverQnty || 0
                    } ctn\nOutlets: ${rcValue?.outletNumber || 0}`}
                    direction="row"
                    onClick={() =>
                      setDetailView({
                        ...info,
                        routeOutlets: mergedRouteOutlets,
                        employee: row?.original?.employee,
                        date: day.format('ddd, DD/MM/YYYY'),
                      })
                    }
                    sx={(theme) => ({
                      gap: 0.5,
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      ':hover, :focus': {
                        backgroundColor: theme?.palette?.action?.hover,
                      },
                    })}>
                    <CheckCircleIcon color="success" />

                    {rcValue?.deliverQnty ? (
                      <Badge
                        badgeContent={rcValue?.deliverQnty}
                        {...badgeProps}>
                        <UnarchiveIcon color="primary" />
                      </Badge>
                    ) : null}

                    {rcValue?.outletNumber ? (
                      <Badge
                        badgeContent={rcValue?.outletNumber}
                        {...badgeProps}>
                        <StoreIcon color="action" />
                      </Badge>
                    ) : null}
                  </Stack>

                  <Typography variant="body2">{value}</Typography>
                </Box>
              );
            },
          });

          i++;
        }

        setColumns(col);
        start = reportDate.startOf('month').toISOString();
        end = reportDate.endOf('month').toISOString();
      }

      let url = `/api/v1/report/sr-attendance?startDate=${start}&endDate=${end}`;

      if (!['Super Admin', ...Admins, 'IMS'].includes(role)) {
        url += `&recordedBy=${userId}`;
      }

      return axiosPrivate.get(url).then((res) => {
        const info = [];

        res?.data?.data?.forEach((d) => {
          const dd = { ...d };

          let totalAttendance = 0;
          let totalWorkWith = 0;
          let orderValue = 0;
          let memoCount = 0;
          let orderQuantity = 0;
          let outletCount = 0;
          let deliveryQuantity = 0;
          let deliveryValue = 0;
          let liftingQuantity = 0;

          Object.values(dd?.dates).forEach((t) => {
            if (t?.activities?.length > 0) {
              totalAttendance += 1;
            }
            if (t?.workWith?.length > 0) {
              totalWorkWith += t?.workWith?.length;
            }

            let routes = '';

            t?.activities?.forEach((a) => {
              if (!routes) {
                routes += `${a?.route}`;
              } else {
                routes += `, ${a?.route}`;
              }
              orderValue += a?.orderValue;
              memoCount += a?.memoCount;
              orderQuantity += a?.orderQuantity;
              outletCount += a?.outletCount;
              deliveryQuantity += a?.deliveryQuantity;
              deliveryValue += a?.deliveryValue;
              liftingQuantity += a?.liftingQuantity;

              const orderValuePerMemoA = roundToDigit(
                a?.orderValue / a?.memoCount,
              );
              const linePerCallA = roundToDigit(
                a?.orderQuantity / a?.memoCount,
              );
              const aveSkuValuePerMemoA = roundToDigit(
                orderValuePerMemoA / linePerCallA,
              );
              const strikeRateA = roundToDigit(
                (a?.memoCount / a?.outletCount) * 100,
              );
              const orderExecutionRateA = roundToDigit(
                (a?.deliveryValue / a?.orderValue) * 100,
              );

              a.orderValuePerMemo = orderValuePerMemoA;
              a.linePerCall = linePerCallA;
              a.aveSkuValuePerMemo = aveSkuValuePerMemoA;
              a.strikeRate = strikeRateA;
              a.orderExecutionRate = orderExecutionRateA;
            });

            t.routes = routes;
          });

          const orderValuePerMemo = roundToDigit(orderValue / memoCount);
          const linePerCall = roundToDigit(orderQuantity / memoCount);
          const aveSkuValuePerMemo = roundToDigit(
            orderValuePerMemo / linePerCall,
          );
          const strikeRate = roundToDigit((memoCount / outletCount) * 100);
          const orderExecutionRate = roundToDigit(
            (deliveryValue / orderValue) * 100,
          );

          const i = {
            ...dd,
            calc: {
              totalAttendance,
              totalWorkWith,
              orderValue,
              memoCount,
              orderQuantity,
              outletCount,
              deliveryQuantity,
              deliveryValue,
              liftingQuantity,
              orderValuePerMemo,
              linePerCall,
              aveSkuValuePerMemo,
              strikeRate,
              orderExecutionRate,
            },
          };
          info.push(i);
        });

        return info;
      });
    },

    { refetchInterval: 300000 },
  );
  const workWithColumns = [
    {
      Header: 'Customer',
      accessor: 'exDealerVisitRef.customer.nameCode',
    },
    {
      Header: 'Order Quantity',
      accessor: 'orderQuantity',
    },
    {
      Header: 'Order Value',
      accessor: 'orderValue',
    },
    {
      Header: 'Working Time',
      accessor: 'workingTime',
    },
  ];

  const outletColumns = [
    {
      Header: 'Outlet Name',
      accessor: 'outletName',
    },
    {
      Header: 'Outlet Owner Name',
      accessor: 'outletOwnerName',
    },
    {
      Header: 'Outlet Contact',
      accessor: 'outletContact',
      Cell: ({ value }) => (
        <a href={`tel:${value}`} style={{ color: 'black' }}>
          {value}
        </a>
      ),
    },
  ];

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="SO/SR Attendance">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Month"
            inputFormat="MMM YYYY"
            views={['month', 'year']}
            value={filterDate}
            onChange={(newValue) => setFilterDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Title>

      <Table
        columns={columns}
        data={data || []}
        isLoading={isLoading}
        hideAddIcon
        fullHeight={false}
        noWrap
      />

      <Dialog
        fullScreen={fullScreen}
        open={detailView !== null}
        onClose={handleViewClose}
        fullWidth
        maxWidth="lg">
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 18px',
            background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
          }}>
          Workday details
          <IconButton size="small" edge="end" onClick={handleViewClose}>
            <CloseIcon
              sx={{ color: (theme) => theme.palette.primary.contrastText }}
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack gap={4} mt={3}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Typography
                color="primary.main"
                sx={{
                  textAlign: 'center',
                  fontSize: { md: '22px', xs: '13px' },
                  fontWeight: 600,
                }}>
                {detailView?.employee?.name} ({detailView?.employee?.code})
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: { md: '16px', xs: '13px' },
                  fontWeight: 600,
                }}>
                Routes: {detailView?.routes}
              </Typography>
            </Paper>
            <Stack
              direction={{ md: 'row' }}
              gap={2}
              sx={{
                th: { whiteSpace: 'nowrap', td: { whiteSpace: 'nowrap' } },
              }}>
              <Paper variant="outlined" sx={{ p: 2, width: 1 }}>
                <Typography
                  sx={{
                    mb: 2,
                    textAlign: 'center',
                    fontSize: { md: '18px', xs: '13px' },
                    fontWeight: 600,
                  }}>
                  Work with Information
                </Typography>
                <Table
                  filename="Work with information"
                  columns={workWithColumns}
                  data={detailView?.workWith || []}
                  hideAddIcon
                />
              </Paper>
              <Paper variant="outlined" sx={{ p: 2, width: 1 }}>
                <Typography
                  sx={{
                    mb: 2,
                    textAlign: 'center',
                    fontSize: { md: '18px', xs: '13px' },
                    fontWeight: 600,
                  }}>
                  Outlet Information
                </Typography>
                <Table
                  filename="Outlet Information"
                  columns={outletColumns}
                  data={detailView?.routeOutlets || []}
                  hideAddIcon
                  hidePagination
                />
              </Paper>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

const badgeProps = {
  overlap: 'circular',
  color: 'error',
  sx: {
    '.MuiBadge-badge': {
      minWidth: '14px',
      height: '14px',
      padding: '1px 2px',
      fontSize: '11px',
    },
  },
};

export default SrReportSummary;
