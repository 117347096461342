import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

function TableBodySkeleton() {
  return (
    <Box m={2}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={35} />
      </Stack>
    </Box>
  );
}

export default TableBodySkeleton;
