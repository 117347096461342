import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Button from './Button';
import CloseIcon from '@mui/icons-material/Close';

function Modals({
  show,
  onHide,
  onSave,
  width,
  title,
  fullWidth,
  hideicon,
  form,
  confirmbuttons,
  nobutton,
  yesbutton,
  children,
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      open={show}
      onClose={onHide}
      maxWidth={width}
      keepMounted>
      <DialogTitle
        sx={{
          padding: '10px 18px',
          background: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.white.main,
        }}>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="h6">{title ? title : 'No title'}</Typography>
          {!hideicon && (
            <IconButton
              sx={{ color: (theme) => theme.palette.white.main }}
              onClick={onHide}>
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ padding: '20px 24px' }}>
        <Box mt={2}>{children}</Box>
      </DialogContent>
      {form ? null : (
        <DialogActions>
          <Stack spacing={2} direction="row" justifyContent="end" pt={3}>
            {confirmbuttons ? (
              <>
                {nobutton} {yesbutton}
              </>
            ) : (
              <Button text="Save" onClick={onSave} />
            )}
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default Modals;
