import React, { useEffect, useMemo, useState } from 'react';
import useHeader from '../../../hooks/useHeader';
import FilterViewLayout from '../../../component/AuthViews/FilterViewLayout';
import { useQuery } from 'react-query';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import useFindBrowserLogos from '../../../hooks/useFindBrowserLogos';
import SessionsViews from '../../../component/AuthViews/SessionsViews';
import DeviceViews from '../../../component/AuthViews/DeviceViews';
import { toast } from 'react-toastify';
import Modals from '../../../component/Modals';

function AuthDevice() {
  const { setHeader } = useHeader();
  const findDeviceName = useFindBrowserLogos();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const [isActive, setIsActive] = useState(false);
  const [deviceId, setDeviceId] = useState('');
  const [showSessionModal, setSessionModal] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState([]);

  useEffect(() => {
    setHeader({ title: 'Authorization' });
  }, []);

  const { data, isFetching, isLoading, isError, error, refetch } = useQuery(
    'auth-devices',
    () => axiosPrivate.get('/api/v1/user-device/devices'),
    { refetchInterval: 300000 },
  );

  if (isError) {
    expired(error);
  }

  const handleOpen = (deviceInfo) => {
    setDeviceInfo(deviceInfo);
    setSessionModal(true);
  };

  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [territoryFilter, setTerritoryFilter] = useState('');

  // update status
  const updateStatus = () => {
    axiosPrivate
      .patch(`/api/v1/user-device/devices/${deviceId}`, {
        isActive: isActive,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
      })
      .catch((error) => {
        toast.warn(error.response.data.message);
      });
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const cards = useMemo(
    () =>
      data?.data?.data?.map((d, i) => {
        if (userTypeFilter && !d?.searchKey?.includes(userTypeFilter)) {
          return null;
        }
        if (
          searchFilter &&
          !d?.searchKey?.toLowerCase().includes(searchFilter?.toLowerCase())
        ) {
          return null;
        }
        if (territoryFilter && !d?.territoryKey?.includes(territoryFilter)) {
          return null;
        }

        return (
          <>
            <Grid2 key={d?.searchKey} lg={4} md={6} xs={12}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: 1.5,
                  background: 'white',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  transform: 'scale(1)',
                  transition: 'all 0.3s',
                  ':hover': {
                    transform: 'scale(1.025)',
                    boxShadow: '0px 0px 6px 0px #80808080',
                    img: {
                      transform: 'rotate(360deg)',
                    },
                  },
                }}>
                <DeviceViews
                  findDeviceName={findDeviceName}
                  device={d?.device}
                  setDeviceId={setDeviceId}
                  isActive={isActive}
                  setIsActive={setIsActive}
                  index={i}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                  toggleAccordion={toggleAccordion}
                  updateStatus={updateStatus}
                />
                <Box pt={1} px={1}>
                  <SessionsViews
                    handleOpen={handleOpen}
                    deviceInfo={d}
                    listView
                  />
                </Box>
              </Box>
            </Grid2>
          </>
        );
      }),
    [
      data?.data?.data,
      searchFilter,
      territoryFilter,
      userTypeFilter,
      isActive,
      activeIndex,
    ],
  );

  return (
    <FilterViewLayout
      userTypeFilter={userTypeFilter}
      setUserTypeFilter={setUserTypeFilter}
      searchFilter={searchFilter}
      setSearchFilter={setSearchFilter}
      territoryFilter={territoryFilter}
      setTerritoryFilter={setTerritoryFilter}>
      <Box sx={{ position: 'relative' }}>
        <Grid2 container spacing={2}>
          {cards}
        </Grid2>

        {isFetching || isLoading ? (
          <Box
            sx={{
              width: '100%',
              height: '50vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
            }}>
            <Stack
              spacing={2}
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
                backgroundColor: (theme) => '#ffffffc0',
                border: '1px solid #e0e0e0',
                borderRadius: '12px',
              }}>
              <CircularProgress />
              <Typography>Fetching data</Typography>
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Modals
        title="All Sessions"
        show={showSessionModal}
        onSave={() => setSessionModal(false)}
        onHide={() => setSessionModal(false)}
        fullWidth={true}
        width="xs"
        form>
        <DeviceViews
          findDeviceName={findDeviceName}
          device={deviceInfo?.device}
          setDeviceId={setDeviceId}
          setIsActive={setIsActive}
          noEdit
        />
        <Box pt={3}>
          <SessionsViews deviceInfo={deviceInfo} />
        </Box>
      </Modals>
    </FilterViewLayout>
  );
}

export default AuthDevice;
