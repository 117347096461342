import React from 'react';
import { Box, Drawer, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function CustomDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  expandRowData,
  expandRow,
  expandRowProps,
  drawerWidth,
}) {
  const s_md = useMediaQuery('(min-width:769px)');
  if (!expandRow) return null;
  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          maxWidth: { s_md: drawerWidth || '600px', xs: '100%' },
          height: { s_md: '100%', xs: '80%' },
          borderRadius: { s_md: '15px 0px 0px 15px', xs: '15px 15px 0px 0px' },
          overflow: 'hidden',
        },
      }}
      anchor={s_md ? 'right' : 'bottom'}
      open={isDrawerOpen}
      onClose={() => {
        setIsDrawerOpen(false);
        expandRowData?.toggleRowExpanded();
      }}>
      <IconButton
        onClick={() => {
          setIsDrawerOpen(false);
          expandRowData?.toggleRowExpanded();
        }}
        size="small"
        sx={{ marginLeft: 'auto', display: 'block' }}>
        <CloseIcon />
      </IconButton>
      <Box px={{ s_md: 3, xs: 1.5 }} pb={3} sx={{ overflowY: 'auto' }}>
        {React.createElement(expandRow, {
          data: expandRowData?.original,
          ...expandRowProps,
        })}
      </Box>
    </Drawer>
  );
}
