import { Typography } from '@mui/material';
import React from 'react';

export default function DashTitle({ title }) {
  return <Typography sx={style}>{title}</Typography>;
}

const style = {
  color: '#4d4d4d',
  fontWeight: '700',
  fontSize: { md: '2.3vw', xs: 22 },
  position: 'relative',
  display: 'inline-block',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '30%',
    height: '4px',
    left: '0%',
    bottom: '-2px',
    background: (theme) => theme.palette.primary.main,
  },
};
