import { Box, Typography } from '@mui/material';
import ExpandTable from '../../component/Table/ExpandTable/ExpandTable';
import dayjs from 'dayjs';

export default function TargetExpandRow({ data }) {
  const productsData = data?.products;
  const targetLabel = `${dayjs(data?.selectedAt).format('MMM YYYY')} Target`;
  const salesLabel = `${dayjs(data?.selectedAt)
    .subtract(1, 'month')
    .format('MMM YYYY')} IMS`;

  const columns = [
    {
      label: 'Product',
      key: 'product.nameCode',
      render: (value, row) =>
        `${row?.product?.name} (${row?.product?.code}) [${row?.product?.quantityPerCarton}]`,
    },
    {
      label: salesLabel,
      key: 'saleQuantity',
      render: (value, row) => {
        const cartons = Math.floor(
          row?.saleQuantity / row?.product?.quantityPerCarton,
        );
        const packets = row?.saleQuantity % row?.product?.quantityPerCarton;
        let saleQuantityDisplay = `${cartons} ctn`;
        if (packets) saleQuantityDisplay += ` ${packets} pcs`;
        return saleQuantityDisplay;
      },
    },
    {
      label: targetLabel,
      key: 'targetQuantity',
      render: (value, row) => {
        const cartonsI = Math.floor(
          row?.targetQuantity / row?.product?.quantityPerCarton,
        );
        const packetsI = row?.targetQuantity % row?.product?.quantityPerCarton;
        let targetQuantityDisplay = `${cartonsI} ctn`;
        if (packetsI) targetQuantityDisplay += ` ${packetsI} pcs`;
        return targetQuantityDisplay;
      },
    },
  ];

  return (
    <Box px={2} py={1}>
      <ExpandTable columns={columns} data={productsData} />

      {data?.employee && (
        <Typography variant="body2" sx={{ pt: 1 }}>
          Employee: <b>{data?.employee?.nameCode}</b>
        </Typography>
      )}
      {data?.customer && (
        <>
          <Typography variant="body2" sx={{ pt: 1 }}>
            Target Customer: <b>{data?.customer?.nameCode}</b>
          </Typography>
          <Typography variant="body2" sx={{ pt: 1 }}>
            Target Location: <b>{data?.customer?.territory?.nameCode}</b>
          </Typography>
        </>
      )}
      {data?.selectedAt && (
        <Typography variant="body2" sx={{ pt: 1 }}>
          Target Month: <b>{dayjs(data?.selectedAt).format('MMMM YYYY')}</b>
        </Typography>
      )}
    </Box>
  );
}
