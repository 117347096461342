import React, { useMemo, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Stack } from '@mui/material';
import qs from 'qs';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import StickyTable from '../../component/Table/StickyTable';
import Filter from '../../component/report/Filter';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import dayjs from 'dayjs';

export default function DoSection() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const navigate = useNavigate();
  const [transactionType, setTransactionType] = useState([]);
  const [typeData, setTypeData] = useState([{}]); //transactionType data
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // TODO: Add filters to change this
  const query = qs.stringify({
    transactionType: transactionType,
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
  });

  // get Do Section
  const { data, isLoading, isError, error } = useQuery(
    ['do-section-report', query],
    () => axiosPrivate.get(`/api/v1/report/do-section?${query}`),
  );

  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/transaction/type?populate=yes&limit=none&sort=-updatedAt')
      .then((res) => {
        setTypeData(res?.data?.data);
      });
  }, [axiosPrivate]);

  const handleInfo = (id) => {
    navigate(`/customers/transaction/${id}`, {
      state: { title: 'Customers Transaction' },
    });
  };

  const COLUMNS = useMemo(() => {
    const c = [
      {
        Header: 'Region',
        accessor: 'territories.rank-2.territoryName',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value) return '-';
          else return value;
        },
        sticky: 'left',
        width: 120,
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3.territoryName',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          if (!value) return '-';
          else return value;
        },
        sticky: 'left',
        width: 120,
      },

      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4.territoryName',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => {
          let n = 18;
          if (!value) return '-';
          return (
            <>
              {value?.length > n ? (
                <span title={value}>{value.slice(0, n)}..</span>
              ) : (
                value
              )}
            </>
          );
        },
        width: 150,
        sticky: 'left',
      },
      {
        Header: 'Ordered At',
        accessor: 'transaction.orderedAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        width: 130,
      },
      {
        Header: 'Date Of Entry',
        accessor: 'collection.dateOfEntry',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        width: 130,
      },
      {
        Header: 'Date Of Deposit',
        accessor: 'collection.dateOfDeposit',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
        width: 130,
      },
      {
        Header: 'Sender Name (Code)',
        accessor: 'sender.name',
        Cell: ({ value, row }) => {
          let n = 12;
          if (value) {
            return (
              <>
                <Stack
                  direction={'row'}
                  gap={1}
                  alignItems={'center'}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleInfo(row?.original?.sender?.id)}>
                  {value.length > n ? (
                    <>
                      {value.slice(0, n) +
                        '...' +
                        row?.original?.sender?.code?.slice(0, n) +
                        '...'}
                    </>
                  ) : (
                    <>{value + row?.original?.sender?.code}</>
                  )}
                  <OpenInNewIcon fontSize="10px" />
                </Stack>
              </>
            );
          }
          return '-';
        },
        width: 230,
      },
      {
        Header: 'Receiver Name',
        accessor: 'receiver.name',
        Cell: ({ value, row }) => {
          let n = 20;
          if (value) {
            return (
              <>
                <Stack
                  direction={'row'}
                  gap={1}
                  alignItems={'center'}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleInfo(row?.original?.receiver?.id)}>
                  {value.length > n ? (
                    <span title={value + `(${row?.original?.receiver?.code})`}>
                      {value.slice(0, n) +
                        '...' +
                        ' (' +
                        row?.original?.receiver?.code +
                        ')'}
                    </span>
                  ) : (
                    <>{value + row?.original?.receiver?.code}</>
                  )}
                  <OpenInNewIcon fontSize="10px" />
                </Stack>
              </>
            );
          }
          return '-';
        },
        width: 250,
      },
      {
        Header: 'Post Under',
        accessor: 'postUnder.name',
        Cell: ({ value, row }) => {
          let n = 20;
          if (value) {
            return (
              <>
                {value.length > n ? (
                  <span title={value + `(${row?.original?.postUnder?.code})`}>
                    {value.slice(0, n) + '...'} (
                    {row?.original?.postUnder?.code})
                  </span>
                ) : (
                  `${value} (${row?.original?.postUnder?.code})`
                )}
              </>
            );
          }
          return '-';
        },
        width: 230,
      },

      {
        Header: 'Total Collection',
        accessor: 'collection.entryAmount',
      },

      {
        Header: 'Approved Collection',
        accessor: 'collection.totalAmount',
        width: 190,
      },
      {
        Header: 'DO Number',
        accessor: 'transaction.doNumber',
        width: 130,
      },
      {
        Header: 'CO Number',
        accessor: 'transaction.coNumber',
        width: 130,
      },
      {
        Header: 'Remarks',
        accessor: 'collection.remarks',
        width: 200,
        Cell: ({ value }) => {
          if (value?.length > 10) {
            return <span title={value}>{value?.slice(0, 10)}...</span>;
          }
          return value;
        },
      },
      {
        Header: 'Total Products',
        accessor: 'totalProducts',
      },
    ];

    data?.data?.data[0]?.products?.forEach((p, i) => {
      c.push({
        Header: p.code,
        accessor: `products[${i}].quantity`,
        width: 100,
      });
    });

    return c;
  }, [data?.data?.data[0]?.products?.length]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Filter
        title="DO Section"
        typeData={typeData}
        transactionType={transactionType}
        setTransactionType={setTransactionType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        dosection
      />

      {!isLoading ? (
        <StickyTable
          columns={COLUMNS}
          data={data?.data?.data}
          filename="do_section"
          hideAddIcon
        />
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
