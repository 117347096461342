import React from 'react';
import { useNavigate } from 'react-router-dom';
import FloatCIrcle from '../../component/FloatCIrcle';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import Logo from '../../component/auth/Logo';
import { AuthContainer, InnerContainer } from '../../assets/style/auth';

function ForgotPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert(data);
  };

  return (
    <AuthContainer>
      <FloatCIrcle />
      <Box mx={2}>
        <Logo />
        <InnerContainer>
          <Typography variant="h2">Forgot Password</Typography>
          <Typography color="grey" pt={1} sx={{ fontSize: 12 }}>
            Enter your email address here, we will send you a link to reset your
            password.
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              sx={{ mt: 2, mb: 3 }}
              id="standard-basic"
              type="email"
              label="Email Address"
              variant="standard"
              fullWidth
              autoComplete="email"
              autoFocus
              {...register('email', {
                required: 'Required field',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Inavild email address',
                },
              })}
              error={!!errors?.email}
              helperText={errors?.email ? errors.email.message : null}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ borderRadius: '40px' }}>
              Send Reset Link
            </Button>
          </form>

          <Typography
            onClick={() => navigate(-1)}
            color="primary"
            display="block"
            pt={2}
            sx={{ cursor: 'pointer' }}>
            {'<'} Back to Login
          </Typography>
        </InnerContainer>
      </Box>
    </AuthContainer>
  );
}

export default ForgotPassword;
