import React, { useEffect, useRef, useState } from 'react';

/**
 * @typedef {Object} Dimensions
 * @property {Number} width container width
 * @property {Number} height container height
 */

/**
 * A React hook that returns the dimensions of a container element and updates them on resize.
 * @param {React.MutableRefObject} ref The reference to the container element to measure.
 * @returns {Dimensions} The current dimensions of the container element.
 * @example
 * import { useRef } from 'react';
 * import useContainerDimensions from '/hooks/useContainerDimensions'
 *
 * const ref = useRef(null);
 * const { width, height } = useContainerDimensions(ref)
 *
 * return (
 *   <div ref={ref}>
 *     ...
 *   </div>
 * );
 */
const useContainerDimensions = (ref) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const timeoutRef = useRef(null);

  const getDimensions = (element) => ({
    width: element.offsetWidth,
    height: element.offsetHeight,
  });

  useEffect(() => {
    if (ref.current) {
      setDimensions(getDimensions(ref.current));
    }

    const resize = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setDimensions(getDimensions(ref.current));
      }, 200);
    };

    const hasListener = window.removeEventListener('resize', resize, false);

    if (!hasListener) {
      window.addEventListener('resize', resize);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('resize', resize);
    };
  }, [ref.current]);

  return dimensions;
};

export default useContainerDimensions;
