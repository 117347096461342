import useAuth from './useAuth';
import useAxios from './useAxios';

const useLogout = () => {
  const axios = useAxios();
  const { setAuth, setRole } = useAuth();

  const logout = async () => {
    setAuth({});
    setRole('');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('refreshTokenExpires');
    try {
      await axios.delete('/api/v1/auth/logout', {
        withCredentials: true,
      });
    } catch (err) {
      console.warn(err);
    }
  };

  return logout;
};

export default useLogout;
