import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

// export default function ExportData({ filename, data, customerYear }) {
export const exportExcelFile = async (
  filename,
  data,
  customerYear,
  columns,
) => {
  // console.log('data', data);
  // console.log(columns);
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');
  sheet.properties.defaultRowHeight = 15;

  // styling part_______________start_______________________(row)

  sheet.getRow(1).border = {
    right: { style: 'thin', color: { argb: '80808097' } },
  };
  sheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '1976d2' },
  };
  sheet.getRow(1).font = {
    bold: true,
    color: { argb: 'FFFFFF' },
  };

  // styling part_______________end_______________________(row)

  // select headers and columns________Post Under_________________________________________(start)
  // ----------------------------------------------------------------------------------
  // sheet.columns = columns.forEach((item) => {
  //   return { header: item?.Header, key: item?.accessor, width: 15 };
  // });

  if (filename === 'All_Employee_table') {
    sheet.columns = [
      { header: 'Name', key: 'name', width: 20 },
      { header: 'Code', key: 'code', width: 12 },
      { header: 'Date Of Birth', key: 'dob', width: 12 },
      { header: 'National ID', key: 'nid', width: 18 },
      { header: 'Present Address', key: 'present', width: 20 },
      { header: 'Permanent Address', key: 'permanent', width: 20 },
      { header: 'Joining Date', key: 'join', width: 12 },
      { header: 'EmployeeRole', key: 'role', width: 15 },
      { header: 'Location', key: 'location', width: 15 },
      { header: 'Education', key: 'edu', width: 15 },
      { header: 'Edu. Verification', key: 'eduveri', width: 15 },
      { header: 'Status', key: 'status', width: 10 },
      { header: 'Mobile No.', key: 'mobile', width: 20 },
      { header: 'Mobile No.(Referral)', key: 'mobileref', width: 18 },
      { header: 'Email', key: 'email', width: 20 },
      { header: 'Social Media', key: 'media', width: 20 },
      { header: 'Bank Name', key: 'bank', width: 10 },
      { header: 'Account Number', key: 'ac', width: 25 },
      { header: 'Salary Basic', key: 'sb', width: 10 },
      { header: 'Salary Internet', key: 'si', width: 10 },
      { header: 'Salary Mobile', key: 'sm', width: 10 },
      { header: 'salary Travel', key: 'st', width: 10 },
      { header: 'ToalSalary', key: 'ts', width: 10 },
      { header: 'Transaction Mode', key: 'tm', width: 13 },
      { header: 'Description', key: 'des', width: 20 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        code: item?.code,
        dob: item?.dateOfBirth
          ? dayjs(item?.dateOfBirth).format('DD/MM/YYYY')
          : '',
        nid: item?.nationalID,
        present: item?.presentAddress,
        permanent: item?.permanentAddress,
        join: item?.dateOfJoin
          ? dayjs(item?.dateOfJoin).format('DD/MM/YYYY')
          : '',
        role: item?.role?.employeeRole,
        location: item?.territory?.name,
        edu: item?.educationalQualification,
        eduveri: item?.isEducationVerified === true ? 'Verified' : 'Unverified',
        status: item?.isActive === true ? 'Active' : 'Inactive',
        // contact
        mobile: item?.contactNumber,
        mobileref: item?.referralNumber,
        email: item?.email,
        media: item?.socialMedia,
        // Salary
        bank: item?.bankName,
        ac: item?.bankAccountNumber,
        sb: item?.salaryBasic,
        si: item?.salaryInternet,
        sm: item?.salaryMobile,
        st: item?.salaryTravel,
        ts:
          item?.salaryBasic +
          item?.salaryInternet +
          item?.salaryMobile +
          item?.salaryTravel,
        tm: item?.transactionMode,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'All_Customer') {
    sheet.columns = [
      { header: 'Name', key: 'name', width: 15 },
      { header: 'Code', key: 'code', width: 10 },
      { header: 'Remarks', key: 'remarks', width: 10 },
      { header: 'Customer Type', key: 'ctype', width: 15 },
      { header: 'Territory', key: 'territory', width: 15 },
      { header: 'Starting Date', key: 'sd', width: 13 },
      { header: 'Closing Date', key: 'cd', width: 13 },
      { header: 'Status', key: 'status', width: 10 },
      { header: 'Personal Contact', key: 'pc', width: 15 },
      { header: 'Email', key: 'email', width: 15 },
      { header: 'Social', key: 'social', width: 15 },
      { header: 'Contact', key: 'contact', width: 15 },
      { header: 'Description', key: 'des', width: 35 },
      { header: 'Created Time', key: 'ct', width: 13 },
      { header: 'Updated Time', key: 'ut', width: 13 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        code: item?.code,
        remarks: item?.remarks,
        ctype: item?.customerType?.customerType,
        territory: item?.territory?.name,
        sd: item?.dateOfStarting
          ? dayjs(item?.dateOfStarting).format('DD/MM/YYYY')
          : '',
        cd: item?.dateOfClosing
          ? dayjs(item?.dateOfClosing).format('DD/MM/YYYY')
          : '',
        status: item?.isActive === true ? 'Active' : 'Inactive',
        pc: item?.personalContact,
        email: item?.email,
        social: item?.socialMedia,
        contact: item?.contact,
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'Customer_Class') {
    sheet.columns = [
      { header: 'Grade', key: 'grade', width: 15 },
      { header: 'Discount', key: 'discount', width: 10 },
      { header: 'Status', key: 'status', width: 10 },
      { header: 'GiftDescription', key: 'gd', width: 15 },
      { header: 'Description', key: 'des', width: 20 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        grade: item?.customerClass,
        discount: item?.discountPercent,
        status: item?.isActive === true ? 'Active' : 'Inactive',
        gd: item?.giftDescription,
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'Customer_Type') {
    sheet.columns = [
      { header: 'Customer Type', key: 'ctype', width: 15 },
      { header: 'Code Prefix', key: 'cp', width: 10 },
      { header: 'Location Type', key: 'lt', width: 15 },
      { header: 'Description', key: 'des', width: 20 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];
    data?.map((item) =>
      sheet.addRow({
        ctype: item?.customerType,
        cp: item?.codePrefix,
        lt: item?.territoryType?.territoryType,
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'All_Collection' || filename === 'Ledger') {
    sheet.columns = [
      { header: 'Entry Date', key: 'dateOfEntry', width: 12 },
      { header: 'Deposit Date', key: 'DepositDate', width: 12 },
      { header: 'Depositor Name', key: 'DepositorName', width: 25 },
      { header: 'Post Under', key: 'postUnder', width: 25 },
      { header: 'Location', key: 'location', width: 25 },
      { header: 'Entry Amt', key: 'entryAmount', width: 10 },
      { header: 'Bank Name', key: 'BankName', width: 10 },
      { header: 'Trx ID', key: 'TransactionId_Code', width: 10 },
      { header: 'Approved Amt', key: 'approvedAmount', width: 10 },
      { header: 'Cost', key: 'costAmount', width: 10 },
      { header: 'Incentive Amt', key: 'bonusAmount', width: 10 },
      // { header: 'Total Amt', key: 'totalAmount', width: 10 },
      // { header: 'Reference', key: 'Reference', width: 20 },
      // { header: 'Payment Mode', key: 'PaymentMode', width: 20 },
      // { header: 'Account Number', key: 'AccountNumber', width: 20 },
      // { header: 'Description', key: 'Description', width: 20 },
      // { header: 'Created Time', key: 'CreatedTime', width: 12 },
      // { header: 'Updated Time', key: 'UpdatedTime', width: 12 },
      { header: 'CO', key: '', width: 10 },
    ];

    data?.map((item) =>
      sheet.addRow({
        DepositDate: dayjs(item?.dateOfDeposit).format('DD/MM/YYYY'),
        dateOfEntry: dayjs(item?.dateOfEntry).format('DD/MM/YYYY'),
        DepositorName: item?.customer?.nameCode,
        postUnder: !item?.postUnder
          ? '-'
          : `${item?.postUnder?.name} (${item?.postUnder?.code})`,
        location: item.location,
        entryAmount: item?.entryAmount,
        bonusAmount: item?.bonusAmount,
        costAmount: item?.costAmount,
        totalAmount: item?.totalAmount,
        approvedAmount: item?.approvedAmount,
        ApprovedCollection: item?.approvedCollection,
        PaymentMode: item?.paymentMode,
        TransactionId_Code: item?.transactionID ? item?.transactionID : '',
        Reference: item?.reference,
        BankName: item?.bankNameX,
        AccountNumber: item?.accountNumber,
        Description: item?.description,
        CreatedTime: item?.createdAt
          ? dayjs(item?.createdAt).format('DD/MM/YYYY')
          : '',
        UpdatedTime: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'Products') {
    sheet.columns = [
      { header: 'Name', key: 'name', width: 15 },
      { header: 'Code', key: 'code', width: 10 },
      { header: 'Category', key: 'category', width: 15 },
      { header: 'Quantity Per Carton', key: 'qpc', width: 20 },
      { header: 'Dealer Price', key: 'dp', width: 20 },
      { header: 'Trade Price', key: 'tp', width: 20 },
      { header: 'Max Retail Price', key: 'mrp', width: 20 },
      { header: 'Weight Per Packet', key: 'wpp', width: 20 },
      { header: 'Offer Type', key: 'ot', width: 20 },
      { header: 'Free Items Per Carton', key: 'fipc', width: 20 },
      { header: 'Minimum Order Quantity For Offer', key: 'moqfo', width: 20 },
      { header: 'Gift Item Description', key: 'gid', width: 20 },
      { header: 'Status', key: 'status', width: 20 },
      { header: 'Description', key: 'des', width: 20 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        code: item?.code,
        category: item?.productCategory?.productCategory,
        qpc: item?.quantityPerCarton,
        dp: item?.dealerPrice,
        tp: item?.tradePrice,
        mrp: item?.maxRetailPrice,
        wpp: item?.weightPerPacket,
        ot: item?.offerType,
        fipc: item?.freeItemsPerCarton,
        moqfo: item?.minimumOrderQuantityForOffer,
        gid: item?.giftItemDescription,
        status: item?.isActive === true ? 'Active' : 'InActive',
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === "Product's_Category") {
    sheet.columns = [
      { header: 'Product Category', key: 'pc', width: 45 },
      { header: 'Code Prefix', key: 'cp', width: 10 },
      { header: 'Status', key: 'status', width: 10 },
      { header: 'Description', key: 'des', width: 20 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];
    data?.map((item) =>
      sheet.addRow({
        pc: item?.productCategory,
        cp: item?.codePrefix,
        status: item?.isActive === true ? 'Active' : 'InActive',
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'transactions') {
    const c = [
      { header: 'Transaction Type', key: 'tt', width: 25 },
      { header: 'Sender', key: 'sender', width: 25 },
      { header: 'Receiver', key: 'receiver', width: 25 },
      { header: 'Recorded By', key: 'recorded_by', width: 40 },
      { header: 'Invoice Amount', key: 'ia', width: 20 },
      { header: 'Paid Amount', key: 'pa', width: 20 },
      { header: 'Discount Percent', key: 'dp', width: 20 },
      { header: 'Order Time', key: 'ot', width: 20 },
      { header: 'Products Confirmed Time', key: 'product_ct', width: 20 },
      { header: 'Payments Confirmed Time', key: 'payment_ct', width: 20 },
      { header: 'Delivery Time', key: 'dt', width: 20 },
      { header: 'DO Number', key: 'do', width: 20 },
      { header: 'CO Number', key: 'co', width: 20 },
      { header: 'Driver Name', key: 'dname', width: 20 },
      { header: 'Driver Contact', key: 'dcontact', width: 20 },
      { header: 'Vehicle Number', key: 'vn', width: 20 },
    ];

    // get max products index---
    const productLengths = data?.map((item) => item?.products?.length);
    const maxLength = Math.max(...productLengths);
    const result = productLengths.findIndex((n) => maxLength === n);
    // get max products index---

    data[result]?.products?.forEach((p, i) => {
      c.push({
        header: p?.product?.name + `(${p?.product?.code})`,
        key: p?.product?.code,
        width: 23,
      });
    });

    sheet.columns = c;

    data?.map((item) => {
      const x = {
        tt: item?.transactionType?.transactionType,
        sender: item?.sender?.name + ` (${item?.sender?.code})`,
        receiver: item?.receiver?.name + ` (${item?.receiver?.code})`,
        recorded_by: item?.recordedBy?.name + ` (${item?.recordedBy?.code})`,
        ia: item?.payableAmount,
        pa: item?.paidAmount,
        dp: item?.discountPercent,
        ot: dayjs(item?.orderedAt).format('DD/MM/YYYY hh:mm A'),
        product_ct: dayjs(item?.productsConfirmedAt).format(
          'DD/MM/YYYY hh:mm A',
        ),
        payment_ct: dayjs(item?.paymentsConfirmedAt).format(
          'DD/MM/YYYY hh:mm A',
        ),
        dt: dayjs(item?.deliveredAt).format('DD/MM/YYYY hh:mm A'),
        do: item?.doNumber,
        co: item?.coNumber,
        dname: item?.deliverymanName,
        dcontact: item?.deliverymanContact,
        vn: item?.vehicleNumber,
      };
      item?.products?.forEach((p) => {
        x[p?.product?.code] = p?.quantity + p?.freeQuantity;
      });
      return sheet.addRow(x);
    });
  } else if (filename === 'transaction_type') {
    sheet.columns = [
      { header: 'Transaction Type', key: 'transactionType', width: 25 },
      { header: 'Sender Type', key: 'senderType', width: 12 },
      { header: 'Receiver Type', key: 'receiverType', width: 12 },
      { header: 'Function', key: 'function', width: 12 },
      // { header: 'Product Condition', key: 'pc', width: 20 },
      {
        header: 'Sender Ledger Action',
        key: 'sla',
        width: 20,
      },
      {
        header: 'Receiver Ledger Action',
        key: 'rla',
        width: 20,
      },
      { header: 'Price Type', key: 'priceType', width: 12 },
      { header: 'Discount Percent', key: 'discountPercent', width: 15 },
      { header: 'Status', key: 'isActive', width: 10 },
      { header: 'Description', key: 'des', width: 30 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];
    data?.map((item) =>
      sheet.addRow({
        transactionType: item?.transactionType,
        senderType: item?.senderType?.customerType,
        receiverType: item?.receiverType?.customerType,
        function: item?.function + ` (${item?.productCondition})`,
        // pc: item?.productCondition,
        sla: item?.senderLedgerAction,
        rla: item?.receiverLedgerAction,
        priceType: item?.priceType,
        discountPercent: item?.discountPercent,
        isActive: item?.isActive === true ? 'Active' : 'Inactive',
        des: item?.description,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'Stock') {
    sheet.columns = [
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Time', key: 'time', width: 15 },
      { header: 'Type', key: 'type', width: 10 },
      { header: 'Intact', key: 'intact', width: 15 },
      { header: 'Damaged', key: 'damaged', width: 12 },
    ];

    data?.map((item) => {
      // intact display
      const totalIntactCartons = item.products
        ?.map((p) =>
          Math.floor(p.intactQuantity / p?.product?.quantityPerCarton),
        )
        .reduce((a, c) => a + c, 0);
      const totalIntactPackets = item.products
        ?.map((p) => p?.intactQuantity % p?.product?.quantityPerCarton)
        .reduce((a, c) => a + c, 0);

      let totalIntactDisplay = `${totalIntactCartons} ctn`;
      if (totalIntactPackets > 0)
        totalIntactDisplay += ` ${totalIntactPackets} pcs`;

      // damage display
      const totalDamageCartons = item.products
        ?.map((p) =>
          Math.floor(p.damagedQuantity / p?.product?.quantityPerCarton),
        )
        .reduce((a, c) => a + c, 0);
      const totalDamagePackets = item.products
        ?.map((p) => p?.damagedQuantity % p?.product?.quantityPerCarton)
        .reduce((a, c) => a + c, 0);

      let totalDamageDisplay = `${totalDamageCartons} ctn`;
      if (totalDamagePackets > 0)
        totalDamageDisplay += ` ${totalDamagePackets} pcs`;

      const x = {
        date: item?.createdAt
          ? dayjs(item?.createdAt).format('DD/MM/YYYY')
          : '',
        time: item?.createdAt
          ? dayjs(item?.createdAt).format('HH:mm:ss A')
          : '',
        type: item?.type,
        intact: totalIntactDisplay,
        damaged: totalDamageDisplay,
      };

      return sheet.addRow(x);
    });
  }
  // report export start here----------
  else if (filename === 'Customer_Collection') {
    sheet.columns = [
      { header: 'Customer Code', key: 'code', width: 15 },
      { header: 'Customer Name', key: 'name', width: 10 },
      { header: 'Customer Type', key: 'type', width: 15 },
      { header: 'Region', key: 'region', width: 20 },
      { header: 'HQ', key: 'hq', width: 20 },
      { header: 'Point/Town', key: 'Point_Town', width: 20 },
      { header: 'Route/Beat', key: 'Route_Beat', width: 20 },
      { header: 'Jan', key: 'Jan', width: 10 },
      { header: 'Feb', key: 'Feb', width: 10 },
      { header: 'Mar', key: 'Mar', width: 10 },
      { header: 'Apr', key: 'Apr', width: 10 },
      { header: 'May', key: 'May', width: 10 },
      { header: 'Jun', key: 'Jun', width: 10 },
      { header: 'Jul', key: 'Jul', width: 10 },
      { header: 'Aug', key: 'Aug', width: 10 },
      { header: 'Sep', key: 'Sep', width: 10 },
      { header: 'Oct', key: 'Oct', width: 10 },
      { header: 'Nov', key: 'Nov', width: 10 },
      { header: 'Dec', key: 'Dec', width: 10 },
    ];

    data?.map((item) =>
      sheet.addRow({
        // customer information
        code: item?.customer?.code,
        name: item?.customer?.name,
        type: item?.customer?.customerType?.customerType,
        // territories
        region: item?.territories?.['rank-2']?.name,
        hq: item?.territories?.['rank-3']?.name,
        Point_Town: item?.territories?.['rank-4']?.name,
        Route_Beat: item?.territories?.['rank-5']?.name,
        // colllection
        Jan: item?.collections?.Jan,
        Feb: item?.collections?.Feb,
        Mar: item?.collections?.Mar,
        Apr: item?.collections?.Apr,
        May: item?.collections?.May,
        Jun: item?.collections?.Jun,
        Jul: item?.collections?.Jul,
        Aug: item?.collections?.Aug,
        Sep: item?.collections?.Sep,
        Oct: item?.collections?.Oct,
        Nov: item?.collections?.Nov,
        Dec: item?.collections?.Dec,
      }),
    );
  } else if (filename === 'do_section') {
    const c = [
      { header: 'Date Of Entry', key: 'dateOfEntry', width: 15 },
      { header: 'Date Of Deposit', key: 'dateOfDeposit', width: 15 },
      { header: 'Sender', key: 'sender', width: 30 },
      { header: 'Receiver', key: 'receiver', width: 30 },
      { header: 'Post Under', key: 'postUnder', width: 20 },
      { header: 'Region', key: 'region', width: 15 },
      { header: 'HQ', key: 'hq', width: 15 },
      { header: 'Point/Town', key: 'pt', width: 15 },
      { header: 'Total Collection', key: 'tc', width: 15 },
      { header: 'Approved Collection', key: 'ac', width: 15 },
      { header: 'DO Number', key: 'do', width: 15 },
      { header: 'CO Number', key: 'co', width: 15 },
      { header: 'Remarks', key: 'remarks', width: 15 },
      { header: 'Total Products', key: 'tp', width: 15 },
    ];

    // get max products index---
    const productLengths = data?.map((item) => item?.products?.length);
    const maxLength = Math.max(...productLengths);
    const result = productLengths.findIndex((n) => maxLength === n);
    // get max products index---

    data[result]?.products?.forEach((p, i) => {
      c.push({
        header: p?.code,
        key: p?.code,
        width: 23,
      });
    });

    sheet.columns = c;

    data?.map((item) => {
      const x = {
        dateOfEntry: item?.collection?.dateOfEntry
          ? dayjs(item?.createdAt).format('DD/MM/YYYY')
          : '',
        dateOfDeposit: item?.collection?.dateOfDeposit
          ? dayjs(item?.createdAt).format('DD/MM/YYYY')
          : '',
        sender: `${item?.sender?.name} (${item?.sender?.code})`,
        receiver: `${item?.receiver?.name} (${item?.receiver?.code})`,
        postUnder: item?.postUnder?.name,
        region: item?.territories?.['rank-2']?.territoryName,
        hq: item?.territories?.['rank-3']?.territoryName,
        pt: item?.territories?.['rank-4']?.territoryName,
        tc: item?.collection?.entryAmount,
        ac: item?.collection.totalAmount,
        do: item?.transaction?.doNumber,
        co: item?.transaction?.coNumber,
        remarks: item?.collection?.remarks,
        tp: item?.totalProducts,
      };
      item?.products?.forEach((p) => {
        x[p?.code] = p?.quantity;
      });
      return sheet.addRow(x);
    });
  } else if (filename === 'Cust_Stock') {
    sheet.columns = [
      { header: 'Customer Id', key: 'id', width: 15 },
      { header: 'Customer Name', key: 'name', width: 10 },
      { header: 'Customer Type', key: 'type', width: 15 },
      { header: 'Region', key: 'region', width: 20 },
      { header: 'HQ', key: 'hq', width: 20 },
      { header: 'Point/Town', key: 'Point_Town', width: 20 },
      { header: 'Jan', key: 'Jan', width: 10 },
      { header: 'Feb', key: 'Feb', width: 10 },
      { header: 'Mar', key: 'Mar', width: 10 },
      { header: 'Apr', key: 'Apr', width: 10 },
      { header: 'May', key: 'May', width: 10 },
      { header: 'Jun', key: 'Jun', width: 10 },
      { header: 'Jul', key: 'Jul', width: 10 },
      { header: 'Aug', key: 'Aug', width: 10 },
      { header: 'Sep', key: 'Sep', width: 10 },
      { header: 'Oct', key: 'Oct', width: 10 },
      { header: 'Nov', key: 'Nov', width: 10 },
      { header: 'Dec', key: 'Dec', width: 10 },
    ];

    data?.map((item) => {
      return sheet.addRow({
        // customer information
        id: item?.customer?.code,
        name: item?.customer?.name,
        type: item?.customer?.customerType?.customerType,
        // territories
        region: item?.territories?.['rank-2']?.name,
        hq: item?.territories?.['rank-3']?.name,
        Point_Town: item?.territories?.['rank-4']?.name,
        // Stocks
        Jan: item?.stocks?.Jan?.totalIntact,
        Feb: item?.stocks?.Feb?.totalIntact,
        Mar: item?.stocks?.Mar?.totalIntact,
        Apr: item?.stocks?.Apr?.totalIntact,
        May: item?.stocks?.May?.totalIntact,
        Jun: item?.stocks?.Jun?.totalIntact,
        Jul: item?.stocks?.Jul?.totalIntact,
        Aug: item?.stocks?.Aug?.totalIntact,
        Sep: item?.stocks?.Sep?.totalIntact,
        Oct: item?.stocks?.Oct?.totalIntact,
        Nov: item?.stocks?.Nov?.totalIntact,
        Dec: item?.stocks?.Dec?.totalIntact,
      });
    });
  } else if (filename === 'Damages') {
    const c = [
      { header: 'Customer Name', key: 'cname', width: 15 },
      { header: 'Customer Code', key: 'ccode', width: 15 },
      { header: 'Customer Location', key: 'clocation', width: 15 },
      { header: 'Claimed At', key: 'claimat', width: 15 },
      { header: 'Claimed Amount', key: 'claimedAmount', width: 15 },
      { header: 'Approved Amount', key: 'approvedAmount', width: 15 },
      { header: 'Ordered Approved', key: 'isApproved', width: 15 },
    ];

    const list = [];
    data.map((item) => {
      return item?.products.map((p) => {
        return list.push(p.product.code);
      });
    });
    const newList = list;
    const uniqueValues = Array.from(new Set(newList));

    uniqueValues?.forEach((p, i) => {
      c.push({
        header: p,
        key: p,
        width: 23,
      });
    });

    sheet.columns = c;

    data?.map((item) => {
      const x = {
        cname: item?.customer?.name,
        ccode: item?.customer?.code,
        clocation: item?.customer?.territory?.name,
        claimat: item?.claimedAt
          ? dayjs(item?.claimedAt).format('DD/MM/YYYY hh:mm')
          : '',
        claimedAmount: item?.claimedAmount,
        approvedAmount: item?.approvedAmount,
        isApproved: item?.isApproved ? 'Yes' : 'No',
      };

      item?.products?.forEach((p) => {
        x[p?.product?.code] = p?.damagedQuantity;
      });
      return sheet.addRow(x);
    });
  }
  // info export start here
  else if (filename === 'OutletInfo') {
    sheet.columns = [
      { header: `Recorder's Name`, key: 'name', width: 22 },
      { header: `Recorder's Mobile`, key: 'mobileNo', width: 18 },
      { header: `Outlet Name`, key: 'outletName', width: 22 },
      { header: `Outlet Category`, key: 'outletCategory', width: 35 },
      { header: `Outlet Contact`, key: 'outletContact', width: 15 },
      { header: `Outlet Owner Name`, key: 'outletOwnerName', width: 22 },
      {
        header: `Outlet Owner Contact`,
        key: 'outletOwnerContact',
        width: 22,
      },
      { header: `Region`, key: 'region', width: 22 },
      { header: `HQ`, key: 'hq', width: 22 },
      { header: `Point/Town`, key: 'pointTown', width: 22 },
      { header: `route/Beat`, key: 'routeBeat', width: 20 },
      { header: `Geo Location`, key: 'location', width: 22 },
      { header: `Status`, key: 'status', width: 15 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        mobileNo: item?.mobileNo,
        outletName: item?.outletName,
        outletCategory: item?.outletCategory,
        outletContact: item?.outletContact,
        outletOwnerName: item?.outletOwnerName,
        outletOwnerContact: item?.outletOwnerContact,
        region: `${item?.region?.name} (${item?.region?.code})`,
        hq: `${item?.hq?.name} (${item?.hq?.code})`,
        pointTown: `${item?.pointTown?.name} (${item?.pointTown?.code})`,
        routeBeat: item?.routeBeat,
        location: `${item?.latitude} (${item?.longitude})`,
        status: item?.status,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'DealerInfo') {
    sheet.columns = [
      { header: `Recorder's Name`, key: 'name', width: 22 },
      { header: `Recorder's Mobile`, key: 'mobileNo', width: 18 },
      { header: `Dealer Name`, key: 'dealerName', width: 22 },
      { header: `Dealer Contact1`, key: 'primaryNo', width: 22 },
      { header: `Dealer Contact2`, key: 'secondaryNo', width: 22 },
      { header: `Dealer Type`, key: 'dealerType', width: 22 },
      { header: `Region`, key: 'region', width: 22 },
      { header: `HQ`, key: 'hq', width: 22 },
      { header: `Point/Town`, key: 'pointTown', width: 22 },
      { header: `Dealer Company Name`, key: 'companyName', width: 22 },
      {
        header: `Approx Sales Volume In Taka (In Tp Value)`,
        key: 'approxSale',
        width: 30,
      },
      { header: `Product Category`, key: 'productCategory', width: 22 },
      {
        header: `Transaction Category`,
        key: 'transactionCategory',
        width: 22,
      },
      { header: `No Of Running So / Sr`, key: 'runningSO_SR', width: 22 },
      { header: `No Of Vans`, key: 'noOfVans', width: 22 },
      { header: `Geo Location`, key: 'location', width: 22 },
      { header: `Status`, key: 'status', width: 15 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        mobileNo: item?.mobileNo,
        dealerName: item?.dealerName,
        primaryNo: item?.primaryNo,
        secondaryNo: item?.secondaryNo,
        dealerType: item?.dealerType,
        region: `${item?.region?.name} (${item?.region?.code})`,
        hq: `${item?.hq?.name} (${item?.hq?.code})`,
        pointTown: `${item?.pointTown?.name} (${item?.pointTown?.code})`,
        companyName: item?.companyName,
        approxSale: item?.approxSale,
        productCategory: item?.productCategory,
        transactionCategory: item?.transactionCategory,
        runningSO_SR: item?.runningSO_SR,
        noOfVans: item?.noOfVans,
        location: `${item?.latitude} (${item?.longitude})`,
        status: item?.status,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else if (filename === 'ExitingDealerInfo') {
    sheet.columns = [
      { header: `Recorder's Name`, key: 'name', width: 22 },
      { header: `Recorder's Mobile`, key: 'mobileNo', width: 18 },
      { header: `Date of Visit`, key: 'visitDate', width: 22 },
      { header: `Region`, key: 'region', width: 22 },
      { header: `HQ`, key: 'hq', width: 22 },
      { header: `Point/Town`, key: 'pointTown', width: 22 },
      { header: `Customer Name (Code)`, key: 'customer', width: 22 },
      { header: `SO/SR Name (Code)`, key: 'srName', width: 22 },
      { header: `Dealer Manager Name`, key: 'dealerManagerName', width: 22 },
      {
        header: `Dealer Manager Number`,
        key: 'dealerManagerNumber',
        width: 22,
      },
      { header: `Van Puller Name`, key: 'vanPullerName', width: 22 },
      { header: `Van Puller Mobile`, key: 'vanPullerMobile', width: 22 },
      { header: `Geo Location`, key: 'location', width: 22 },
      { header: `Status`, key: 'status', width: 15 },
      { header: 'Created Time', key: 'ct', width: 12 },
      { header: 'Updated Time', key: 'ut', width: 12 },
    ];

    data?.map((item) =>
      sheet.addRow({
        name: item?.name,
        mobileNo: item?.mobileNo,
        visitDate: dayjs(item?.visitDate).format('DD/MM/YYYY hh:mm'),
        region: `${item?.region?.name} (${item?.region?.code})`,
        hq: `${item?.hq?.name} (${item?.hq?.code})`,
        pointTown: `${item?.pointTown?.name} (${item?.pointTown?.code})`,
        customer: `${item?.customer?.name} (${item?.customer?.code})`,
        srName: `${item?.srName?.name} (${item?.srName?.code})`,
        dealerManagerName: item?.dealerManagerName,
        dealerManagerNumber: item?.dealerManagerNumber,
        vanPullerName: item?.vanPullerName,
        vanPullerMobile: item?.vanPullerMobile,
        location: `${item?.latitude} (${item?.longitude})`,
        status: item?.status,
        ct: item?.createdAt ? dayjs(item?.createdAt).format('DD/MM/YYYY') : '',
        ut: dayjs(item?.updatedAt).format('DD/MM/YYYY'),
      }),
    );
  } else {
    try {
      sheet.columns = await columns.map((item) => {
        return {
          header:
            typeof item?.Header === 'string'
              ? item?.Header
              : 'Not Found Header',
          key: item?.accessor || 'Not_Found_Accessor',
          width: item?.accessor?.length < 15 ? 15 : item?.accessor?.length,
        };
      });

      const initials = await columns.map((item) => {
        return {
          header: item?.Header,
          key: item?.accessor,
          width: item?.Header?.length,
        };
      });

      const vv = await data?.map((item) => {
        let mergedObject = {};

        const array = initials?.map((value) => {
          const propertyMatcher = (len) => {
            if (len === 1) {
              return item?.[value?.key?.split('.')[0]];
            }
            if (len === 2) {
              return item?.[value?.key?.split('.')[0]]?.[
                value?.key?.split('.')[1]
              ];
            }
            if (len === 3) {
              return item?.[value?.key?.split('.')[0]]?.[
                value?.key?.split('.')[1]
              ]?.[value?.key?.split('.')[2]];
            }
            if (len === 4) {
              return item?.[value?.key?.split('.')[0]]?.[
                value?.key?.split('.')[1]
              ]?.[value?.key?.split('.')[2]]?.[value?.key?.split('.')[3]];
            }
            if (len === 5) {
              return item?.[value?.key?.split('.')[0]]?.[
                value?.key?.split('.')[1]
              ]?.[value?.key?.split('.')[2]]?.[value?.key?.split('.')[3]]?.[
                value?.key?.split('.')[5]
              ];
            }
          };
          const hasDate = (value) => {
            const dateRegex = /(\d{4})[-/](\d{1,2})[-/](\d{1,2})/;
            return dateRegex.test(value);
          };
          return {
            [value.key]: hasDate(propertyMatcher(value?.key?.split('.').length))
              ? dayjs(propertyMatcher(value?.key?.split('.').length)).format(
                  'DD-MM-YYYY',
                )
              : propertyMatcher(value?.key?.split('.').length),
            // value?.key?.split('.').length === 1
            //   ? item?.[value?.key?.split('.')[0]]
            //   : item?.[value?.key?.split('.')[0]]?.[value?.key?.split('.')[1]],
          };
        });

        for (let obj of array) {
          let key = Object.keys(obj)[0];
          let value = obj[key];
          mergedObject[key] = value;
        }
        sheet.addRow(mergedObject);
        return mergedObject;
      });

      console.log(vv);
    } catch (error) {
      console.warn(error);
    }
  }

  // info export end here

  // select headers and columns_________________________________________________(end)
  // ----------------------------------------------------------------------------------

  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = !customerYear
      ? `${filename}.xlsx`
      : `${filename}_${customerYear}.xlsx`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};
