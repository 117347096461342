import axios from '../api/axios';
import { useEffect } from 'react';
import useRequestId from './useRequestId';

const useAxios = () => {
  const { requestId } = useRequestId();

  useEffect(() => {
    const requestIntercept = axios.interceptors.request.use(
      (config) => {
        config.headers['X-Request-Id'] = requestId;
        return config;
      },
      (error) => Promise.reject(error),
    );

    return () => {
      axios.interceptors.request.eject(requestIntercept);
    };
  }, [requestId]);

  return axios;
};

export default useAxios;
