import React, { useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Autocomplete,
  Box,
  Link as MuiLink,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterNAIMS,
  SelectColumnFilterNAIMSAu,
  SelectColumnFilterNAIMSSS,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import roundToDigit from '../../utils/roundToDigit';

export default function CustomerAuditedIMS() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [employees, setEmployees] = React.useState([]);
  const [recordedBy, setRecordedBy] = React.useState({ id: '' });
  const [startDate, setStartDate] = useState(
    dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .subtract(1, 'day')
      .startOf('date')
      .toISOString(),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());

  // Get all employees
  React.useEffect(() => {
    setEmployees([]);
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true&sort=code')
      .then((res) => {
        if (res?.data?.data) {
          setEmployees(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, []);

  const { data, isLoading, isError, error } = useQuery(
    ['customer-audited-ims', startDate, endDate, recordedBy?.id],

    () =>
      axiosPrivate
        .get(
          `/api/v1/report/national-audited-ims?populate=yes&startDate=${startDate}&endDate=${endDate}&employee=${recordedBy?.id}`,
        )
        .then((res) => {
          const results = [];

          res?.data?.data?.forEach((d) => {
            let liftingDisplay = '-';
            if (d?.lifting?.individual?.length > 0) {
              liftingDisplay = `${d.lifting.total.quantityC} ctn ${d.lifting.total.quantityP} pcs`;
            }

            let transferInDisplay = '-';
            if (d?.transferIn?.individual?.length > 0) {
              transferInDisplay = `${d.transferIn.total.quantityC} ctn ${d.transferIn.total.quantityP} pcs`;
            }

            let inMarketSaleDisplay = '-';
            if (d?.inMarketSale?.products?.length > 0) {
              inMarketSaleDisplay = `${d.inMarketSale.total.quantityC} ctn ${d.inMarketSale.total.quantityP} pcs`;
            }

            let damageOutDisplay = '-';
            if (d?.damageOut?.individual?.length > 0) {
              damageOutDisplay = `${d.damageOut.total.quantityC} ctn ${d.damageOut.total.quantityP} pcs`;
            }

            let transferOutDisplay = '-';
            if (d?.transferOut?.individual?.length > 0) {
              transferOutDisplay = `${d.transferOut.total.quantityC} ctn ${d.transferOut.total.quantityP} pcs`;
            }

            let startStockDisplay = '-';
            if (d?.stockStart?.products?.length > 0) {
              startStockDisplay = `${d.stockStart.total.quantityC} ctn ${d.stockStart.total.quantityP} pcs`;
            }

            let endStockDisplay = '-';
            if (d?.stockEnd?.products?.length > 0) {
              endStockDisplay = `${d.stockEnd.total.quantityC} ctn ${d.stockEnd.total.quantityP} pcs`;
            }

            let targetQuantityC = 0;
            let targetDisplay = '-';
            if (d?.targets?.[0]?.products?.length > 0) {
              targetDisplay = `${d.targets[0].total.quantityC} ctn ${d.targets[0].total.quantityP} pcs`;
              targetQuantityC = d.targets[0].total.quantityC;
            }

            let achievement = null;
            if (
              (d?.inMarketSale?.total?.quantityC > 0) &
              (targetQuantityC > 0)
            ) {
              achievement =
                (d.inMarketSale.total.quantityC * 100) / targetQuantityC;
            }

            let saleByLifting = null;
            if (d?.lifting?.total?.quantity > 0) {
              saleByLifting =
                ((d?.inMarketSale?.total?.quantity || 0) /
                  d.lifting.total.quantity) *
                100;
            }

            let liftingBySale = null;
            if (d?.inMarketSale?.total?.quantity > 0) {
              liftingBySale =
                ((d?.lifting?.total?.quantity || 0) /
                  d.inMarketSale.total.quantity) *
                100;
            }

            const result = {
              ...d,
              liftingDisplay,
              transferInDisplay,
              inMarketSaleDisplay,
              damageOutDisplay,
              transferOutDisplay,
              startStockDisplay,
              endStockDisplay,
              targetDisplay,
              achievement,
              saleByLifting,
              liftingBySale,
            };

            results.push(result);
          });

          return results;
        }),
  );

  data?.map((item) => {
    if (item?.startStockDisplay !== '-') {
      item['startStockDisplayCtn'] = Number(
        item?.startStockDisplay.match(/\d+/)[0],
      );
    }
    if (item?.inMarketSaleDisplay !== '-') {
      item['inMarketSaleDisplayCtn'] = Number(
        item?.inMarketSaleDisplay.match(/\d+/)[0],
      );
    }
    return item;
  });

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => (
          <>
            {row.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </>
        ),
      },
      {
        Header: 'Region',
        accessor: 'territories.rank-2.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      /* {
        Header: 'Route/Beat',
        accessor: 'territories.rank-5.name',
      }, */
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
        Cell: ({ row, value }) => (
          <MuiLink
            component={Link}
            color="inherit"
            to={`/customers/transaction/${row?.original?.customer?.id}`}>
            {value}
          </MuiLink>
        ),
      },
      {
        Header: 'Type',
        accessor: 'customer.customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Date of Starting',
        accessor: 'customer.dateOfStarting',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY') : value,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Date of Closing',
        accessor: 'customer.dateOfClosing',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY') : value,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Starting Stock',
        accessor: 'startStockDisplayCtn',
        Cell: ({ row }) => `${row?.original?.startStockDisplay}`,
        Filter: SelectColumnFilterNAIMSSS,
        filter: 'between',
      },
      {
        Header: 'Lifting In',
        accessor: 'liftingDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Transfer In',
        accessor: 'transferInDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Transfer Out',
        accessor: 'transferOutDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Damage Out',
        accessor: 'damageOutDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Audited IMS',
        accessor: 'inMarketSaleDisplayCtn',
        Cell: ({ row }) => `${row?.original?.inMarketSaleDisplay}`,
        Filter: SelectColumnFilterNAIMSAu,
        filter: 'between',
      },
      {
        Header: 'Physical Stock',
        accessor: 'endStockDisplay',
        Filter: SelectColumnFilterNAIMS,
        filter: 'between',
      },
      {
        Header: 'IMS ÷ Lifting %',
        accessor: 'saleByLifting',
        Cell: ({ value }) =>
          value === null ? (
            <Typography color="error.main" variant="inherit">
              Invalid
            </Typography>
          ) : (
            `${roundToDigit(value)}%`
          ),
      },
      {
        Header: 'Lifting ÷ IMS %',
        accessor: 'liftingBySale',
        Cell: ({ value }) =>
          value === null ? (
            <Typography color="error.main" variant="inherit">
              Invalid
            </Typography>
          ) : (
            `${roundToDigit(value)}%`
          ),
      },
      {
        Header: 'Target',
        accessor: 'targetDisplay',
      },
      {
        Header: 'Achievement',
        accessor: 'achievement',
        Cell: ({ value }) =>
          value === null ? (
            <Typography color="error.main" variant="inherit">
              NULL
            </Typography>
          ) : (
            `${roundToDigit(value)}%`
          ),
      },
      {
        Header: 'Starting Stock Time',
        accessor: 'phStockStartTime',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : value,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Physical Stock Time',
        accessor: 'phStockEndTime',
        Cell: ({ value }) =>
          value ? dayjs(value).format('DD/MM/YYYY hh:mm A') : value,
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Work With',
        accessor: 'visits.length',
      },
      {
        Header: 'Outlets',
        accessor: 'outlets.length',
      },
      {
        Header: 'DSM',
        accessor: 'employees[DSM].nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <MuiLink
                href={`tel:${row?.original?.employees?.['DSM']?.contactNumber}`}
                color="inherit"
                variant="inherit">
                {row?.original?.employees?.['DSM']?.contactNumber}
              </MuiLink>
            </Typography>
          ) : null,
      },
      {
        Header: 'RSM',
        accessor: 'employees[RSM].nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <MuiLink
                href={`tel:${row?.original?.employees?.['RSM']?.contactNumber}`}
                color="inherit"
                variant="inherit">
                {row?.original?.employees?.['RSM']?.contactNumber}
              </MuiLink>
            </Typography>
          ) : null,
      },
      {
        Header: 'ASM',
        accessor: 'employees[ASM].nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <MuiLink
                href={`tel:${row?.original?.employees?.['ASM']?.contactNumber}`}
                color="inherit"
                variant="inherit">
                {row?.original?.employees?.['ASM']?.contactNumber}
              </MuiLink>
            </Typography>
          ) : null,
      },
      {
        Header: 'SO/SR',
        accessor: 'employees[SO/SR].nameCode',
        Cell: ({ row, value }) =>
          value ? (
            <Typography variant="inherit" sx={{ whiteSpace: 'nowrap' }}>
              {value}
              <br />
              <MuiLink
                href={`tel:${row?.original?.employees?.['SO/SR']?.contactNumber}`}
                color="inherit"
                variant="inherit">
                {row?.original?.employees?.['SO/SR']?.contactNumber}
              </MuiLink>
            </Typography>
          ) : null,
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="National Audited IMS Report">
        <Autocomplete
          disabled={employees.length < 1}
          options={employees}
          value={recordedBy}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => {
            setRecordedBy(value);
          }}
          getOptionLabel={(option) =>
            option?.name
              ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
              : ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Recorded by"
              size="small"
              sx={{ width: 240 }}
            />
          )}
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={1}>
            <DesktopDatePicker
              label="From"
              inputFormat="DD/MM/YYYY"
              views={['month', 'year', 'day']}
              value={startDate}
              onChange={(newValue) =>
                setStartDate(dayjs(newValue).startOf('date').toISOString())
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: { md: 160 } }}
                />
              )}
            />

            <DesktopDatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              views={['month', 'year', 'day']}
              value={endDate}
              onChange={(newValue) =>
                setEndDate(dayjs(newValue).endOf('date').toISOString())
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{ width: { md: 160 } }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Title>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={COLUMNS}
          data={data}
          hideAddIcon
          noWrap
          expandRow={ExpandRowInfo}
          hasDrawer
        />
      )}
    </>
  );
}

function ExpandRowInfo({ data }) {
  const [accList, setAccList] = useState([
    {
      isExpanded: false,
      title: 'Starting Physical Stock',
      component: <StocksTable data={data?.stockStart} />,
    },
    {
      isExpanded: false,
      title: 'Lifting',
      component: <SpecialTable data={data?.lifting} transaction />,
    },
    {
      isExpanded: false,
      title: 'Transfer In',
      component: <SpecialTable data={data?.transferIn} transaction />,
    },
    {
      isExpanded: false,
      title: 'Transfer Out',
      component: <SpecialTable data={data?.transferOut} transaction />,
    },
    {
      isExpanded: false,
      title: 'Damage Out',
      component: <SpecialTable data={data?.damageOut} damageOut />,
    },
    {
      isExpanded: true,
      title: 'Audited IMS',
      component: <StocksTable data={data?.inMarketSale} />,
    },
    {
      isExpanded: false,
      title: 'Latest Physical Stock',
      component: <StocksTable data={data?.stockEnd} />,
    },
    {
      isExpanded: false,
      title: 'Physical Stock Entries',
      component: <SpecialTable data={data?.phStock} phStock />,
    },
  ]);

  const toggleAccordion = (index) => {
    const toggled = [...accList];
    toggled[index].isExpanded = !accList[index].isExpanded;
    setAccList(toggled);
  };

  return (
    <Stack gap={2} pb={4}>
      <Typography variant="h6" align="center">
        {data?.customer?.nameCode} - {data?.customer?.customerType}
      </Typography>
      {accList?.map((item, i) => (
        <Box
          key={i}
          sx={{
            borderColor: (theme) => theme.palette.primary.main,
            borderWidth: 1,
            borderStyle: 'solid',
          }}>
          {/* head */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width={1}
            sx={{
              background: (theme) => theme.palette.primary.main,
              padding: 1,
              cursor: 'pointer',
            }}
            onClick={() => toggleAccordion(i)}>
            <Typography
              variant="h1"
              sx={{
                textAlign: 'start',
                display: 'block',
                color: '#fff',
                fontSize: 16,
                fontWeight: 500,
                textTransform: 'uppercase',
              }}>
              {item.title}
            </Typography>
            <KeyboardArrowDownIcon
              sx={{
                color: '#fff',
                transform: item?.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 0.4s ease',
              }}
            />
          </Stack>
          {item?.isExpanded ? (
            <Box sx={{ padding: 1 }}>{item.component}</Box>
          ) : null}
        </Box>
      ))}
    </Stack>
  );
}

function StocksTable({ data }) {
  return (
    <Box sx={styles.main}>
      {data?.products?.length > 0 ? (
        <TableContainer>
          <MuiTable>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Typography>
                    <b>Total: </b>
                    {data?.total?.quantityC} ctn {data?.total?.quantityP} pcs
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.products?.map((p) => (
                <TableRow key={p?.id}>
                  <TableCell>{`${p?.name} (${p?.code})`}</TableCell>
                  <TableCell>
                    {p?.quantityP
                      ? `${p?.quantityC} ctn ${p?.quantityP} pcs`
                      : `${p?.quantityC} ctn`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100px' }}>
          <Typography>No Data Found</Typography>
        </Stack>
      )}
    </Box>
  );
}

function SpecialTable({ data, transaction, damageOut, phStock }) {
  // const q = data?.individual?.reduce((a, c) => a + c?.total?.quantity, 0);
  // const qc = data?.individual?.reduce((a, c) => a + c?.total?.quantityC, 0);
  // const qp = data?.individual?.reduce((a, c) => a + c?.total?.quantityP, 0);

  const result = data?.individual?.reduce(
    (a, c) => {
      a.quantity += c?.total?.quantity;
      a.quantityC += c?.total?.quantityC;
      a.quantityP += c?.total?.quantityP;

      return a;
    },
    {
      quantity: 0,
      quantityC: 0,
      quantityP: 0,
    },
  );

  const obj = { ...data };
  obj.total = result; //{quantity: sum, quantityC: sum, quantityP: sum}

  return (
    <Stack gap={2} sx={{ ...styles.main }}>
      {obj?.individual?.length > 0 ? (
        <>
          {obj?.total?.quantity ? (
            <Typography textAlign="center">
              <b>Total: </b>
              {obj?.total?.quantityC} ctn {obj?.total?.quantityP} pcs
            </Typography>
          ) : null}
          <Stack gap={1.5}>
            {obj?.individual?.map((item) => (
              <Stack
                key={item.id}
                direction={{ md: 'row' }}
                gap={1}
                alignItems="flex-start"
                sx={{
                  background: '#e0e0e0',
                  padding: 1,
                  overflow: 'auto',
                }}>
                <Box sx={{ width: 1 }}>
                  <Typography
                    variant="h1"
                    sx={{
                      textAlign: 'center',
                      display: 'block',
                      color: '#fff',
                      background: (theme) => theme.palette.primary.main,
                      padding: 1,
                      fontSize: 14,
                      fontWeight: 500,
                    }}>
                    Info
                  </Typography>
                  {transaction ? (
                    <TableContainer>
                      <MuiTable>
                        <TableRow>
                          <TableCell component="th">Transaction Type</TableCell>
                          <TableCell>{item?.transactionType}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Sender</TableCell>
                          <TableCell>{item?.sender}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Receiver</TableCell>
                          <TableCell>{item?.receiver}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Total Quantity</TableCell>
                          <TableCell>
                            {item?.total?.quantityP
                              ? `${item?.total?.quantityC} ctn ${item?.total?.quantityP} pcs`
                              : `${item?.total?.quantityC} ctn`}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Paid Amount</TableCell>
                          <TableCell>
                            {roundToDigit(item?.paidAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Delivery Date</TableCell>
                          <TableCell>
                            {dayjs(item?.deliveredAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">DO Number</TableCell>
                          <TableCell>{item?.doNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Recorded By</TableCell>
                          <TableCell>{item?.recordedBy}</TableCell>
                        </TableRow>
                      </MuiTable>
                    </TableContainer>
                  ) : null}
                  {damageOut ? (
                    <TableContainer>
                      <MuiTable>
                        <TableRow>
                          <TableCell component="th">Customer Name</TableCell>
                          <TableCell>{item?.customer}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Claimed Amount</TableCell>
                          <TableCell>
                            {roundToDigit(item?.claimedAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Claim Date</TableCell>
                          <TableCell>
                            {dayjs(item?.claimedAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Approved Amount</TableCell>
                          <TableCell>
                            {roundToDigit(item?.approvedAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Approve Date</TableCell>
                          <TableCell>
                            {dayjs(item?.approvedAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Recorded By</TableCell>
                          <TableCell>{item?.recordedBy}</TableCell>
                        </TableRow>
                      </MuiTable>
                    </TableContainer>
                  ) : null}
                  {phStock ? (
                    <TableContainer>
                      <MuiTable>
                        <TableRow>
                          <TableCell component="th">Entry Date</TableCell>
                          <TableCell>
                            {dayjs(item?.recordedAt).format('DD/MM/YYYY')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th">Entered By</TableCell>
                          <TableCell>{item?.recordedBy}</TableCell>
                        </TableRow>
                      </MuiTable>
                    </TableContainer>
                  ) : null}
                </Box>
                {item?.products?.length > 0 ? (
                  <Box
                    sx={{
                      ...styles.main,
                      width: '100%',
                      padding: 0,
                    }}
                    p={2}>
                    <Typography
                      variant="h1"
                      sx={{
                        textAlign: 'center',
                        display: 'block',
                        color: '#fff',
                        background: (theme) => theme.palette.primary.main,
                        padding: 1,
                        fontSize: 14,
                        fontWeight: 500,
                      }}>
                      Products (
                      {`${item?.total?.quantityC} ctn ${item?.total?.quantityP} pcs`}
                      )
                    </Typography>
                    <TableContainer>
                      <MuiTable>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            {transaction ? <TableCell>Amount</TableCell> : null}
                            {damageOut ? (
                              <TableCell>Damage Description</TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.products?.map((p, i) => (
                            <TableRow
                              key={`${item?.id}-${p?.id}`}
                              sx={{ td: { whiteSpace: 'initial' } }}>
                              <TableCell>
                                {p?.name} ({p?.code})
                              </TableCell>
                              <TableCell>
                                {p?.quantityP
                                  ? `${p?.quantityC} ctn ${p?.quantityP} pcs`
                                  : `${p?.quantityC} ctn`}
                              </TableCell>
                              {transaction ? (
                                <TableCell>
                                  {roundToDigit(p?.totalPrice)}
                                </TableCell>
                              ) : null}
                              {damageOut ? (
                                <TableCell>{p?.damageDescription}</TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </MuiTable>
                    </TableContainer>
                  </Box>
                ) : null}
              </Stack>
            ))}
          </Stack>
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100px' }}>
          <Typography>No Data Found</Typography>
        </Stack>
      )}
    </Stack>
  );
}

const styles = {
  main: {
    h6: {
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 700,
    },
    th: {
      fontWeight: 'bold',
      padding: 1,
      whiteSpace: 'nowrap',
      textTransfrom: 'capitalize',
      background: '#fff',
    },
    td: {
      padding: 1,
      whiteSpace: 'nowrap',
      background: '#fff',
    },
  },
};
