import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import { Box, Button, Stack, Typography } from '@mui/material';
import logo from '../assets/images/logo.png';
import useAuth from '../hooks/useAuth';

export default function Welcome() {
  const { name, role, user, SalesManagers, SalesOfficers } = useAuth();
  const [logInfo, setLogInfo] = useState(
    JSON.parse(localStorage.getItem('LoginInfo')) || '',
  );

  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
    const newLists = { ...logInfo };
    delete newLists[user?.phone];
    localStorage.setItem('LoginInfo', JSON.stringify(newLists));
    setLogInfo(newLists);
  };

  if ([...SalesManagers, ...SalesOfficers].includes(role)) {
    navigate('/customers');
  }

  if (role === 'Super Admin') {
    navigate('/');
  }

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ width: '100%', height: '100vh' }}>
        <Box sx={{ textAlign: 'center' }}>
          <img style={{ width: '220px' }} src={logo} alt="" />
          <Typography variant={'h5'}>
            Welcome, <b>{name}</b>
          </Typography>
          <p>Please contact to the Office for getting the role</p>
          <br />
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Stack>
    </>
  );
}
