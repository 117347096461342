import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import EditModal from './EditModal';
import useHeader from '../../../hooks/useHeader';

function AuthUsers() {
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  useEffect(() => {
    setHeader({ title: 'Authorization' });
  }, []);

  // get users
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'users',
    () => {
      return axiosPrivate.get(
        '/api/v1/user?populate=yes&limit=none&userType=employee&sort=-createdAt',
      );
    },
  );

  const handleEditShow = (row) => {
    setEditData(row);
    setShowModal(true);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/user/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('User was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Login Email',
        accessor: 'email',
        Cell: ({ value, row }) => {
          if (row?.original?.isEmailVerified) return <>{value} &#10004;</>;
          return value;
        },
      },
      {
        Header: 'Login Phone',
        accessor: 'phone',
        Cell: ({ value, row }) => {
          if (row?.original?.isPhoneVerified) return <>{value} &#10004;</>;
          return value;
        },
      },
      {
        Header: 'Emp. Profile',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'Emp. Role',
        accessor: 'employee.role.employeeRole',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Emp. Location',
        accessor: 'employee.territory.nameCode',
      },
      {
        Header: 'Emp. Email',
        accessor: 'employee.email',
      },
      {
        Header: 'Emp. Phone',
        accessor: 'employee.contactNumber',
      },
      {
        Header: 'Sign Up Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => (
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton
              onClick={() => handleEditShow(row?.original)}
              className="Table_icon_btn">
              <BorderColorIcon />
            </IconButton>

            <IconButton
              onClick={() => handleDeleteShow(row?.original?.id)}
              className="Table_icon_btn delete">
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
        disableFilters: true,
      },
    ],
    [],
  );

  const mData = useMemo(() => data?.data?.data, [data?.data?.data]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={mData || []}
        filename="UserEmployeeProfile_table"
        hideAddIcon
        noWrap
        isLoading={isLoading || isFetching}
      />

      <EditModal
        showModal={showModal}
        setShowModal={setShowModal}
        editData={editData}
        setEditData={setEditData}
        refetch={refetch}
      />

      <ConfirmModal
        title="Do you want to delete this user profile?"
        subtitle="If you delete someone's user profile, he/she will not be able to log in here anymore."
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default AuthUsers;
