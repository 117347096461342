import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import {
  ExpTable,
  ExpandTableCaption,
  ExpandTableContainer,
} from '../../../assets/style/table/customTable';

function ExpandTable({ caption, columns, data }) {
  return (
    <Box flexGrow={1} component={Paper} sx={{ width: '100%' }}>
      {caption ? <ExpandTableCaption>{caption}</ExpandTableCaption> : null}
      <ExpandTableContainer>
        <ExpTable>
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                <TableCell
                  key={index}
                  sx={{ color: (theme) => theme.palette.primary.main }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow key={i}>
                {columns.map((column, i) => (
                  <TableCell key={i}>
                    {column.render
                      ? column.render(row[column.key], row)
                      : row[column.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </ExpTable>
      </ExpandTableContainer>
    </Box>
  );
}

export default ExpandTable;
