import { Box, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import Layout from '../../component/Layout';
import EditProfile from './EditProfile';
import ViewProfile from './ViewProfile';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useQuery } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';

const ProfileStyle = [
  {
    minHeight: '78vh',
    marginTop: '1vh',
    background: '#fff',
    position: 'relative',
  },
];

function Profile() {
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const disabled = false;
  const [page, setPage] = useState(1);

  useEffect(() => {
    setHeader({ title: 'Profile' });
  }, []);

  const { data, isFetching, isError, error, refetch } = useQuery(
    'viewProfile',
    () => {
      return axiosPrivate.get('/api/v1/user/me');
    },
  );

  const user = data?.data?.data;

  return (
    <>
      <Layout>
        <Box sx={ProfileStyle} p={3} boxShadow={2} borderRadius={3}>
          <Tooltip title={page === 1 ? 'Edit Profile' : 'Back'}>
            {page === 1 ? (
              <IconButton
                disabled={disabled}
                sx={{ position: 'absolute', right: '30px', top: '30px' }}
                onClick={() => setPage(2)}>
                <EditIcon />
              </IconButton>
            ) : (
              <IconButton
                sx={{ position: 'absolute', right: '30px', top: '30px' }}
                onClick={() => setPage(1)}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </Tooltip>
          {page === 1 ? (
            <ViewProfile
              isloading={isFetching}
              refetch={refetch}
              iserror={isError}
              error={error}
              user={user}
            />
          ) : (
            <EditProfile
              setPage={setPage}
              isloading={isFetching}
              refetch={refetch}
              iserror={isError}
              error={error}
              user={user}
            />
          )}
        </Box>
      </Layout>
    </>
  );
}

export default Profile;
