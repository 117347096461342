import React from 'react';
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { offerTypeLabels, unitLabels } from '../../staticData/ProductUnits';
import roundToDigit from '../../utils/roundToDigit';

export default function ModifyMarketPrice() {
  const navigate = useNavigate();
  const params = useParams();
  const [refetch] = useOutletContext();
  const { userId, role, Admins } = useAuth();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const editId = params.priceId;

  // form data states
  const [productName, setProductName] = React.useState('');
  const [product, setProduct] = React.useState({});
  const [dateOfEntry, setDateOfEntry] = React.useState(dayjs());
  const [dateOfPrice, setDateOfPrice] = React.useState(dayjs());

  const [code, setCode] = React.useState('');
  const [name, setName] = React.useState('');
  const [productCategory, setProductCategory] = React.useState({});
  const [productCompany, setProductCompany] = React.useState({});
  const [quantityPerCarton, setQuantityPerCarton] = React.useState('');
  const [depotPrice, setDepotPrice] = React.useState('');
  const [dealerPrice, setDealerPrice] = React.useState('');
  const [dsrPrice, setDsrPrice] = React.useState('');
  const [tradePrice, setTradePrice] = React.useState('');
  const [maxRetailPrice, setMaxRetailPrice] = React.useState('');
  const [unitOfMeasurement, setUnitOfMeasurement] = React.useState('pcs');
  const [unitPerPacket, setUnitPerPacket] = React.useState('');
  const [weightPerPacket, setWeightPerPacket] = React.useState('');
  const [offerType, setOfferType] = React.useState('none');
  const [freeItemsPerCarton, setFreeItemsPerCarton] = React.useState(0);
  const [minimumOrderQuantityForOffer, setMinimumOrderQuantityForOffer] =
    React.useState(0);
  const [giftItemDescription, setGiftItemDescription] = React.useState('');
  const [description, setDescription] = React.useState('');

  const [categoriesList, setCategoriesList] = React.useState([]);
  const [companiesList, setCompaniesList] = React.useState([]);
  const [productCodesList, setProductCodesList] = React.useState([]);
  const [nameCodeList, setNameCodeList] = React.useState([]);
  const [productsData, setProductsData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError1, setIsDateError1] = React.useState(false);
  const [isDateError2, setIsDateError2] = React.useState(false);
  const [isNewProduct, setIsNewProduct] = React.useState(true);

  const [trigger, setTrigger] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // get edit product data
  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/market-price/${editId}?populate=yes`)
        .then((res) => {
          const info = res?.data?.data;
          const qpc = parseFloat(info?.product?.quantityPerCarton);

          setProductName(info?.product?.nameCode);
          setDateOfEntry(info?.dateOfEntry);
          setDateOfPrice(info?.dateOfPrice);
          setCode(info?.product?.code);
          setName(info?.product?.name);
          setProductCategory(info?.product?.productCategory);
          setProductCompany(info?.product?.productCompany);
          setQuantityPerCarton(info?.product?.quantityPerCarton);
          setDepotPrice(roundToDigit(parseFloat(info?.depotPrice) * qpc));
          setDealerPrice(roundToDigit(parseFloat(info?.dealerPrice) * qpc));
          setDsrPrice(roundToDigit(parseFloat(info?.dsrPrice) * qpc));
          setTradePrice(roundToDigit(parseFloat(info?.tradePrice) * qpc));
          setMaxRetailPrice(info?.maxRetailPrice);
          setUnitOfMeasurement(info?.product?.unitOfMeasurement);
          setUnitPerPacket(info?.product?.unitPerPacket);
          setWeightPerPacket(info?.product?.weightPerPacket);
          setOfferType(info?.offerType);
          setFreeItemsPerCarton(info?.freeItemsPerCarton);
          setMinimumOrderQuantityForOffer(info?.minimumOrderQuantityForOffer);
          setGiftItemDescription(info?.giftItemDescription);
          setDescription(info?.product?.description);
        })
        .catch((error) => expired(error))
        .finally(() => setIsLoading(false));
    } else {
      setProductName('');
      setDateOfEntry(dayjs());
      setDateOfPrice(dayjs());
      setCode('');
      setName('');
      setProductCategory({});
      setProductCompany({});
      setQuantityPerCarton('');
      setDepotPrice('');
      setDealerPrice('');
      setDsrPrice('');
      setTradePrice('');
      setMaxRetailPrice('');
      setUnitOfMeasurement('pcs');
      setUnitPerPacket('');
      setWeightPerPacket('');
      setOfferType('none');
      setFreeItemsPerCarton(0);
      setMinimumOrderQuantityForOffer(0);
      setGiftItemDescription('');
      setDescription('');
    }
  }, [editId]);

  // get category list
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/product/category?limit=none&sort=productCategory&isActive=true',
      )
      .then((res) => setCategoriesList(res?.data?.data))
      .catch((error) => expired(error));
  }, []);

  // get company list
  React.useEffect(() => {
    axiosPrivate
      .get(
        '/api/v1/product/company?limit=none&sort=productCompany&isActive=true',
      )
      .then((res) => setCompaniesList(res?.data?.data))
      .catch((error) => expired(error));
  }, []);

  // get product list
  React.useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get('/api/v1/product?populate=yes&limit=none&sort=name')
      .then((res) => {
        const pData = {};
        const cList = [];
        res?.data?.data?.forEach((p) => {
          const key = `${p?.nameCode}`;
          if (key) pData[key] = p;
          if (p?.code) cList.push(p.code);
        });
        setProductsData(pData);
        setProductCodesList(cList);
      })
      .catch((error) => expired(error))
      .finally(() => setIsLoading(false));
  }, [trigger]);

  // Filtered products by compant
  React.useEffect(() => {
    if (productCompany?.id) {
      const list = [];
      Object.entries(productsData).forEach(([key, value]) => {
        if (value?.productCompany?.id === productCompany?.id) {
          list.push(key);
        }
      });
      setNameCodeList(list);
    } else {
      setNameCodeList(Object.keys(productsData));
    }
  }, [productCompany?.id, productsData]);

  // Select froduct from name
  React.useEffect(() => {
    const p = productsData[productName];
    if (p) {
      setIsNewProduct(false);
      setProduct(p);

      setCode(p?.code);
      setName(p?.name);
      setQuantityPerCarton(p?.quantityPerCarton);
      // setDepotPrice(p?.depotPrice); // Not dependent
      // setDealerPrice(p?.dealerPrice); // Not dependent
      // setDsrPrice(p?.dsrPrice); // Not dependent
      // setTradePrice(p?.tradePrice); // Not dependent
      // setMaxRetailPrice(p?.maxRetailPrice); // Not dependent
      setUnitOfMeasurement(p?.unitOfMeasurement);
      setUnitPerPacket(p?.unitPerPacket);
      setWeightPerPacket(p?.weightPerPacket);
      // setOfferType(p?.offerType); // Not dependent
      // setFreeItemsPerCarton(p?.freeItemsPerCarton); // Not dependent
      // setMinimumOrderQuantityForOffer(p?.minimumOrderQuantityForOffer); // Not dependent
      // setGiftItemDescription(p?.giftItemDescription); // Not dependent
      setDescription(p?.description);
    } else {
      setIsNewProduct(true);
      setProduct({});

      setCode('');
      setName(productName);
      setQuantityPerCarton('');
      // setDepotPrice(''); // Not dependent
      // setDealerPrice(''); // Not dependent
      // setDsrPrice(''); // Not dependent
      // setTradePrice(''); // Not dependent
      // setMaxRetailPrice(''); // Not dependent
      setUnitOfMeasurement('pcs');
      setUnitPerPacket('');
      setWeightPerPacket('');
      // setOfferType('none'); // Not dependent
      // setFreeItemsPerCarton(0); // Not dependent
      // setMinimumOrderQuantityForOffer(0); // Not dependent
      // setGiftItemDescription(''); // Not dependent
      setDescription('');
    }
  }, [productName, productsData]);

  // Set MOQ for free offer
  React.useEffect(() => {
    const qpc = parseFloat(quantityPerCarton);
    const fpc = parseFloat(freeItemsPerCarton);

    switch (offerType) {
      case 'free':
        if (!Number.isNaN(qpc) && !Number.isNaN(fpc)) {
          setMinimumOrderQuantityForOffer(Math.ceil(qpc / fpc));
        } else {
          setMinimumOrderQuantityForOffer(0);
        }
        setGiftItemDescription('');
        break;

      case 'gift':
        setFreeItemsPerCarton(0);
        break;

      default:
        setMinimumOrderQuantityForOffer(0);
        setFreeItemsPerCarton(0);
        setGiftItemDescription('');
        break;
    }
  }, [offerType, freeItemsPerCarton, quantityPerCarton]);

  const generateCode = (c) => {
    if (!isNewProduct) return c;
    let cg = `${productCategory?.codePrefix}-${parseInt(
      Math.random() * 1000000000,
    )
      .toString(36)
      .toUpperCase()}`;
    if (!productCodesList.includes(cg)) return cg;
    generateCode(cg);
  };

  const handleSubmit = (event) => {
    const qpc = parseFloat(quantityPerCarton);
    const dptp = parseFloat(depotPrice);
    const dsrp = parseFloat(dsrPrice);
    const dp = parseFloat(dealerPrice);
    const tp = parseFloat(tradePrice);

    const generatedCode = generateCode(code);

    if (
      Number.isNaN(qpc) ||
      Number.isNaN(dptp) ||
      Number.isNaN(dsrp) ||
      Number.isNaN(dp) ||
      Number.isNaN(tp) ||
      !generatedCode
    ) {
      toast.warn(
        'Quantity Per Carton, Depot Price, DSR Price, Dealer Price, Trade Price required',
      );
    } else {
      setIsLoading(true);

      const data = {
        product: product?.id,
        recordedBy: userId,
        dateOfEntry,
        dateOfPrice,
        code: generatedCode,
        name: name || productName,
        productCategory: productCategory?.id,
        productCompany: productCompany?.id,
        quantityPerCarton: qpc,
        depotPrice: roundToDigit(dptp / qpc, 5),
        dealerPrice: roundToDigit(dp / qpc, 5),
        dsrPrice: roundToDigit(dsrp / qpc, 5),
        tradePrice: roundToDigit(tp / qpc, 5),
        maxRetailPrice: maxRetailPrice,
        unitOfMeasurement: unitOfMeasurement,
        unitPerPacket: unitPerPacket,
        weightPerPacket: weightPerPacket,
        offerType: offerType,
        freeItemsPerCarton: freeItemsPerCarton,
        minimumOrderQuantityForOffer: minimumOrderQuantityForOffer,
        giftItemDescription:
          offerType === 'gift' ? giftItemDescription : 'none',
        description: description,
      };

      axiosPrivate({
        method: editId ? 'patch' : 'post',
        url: editId ? `/api/v1/market-price/${editId}` : '/api/v1/market-price',
        data: data,
      })
        .then((res) => {
          toast.success(res?.data?.message);
          if (editId) {
            refetch();
            navigate('/market-price');
          } else {
            setTrigger(Date.now());
            setAnchorEl(event.target);
          }
        })
        .catch((error) => expired(error))
        .finally(() => setIsLoading(false));
    }
  };

  const handleAddMore = () => {
    setProductName('');
    // setDateOfEntry(dayjs());
    // setDateOfPrice(dayjs());
    setCode('');
    setName('');
    setProductCategory({});
    // setProductCompany({});
    setQuantityPerCarton('');
    setDepotPrice('');
    setDealerPrice('');
    setDsrPrice('');
    setTradePrice('');
    setMaxRetailPrice('');
    setUnitOfMeasurement('pcs');
    setUnitPerPacket('');
    setWeightPerPacket('');
    setOfferType('none');
    setFreeItemsPerCarton(0);
    setMinimumOrderQuantityForOffer(0);
    setGiftItemDescription('');
    setDescription('');

    setAnchorEl(null);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleDone = () => {
    setAnchorEl(null);
    refetch();
    navigate('/market-price');
  };

  return (
    <Box component={Paper}>
      <Stack direction={{ xs: 'column', md: 'row' }} sx={{ gap: 4, p: 3 }}>
        <Box width={{ xs: 1, md: 0.5 }}>
          <Stack direction="row" flexWrap="wrap" sx={{ gap: 2 }}>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Product definition
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Date of entry</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfEntry}
                  onError={(reason) => setIsDateError1(Boolean(reason))}
                  onChange={(newValue) => setDateOfEntry(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Date of price update</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfPrice}
                  onError={(reason) => setIsDateError2(Boolean(reason))}
                  onChange={(newValue) => setDateOfPrice(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Company</Typography>
              <Autocomplete
                disabled={editId}
                fullWidth
                size="small"
                disableClearable
                options={companiesList}
                getOptionLabel={(option) => option?.productCompany || ''}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                value={productCompany}
                onChange={(event, value) => setProductCompany(value)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Category</Typography>
              <Autocomplete
                // disabled={editId}
                fullWidth
                size="small"
                disableClearable
                options={categoriesList}
                getOptionLabel={(option) => option?.productCategory || ''}
                isOptionEqualToValue={(option, value) =>
                  value?.id === option?.id
                }
                value={productCategory}
                onChange={(event, value) => setProductCategory(value)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Name</Typography>
              <Autocomplete
                disabled={editId}
                fullWidth
                size="small"
                disableClearable
                autoComplete
                // autoHighlight
                autoSelect
                freeSolo
                options={nameCodeList}
                value={productName}
                onChange={(event, value) => setProductName(value)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Code</Typography>
              <TextField
                disabled={!isNewProduct || editId}
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>
            {editId ? (
              <Box width={{ xs: 1 }}>
                <Typography variant="caption" color="warning.main">
                  To edit product's Company, Category, Name and Code please go
                  to the "Products" page
                </Typography>
              </Box>
            ) : null}
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle2">Description</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="text"
                multiline
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Box>
          </Stack>
        </Box>
        <Box width={{ xs: 1, md: 0.5 }}>
          <Stack direction="row" flexWrap="wrap" sx={{ gap: 2 }}>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Package info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Quantity per carton</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={quantityPerCarton}
                onChange={(e) => setQuantityPerCarton(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Unit of measurement</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={unitOfMeasurement}
                  onChange={(e) => setUnitOfMeasurement(e.target.value)}>
                  {Object.entries(unitLabels).map(([value, label]) => (
                    <MenuItem key={label} value={value}>
                      {label} ({value})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                {unitLabels[unitOfMeasurement] || 'Units'} per packet
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={unitPerPacket}
                onChange={(e) => setUnitPerPacket(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Net weight per packet</Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={weightPerPacket}
                onChange={(e) => setWeightPerPacket(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Price info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Depot price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={depotPrice}
                onChange={(e) => setDepotPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                DSR price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={dsrPrice}
                onChange={(e) => setDsrPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Dealer price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={dealerPrice}
                onChange={(e) => setDealerPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Trade price per
                <b>
                  <i> carton</i>
                </b>
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={tradePrice}
                onChange={(e) => setTradePrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Max retail price per packet
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={maxRetailPrice}
                onChange={(e) => setMaxRetailPrice(e.target.value)}
              />
            </Box>
            <Box width={{ xs: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Offer info
              </Typography>
            </Box>
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">Offer type</Typography>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={offerType}
                  onChange={(e) => setOfferType(e.target.value)}>
                  {Object.entries(offerTypeLabels).map(([value, label]) => (
                    <MenuItem key={label} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {offerType === 'free' ? (
              <Box width={{ xs: 1, lg: '48%' }}>
                <Typography variant="subtitle2">
                  Free items per carton
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={freeItemsPerCarton}
                  onChange={(e) => setFreeItemsPerCarton(e.target.value)}
                />
              </Box>
            ) : null}
            <Box width={{ xs: 1, lg: '48%' }}>
              <Typography variant="subtitle2">
                Minimum order quantity for offer
              </Typography>
              <TextField
                disabled={offerType !== 'gift'}
                fullWidth
                variant="outlined"
                size="small"
                type="number"
                onWheel={(e) => e.target.blur()}
                value={minimumOrderQuantityForOffer}
                onChange={(e) =>
                  setMinimumOrderQuantityForOffer(e.target.value)
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">CTN</InputAdornment>
                  ),
                }}
              />
            </Box>
            {offerType === 'gift' ? (
              <Box width={{ xs: 1 }}>
                <Typography variant="subtitle2">
                  Gift item description
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type="text"
                  multiline
                  rows={2}
                  value={giftItemDescription}
                  onChange={(e) => setGiftItemDescription(e.target.value)}
                />
              </Box>
            ) : null}
          </Stack>
        </Box>
      </Stack>

      <Box sx={{ mt: 1, pb: 3, textAlign: 'center' }}>
        <Button
          disabled={isLoading || isDateError1 || isDateError2}
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          sx={{ minWidth: 160 }}>
          Save
        </Button>
      </Box>

      <Popover
        open={Boolean(anchorEl)}
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        elevation="12"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}>
        <Stack sx={{ p: 3, alignItems: 'center' }}>
          <Typography variant="h6" color="primary.main">
            Price information was saved successfully
          </Typography>
          <Typography variant="subtitle1">
            Do you want to add another product's information?
          </Typography>
          <Stack
            direction="row"
            sx={{
              pt: 2,
              gap: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleDone}
              sx={{ minWidth: 120 }}>
              Not now
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleAddMore}
              sx={{ minWidth: 120 }}>
              Add more
            </Button>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
}
