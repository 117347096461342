import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import CustomMultiselect from '../input/CustomMultiselect';
import { Checkbox, FormControlLabel } from '@mui/material';

export const SelectColumnFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows, id } = column;
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows?.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  const selectStyle = {
    border: '1px solid #80808078',
    width: '100%',
  };

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={selectStyle}>
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export const MultiSelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Get all unique values in the column
  const options = React.useMemo(() => {
    const options = new Set();

    preFilteredRows.forEach((row) => {
      // options.add(row.values[id]);
      if (row.values[id] !== '') {
        options.add(row.values[id]);
      }
      // else {
      //   options.add('empty');
      // }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // console.log(options);

  // const containsNumbers = options.some(
  //   (element) => typeof element === 'number',
  // );
  // const containsStrings = options.some(
  //   (element) => typeof element === 'string',
  // );

  // containsNumbers
  //   ? options.sort((a, b) => parseFloat(a) - parseFloat(b))
  //   : options.sort()

  return (
    <>
      <CustomMultiselect
        lists={options}
        state={filterValue || []}
        setState={setFilter}
      />
    </>
  );
};

export const SelectColumnFilterBool = ({ column }) => {
  const { filterValue, setFilter } = column;

  const selectStyle = {
    border: '1px solid #80808078',
  };

  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={selectStyle}>
      <option value="">All</option>
      <option value="true">Yes</option>
      <option value="false">No</option>
    </select>
  );
};

export const SelectColumnFilterDate = ({ column }) => {
  const { filterValue, setFilter } = column;
  const selectStyle = {
    border: '1px solid #80808078',
  };
  return (
    <>
      <input
        type="date"
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        className="w-100"
        style={selectStyle}
      />
      <input
        type="date"
        value={filterValue}
        onChange={(e) => setFilter(e.target.value || undefined)}
        className="w-100"
        style={selectStyle}
      />
    </>
  );
};

export function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    if (preFilteredRows?.length) {
      let min = dayjs();
      let max = dayjs(0);

      preFilteredRows?.forEach((row) => {
        if (row.values[id]) {
          const rowDate = dayjs(row.values[id]);

          if (rowDate.valueOf() <= min.valueOf()) {
            min = rowDate;
          }
          if (rowDate.valueOf() >= max.valueOf()) {
            max = rowDate;
          }
        }
      });

      return [min.format('YYYY-MM-DD'), max.format('YYYY-MM-DD')];
    }
    return ['', ''];
  }, [id, preFilteredRows]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <input
        type="date"
        min={min}
        max={max}
        value={filterValue[0] ? dayjs(filterValue[0]).format('YYYY-MM-DD') : ''}
        onChange={({ target: { value } }) => {
          const newDate = value
            ? dayjs(value).startOf('date').toISOString()
            : undefined;

          setFilter((old = []) => [newDate, old[1]]);
        }}
      />
      {/* {' to '} */}
      <input
        type="date"
        min={min}
        max={max}
        value={filterValue[1] ? dayjs(filterValue[1]).format('YYYY-MM-DD') : ''}
        onChange={({ target: { value } }) => {
          const newDate = value
            ? dayjs(value).endOf('date').toISOString()
            : undefined;

          setFilter((old = []) => [old[0], newDate]);
        }}
      />
    </div>
  );
}

// ---------------------------------------------

export const SelectColumnFilterStatus = ({ column }) => {
  const { filterValue, setFilter } = column;

  const selectStyle = {
    border: '1px solid #80808078',
    width: '100%',
  };

  return (
    <>
      <select
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        style={selectStyle}>
        <option value="">All</option>
        <option value="000">⚪ ⚪ ⚪</option>
        <option value="100">🔵 ⚪ ⚪</option>
        <option value="110">🔵 🟣 ⚪</option>
        <option value="111">🔵 🟣 🟢</option>
      </select>
    </>
  );
};
/* 
 -------------------------------------
 -------------------------------------
// National Audited IMS -------------
-------------------------------------
 ------------------------------------
*/

export const SelectColumnFilterNAIMS = ({ column }) => {
  const { setFilter, filteredRows, preFilteredRows } = column;
  const [value, setValue] = useState();
  const [sum, setSum] = useState();

  const pF = [];
  preFilteredRows.forEach((value, index) => pF.push(value?.original));
  // console.log('preFilteredRows:===>', pF);

  useEffect(() => {
    let cartons = 0;
    let packets = 0;

    // console.log('filteredRows:', filteredRows);

    filteredRows?.forEach((pr) => {
      const txt = pr.original.endStockDisplay;
      if (txt?.includes(' ctn')) {
        const t = txt.split(' ctn');

        const carton = parseInt(t[0], 10);

        cartons += carton;

        if (t[1] && t[1].includes(' pcs')) {
          const packet = parseInt(t[1].split(' pcs')[0], 10);
          packets += packet;
        }
      }
    });

    let sumDisplay = `${cartons} ctn`;
    if (packets) {
      sumDisplay += ` ${packets} pcs`;
    }

    setSum(sumDisplay);
  }, [filteredRows]);

  useEffect(() => {
    switch (value) {
      case '<10':
        return setFilter([0, 9]);
      case '10-25':
        return setFilter([10, 25]);
      case '26-50':
        return setFilter([26, 50]);
      case '51-100':
        return setFilter([51, 100]);
      case '101-200':
        return setFilter([101, 200]);
      case '201-300':
        return setFilter([201, 300]);
      case '301-400':
        return setFilter([301, 400]);
      case '401-500':
        return setFilter([401, 500]);
      case '501>':
        return setFilter([501, 9999999]);
      default:
        return setFilter('');
    }
  }, [value]);

  // console.log(value);

  return (
    <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{ border: '1px solid #80808078' }}>
        <option value="">All</option>
        <option value="<10">Less than 10 ctn</option>
        <option value="10-25">10 to 25 ctn</option>
        <option value="26-50">26 to 50 ctn</option>
        <option value="51-100">51 to 100 ctn</option>
        <option value="101-200">101 to 200 ctn</option>
        <option value="201-300">201 to 300 ctn</option>
        <option value="301-400">301 to 400 ctn</option>
        <option value="401-500">401 to 500 ctn</option>
        <option value="501>">More than 500 ctn</option>
      </select>
      <p>{sum}</p>
    </div>
  );
};

export const SelectColumnFilterNAIMSSS = ({ column }) => {
  const { setFilter, filteredRows } = column;
  const [value, setValue] = useState();
  const [sum, setSum] = useState();

  useEffect(() => {
    let cartons = 0;
    let packets = 0;

    filteredRows?.forEach((pr) => {
      const txt = pr.original.startStockDisplay;
      if (txt?.includes(' ctn')) {
        const t = txt.split(' ctn');

        const carton = parseInt(t[0], 10);
        cartons += carton;

        if (t[1] && t[1].includes(' pcs')) {
          const packet = parseInt(t[1].split(' pcs')[0], 10);
          packets += packet;
        }
      }
    });

    let sumDisplay = `${cartons} ctn`;
    if (packets) {
      sumDisplay += ` ${packets} pcs`;
    }

    setSum(sumDisplay);
  }, [filteredRows]);

  useEffect(() => {
    switch (value) {
      case '<10':
        return setFilter([0, 9]);
      case '10-25':
        return setFilter([10, 25]);
      case '26-50':
        return setFilter([26, 50]);
      case '51-100':
        return setFilter([51, 100]);
      case '101-200':
        return setFilter([101, 200]);
      case '201-300':
        return setFilter([201, 300]);
      case '301-400':
        return setFilter([301, 400]);
      case '401-500':
        return setFilter([401, 500]);
      case '501>':
        return setFilter([501, 9999999]);
      default:
        return setFilter('');
    }
  }, [value]);

  return (
    <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{ border: '1px solid #80808078' }}>
        <option value="">All</option>
        <option value="<10">Less than 10 ctn</option>
        <option value="10-25">10 to 25 ctn</option>
        <option value="26-50">26 to 50 ctn</option>
        <option value="51-100">51 to 100 ctn</option>
        <option value="101-200">101 to 200 ctn</option>
        <option value="201-300">201 to 300 ctn</option>
        <option value="301-400">301 to 400 ctn</option>
        <option value="401-500">401 to 500 ctn</option>
        <option value="501>">More than 500 ctn</option>
      </select>
      <p>{sum}</p>
    </div>
  );
};

export const SelectColumnFilterNAIMSAu = ({ column }) => {
  const { setFilter, filteredRows } = column;
  const [value, setValue] = useState();
  const [sum, setSum] = useState();
  // console.log(value);
  useEffect(() => {
    let cartons = 0;
    let packets = 0;

    filteredRows?.forEach((pr) => {
      const txt = pr.original.inMarketSaleDisplay;
      if (txt?.includes(' ctn')) {
        const t = txt.split(' ctn');

        const carton = parseInt(t[0], 10);
        cartons += carton;

        if (t[1] && t[1].includes(' pcs')) {
          const packet = parseInt(t[1].split(' pcs')[0], 10);
          packets += packet;
        }
      }
    });

    let sumDisplay = `${cartons} ctn`;
    if (packets) {
      sumDisplay += ` ${packets} pcs`;
    }

    setSum(sumDisplay);
  }, [filteredRows]);

  useEffect(() => {
    switch (value) {
      case '<10':
        return setFilter([0, 9]);
      case '10-25':
        return setFilter([10, 25]);
      case '26-50':
        return setFilter([26, 50]);
      case '51-100':
        return setFilter([51, 100]);
      case '101-200':
        return setFilter([101, 200]);
      case '201-300':
        return setFilter([201, 300]);
      case '301-400':
        return setFilter([301, 400]);
      case '401-500':
        return setFilter([401, 500]);
      case '501>':
        return setFilter([501, 9999999]);
      default:
        return setFilter('');
    }
  }, [value]);

  return (
    <div style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
      <select
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        style={{ border: '1px solid #80808078' }}>
        <option value="">All</option>
        <option value="<10">Less than 10 ctn</option>
        <option value="10-25">10 to 25 ctn</option>
        <option value="26-50">26 to 50 ctn</option>
        <option value="51-100">51 to 100 ctn</option>
        <option value="101-200">101 to 200 ctn</option>
        <option value="201-300">201 to 300 ctn</option>
        <option value="301-400">301 to 400 ctn</option>
        <option value="401-500">401 to 500 ctn</option>
        <option value="501>">More than 500 ctn</option>
      </select>
      <p>{sum}</p>
    </div>
  );
};

// customer activity report page
export const SelectFilterSrReport = ({ column }) => {
  const [value, setValue] = useState();
  const { filterValue, setFilter } = column;

  useEffect(() => {
    switch (value) {
      case '0':
        return setFilter([0, 0]);
      case '>0':
        return setFilter([1, 9999999]);
      default:
        return setFilter('');
    }
  }, [value]);

  return (
    <select
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      style={{ border: '1px solid #80808078' }}>
      <option value="" disabled>
        choose
      </option>
      <option value="all">All</option>
      <option value={'0'}>{'0'}</option>
      <option value={'>0'}>{'>0'}</option>
    </select>
  );
};

export const SelectHaveValue = ({ column }) => {
  const [value, setValue] = useState();
  const { filterValue, setFilter } = column;

  useEffect(() => {}, [value]);

  // console.log(value);

  useEffect(() => {
    if (value === 'true') {
      return setFilter('hasValue');
    } else if (value === 'false') {
      return setFilter('empty');
    } else {
      return setFilter('all');
    }
  }, [value]);

  return (
    <select
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      style={{ border: '1px solid #80808078' }}>
      <option value="" disabled>
        choose
      </option>
      <option value="all">All</option>
      <option value={'true'}>with value</option>
      <option value={'false'}>empty</option>
    </select>
  );
};
