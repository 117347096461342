import Title from '../../component/report/Title';
import React from 'react';
import useHeader from '../../hooks/useHeader';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useAuth from '../../hooks/useAuth';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import Table from '../../component/Table/Table';

function SDCommission() {
  const { setHeader } = useHeader();
  const { user } = useAuth();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const [customersList, setCustomersList] = React.useState([]);
  const [customer, setCustomer] = React.useState({});
  const [currentDue, setCurrentDue] = React.useState(0);

  React.useEffect(() => {
    setHeader({ title: 'SD Commission' });
  }, []);

  // get customers list
  React.useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/customer?populate=yes&limit=none&sort=name&customerType=63d9ef5df3507d5b693cc0da&parentTerritory=${user?.employee?.territory?.id}`,
      )
      .then((res) => {
        setCustomersList(res?.data?.data);
        if (user?.employee?.role?.territoryType?.rank !== 1) {
          setCustomer(res?.data?.data?.[0]);
        }
      })
      .catch((error) => expired(error));
  }, [user]);

  const { data, isError, error, isLoading } = useQuery(
    ['sd-commission', customer?.id],
    ({ queryKey }) => {
      const cId = queryKey[1];

      return axiosPrivate
        .get(`/api/v1/report/sd-commission?&customer=${cId}`)
        .then((res) => {
          setCurrentDue(res?.data?.totalDuePayment);
          return res?.data?.data?.reverse();
        });
    },
  );

  if (isError) {
    expired(error);
  }

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Month',
        accessor: 'monthKey',
      },
      {
        Header: 'Ordered Invoice Amount',
        accessor: 'claimedInvoiceAmount',
      },
      {
        Header: 'Ordered SD Commission',
        accessor: 'claimedCommission',
      },
      {
        Header: 'Delivered Invoice Amount',
        accessor: 'invoiceAmount',
      },
      {
        Header: 'SD Commission',
        accessor: 'commission',
      },
      {
        Header: 'Paid As Cash',
        accessor: 'paidAsCash',
      },
      {
        Header: 'Paid As Product',
        accessor: 'paidAsProduct',
      },
      {
        Header: 'Due Payment',
        accessor: 'duePayment',
      },
    ],
    [],
  );

  return (
    <>
      <Title title="SD Commission">
        <Autocomplete
          size="small"
          disableClearable
          options={customersList}
          value={customer}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          getOptionLabel={(option) =>
            option?.nameCode
              ? `${option?.nameCode} - ${option?.territory?.name}`
              : ''
          }
          onChange={(event, value) => setCustomer(value)}
          renderInput={(params) => (
            <TextField {...params} label="Select SD" sx={{ width: 240 }} />
          )}
        />
      </Title>

      <Stack
        direction={{ sm: 'row' }}
        sx={{ pb: 2, alignItems: 'baseline', gap: { sm: 2 } }}>
        <Typography variant="h6">
          Current due: <b>{currentDue}</b>
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Commission rate: <b>{customer?.commissionPercent || 0}%</b>
        </Typography>
      </Stack>

      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        fullHeight
        noWrap
        filename="SD_Commission"
        hideAddIcon={true}
      />
    </>
  );
}

export default SDCommission;

//
//
// Removed commissions for these customers
// 2158 - 65dadb265c6c40d3cd0893d7
// 1952 - 64d1fa42454976f38839895f
// 2123 - 65bf5f35dce47842b382db85
// 2011 - 6548e56f59954e143f74bc10
// 1875 - 6475cb8afd795e4c35935d13
// 2318 - 66b9f1c52ee10778b720d9ce
// 2826 - NOT FOUND
// 2326 - 66c3179032f67f8b9c60aec1
// 1757 - 63da982564b5b55fad60dc4d
// 1531 - 63da980164b5b55fad60d859
// 2307 - 66b222c22ee10778b7b4f1f3
// 2110 - 65afa5df019708ec76d011c2
// 1924 - 649034b8122d539b2fb8b28a

// ObjectId("65dadb265c6c40d3cd0893d7"),
// ObjectId("64d1fa42454976f38839895f"),
// ObjectId("65bf5f35dce47842b382db85"),
// ObjectId("6548e56f59954e143f74bc10"),
// ObjectId("6475cb8afd795e4c35935d13"),
// ObjectId("66b9f1c52ee10778b720d9ce"),
// ObjectId("66c3179032f67f8b9c60aec1"),
// ObjectId("63da982564b5b55fad60dc4d"),
// ObjectId("63da980164b5b55fad60d859"),
// ObjectId("66b222c22ee10778b7b4f1f3"),
// ObjectId("65afa5df019708ec76d011c2"),
// ObjectId("649034b8122d539b2fb8b28a"),
