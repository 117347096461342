import { useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  ExpTable,
  ExpandTableCaption,
  ExpandTableContainer,
} from '../../../assets/style/table/customTable';
import useAuth from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import roundToDigit from '../../../utils/roundToDigit';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';

export default function ExpandRowInfo({ data, refetch, hideActions }) {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { role, Admins } = useAuth();
  const viewTransactionRef = useRef();

  const priceType = data?.transactionType?.priceType;
  let priceTypeDisplay;
  switch (priceType) {
    case 'dealerPrice':
      priceTypeDisplay = 'DP / CTN';
      break;
    case 'dsrPrice':
      priceTypeDisplay = 'DSRP / CTN';
      break;
    case 'tradePrice':
      priceTypeDisplay = 'TP / CTN';
      break;
    case 'maxRetailPrice':
      priceTypeDisplay = 'MRP / CTN';
      break;
    default:
      priceTypeDisplay = null;
      break;
  }

  const [allOrder, setAllOrder] = useState('');
  const [allFree, setAllFree] = useState('');
  const [allTotal, setAllTotal] = useState('');
  const [allFreePrice, setAllFreePrice] = useState(0);
  const [allTotalPrice, setAllTotalPrice] = useState(0);

  const {
    data: collections,
    isLoading,
    isError,
    error,
  } = useQuery(['transaction-linked-collections', data?.collectionIds], () => {
    let url = '/api/v1/ledger?populate=yes&limit=none&sort=-dateOfDeposit';

    if (data?.collectionRefs?.length > 0) {
      data?.collectionRefs?.forEach((i, index) => {
        url += `&_id[in][${index}]=${i}`;
      });
    } else {
      url += '&type=NOTHING';
    }

    return axiosPrivate.get(url).then((res) => res?.data?.data);
  });

  const products = useMemo(() => {
    let allOrderCartons = 0;
    let allOrderPieces = 0;
    let allFreeCartons = 0;
    let allFreePieces = 0;
    let allTotalCartons = 0;
    let allTotalPieces = 0;
    let allFreePriceX = 0;
    let allTotalPriceX = 0;

    const allTotal = data?.products?.reduce(
      (a, p) => a + p?.quantity + p?.freeQuantity,
      0,
    );

    const p = data?.products?.map((p) => {
      // prices
      const freePrice = p?.freeQuantity * p[priceType];
      allFreePriceX += freePrice;
      allTotalPriceX += p?.totalPrice;

      // ordered quantity
      const orderCartons = Math.floor(p?.quantity / p?.quantityPerCarton);
      const orderPieces = p?.quantity % p?.quantityPerCarton;

      allOrderCartons += orderCartons;
      allOrderPieces += orderPieces;

      let quantityDisplay = `${orderCartons} ctn`;
      if (orderPieces > 0) quantityDisplay += ` ${orderPieces} pcs`;

      // free quantity
      const freeCartons = Math.floor(p?.freeQuantity / p?.quantityPerCarton);
      const freePieces = p?.freeQuantity % p?.quantityPerCarton;

      allFreeCartons += freeCartons;
      allFreePieces += freePieces;

      let freeQuantityDisplay = `${freeCartons} ctn`;
      if (freePieces > 0) freeQuantityDisplay += ` ${freePieces} pcs`;

      // total quantity
      const totalCartons = Math.floor(
        (p?.quantity + p?.freeQuantity) / p?.quantityPerCarton,
      );
      const totalPieces =
        (p?.quantity + p?.freeQuantity) % p?.quantityPerCarton;

      allTotalCartons += totalCartons;
      allTotalPieces += totalPieces;

      let totalQuantityDisplay = `${totalCartons} ctn`;
      if (totalPieces > 0) totalQuantityDisplay += ` ${totalPieces} pcs`;

      return (
        <TableRow key={data?.id + p?.product?.code}>
          <TableCell>{p?.product?.nameCode}</TableCell>
          {priceTypeDisplay && (
            <TableCell style={{ textAlign: 'end' }}>
              {roundToDigit(p[priceType] * p?.quantityPerCarton)}
            </TableCell>
          )}
          <TableCell>{quantityDisplay}</TableCell>
          <TableCell>{freeQuantityDisplay}</TableCell>
          <TableCell>{totalQuantityDisplay}</TableCell>
          <TableCell sx={{ textAlign: 'end', whiteSpace: 'nowrap' }}>
            {roundToDigit(((p?.quantity + p?.freeQuantity) / allTotal) * 100)}%
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {roundToDigit(p?.totalPrice)}
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {roundToDigit(freePrice)}
          </TableCell>
        </TableRow>
      );
    });

    let allOrderDisplay = `${allOrderCartons} ctn`;
    if (allOrderPieces > 0) allOrderDisplay += ` ${allOrderPieces} pcs`;

    let allFreeDisplay = `${allFreeCartons} ctn`;
    if (allFreePieces > 0) allFreeDisplay += ` ${allFreePieces} pcs`;

    let allTotalDisplay = `${allTotalCartons} ctn`;
    if (allTotalPieces) allTotalDisplay += ` ${allTotalPieces} pcs`;

    setAllOrder(allOrderDisplay);
    setAllFree(allFreeDisplay);
    setAllTotal(allTotalDisplay);
    setAllFreePrice(roundToDigit(allFreePriceX));
    setAllTotalPrice(roundToDigit(allTotalPriceX));

    return p;
  }, [data?.products?.length, priceType, priceTypeDisplay]);

  const collectionsView = useMemo(() => {
    if (isLoading) {
      return (
        <Typography align="center" color="text.disabled" sx={{ py: 3 }}>
          Loading...
        </Typography>
      );
    }

    if (collections?.length < 1) {
      return (
        <Typography align="center" sx={{ py: 3 }}>
          No linked collections
        </Typography>
      );
    }

    const totalCollection = collections?.reduce(
      (accumulator, i) => ({
        totalApproved: accumulator.totalApproved + i?.approvedAmount,
        totalBonus: accumulator.totalBonus + i?.bonusAmount,
        totalCost: accumulator.totalCost + i?.costAmount,
      }),
      { totalApproved: 0, totalBonus: 0, totalCost: 0 },
    );

    return (
      <ExpandTableContainer>
        <ExpTable cellSpacing="0">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Deposit Date</TableCell>
              <TableCell>Depositor</TableCell>
              <TableCell>Post Under</TableCell>
              <TableCell>Approved Amount</TableCell>
              <TableCell>Online Charge</TableCell>
              <TableCell>Incentive</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections?.map((i) => (
              <TableRow key={i?.id}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {i?.shortId}
                </TableCell>
                <TableCell>
                  {dayjs(i?.dateOfDeposit).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell>{i?.customer?.nameCode}</TableCell>
                <TableCell>{i?.postUnder?.nameCode}</TableCell>
                <TableCell>{i?.approvedAmount}</TableCell>
                <TableCell>{i?.costAmount}</TableCell>
                <TableCell>{i?.bonusAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableRow>
            <TableCell colSpan={4}>
              <b>
                Total:&ensp;
                {totalCollection?.totalApproved +
                  totalCollection?.totalCost +
                  totalCollection?.totalBonus}
              </b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalApproved}</b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalCost}</b>
            </TableCell>
            <TableCell>
              <b>{totalCollection?.totalBonus}</b>
            </TableCell>
          </TableRow>
        </ExpTable>
      </ExpandTableContainer>
    );
  }, [collections, isLoading]);

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Stack direction="row" justifyContent="flex-end">
        <ReactToPrint
          trigger={() => {
            return (
              <Button
                variant="outlined"
                // disabled={isError || isFetching}
                startIcon={<PrintIcon />}>
                Print
              </Button>
            );
          }}
          content={() => viewTransactionRef.current}
        />
      </Stack>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        // justifyContent={'center'}
        alignItems={{ xs: 'normal', md: 'flex-start' }}
        gap={2}
        p={{ xs: 0, md: 2 }}>
        <Box component={Paper} overflow="hidden" sx={{ width: '100%' }}>
          <ExpandTableCaption>General Details</ExpandTableCaption>
          <ExpandTableContainer>
            <ExpTable cellSpacing="0">
              {data?.shortId ? (
                <TableRow>
                  <TableCell component="th">Transaction ID</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.shortId}</TableCell>
                </TableRow>
              ) : null}

              {data?.transactionType?.transactionType ? (
                <TableRow>
                  <TableCell component="th">Transaction Type</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>
                    {data?.transactionType?.transactionType}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.transactionType?.function ? (
                <TableRow>
                  <TableCell component="th">Function</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell
                    sx={{
                      textTransform: 'capitalize',
                    }}>
                    {`${data?.transactionType?.function} (${data?.transactionType?.productCondition})`}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.sender?.nameCode ? (
                <TableRow>
                  <TableCell component="th">Sender</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.sender?.nameCode}</TableCell>
                </TableRow>
              ) : null}

              {data?.receiver?.nameCode ? (
                <TableRow>
                  <TableCell component="th">Receiver</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.receiver?.nameCode}</TableCell>
                </TableRow>
              ) : null}

              {data?.recordedBy?.nameCode ? (
                <TableRow>
                  <TableCell component="th">Recorded By</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.recordedBy?.nameCode}</TableCell>
                </TableRow>
              ) : null}

              {data?.collectionIds ? (
                <TableRow>
                  <TableCell component="th">Linked Collections ID</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.collectionIds}</TableCell>
                </TableRow>
              ) : null}

              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ----------------------------------------amount------------ */}

              {/* <tr>
                <td>
                  <b>Invoice Amount</b>
                </td>
                <td>:</td>
                <td>{data?.invoiceAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Discount Amount</b>
                </td>
                <td>:</td>
                <td>{data?.discountAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Discount Percent</b>
                </td>
                <td>:</td>
                <td>{data?.discountPercent}</td>
              </tr>
              <tr>
                <td>
                  <b>Payable Amount</b>
                </td>
                <td>:</td>
                <td>{data?.netPayableAmount}</td>
              </tr>
              <tr>
                <td>
                  <b>Paid Amount</b>
                </td>
                <td>:</td>
                <td>{data?.paidAmount}</td>
              </tr> */}
              {/* ----------------------------------------amount------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}
              {/* ------------------------ */}

              {data?.orderedAt ? (
                <TableRow>
                  <TableCell component="th">Order Time</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>
                    {dayjs(data?.orderedAt).format('DD/MM/YYYY hh:mm A')}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.isProductsConfirmed ? (
                <TableRow>
                  <TableCell component="th">Products Confirm Time</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>
                    {data?.isProductsConfirmed
                      ? dayjs(data?.productsConfirmedAt).format(
                          'DD/MM/YYYY hh:mm A',
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.isPaymentsConfirmed ? (
                <TableRow>
                  <TableCell component="th">Payments Confirm Time</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>
                    {data?.isPaymentsConfirmed
                      ? dayjs(data?.paymentsConfirmedAt).format(
                          'DD/MM/YYYY hh:mm A',
                        )
                      : ''}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.isDelivered ? (
                <TableRow>
                  <TableCell component="th">Delivery Time</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>
                    {data?.isDelivered
                      ? dayjs(data?.deliveredAt).format('DD/MM/YYYY hh:mm A')
                      : ''}
                  </TableCell>
                </TableRow>
              ) : null}

              {data?.doNumber ? (
                <TableRow>
                  <TableCell component="th">DO Number</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.doNumber}</TableCell>
                </TableRow>
              ) : null}

              {data?.coNumber ? (
                <TableRow>
                  <TableCell component="th">CO Number</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.coNumber}</TableCell>
                </TableRow>
              ) : null}

              {data?.deliverymanName ? (
                <TableRow>
                  <TableCell component="th">Driver Name</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.deliverymanName}</TableCell>
                </TableRow>
              ) : null}

              {data?.deliverymanContact ? (
                <TableRow>
                  <TableCell component="th">Driver Contact</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.deliverymanContact}</TableCell>
                </TableRow>
              ) : null}

              {data?.vehicleNumber ? (
                <TableRow>
                  <TableCell component="th">Vehicle Number</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.vehicleNumber}</TableCell>
                </TableRow>
              ) : null}

              {data?.id ? (
                <TableRow>
                  <TableCell component="th">Full ID</TableCell>
                  <TableCell component="th">:</TableCell>
                  <TableCell>{data?.id}</TableCell>
                </TableRow>
              ) : null}
            </ExpTable>
          </ExpandTableContainer>
        </Box>
        <Stack gap={3}>
          <Box component={Paper} sx={{ width: '100%' }} overflow="hidden">
            <ExpandTableCaption>Products Details</ExpandTableCaption>
            <ExpandTableContainer>
              <ExpTable cellSpacing="0">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      Product Name
                    </TableCell>
                    {priceTypeDisplay && (
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {priceTypeDisplay}
                      </TableCell>
                    )}
                    <TableCell>Ordered</TableCell>
                    <TableCell>Free</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>SKU %</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      Payable &#2547;
                    </TableCell>
                    <TableCell>Free &#2547;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products}
                  <TableRow>
                    <TableCell colSpan={priceTypeDisplay ? 2 : 1}>
                      <b>Total</b>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <b>{allOrder}</b>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <b>{allFree}</b>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap' }}>
                      <b>{allTotal}</b>
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      style={{ textAlign: 'end', whiteSpace: 'nowrap' }}>
                      <b>{allTotalPrice}</b>
                    </TableCell>
                    <TableCell
                      style={{ textAlign: 'end', whiteSpace: 'nowrap' }}>
                      <b>{allFreePrice}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </ExpTable>
            </ExpandTableContainer>
          </Box>
          <Box component={Paper} sx={{ width: '100%' }} overflow="hidden">
            <ExpandTableCaption>Payments Details</ExpandTableCaption>
            <ExpandTableContainer>
              <ExpTable cellSpacing="0">
                {data?.invoiceAmount ? (
                  <TableRow>
                    <TableCell component="th">Invoice Amount</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.invoiceAmount}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.damage ? (
                  <TableRow>
                    <TableCell component="th">Damage</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.damage}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.return ? (
                  <TableRow>
                    <TableCell component="th">Return</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.return}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.transport ? (
                  <TableRow>
                    <TableCell component="th">Transport</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.transport}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.salary ? (
                  <TableRow>
                    <TableCell component="th">Salary</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.salary}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.bonus ? (
                  <TableRow>
                    <TableCell component="th">Bonus</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.bonus}</TableCell>
                  </TableRow>
                ) : null}

                {data?.ledgerValues?.commission ? (
                  <TableRow>
                    <TableCell component="th">Commission</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.commission}</TableCell>
                  </TableRow>
                ) : null}
                {data?.ledgerValues?.other ? (
                  <TableRow>
                    <TableCell component="th">Other Funds</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.ledgerValues?.other}</TableCell>
                  </TableRow>
                ) : null}

                {data?.netPayableAmount ? (
                  <TableRow>
                    <TableCell component="th">Net Payable Amount</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.netPayableAmount}</TableCell>
                  </TableRow>
                ) : null}

                {data?.applicableCommission ? (
                  <TableRow>
                    <TableCell component="th">SD Commission</TableCell>
                    <TableCell component="th">:</TableCell>
                    <TableCell>{data?.applicableCommission}</TableCell>
                  </TableRow>
                ) : null}
              </ExpTable>
            </ExpandTableContainer>
          </Box>
          <Box component={Paper} sx={{ width: '100%' }} overflow="hidden">
            <ExpandTableCaption>Linked Collections</ExpandTableCaption>
            {collectionsView}
          </Box>
        </Stack>
      </Stack>

      {hideActions ? null : (
        <Stack
          direction={'row'}
          flexWrap="wrap"
          sx={{ p: 2, gap: 2, alignItems: 'center', justifyContent: 'center' }}>
          {['Super Admin', ...Admins, 'Accounts', 'DO'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/link-collections"
              state={{ data: data }}
              variant="outlined"
              disabled={data?.isPaymentsConfirmed}>
              Link Collections
            </Button>
          ) : null}

          {['Super Admin', ...Admins, 'DO'].includes(role) ? (
            <Button
              component={Link}
              to={`/transactions/edit/${data?.id}`}
              variant="outlined"
              disabled={data?.isPaymentsConfirmed || data?.isDelivered}>
              Confirm Product
            </Button>
          ) : null}

          {['Super Admin', ...Admins, 'Accounts'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/confirm-payment"
              state={{ data: data }}
              variant="outlined"
              disabled={
                data?.isPaymentsConfirmed || !data?.isProductsConfirmed
              }>
              Confirm Payment
            </Button>
          ) : null}

          {['Super Admin', ...Admins, 'DO'].includes(role) ? (
            <Button
              component={Link}
              to="/transactions/confirm-delivery"
              state={{ data: data }}
              variant="outlined"
              disabled={
                data?.isDelivered ||
                !(data?.isProductsConfirmed && data?.isPaymentsConfirmed)
              }>
              Confirm Delivery
            </Button>
          ) : null}
        </Stack>
      )}

      {/* print report */}
      <Box sx={{ display: 'none' }}>
        <Box ref={viewTransactionRef}>
          {/* head section */}
          <Typography textAlign="center" variant="subtitle1" fontWeight={700}>
            Transaction Memo
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Title label="Sender Name" value={data?.sender?.nameCode} />
              <Title label="Receiver Name" value={data?.receiver?.nameCode} />
              <Title label="Do Number" value={data?.doNumber} />
            </Box>
            <Box>
              <Title
                label="Order Time"
                value={`${dayjs(data?.orderedAt).format('DD/MM/YYYY hh:mm A')}`}
              />
              <Title
                label="Delivery Time"
                value={`${dayjs(data?.deliveredAt).format(
                  'DD/MM/YYYY hh:mm A',
                )}`}
              />
            </Box>
          </Stack>

          <Box overflow="hidden" sx={{ mt: 2 }}>
            <Box sx={{ width: '100%', flexGrow: 1, mb: 3 }}>
              <ExpandTableCaption>Products Details</ExpandTableCaption>
              <ExpandTableContainer>
                <ExpTable cellSpacing="0">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      {priceTypeDisplay && (
                        <TableCell>{priceTypeDisplay}</TableCell>
                      )}
                      <TableCell>Ordered</TableCell>
                      <TableCell>Free</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>SKU %</TableCell>
                      <TableCell>Payable &#2547;</TableCell>
                      <TableCell>Free &#2547;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products}
                    <TableRow>
                      <TableCell colSpan={priceTypeDisplay ? 2 : 1}>
                        <b>Total</b>
                      </TableCell>
                      <TableCell>
                        <b>{allOrder}</b>
                      </TableCell>
                      <TableCell>
                        <b>{allFree}</b>
                      </TableCell>
                      <TableCell>
                        <b>{allTotal}</b>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell style={{ textAlign: 'end' }}>
                        <b>{allTotalPrice}</b>
                      </TableCell>
                      <TableCell style={{ textAlign: 'end' }}>
                        <b>{allFreePrice}</b>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </ExpTable>
              </ExpandTableContainer>
            </Box>

            <Box sx={{ width: '100%', mb: 3 }}>
              <ExpandTableCaption>Payments Details</ExpandTableCaption>
              <ExpandTableContainer>
                <ExpTable cellSpacing="0">
                  {data?.invoiceAmount ? (
                    <TableRow>
                      <TableCell component="th">Invoice Amount</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.invoiceAmount}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.damage ? (
                    <TableRow>
                      <TableCell component="th">Damage</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.damage}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.return ? (
                    <TableRow>
                      <TableCell component="th">Return</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.return}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.transport ? (
                    <TableRow>
                      <TableCell component="th">Transport</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.transport}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.salary ? (
                    <TableRow>
                      <TableCell component="th">Salary</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.salary}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.bonus ? (
                    <TableRow>
                      <TableCell component="th">Bonus</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.bonus}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.ledgerValues?.commission ? (
                    <TableRow>
                      <TableCell component="th">Commission</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.commission}</TableCell>
                    </TableRow>
                  ) : null}
                  {data?.ledgerValues?.other ? (
                    <TableRow>
                      <TableCell component="th">Other Funds</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.ledgerValues?.other}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.netPayableAmount ? (
                    <TableRow>
                      <TableCell component="th">Net Payable Amount</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.netPayableAmount}</TableCell>
                    </TableRow>
                  ) : null}

                  {data?.applicableCommission ? (
                    <TableRow>
                      <TableCell component="th">SD Commission</TableCell>
                      <TableCell component="th">:</TableCell>
                      <TableCell>{data?.applicableCommission}</TableCell>
                    </TableRow>
                  ) : null}
                </ExpTable>
              </ExpandTableContainer>
            </Box>

            <Box sx={{ mt: 4, width: '100%', flexGrow: 1 }}>
              <ExpandTableCaption>Linked Collections</ExpandTableCaption>
              {collectionsView}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function Title({ label, value }) {
  return (
    <Typography fontSize={14}>
      <b>{label}: </b>
      {value}
    </Typography>
  );
}
