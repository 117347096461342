import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputBase } from '@mui/material';

export const GlobalFilter = ({ rows, globalFilter, setGlobalFilter }) => {
  const count = rows?.length;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setGlobalFilter(searchParams.get('name'));
  }, [searchParams]);

  return (
    <InputBase
      type="search"
      value={globalFilter}
      onChange={(e) => setGlobalFilter(e.target.value)}
      placeholder={`Search ${count} records...`}
      sx={styles}
    />
  );
};

// style
const styles = {
  width: { xs: 1, sm: 1 / 2 },
  marginTop: 1,
  borderRadius: 0,
  border: '1px solid rgba(224, 224, 224, 1)',
  overflow: 'hidden',
  backgroundColor: '#fff',
  padding: '2px 12px',
};
