import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useHeader from '../../hooks/useHeader';
import dayjs from 'dayjs';
import roundToDigit from '../../utils/roundToDigit';
import { BankList, MobileBankList } from '../../staticData/Banks';

function ModifyPayrollTicket() {
  const navigate = useNavigate();
  const params = useParams();
  const [query] = useSearchParams();
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const { setHeader } = useHeader();
  const { userId } = useAuth();

  const editId = params?.ticketID;

  const typeOptions = [
    'basic',
    'travel',
    'mobile',
    'internet',
    'incentive',
    'commission',
    'bonus',
    'cost',
    'non compliance',
    'absence',
    'salary',
  ];

  const [navigateUrl, setNavigateUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDateError1, setIsDateError1] = React.useState(false);
  const [isDateError2, setIsDateError2] = React.useState(false);
  const [employeeList, setEmployeeList] = React.useState([]);

  const [employee, setEmployee] = React.useState({});
  const [isTicket, setIsTicket] = React.useState(true);
  const [type, setType] = React.useState('');
  const [entryAmount, setEntryAmount] = React.useState(0);
  const [approvedAmount, setApprovedAmount] = React.useState(0);
  const [dateOfEntry, setDateOfEntry] = React.useState(new Date());
  const [dateOfApprove, setDateOfApprove] = React.useState(new Date());
  const [paymentMode, setPaymentMode] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [bankBranch, setBankBranch] = React.useState('');
  const [accountNumber, setAccountNumber] = React.useState('');
  const [transactionID, setTransactionID] = React.useState('');
  const [reference, setReference] = React.useState('');
  const [remarks, setRemarks] = React.useState('');

  React.useEffect(() => {
    if (editId) {
      setHeader({ title: 'Edit Pay Ticket' });
    } else {
      setHeader({ title: 'Add Pay Ticket' });
    }
  }, [editId]);

  React.useEffect(() => {
    const e = query.get('employee');
    const d = query.get('dateOfEntry');
    const redirect = query.get('redirect');
    if (!editId && e && employeeList.length > 0) {
      const eData = employeeList.find((emp) => emp?.id === e);
      if (!eData?.id) {
        toast.warn('This employee is not active.');
        navigate(navigateUrl);
      } else {
        setEmployee(eData);
      }
    }
    if (d) {
      setDateOfEntry(dayjs(d, 'YYYY-MM-DD'));
    }
    if (redirect) {
      setNavigateUrl(redirect);
    } else {
      setNavigateUrl('/payroll-ticket');
    }
  }, [editId, employeeList, query]);

  React.useEffect(() => {
    if (editId) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/employee-ledger/${editId}?populate=yes`)
        .then((res) => {
          setEmployee(res?.data?.data?.employee);
          setIsTicket(res?.data?.data?.isTicket);
          setType(res?.data?.data?.type);
          setEntryAmount(res?.data?.data?.entryAmount);
          setApprovedAmount(res?.data?.data?.approvedAmount);
          setDateOfEntry(res?.data?.data?.dateOfEntry);
          setDateOfApprove(res?.data?.data?.dateOfApprove);
          setPaymentMode(res?.data?.data?.paymentMode);
          setBankName(res?.data?.data?.bankName);
          setBankBranch(res?.data?.data?.bankBranch);
          setAccountNumber(res?.data?.data?.accountNumber);
          setTransactionID(res?.data?.data?.transactionID);
          setReference(res?.data?.data?.reference);
          setRemarks(res?.data?.data?.remarks);
        })
        .catch((error) => toast.warn(error?.response?.data?.message))
        .finally(() => setIsLoading(false));
    }
  }, [editId]);

  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true&sort=code')
      .then((res) => {
        setEmployeeList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    let method = 'post';
    let url = '/api/v1/employee-ledger';
    const data = {
      isTicket,
      type,
      employee: employee?.id,
      recordedBy: userId,
      entryAmount,
      approvedAmount,
      dateOfEntry,
      dateOfApprove: approvedAmount ? dateOfApprove : undefined,
      paymentMode,
      bankName,
      bankBranch,
      accountNumber,
      transactionID,
      reference,
      remarks,
    };

    if (editId) {
      method = 'patch';
      url += `/${editId}`;
    }

    axiosPrivate({ method, url, data })
      .then((res) => {
        toast.success(res?.data?.message);
        refetch();
        navigate(navigateUrl);
      })
      .catch((error) => toast.warn(error?.response?.data?.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <Container maxWidth="sm">
      <IconButton
        component={Link}
        to={navigateUrl}
        aria-label="Go back"
        edge="start"
        sx={{ mb: 1 }}>
        <ArrowBackIcon />
      </IconButton>

      <Stack component={Paper} sx={{ px: 2, py: 3, gap: 3 }}>
        <Autocomplete
          disabled={editId || isLoading || employeeList?.length < 1}
          fullWidth
          options={employeeList}
          value={employee}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setEmployee(value)}
          getOptionLabel={(option) =>
            option?.nameCode
              ? `${option?.nameCode} - ${option?.territory?.name}`
              : ''
          }
          renderInput={(params) => (
            <TextField {...params} label="Select Employee" />
          )}
        />

        {employee?.id ? (
          <table>
            <tr>
              <td>Role:</td>
              <td>
                <b>{employee?.role?.employeeRole}</b>
              </td>
              <td>{employee?.territory?.territoryType?.territoryType}:</td>
              <td>
                <b>{employee?.territory?.name}</b>
              </td>
            </tr>
            <tr>
              <td>Basic Salary:</td>
              <td>
                <b>{employee?.salaryBasic}</b>
              </td>
              <td>Per Day Basic:</td>
              <td>
                <b>
                  {roundToDigit(
                    parseFloat(employee?.salaryBasic) /
                      dayjs(dateOfEntry).daysInMonth(),
                    3,
                  )}
                </b>
              </td>
            </tr>
            <tr>
              <td>Mobile Allowance:</td>
              <td>
                <b>{employee?.salaryMobile}</b>
              </td>
              <td>Internet Allowance:</td>
              <td>
                <b>{employee?.salaryInternet}</b>
              </td>
            </tr>
            {employee?.role?.attendanceMethod === 'representative' ? (
              <tr>
                <td>Minimum Collection:</td>
                <td>
                  <b>{employee?.minimumCollection}</b>
                </td>
                <td>Salary & TA Percent:</td>
                <td>
                  <b>
                    {employee?.salaryPercent}%,{' '}
                    {employee?.travelAllowancePercent}%
                  </b>
                </td>
              </tr>
            ) : null}
          </table>
        ) : (
          <Typography color="text.disabled" align="center" variant="body2">
            Employee info
          </Typography>
        )}

        <Divider />

        <Stack direction={{ sm: 'row' }} sx={{ gap: 2 }}>
          <TextField
            disabled={editId || isLoading}
            label="Select Type"
            fullWidth
            select
            value={type}
            onChange={(e) => setType(e.target.value)}>
            {typeOptions.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ textTransform: 'capitalize' }}>
                {option}
              </MenuItem>
            ))}
          </TextField>

          <FormControlLabel
            disabled
            label="As Ticket"
            checked={isTicket}
            onChange={() => setIsTicket(!isTicket)}
            control={<Checkbox />}
            slotProps={{
              typography: {
                sx: { width: 'max-content' },
              },
            }}
          />
        </Stack>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={isLoading}
            label="Issue Date"
            fullWidth
            inputFormat="DD/MM/YYYY"
            disableFuture
            value={dateOfEntry}
            onError={(reason) => setIsDateError1(Boolean(reason))}
            onChange={(newValue) => setDateOfEntry(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <TextField
          disabled={isLoading}
          label="Issue Amount"
          fullWidth
          type="number"
          value={entryAmount}
          onChange={(e) => setEntryAmount(e.target.value)}
          onWheel={(e) => e.target.blur()}
          InputProps={{
            endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
          }}
        />

        {editId ? (
          <>
            <Divider>
              <Typography variant="subtitle2" color="text.disabled">
                Payment Info
              </Typography>
            </Divider>

            <TextField
              disabled={isLoading}
              label="Confirm Amount"
              fullWidth
              type="number"
              value={approvedAmount}
              onChange={(e) => setApprovedAmount(e.target.value)}
              onWheel={(e) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Tk</InputAdornment>
                ),
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={isLoading}
                label="Confirm Date"
                fullWidth
                inputFormat="DD/MM/YYYY"
                disableFuture
                value={dateOfApprove}
                onError={(reason) => setIsDateError2(Boolean(reason))}
                onChange={(newValue) => setDateOfApprove(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

            <TextField
              disabled={isLoading}
              label="Select Payment Mode"
              fullWidth
              select
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}>
              <MenuItem value="Bank">Bank</MenuItem>
              <MenuItem value="Mobile Bank">Mobile Bank</MenuItem>
            </TextField>

            {paymentMode === 'Bank' ? (
              <>
                <Autocomplete
                  disabled={isLoading}
                  fullWidth
                  options={BankList}
                  value={bankName}
                  isOptionEqualToValue={(option, value) => value === option}
                  onChange={(event, value) => setBankName(value)}
                  getOptionLabel={(option) => option || ''}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Bank" />
                  )}
                />

                <TextField
                  disabled={isLoading}
                  label="Bank Branch"
                  fullWidth
                  value={bankBranch}
                  onChange={(e) => setBankBranch(e.target.value)}
                />
              </>
            ) : null}

            {paymentMode === 'Mobile Bank' ? (
              <Autocomplete
                disabled={isLoading}
                fullWidth
                options={MobileBankList}
                value={bankName}
                isOptionEqualToValue={(option, value) => value === option}
                onChange={(event, value) => setBankName(value)}
                getOptionLabel={(option) => option || ''}
                renderInput={(params) => (
                  <TextField {...params} label="Select Mobile Bank" />
                )}
              />
            ) : null}

            {paymentMode ? (
              <>
                <TextField
                  disabled={isLoading}
                  label="Account Number"
                  fullWidth
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />

                <TextField
                  disabled={isLoading}
                  label="Transaction Code"
                  fullWidth
                  value={transactionID}
                  onChange={(e) => setTransactionID(e.target.value)}
                />

                <TextField
                  disabled={isLoading}
                  label="Reference"
                  fullWidth
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                />
              </>
            ) : null}
          </>
        ) : null}

        <TextField
          disabled={isLoading}
          label="Remarks"
          fullWidth
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />

        <Button
          disabled={isLoading || isDateError1 || isDateError2}
          fullWidth
          variant="contained"
          size="large"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Container>
  );
}

export default ModifyPayrollTicket;
