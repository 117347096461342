import { useQuery } from 'react-query';
import useAxiosPrivate from './useAxiosPrivate';
import { useEffect, useState } from 'react';
import useAuth from './useAuth';

const useDamages = () => {
  const axiosPrivate = useAxiosPrivate();
  const [unApprove, setunApprove] = useState(0);
  const { role, user, Admins } = useAuth();

  const { data, isLoading, isError, error, refetch } = useQuery(
    'damages',
    () => {
      let url = '/api/v1/damage?populate=yes&limit=none&sort=-createdAt';
      if (!['Super Admin', ...Admins, 'Accounts', 'DO', 'IMS'].includes(role)) {
        url += `&parentTerritory=${user?.employee?.territory?.id}`;
      }
      return axiosPrivate.get(url);
    },
    {
      refetchInterval: 60000,
    },
  );

  useEffect(() => {
    const total = data?.data?.data
      ?.map((val) => {
        return val.isApproved === false;
      })
      .reduce((a, c) => a + c, 0);
    setunApprove(total);
  }, [data?.data?.data]);

  return { data, isLoading, isError, error, refetch, unApprove };
};

export default useDamages;
