import React from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { stringify } from 'qs';
import { useQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import roundToDigit from '../../utils/roundToDigit';

function PayrollLedger() {
  const [query] = useSearchParams();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, user, Admins } = useAuth();

  const [employeeList, setEmployeeList] = React.useState([]);
  const [employee, setEmployee] = React.useState({});
  const [due, setDue] = React.useState(0);

  const [deleteShow, setDeleteShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState('');

  React.useEffect(() => {
    setHeader({ title: 'Payroll Ledger' });
  }, []);

  React.useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&sort=code')
      .then((res) => {
        setEmployeeList(res?.data?.data);
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  }, []);

  React.useEffect(() => {
    const e = query.get('employee');
    if (e) {
      setEmployee(employeeList.find((emp) => emp?.id === e));
    } else {
      setEmployee(user?.employee);
    }
  }, [employeeList, query, user?.employee]);

  const handleDeleteSelection = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };
  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/employee-ledger/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId('');
        setDeleteShow(false);
      });
  };

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['lifting-commitment', employee?.id],
    () => {
      let url = `/api/v1/employee-ledger?populate=yes&limit=none&sort=-dateOfEntry&employee=${employee?.id}`;

      return axiosPrivate.get(url).then((res) => {
        const entries = [];
        let sum = 0;

        res?.data?.data?.forEach((i) => {
          sum += i?.approvedAmount;
          entries.push({ ...i });
        });

        setDue(roundToDigit(sum));
        return entries;
      });
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value, row }) => (
          <span style={{ textTransform: 'capitalize' }}>
            {value} {row?.original?.isTicket ? '💳' : null}
          </span>
        ),
      },
      {
        Header: 'Issue Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Issued Amount',
        accessor: 'entryAmount',
        Cell: ({ value }) => `${value}Tk`,
        aggregate: 'sum',
      },
      {
        Header: 'Confirm Date',
        accessor: 'dateOfApprove',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Confirmed Amount',
        accessor: 'approvedAmount',
        Cell: ({ value }) => `${value}Tk`,
        aggregate: 'sum',
      },
      {
        Header: 'Issued By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Payment Mode',
        accessor: 'paymentMode',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Bank Branch',
        accessor: 'bankBranch',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'Transaction ID/Code',
        accessor: 'transactionID',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (['Super Admin'].includes(role)) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  component={Link}
                  to={`/payroll-ticket/edit/${row?.original?.id}?${stringify({
                    redirect: `/payroll-ledger?employee=${row?.original?.employee?.id}`,
                  })}`}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleDeleteSelection(row?.original?.id)}
                  className="Table_icon_btn delete">
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }

          return null;
        },
      },
    ],
    [role],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Stack
        direction={{ sm: 'row' }}
        sx={{
          pb: 1,
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {['Super Admin', ...Admins].includes(role) ? (
          <Autocomplete
            disabled={employeeList?.length < 1}
            size="small"
            options={employeeList}
            value={employee}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => setEmployee(value)}
            getOptionLabel={(option) =>
              option?.nameCode
                ? `${option?.nameCode} - ${option?.territory?.name}`
                : ''
            }
            renderInput={(params) => <TextField {...params} label="Employee" />}
            sx={{ width: 320 }}
          />
        ) : null}

        {due < 0 ? (
          <Typography variant="h6" color="error.main">
            Owed: {due * -1}
          </Typography>
        ) : (
          <Typography variant="h6" color="success.main">
            Due: {due}
          </Typography>
        )}
      </Stack>

      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        hideAddIcon
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={handleDelete}
        onHide={() => {
          setDeleteId('');
          setDeleteShow(false);
        }}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default PayrollLedger;
