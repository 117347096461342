import React, { useEffect, useState } from 'react';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import useGetCurrentDate from '../../../hooks/useGetCurrentDate';
import TransactionData from './ExpandRowInfo';

function ConfirmDelivery() {
  const location = useLocation();
  const transaction = location?.state?.data;
  const { currentDate } = useGetCurrentDate();
  const navigate = useNavigate();
  const [refetch] = useOutletContext();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const [isLoading, setIsLoading] = useState(false);
  const [isDateError, setIsDateError] = useState(false);

  const [doNumber, setDoNumber] = useState(transaction?.doNumber || '');
  const [coNumber, setCoNumber] = useState(transaction?.coNumber || '');
  const [deliverymanName, setDeliverymanName] = useState(
    transaction?.deliverymanName || '',
  );
  const [deliverymanContact, setDeliverymanContact] = useState(
    transaction?.deliverymanContact || '',
  );
  const [vehicleNumber, setVehicleNumber] = useState(
    transaction?.vehicleNumber || '',
  );
  const [deliveredAt, setDeliveredAt] = useState();

  useEffect(() => {
    if (transaction?.deliveredAt) {
      setDeliveredAt(dayjs(transaction?.deliveredAt));
    } else {
      setDeliveredAt(dayjs(currentDate));
    }
  }, [transaction?.deliveredAt, currentDate]);

  const handleSubmit = () => {
    if (!doNumber) {
      toast.warn('DO Number is required');
      return;
    }
    setIsLoading(true);
    axiosPrivate
      .patch(`/api/v1/transaction/${transaction?.id}/confirm-delivery`, {
        doNumber,
        coNumber,
        deliverymanName,
        deliverymanContact,
        vehicleNumber,
        deliveredAt,
      })
      .then((res) => {
        refetch();
        toast.success(res?.data?.message);
        navigate('/transactions');
      })
      .catch((error) => {
        expired(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <Accordion variant="outlined">
          <AccordionSummary
            expandIcon={<ExpandIcon />}
            sx={{
              '.MuiAccordionSummary-content': {
                flexDirection: 'column',
              },
            }}>
            <Typography variant="h6" gutterBottom>
              Transaction Details
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              divider={<Divider flexItem orientation="vertical" />}>
              <Typography variant="subtitle2">
                Order Date:&nbsp;
                <Typography
                  component="span"
                  variant="inherit"
                  fontWeight="bold">
                  {dayjs(transaction?.orderedAt).format('DD/MM/YYYY')}
                </Typography>
              </Typography>
              <Typography variant="subtitle2">
                Total Quantity:&nbsp;
                <Typography
                  component="span"
                  variant="inherit"
                  fontWeight="bold">
                  {transaction?.totalQuantity}
                </Typography>
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <TransactionData data={transaction} hideActions />
          </AccordionDetails>
        </Accordion>
      </Box>

      <Stack
        component={Paper}
        direction="column"
        gap={3}
        p={2}
        sx={{ maxWidth: 400, margin: 'auto' }}>
        <Typography variant="h5">Confirm Delivery</Typography>
        <TextField
          type="text"
          label="DO Number"
          fullWidth
          value={doNumber}
          onChange={(e) => setDoNumber(e.target.value)}
        />
        <TextField
          type="text"
          label="CO Number"
          fullWidth
          value={coNumber}
          onChange={(e) => setCoNumber(e.target.value)}
        />
        <TextField
          type="text"
          label="Driver Name"
          fullWidth
          value={deliverymanName}
          onChange={(e) => setDeliverymanName(e.target.value)}
        />
        <TextField
          type="number"
          label="Driver Contact"
          fullWidth
          onWheel={(e) => e.target.blur()}
          value={deliverymanContact}
          onChange={(e) => setDeliverymanContact(e.target.value)}
        />
        <TextField
          type="text"
          label="Vehicle Number"
          fullWidth
          value={vehicleNumber}
          onChange={(e) => setVehicleNumber(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            maxDate={currentDate}
            fullWidth
            label="Delivery Time"
            inputFormat="DD/MM/YYYY hh:mm A"
            disableFuture
            views={['month', 'year', 'day', 'hours', 'minutes']}
            value={deliveredAt}
            onError={(reason) => setIsDateError(Boolean(reason))}
            onChange={(newValue) => setDeliveredAt(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>

        <Button
          fullWidth
          size="large"
          variant="contained"
          disabled={isLoading || isDateError}
          onClick={handleSubmit}>
          Confirm Delivery
        </Button>
      </Stack>
    </>
  );
}

export default ConfirmDelivery;
