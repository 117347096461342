import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
  DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ReactToPrint from 'react-to-print';
import { useQuery } from 'react-query';
import ActiveSwitch from '../input/ActiveSwitch';

export default function TaDaPrintModal() {
  const axiosPrivate = useAxiosPrivate();
  const tableRef = useRef(null);

  //   filter states
  const [employees, setEmployees] = React.useState([]);
  const [employee, setEmployee] = useState({});

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );
  const [switchDate, setSwitchDate] = useState(true);

  // Get all employees
  React.useEffect(() => {
    setEmployees([]);
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true&sort=code')
      .then((res) => {
        if (res?.data?.data) {
          setEmployees(res.data.data);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  }, [axiosPrivate]);

  // Get summary data
  const { data, error } = useQuery(
    ['tadaInfoPrint', employee?.id, startDate, endDate],
    () => {
      const url = `/api/v1/report/tada-summary?startDate=${startDate}&endDate=${endDate}&employee=${employee?.id}`;
      return axiosPrivate.get(url).then((res) => {
        const info = res?.data?.data;

        const data = {
          employee: {
            name: info?.employee?.nameCode,
            designation: info?.employee?.role?.employeeRole,
            territoryType: info?.employee?.role?.territoryType?.territoryType,
            territory: info?.employee?.territory?.name,
          },
          entries: [],
        };

        info?.entries.forEach((item) => {
          const acceptedBills =
            item?.insideDearnessAllowance?.accepted +
            item?.outsideDearnessAllowance?.accepted +
            item?.travelAllowance?.accepted +
            item?.hotelBill?.accepted +
            item?.otherBill?.accepted;

          const claimedBills =
            item?.insideDearnessAllowance?.claimed +
            item?.outsideDearnessAllowance?.claimed +
            item?.travelAllowance?.claimed +
            item?.hotelBill?.claimed +
            item?.otherBill?.claimed;

          item.totalBill = {
            acceptedBills: acceptedBills,
            claimedBills: claimedBills,
          };

          data.entries.push(item);
        });

        return data;
      });
    },
  );

  const totalValues = data?.entries?.reduce(
    (a, c) => {
      if (c?.insideDearnessAllowance?.status === 'approved') {
        a.da += c?.insideDearnessAllowance?.accepted;
      }
      if (c?.outsideDearnessAllowance?.status === 'approved') {
        a.exda += c?.outsideDearnessAllowance?.accepted;
      }
      if (c?.travelAllowance?.status === 'approved') {
        a.ta += c?.travelAllowance?.accepted;
      }
      if (c?.hotelBill?.status === 'approved') {
        a.hotelBill += c?.hotelBill?.accepted;
      }
      if (c?.otherBill?.status === 'approved') {
        a.otherBill += c?.otherBill?.accepted;
      }
      a.totalBill += c?.totalBill?.acceptedBills;
      return a;
    },
    { da: 0, exda: 0, ta: 0, hotelBill: 0, otherBill: 0, totalBill: 0 },
  );

  useEffect(() => {
    if (switchDate === true) {
      setStartDate(dayjs(startDate).startOf('month').format('YYYY-MM-DD'));
      setEndDate(dayjs(startDate).endOf('month').format('YYYY-MM-DD'));
    }
  }, [switchDate, startDate]);

  return (
    <>
      {/* filter content goes here */}
      <Box>
        <Stack direction="row" justifyContent="center" mb={3}>
          <ActiveSwitch
            value={switchDate}
            setValue={setSwitchDate}
            primaryText="Month"
            secondaryText="Date"
            color="primary"
          />
        </Stack>

        <Stack direction="column" gap={2}>
          <Autocomplete
            fullWidth
            loading={employees?.length < 1}
            options={employees}
            value={employee}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setEmployee(value);
            }}
            getOptionLabel={(option) =>
              option?.name
                ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
                : ''
            }
            renderInput={(params) => (
              <TextField {...params} label="Employee" size="small" fullWidth />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {switchDate ? (
              <DatePicker
                label="Select Month"
                views={['month', 'year']}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(
                    dayjs(newValue).startOf('month').format('YYYY-MM-DD'),
                  );
                  setEndDate(
                    dayjs(newValue).endOf('month').format('YYYY-MM-DD'),
                  );
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            ) : (
              <>
                <DesktopDatePicker
                  label="From"
                  inputFormat="DD/MM/YYYY"
                  fullWidth
                  value={startDate}
                  onChange={(newValue) =>
                    setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />

                <DesktopDatePicker
                  label="To"
                  fullWidth
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newValue) =>
                    setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
                  }
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </>
            )}
          </LocalizationProvider>
        </Stack>

        <Stack direction="row" justifyContent="end" mt={2}>
          <ReactToPrint
            trigger={() => {
              return (
                <Button
                  variant="contained"
                  sx={{ px: 5 }}
                  disabled={!error ? false : true}>
                  Print
                </Button>
              );
            }}
            content={() => tableRef.current}
          />
        </Stack>
      </Box>
      {/* error content goes here */}
      <Typography color="error" textAlign="center" mt={2}>
        {error?.response?.data?.message}
      </Typography>

      {/* print content goes here --------------- */}
      <Box style={{ display: 'none' }}>
        <Box ref={tableRef}>
          {/* head section */}
          <Typography textAlign="center" variant="subtitle1">
            TA DA Summary
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Title label="Name" value={data?.employee?.name} />
              <Title label="Designation" value={data?.employee?.designation} />
            </Box>
            <Box>
              {switchDate ? (
                <Title
                  label="Month"
                  value={`${dayjs(startDate).format('MMMM YYYY')}`}
                />
              ) : (
                <Title
                  label="Date"
                  value={`${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(
                    endDate,
                  ).format('DD/MM/YYYY')}`}
                />
              )}
              <Title
                label={data?.employee?.territoryType}
                value={data?.employee?.territory}
              />
            </Box>
          </Stack>
          {/* body section */}

          <TableContainer sx={styles}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell rowSpan={2}>Travel Date</TableCell>
                  <TableCell rowSpan={2}>Travel Path</TableCell>
                  {/* <TableCell colSpan={2} height={60}>
                    Name of Visited Point
                  </TableCell> */}
                  <TableCell colSpan={2}>DA</TableCell>
                  <TableCell rowSpan={2}>TA</TableCell>
                  <TableCell rowSpan={2}>Hotel Bill</TableCell>
                  <TableCell rowSpan={2}>Others Bill</TableCell>
                  <TableCell rowSpan={2}>Total Bill</TableCell>
                  <TableCell rowSpan={2}>Remarks</TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell height={10}>Travel From</TableCell>
                  <TableCell height={10}>Travel To</TableCell> */}
                  <TableCell>HQ</TableCell>
                  <TableCell>Ex-HQ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    td: {
                      fontWeight: 'bold',
                      color: 'green',
                      textAlign: 'end',
                    },
                  }}>
                  <TableCell colSpan={2}>Total Approved Bill</TableCell>
                  {/* <TableCell colSpan={3}>Total</TableCell> */}
                  <TableCell>{totalValues?.da}</TableCell>
                  <TableCell>{totalValues?.exda}</TableCell>
                  <TableCell>{totalValues?.ta}</TableCell>
                  <TableCell>{totalValues?.hotelBill}</TableCell>
                  <TableCell>{totalValues?.otherBill}</TableCell>
                  <TableCell>{totalValues?.totalBill}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {data?.entries?.map((en, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {dayjs(en?.travelDate).format('DD/MM/YYYY')}
                      </TableCell>
                      <TableCell>{en?.travelPath}</TableCell>
                      {/* <TableCell>{en?.travelStart}</TableCell>
                      <TableCell>{en?.travelEnd}</TableCell> */}
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {CheckStatus(
                          en?.insideDearnessAllowance?.status,
                          en?.insideDearnessAllowance?.claimed,
                          en?.insideDearnessAllowance?.accepted,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {CheckStatus(
                          en?.outsideDearnessAllowance?.status,
                          en?.outsideDearnessAllowance?.claimed,
                          en?.outsideDearnessAllowance?.accepted,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {CheckStatus(
                          en?.travelAllowance?.status,
                          en?.travelAllowance?.claimed,
                          en?.travelAllowance?.accepted,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {CheckStatus(
                          en?.hotelBill?.status,
                          en?.hotelBill?.claimed,
                          en?.hotelBill?.accepted,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {CheckStatus(
                          en?.otherBill?.status,
                          en?.otherBill?.claimed,
                          en?.otherBill?.accepted,
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ whiteSpace: 'nowrap', textAlign: 'end' }}>
                        {en?.totalBill?.acceptedBills
                          ? CheckStatus(
                              'approved',
                              en?.totalBill?.claimedBills,
                              en?.totalBill?.acceptedBills,
                            )
                          : CheckStatus(
                              'pending',
                              en?.totalBill?.claimedBills,
                              en?.totalBill?.acceptedBills,
                            )}
                      </TableCell>
                      <TableCell>{en?.remarks}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}

const CheckStatus = (status, claimed, accepted) => {
  switch (status) {
    case 'pending':
      return claimed;
    case 'approved':
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <span
            style={{
              color: 'green',
              fontWeight: 'bold',
            }}>
            {accepted}
          </span>
          {` / ${claimed}`}
        </div>
      );
    case 'rejected':
      return (
        <div style={{ whiteSpace: 'nowrap' }}>
          <span
            style={{
              color: 'red',
              textDecoration: 'line-through',
            }}>
            {claimed}
          </span>
        </div>
      );
    default:
      return '-';
  }
};

function Title({ label, value }) {
  return (
    <Typography fontSize={14}>
      <b>{label}: </b>
      {value}
    </Typography>
  );
}

const styles = {
  marginTop: '10px',
  table: {
    th: {
      padding: 0.5,
      border: '1px solid #000',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      fontSize: 14,
      textAlign: 'center',
    },
    td: {
      padding: 0.5,
      border: '1px solid #000',
      fontSize: 14,
    },
  },
};
