import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

/**
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Function} props.onAccept
 * @param {Function} props.onClose
 * @returns
 */
function RedirectDialog({ open, onAccept, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        })}>
        Submit TA DA Form?
      </DialogTitle>

      <DialogContent>
        <DialogContentText
          color="text.primary"
          sx={{ fontSize: '1.125rem', pt: 2 }}>
          Would you like to submit TA DA from for this visit now?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="error"
          size="large"
          variant="contained"
          sx={{ flexGrow: 1 }}>
          NO
        </Button>

        <Button
          onClick={onAccept}
          color="success"
          size="large"
          variant="contained"
          sx={{ flexGrow: 4 }}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RedirectDialog;
