/**
 * Delete keys from object\
 * Deletes the provided keys and keeps other keys
 * @param {Object} object The object to trim from
 * @param {Array<String>} keys Array of keys to trim
 * @returns {Object} Trimmed `object`
 */
export function deleteKeys(object, keys = []) {
  const newObj = {};
  Object.keys(object).forEach((el) => {
    if (!keys.includes(el)) {
      newObj[el] = object[el];
    }
  });
  return newObj;
}

/**
 * Delete keys from object\
 * Keeps the provided keys and deletes other keys
 * @param {Object} object The object to trim from
 * @param {Array<String>} keys Array of keys to trim
 * @returns {Object} Trimmed `object`
 */
export function keepKeys(object, keys = []) {
  const newObj = {};
  Object.keys(object).forEach((el) => {
    if (keys.includes(el)) {
      newObj[el] = object[el];
    }
  });
  return newObj;
}
