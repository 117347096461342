import React from 'react';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  IconButton,
  InputAdornment,
  Table as MuiTable,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import Modals from '../../component/Modals';
import { DateRangeColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';

function PayrollTickets() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const { role, userId, Admins } = useAuth();

  React.useEffect(() => {
    setHeader({ title: 'Pay Tickets' });
  }, []);

  const [editData, setEditdata] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState(null);

  const handleAdd = () => {
    navigate('/payroll-ticket/add');
  };
  const handleEdit = (id) => {
    navigate(`/payroll-ticket/edit/${id}`);
  };
  const handleConfirmSelection = (d) => {
    setEditdata({
      ...d,
      approvedAmount: typeof d?.entryAmount === 'number' ? d.entryAmount : 0,
    });
  };
  const handleDeleteSelection = (id) => {
    setDeleteId(id);
  };
  const handleConfirm = () => {
    axiosPrivate
      .patch(`/api/v1/employee-ledger/${editData?.id}`, {
        approvedAmount: editData?.approvedAmount,
        reference: editData?.reference,
        approvedBy: userId,
        dateOfApprove: new Date(),
      })
      .then(() => {
        toast.success('Ticket has been confirmed');
        setEditdata(null);
        refetch();
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };
  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/employee-ledger/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteId(null);
      });
  };

  const { data, isLoading, error, isError, refetch } = useQuery(
    ['pay-tickets'],
    () => {
      let url =
        '/api/v1/employee-ledger?populate=yes&limit=none&sort=-dateOfEntry&isTicket=true';

      if (!['Super Admin', ...Admins].includes(role)) {
        url += `&employee=${userId}`;
      }

      return axiosPrivate.get(url).then((res) =>
        res?.data?.data?.map((i) => ({
          ...i,
          territoryLabel: `${i?.employee?.territory?.territoryType?.territoryType}: ${i?.employee?.territory?.name}`,
        })),
      );
    },
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'territoryLabel',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ value }) => (
          <span style={{ textTransform: 'capitalize' }}>{value}</span>
        ),
      },
      {
        Header: 'Issued To',
        accessor: 'employee.nameCode',
      },
      {
        Header: 'Issue Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Issued Amount',
        accessor: 'entryAmount',
        Cell: ({ value }) => `${value} Tk`,
        aggregate: 'sum',
      },
      {
        Header: 'Confirm Date',
        accessor: 'dateOfApprove',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Confirmed Amount',
        accessor: 'approvedAmount',
        Cell: ({ value }) => `${value} Tk`,
        aggregate: 'sum',
      },
      {
        Header: 'Remarks',
        accessor: 'remarks',
      },
      {
        Header: 'Reference',
        accessor: 'reference',
      },
      {
        Header: 'Issued By',
        accessor: 'recordedBy.name',
      },
      {
        Header: 'Confirmed By',
        accessor: 'approvedBy.name',
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          if (['Super Admin', ...Admins].includes(role)) {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton
                  disabled={Boolean(row?.original?.approvedBy)}
                  onClick={() => handleConfirmSelection(row?.original)}
                  className="Table_icon_btn">
                  <BeenhereIcon />
                </IconButton>
                {['Super Admin'].includes(role) ? (
                  <>
                    <IconButton
                      onClick={() => handleEdit(row?.original?.id)}
                      className="Table_icon_btn">
                      <BorderColorIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeleteSelection(row?.original?.id)}
                      className="Table_icon_btn delete">
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : null}
              </Stack>
            );
          }

          return null;
        },
      },
    ],
    [role],
  );

  if (isError) {
    expired(error);
  }

  if (pathname !== '/payroll-ticket') {
    return <Outlet context={[refetch]} />;
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        onAddButtonClick={handleAdd}
        addButtonTooltip="Add Pay Ticker"
        filename="Pay_Tickets"
      />

      <Modals
        title="Confirm Ticket"
        show={Boolean(editData?.id)}
        onHide={() => setEditdata(null)}
        onSave={handleConfirm}>
        <MuiTable size="small">
          <TableBody>
            <TableRow>
              <TableCell>Type:</TableCell>
              <TableCell sx={{ textTransform: 'capitalize' }}>
                {editData?.type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issued To:</TableCell>
              <TableCell>{editData?.employee?.nameCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issue Date:</TableCell>
              <TableCell>
                {dayjs(editData?.dateOfEntry).format('DD/MM/YYYY')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Issued Amount:</TableCell>
              <TableCell>{editData?.entryAmount} Tk</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Remarks:</TableCell>
              <TableCell>{editData?.remarks}</TableCell>
            </TableRow>
          </TableBody>
        </MuiTable>

        <Stack sx={{ pt: 3, gap: 2 }}>
          <TextField
            disabled={isLoading}
            label="Confirm Amount"
            fullWidth
            type="number"
            value={editData?.approvedAmount || 0}
            onChange={(e) =>
              setEditdata({
                ...editData,
                approvedAmount: e.target.value,
              })
            }
            onWheel={(e) => e.target.blur()}
            InputProps={{
              endAdornment: <InputAdornment position="end">Tk</InputAdornment>,
            }}
          />

          <TextField
            disabled={isLoading}
            label="Reference"
            fullWidth
            value={editData?.reference || ''}
            onChange={(e) =>
              setEditdata({
                ...editData,
                reference: e.target.value,
              })
            }
          />
        </Stack>
      </Modals>

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteId}
        onSave={handleDelete}
        onHide={() => setDeleteId(null)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </>
  );
}

export default PayrollTickets;
