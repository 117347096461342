import { Box, IconButton, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ConfirmModal from '../../../component/ConfirmModal';
import { toast } from 'react-toastify';
import useExpiredSession from '../../../hooks/useExpiredSession';
import { MultiSelectColumnFilter } from '../../../component/Table/SelectColumnFilter';
import { useQuery } from 'react-query';

function ViewTransactionsType() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const navigate = useNavigate();
  const location = useLocation();

  // get transaction type
  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'productCat',
    () => {
      return axiosPrivate.get(
        '/api/v1/transaction/type?populate=yes&limit=none&sort=transactionType',
      );
    },
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Transaction type',
        accessor: 'transactionType',
      },
      {
        Header: 'Sender',
        accessor: 'senderType.customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Receiver',
        accessor: 'receiverType.customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Function',
        accessor: 'function',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ row }) => (
          <span style={{ textTransform: 'capitalize' }}>
            {`${row?.original?.function} (${row?.original?.productCondition})`}
          </span>
        ),
      },
      {
        Header: 'Ledger action',
        Cell: ({ row }) => (
          <span>
            Sender :&emsp;
            <span
              style={{
                textTransform: 'capitalize',
                color:
                  row?.original?.senderLedgerAction === 'debit'
                    ? 'red'
                    : row?.original?.senderLedgerAction === 'credit'
                    ? 'green'
                    : 'inherit',
              }}>
              {row?.original?.senderLedgerAction}
            </span>
            <br />
            Receiver :&ensp;
            <span
              style={{
                textTransform: 'capitalize',
                color:
                  row?.original?.receiverLedgerAction === 'debit'
                    ? 'red'
                    : row?.original?.receiverLedgerAction === 'credit'
                    ? 'green'
                    : 'inherit',
              }}>
              {row?.original?.receiverLedgerAction}
            </span>
          </span>
        ),
      },
      {
        Header: 'Price Type',
        accessor: 'priceType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ row }) => (
          <span style={{ textTransform: 'capitalize' }}>
            {row?.original?.priceType}
            <br />
            Discount :&nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {row?.original?.discountPercent}%
            </span>
          </span>
        ),
      },
      {
        Header: 'Offer',
        accessor: 'applyOffer',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        disableFilters: true,
      },
      {
        Header: 'Auto pay',
        accessor: 'autoUpdatePayment',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        disableFilters: true,
      },
      {
        Header: 'Auto deliver',
        accessor: 'autoUpdateDelivery',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        disableFilters: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        disableFilters: true,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          const [deleteShow, setDeleteShow] = useState(false);
          // delete
          const handleDelete = (id) => {
            setDeleteShow(false);
            axiosPrivate
              .delete(`/api/v1/transaction/type/${id}`)
              .then((res) => {
                if (res.status === 204) {
                  toast.success('Document was deleted');
                  refetch();
                }
              })
              .catch((error) => {
                toast.warn(error?.response?.data?.message);
              });
          };
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Link
                  to={`/settings/transaction-type/edit/${row?.original?.id}`}
                  className="Table_icon_btn">
                  <IconButton>
                    <BorderColorIcon />
                  </IconButton>
                </Link>
                <Box>
                  <IconButton
                    onClick={() => setDeleteShow(true)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                    title="Are you sure?"
                    subtitle="You won't be able to revert this!"
                    show={deleteShow}
                    onSave={() => handleDelete(row?.original?.id)}
                    onHide={() => setDeleteShow(false)}
                    actionButtonLabel="Delete"
                    actionButtonColor="error"
                  />
                </Box>
              </Stack>
            </>
          );
        },
      },
    ],
    [],
  );

  const handleAddTranClick = () => {
    navigate('/settings/transaction-type/add', {
      state: { title: 'Add Transactions Type' },
    });
  };

  if (isError) {
    expired(error);
  }
  return (
    <>
      {location.pathname !== '/settings/transaction-type' ? (
        <Outlet context={[refetch]} />
      ) : (
        <Table
          columns={COLUMNS}
          data={data?.data?.data || []}
          filename="transaction_type"
          addButtonTooltip="Add new transaction type"
          isLoading={isLoading || isFetching}
          onAddButtonClick={handleAddTranClick}
        />
      )}
    </>
  );
}

export default ViewTransactionsType;
