import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Box, IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import * as React from 'react';

export default function CustomPopover({ onClose, onOpen, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    onOpen();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <IconButton
        // disabled
        color="primary"
        className="Table_icon_btn"
        aria-describedby={id}
        onClick={handleClick}>
        <DownloadForOfflineIcon style={{ fontSize: '36px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Box>{children}</Box>
      </Popover>
    </Box>
  );
}
