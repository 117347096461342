import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import Modals from '../../component/Modals';
import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import ExpandTable from '../../component/Table/ExpandTable/ExpandTable';

export default function ExpandRow({ data, refetch }) {
  const axiosPrivate = useAxiosPrivate();
  const { role } = useAuth();
  const [approvedAmount, setApprovedAmount] = useState(null);
  const [showApproveModal, setApproveModal] = useState(false);

  const productData = data?.products;
  const productColumns = [
    {
      label: 'Product Name',
      key: '',
      render: (value, row) => `${row.product.name} (${row.product.code})`,
    },
    {
      label: 'Damaged Quantity',
      key: 'damagedQuantity',
    },
    {
      label: 'Damage Description',
      key: 'damageDescription',
    },
  ];

  const handleSubmit = () => {
    axiosPrivate
      .patch(`/api/v1/damage/${data?.id}/approve`, {
        approvedAmount,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        setApproveModal(false);
        refetch();
      })
      .catch((error) => {
        toast.warn(error.response.data.message);
      });
  };

  return (
    <Box py={2} px={1}>
      <ExpandTable columns={productColumns} data={productData} />

      <Typography variant="subtitle2" pt={1}>
        Recorded by : {data?.recordedBy?.nameCode}
      </Typography>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}>
        {role === 'Super Admin' || role === 'DO' ? (
          <Button
            disabled={data?.isApproved ? true : false}
            variant="outlined"
            onClick={() => setApproveModal(true)}>
            Approve
          </Button>
        ) : null}
      </Stack>

      <Modals
        title="Approve Amount"
        show={showApproveModal}
        onSave={() => setApproveModal(false)}
        onHide={() => setApproveModal(false)}
        width="lg"
        form>
        <Stack gap={2}>
          <Stack direction="row" gap={3} justifyContent="space-between">
            <Typography>Claimed Amount</Typography>
            <Typography fontWeight="bold">{data?.claimedAmount}</Typography>
          </Stack>
          <TextField
            label="Approve Amount"
            onWheel={(e) => e.target.blur}
            value={approvedAmount}
            onChange={(e) => setApprovedAmount(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </Modals>
    </Box>
  );
}
