import React, { useEffect, useState } from 'react';
import {
  Grid,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

export default function ModifiedProductCat() {
  const [refetch] = useOutletContext();
  const params = useParams();
  const id = params.productCatID;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  // form data sates
  const [productCategory, setProductCategory] = useState('');
  const [codePrefix, setCodePrefix] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/product/category/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          setProductCategory(data?.productCategory);
          setCodePrefix(data?.codePrefix);
          setDescription(data?.description);
          setIsActive(data?.isActive);
        })
        .catch((error) => console.warn(error))
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setProductCategory('');
      setCodePrefix('');
      setDescription('');
      setIsActive(true);
    }
  }, [id, axiosPrivate]);

  const handleSubmit = () => {
    axiosPrivate({
      method: id ? 'patch' : 'post',
      url: id ? `/api/v1/product/category/${id}` : '/api/v1/product/category',
      data: {
        productCategory,
        codePrefix,
        description,
        isActive,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        navigate('/settings/product-category');
        refetch();
      })
      .catch((error) => toast.warn(error?.response?.data?.message));
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading ...</Typography>
      </Stack>
    );
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Product Category"
            variant="outlined"
            fullWidth
            value={productCategory}
            onChange={(e) => setProductCategory(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Code Prefix"
            variant="outlined"
            fullWidth
            value={codePrefix}
            onChange={(e) => setCodePrefix(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            id="outlined-basic"
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label={isActive ? 'Active' : 'Inactive'}
            value="active"
            control={
              <Switch
                color="primary"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            }
          />
        </Grid>
      </Grid>
      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/settings/product-category')}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          sx={{ px: 3 }}
          onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
