import { Stack, styled } from '@mui/material';

export const ProfileInfoBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  cursor: 'pointer',
  marginBottom: '20px',
  flexDirection: 'row',
  gap: '40px',
  justifyContent: 'space-between',
  img: {
    width: '38px',
    height: '38px',
    objectFit: 'contain',
    borderRadius: '50%',
  },
  h6: {
    fontSize: '1rem',
    lineHeight: 'initial',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '25px',
    h6: {
      fontSize: '0.8rem',
    },
    '.email': {
      fontSize: '0.6rem',
    },
    '.link': {
      fontSize: '16px',
    },
  },
}));

export const AllLoginProfileBox = styled(Stack)(({ theme }) => ({
  maxHeight: '215px',
  overflowY: 'auto',
}));
