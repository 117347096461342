import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';

function ProductSkeleton() {
  return (
    <Box mt={4}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={35} />
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={70} />
      </Stack>
    </Box>
  );
}

export default ProductSkeleton;
