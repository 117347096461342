import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useCollections from '../../hooks/useCollections';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import ExpandRow from './ExpandRow';

export default function Collections() {
  const navigate = useNavigate();
  const location = useLocation();
  const { role, userId, Admins, SalesManagers, SalesOfficers } = useAuth();
  const { setHeader } = useHeader();
  const expired = useExpiredSession();
  const axiosPrivate = useAxiosPrivate();

  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  // filter states
  const [depositStartDate, setDepositStartDate] = useState(
    dayjs().subtract(1, 'week').startOf('month'),
  );
  const [depositEndDate, setDepositEndDate] = useState(dayjs().endOf('month'));

  const [entryStartDate, setEntryStartDate] = useState(null);
  const [entryEndDate, setEntryEndDate] = useState(null);

  useEffect(() => {
    setHeader({ title: 'Collections' });
  }, []);

  // get Collection
  const { res, isLoading, isFetching, isError, error, refetch, pendingCount } =
    useCollections({
      depositStartDate: depositStartDate?.isValid()
        ? depositStartDate?.toISOString()
        : '',
      depositEndDate: depositEndDate?.isValid()
        ? depositEndDate?.toISOString()
        : '',
      entryStartDate: entryStartDate?.isValid()
        ? entryStartDate?.toISOString()
        : '',
      entryEndDate: entryEndDate?.isValid() ? entryEndDate?.toISOString() : '',
    });

  const handleAdd = () => {
    navigate('/collections/add');
  };

  const handleEdit = (id) => {
    navigate(`/collections/edit/${id}`);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/ledger/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const depositDateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={depositStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setDepositStartDate(dayjs(e.target.value).startOf('date'));
            setEntryStartDate(null);
            setEntryEndDate(null);
          }}
        />
        <input
          type="date"
          value={depositEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setDepositEndDate(dayjs(e.target.value).endOf('date'));
            setEntryStartDate(null);
            setEntryEndDate(null);
          }}
        />
      </Stack>
    );
  }, [depositEndDate, depositStartDate]);

  const entryDateRangeFilter = useMemo(() => {
    return (
      <Stack>
        <input
          type="date"
          value={entryStartDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryStartDate(dayjs(e.target.value).startOf('date'));
            setDepositStartDate(null);
            setDepositEndDate(null);
          }}
        />
        <input
          type="date"
          value={entryEndDate?.format('YYYY-MM-DD') || ''}
          onChange={(e) => {
            setEntryEndDate(dayjs(e.target.value).endOf('date'));
            setDepositStartDate(null);
            setDepositEndDate(null);
          }}
        />
      </Stack>
    );
  }, [entryStartDate, entryEndDate]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        hidePrint: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        id: 'ID',
        Header: 'Full ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'Col. ID',
        accessor: 'shortId',
      },
      {
        Header: 'Deposit Date',
        accessor: 'dateOfDeposit',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: depositDateRangeFilter,
      },
      {
        Header: 'Entry Date',
        accessor: 'dateOfEntry',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: entryDateRangeFilter,
      },
      {
        Header: 'Depositor',
        accessor: 'customer.nameCode',
        Cell: ({ value, row }) => {
          const count = res?.depoCount[value]?.count;
          const color = res?.depoCount[value]?.color;

          if (value) {
            return (
              <Box
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                onClick={() =>
                  [...SalesManagers, ...SalesOfficers].includes(role)
                    ? navigate(
                        `/customers/transaction/${row?.original?.customer?.id}`,
                        {
                          state: { title: 'Customers Transaction' },
                        },
                      )
                    : navigate(
                        `/customers/report-ledger/${row?.original?.customer?.id}`,
                        {
                          state: { title: 'Customers Ledger' },
                        },
                      )
                }>
                {count > 1 ? (
                  <span style={{ color: color }}>
                    {`${value} `}
                    <span
                      style={{
                        borderRadius: 8,
                        color: 'white',
                        backgroundColor: color,
                      }}>
                      &nbsp;{count}&nbsp;
                    </span>
                  </span>
                ) : (
                  value
                )}
              </Box>
            );
          }
          return '-';
        },
      },
      {
        Header: 'Post Under',
        accessor: 'postUnder.nameCode',
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <Stack
                direction={'row'}
                gap={1}
                alignItems={'center'}
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  },
                }}
                onClick={() =>
                  [...SalesManagers, ...SalesOfficers].includes(role)
                    ? navigate(
                        `/customers/transaction/${row?.original?.postUnder?.id}`,
                        {
                          state: { title: 'Customers Transaction' },
                        },
                      )
                    : navigate(
                        `/customers/report-ledger/${row?.original?.postUnder?.id}`,
                        {
                          state: { title: 'Customers Ledger' },
                        },
                      )
                }>
                {value}
              </Stack>
            );
          }
          return '-';
        },
      },
      {
        Header: 'Location',
        accessor: 'location',
        Cell: ({ value }) => {
          const locations = value.split('; ');
          return (
            <>
              {locations[0]}
              <br />
              {locations[1]}
            </>
          );
        },
      },
      {
        Header: 'Entry Amount',
        accessor: 'entryAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Approved Amount',
        accessor: 'approvedAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Online Charge',
        accessor: 'costAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Incentive Amount',
        id: 'Incentive_bonusAmount',
        accessor: 'bonusAmount',
        aggregate: 'sum',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
        Cell: ({ value, row }) => {
          if (!value) {
            return row?.original?.paymentMode;
          }
          return <span title={value}>{row?.original?.bankNameX || value}</span>;
        },
      },
      {
        Header: 'Trx. Code',
        accessor: 'transactionID',
      },
      {
        Header: 'Recorded By',
        accessor: 'recordedBy.nameCode',
      },
      {
        Header: 'Action',
        id: 'Action',
        hidePrint: true,
        Cell: ({ row }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Tooltip title="Transactions History">
                  <IconButton
                    component={Link}
                    className="Table_icon_btn"
                    to={`/transactions?name=${row?.original?.customer?.name}`}>
                    <HistoryIcon />
                  </IconButton>
                </Tooltip>

                {(role !== 'Admin' &&
                  ['Super Admin', ...Admins, 'Accounts', 'DO'].includes(
                    role,
                  )) ||
                row?.original?.recordedBy?.id === userId ? (
                  <>
                    <IconButton
                      onClick={() => handleEdit(row?.original?.id)}
                      className="Table_icon_btn">
                      <BorderColorIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => handleDeleteShow(row?.original?.id)}
                      className="Table_icon_btn delete">
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : null}
              </Stack>
            </>
          );
        },
      },
    ],
    [depositDateRangeFilter, entryDateRangeFilter, res?.depoCount, role],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/collections' ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={res?.res || []}
            filename="All_Collection"
            expandRow={ExpandRow}
            onAddButtonClick={handleAdd}
            pendingCount={pendingCount}
            fullHeight={true}
            noWrap={true}
            isLoading={isLoading || isFetching}
          />
          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={deleteShow}
            onSave={() => handleDelete()}
            onHide={() => setDeleteShow(false)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}

      {/* {showModal && (
        <EditModal
          showModal={showModal}
          data={data}
          refetch={refetch}
          setShowModal={setShowModal}
        />
      )} */}
    </>
  );
}
