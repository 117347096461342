import dayjs from 'dayjs';
import {
  Box,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ExpandRowInfo({ data }) {
  return (
    <>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={2}
        justifyContent={'center'}
        gap={2}
        className="ExpandTableMain">
        <Box flexGrow={1} component={Paper}>
          <Typography sx={caption}>Basic Information</Typography>
          <Table cellPadding={0} cellSpacing={0} border={0}>
            <TableRow>
              <TableCell>
                <b>Full ID</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.isActive ? 'Active' : 'Inactive'} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Name</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Code</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.code}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Employee Role</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.role?.employeeRole}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Location</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.territory?.name} ({data?.territory?.code})
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Date Of Join</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.dateOfJoin
                  ? dayjs(data.dateOfJoin).format('DD/MM/YYYY')
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Date Of Resign</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.dateOfResign
                  ? dayjs(data.dateOfResign).format('DD/MM/YYYY')
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Date Of Birth</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>
                {data?.dateOfBirth
                  ? dayjs(data.dateOfBirth).format('DD/MM/YYYY')
                  : ''}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>National ID</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.nationalID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Stack direction={'row'} gap={1} alignItems={'center'}>
                  <b>Educational Qualification </b>
                  {data?.isEducationVerified ? (
                    <Tooltip title="Verified">
                      <CheckCircleIcon
                        fontSize="small"
                        sx={{ color: 'success.light' }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Unverified">
                      <CancelIcon
                        fontSize="small"
                        sx={{ color: 'error.light' }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.educationalQualification} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Present Address</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.presentAddress}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Permanent Address</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.permanentAddress}</TableCell>
            </TableRow>
          </Table>
        </Box>
        <Box flexGrow={1} component={Paper}>
          <Typography sx={caption}>Contact Information</Typography>
          <Table cellPadding={0} cellSpacing={0} border={0}>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Mobile No.</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.contactNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Social</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.socialMedia}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Reference</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.reference}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Referral Mobile No.</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.referralNumber}</TableCell>
            </TableRow>
          </Table>
        </Box>
        <Box flexGrow={1} component={Paper}>
          <Typography sx={caption}>Salary Information</Typography>
          <Table cellPadding={0} cellSpacing={0} border={0}>
            <TableRow>
              <TableCell>
                <b>Bank Name</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.bankName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Bank Account Number</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.bankAccountNumber}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Salary (Basic)</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.salaryBasic}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Salary (Tada)</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.salaryTravel}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Salary (Mobile)</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.salaryMobile}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Salary (Internet)</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.salaryInternet}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Transaction Mode</b>
              </TableCell>
              <TableCell>
                <b>:</b>
              </TableCell>
              <TableCell>{data?.TableRowansactionMode}</TableCell>
            </TableRow>
          </Table>
        </Box>
      </Stack>
    </>
  );
}

export default ExpandRowInfo;

const caption = {
  textAlign: 'center',
  display: 'block',
  color: '#fff',
  background: (theme) => theme.palette.primary.main,
  padding: '5px',
  fontSize: 14,
};
