import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton } from '@mui/material';
import React from 'react';

export default function ScrollToTop() {
  const [showTopBtn, setShowTopBtn] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="top-to-btm">
      {showTopBtn && (
        <Box sx={{ position: 'fixed', right: '20px', bottom: '65px' }}>
          <IconButton onClick={goToTop}>
            <KeyboardArrowUpIcon
              sx={{
                background: (theme) => theme.palette.primary.main,
                color: '#fff',
                borderRadius: '50%',
              }}
            />
          </IconButton>
        </Box>
      )}
    </div>
  );
}
