import { Box, Typography } from '@mui/material';
import ExpandTable from '../../component/Table/ExpandTable/ExpandTable';

export default function StockExpandRow({ data }) {
  const productsData = data?.products;

  const columns = [
    {
      label: 'Product',
      key: 'product.name',
      render: (value, row) => `${row?.product?.name} (${row?.product?.code})`,
    },
    {
      label: 'Intact',
      key: 'intactQuantity',
      render: (value, row) => {
        const cartonsI = Math.floor(
          row?.intactQuantity / row?.product?.quantityPerCarton,
        );
        const packetsI = row?.intactQuantity % row?.product?.quantityPerCarton;
        let intactQuantityDisplay = `${cartonsI} ctn`;
        if (packetsI) intactQuantityDisplay += ` ${packetsI} pcs`;
        return intactQuantityDisplay;
      },
    },
    {
      label: 'Damaged',
      key: 'damagedQuantity',
      render: (value, row) => {
        const cartonsD = Math.floor(
          row.damagedQuantity / row?.product?.quantityPerCarton,
        );
        const packetsD = row.damagedQuantity % row?.product?.quantityPerCarton;
        let damagedQuantityDisplay = `${cartonsD} ctn`;
        if (packetsD) damagedQuantityDisplay += ` ${packetsD} pcs`;
        return damagedQuantityDisplay;
      },
    },
    {
      label: 'Damage description',
      key: 'damageDescription',
    },
  ];

  return (
    <Box px={2} py={1}>
      <ExpandTable columns={columns} data={productsData} />
      {data?.recordedBy && (
        <Typography variant="subtitle2" sx={{ pt: 2 }}>
          Recorded by: <b>{data?.recordedBy?.nameCode}</b>
        </Typography>
      )}
      {data?.transactionRef && (
        <Typography variant="subtitle2" sx={{ pt: 1 }}>
          Transaction Reference: <b>{data?.transactionRef}</b>
        </Typography>
      )}
      {data?.damageRef && (
        <Typography variant="subtitle2" sx={{ pt: 1 }}>
          Damage Reference: <b>{data?.damageRef}</b>
        </Typography>
      )}
    </Box>
  );
}
