import { Box, Typography } from '@mui/material';
import React from 'react';
import img from '../../assets/images/no_data.svg';

function NoData({ report }) {
  return (
    <>
      <Box sx={{ textAlign: 'start', p: { sm: 2, md: 5 } }}>
        <picture>
          <img
            src={img}
            alt="Data Not Found"
            style={{ maxWidth: report ? '70px' : '200px' }}
          />
        </picture>
        <Typography color="grey">Data Not Found</Typography>
      </Box>
    </>
  );
}

export default NoData;
