import React, { useState } from 'react';
import Modals from '../../component/Modals';
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import useLogout from '../../hooks/useLogout';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

export default function UpdatePasswordModal({ show, onSave, onHide }) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();
  const logout = useLogout();
  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [logInfo, setLogInfo] = React.useState(
    JSON.parse(localStorage.getItem('LoginInfo')) || '',
  );

  const handleLogout = async () => {
    const newLists = { ...logInfo };
    delete newLists[user?.phone];
    localStorage.setItem('LoginInfo', JSON.stringify(newLists));
    setLogInfo(newLists);
    await logout();
    navigate('/login');
  };

  //   update password
  const handleSubmit = () => {
    axiosPrivate
      .patch('/api/v1/auth/update-password', {
        oldPassword: formState.oldPassword,
        newPassword: formState.newPassword,
        confirmPassword: formState.confirmPassword,
      })
      .then((res) => {
        if (res) {
          onSave();
          handleLogout();
          toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.message);
      });
  };
  return (
    <Modals
      title="Update Password"
      fullWidth={true}
      width="xs"
      show={show}
      onSave={onSave}
      onHide={onHide}
      form>
      <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
        <InputLabel>Old Password</InputLabel>
        <Input
          value={formState.oldPassword}
          onChange={(e) =>
            setFormState({ ...formState, oldPassword: e.target.value })
          }
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
        <InputLabel>New Password</InputLabel>
        <Input
          value={formState.newPassword}
          onChange={(e) =>
            setFormState({ ...formState, newPassword: e.target.value })
          }
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="standard" sx={{ mt: 2 }} fullWidth>
        <InputLabel>Confirm Password</InputLabel>
        <Input
          value={formState.confirmPassword}
          onChange={(e) =>
            setFormState({ ...formState, confirmPassword: e.target.value })
          }
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Stack mt={4}>
        <Button variant="contained" onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Modals>
  );
}
