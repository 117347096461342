import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Stack } from '@mui/material';

import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../component/ConfirmModal';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
} from '../../../component/Table/SelectColumnFilter';
import Table from '../../../component/Table/Table';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../hooks/useExpiredSession';
import ExpandRowInfo from './ExpandRowInfo';
import useHeader from '../../../hooks/useHeader';
import getRandomColorName from '../../../utils/getRandomColorName';
import CopyClipboard from '../../../component/CopyClipboard';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import useAuth from '../../../hooks/useAuth';

export default function AllEmployees() {
  const { role, Admins } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  scroll.scrollToTop(0);
  useEffect(() => {
    setHeader({ title: 'Employees' });
  }, []);

  // get All Employee Data

  const { data, isLoading, isFetching, isError, error, refetch } = useQuery(
    'AllEmployee',
    () => {
      return axiosPrivate
        .get('/api/v1/employee?populate=yes&limit=none&sort=-createdAt')
        .then((res) => {
          const d = [];

          const accCount = {};

          res?.data?.data?.forEach((item) => {
            const i = {
              ...item,
              locationInfo: `${item?.territory?.territoryType?.territoryType}: ${item?.territory?.name} (${item?.territory?.code})`,
            };

            const hasAcc = Boolean(
              item?.bankAccountNumber &&
                item?.bankAccountNumber !== 'undefined',
            );

            if (hasAcc) {
              if (accCount[item?.bankAccountNumber]?.count) {
                accCount[item?.bankAccountNumber].count += 1;
              } else {
                accCount[item?.bankAccountNumber] = {
                  count: 1,
                  color: getRandomColorName(),
                };
              }
            }

            d.push(i);
          });
          return { res: d, accCount };
        });
    },
  );

  // add employee
  const handleAdd = () => {
    navigate('/employees/add');
  };
  // edit employee
  const handleEdit = (id) => {
    navigate(`/employees/edit/${id}`);
  };

  // delete operations Functions
  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/employee/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Name & Code',
        accessor: 'nameCode',
      },
      /* {
        Header: 'Name',
        accessor: 'name',
      }, */
      /* {
        Header: 'Code',
        accessor: 'code',
      }, */
      {
        id: 'ID',
        Header: 'ID',
        accessor: 'id',
        hidden: true,
      },
      {
        Header: 'System ID',
        accessor: 'shortId',
        Cell: ({ value, row }) => (
          <CopyClipboard
            text={row?.original?.id}
            toastText={`Copied ${row?.original?.name}'s system ID`}
            title={`Click to copy ${row?.original?.name}'s system ID`}
            typographyProps={{ variant: 'body2' }}>
            {value}
          </CopyClipboard>
        ),
      },
      {
        Header: 'Employee Role',
        accessor: 'role.employeeRole',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Joining Date',
        accessor: 'dateOfJoin',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Leaving Date',
        accessor: 'dateOfResign',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Location',
        accessor: 'locationInfo',
      },
      {
        Header: 'Contact',
        accessor: 'contactNumber',
      },
      {
        Header: 'Email',
        accessor: 'email',
        hidden: true,
      },
      {
        Header: 'Bank',
        accessor: 'bankName',
      },
      {
        Header: 'A/C Number',
        accessor: 'bankAccountNumber',
        Cell: ({ value }) => {
          const count = data?.accCount[value]?.count;
          const color = data?.accCount[value]?.color;
          if (count > 1) {
            return (
              <span style={{ color: color }}>
                {`${value} `}
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: color,
                  }}>
                  &nbsp;{count}&nbsp;
                </span>
              </span>
            );
          }
          return value;
        },
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <>
              {role !== 'Admin' && ['Super Admin', ...Admins].includes(role) ? (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={() => handleEdit(row?.original?.id)}
                    className="Table_icon_btn">
                    <BorderColorIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => handleDeleteShow(row?.original?.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ) : null}
            </>
          );
        },
        disableFilters: true,
      },
    ],
    [data?.accCount],
  );

  // if (isLoading || isFetching) {
  //   return <TableSkeleton />;
  // }

  if (isError) {
    expired(error);
  }

  return (
    <>
      {location.pathname !== '/employees' ? (
        <Outlet context={[refetch]} />
      ) : (
        <>
          <Table
            columns={COLUMNS}
            data={data?.res || []}
            filename="All_Employee_table"
            addButtonTooltip="Add Employee"
            onAddButtonClick={
              role !== 'Admin'
                ? handleAdd
                : () => toast.warn('Function not available')
            }
            expandRow={ExpandRowInfo}
            fullHeight={true}
            isLoading={isLoading || isFetching}
            noWrap
          />

          <ConfirmModal
            title="Are you sure?"
            subtitle="You won't be able to revert this!"
            show={deleteShow}
            onSave={() => handleDelete()}
            onHide={() => setDeleteShow(false)}
            actionButtonLabel="Delete"
            actionButtonColor="error"
          />
        </>
      )}
    </>
  );
}
