import React, { useEffect, useMemo, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, IconButton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  SelectColumnFilterBool,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useDamages from '../../hooks/useDamages';
import useExpiredSession from '../../hooks/useExpiredSession';
import DamClaimModal from './DamClaimModal';
import ExpandRow from './ExpandRow';
import useHeader from '../../hooks/useHeader';
import useAuth from '../../hooks/useAuth';

function Damages() {
  const navigate = useNavigate();
  const { setHeader } = useHeader();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const [editData, setEditData] = useState({});
  // modal
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { role, Admins } = useAuth();

  // get damages
  const { data, isLoading, isFetching, isError, error, refetch, unApprove } =
    useDamages();

  useEffect(() => {
    setHeader({ title: 'Damages' });
  }, []);

  //   modal -------------------------------------------------------
  const handleEditShow = (data) => {
    setEditData(data);
    setShowModal(true);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/damage/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      })
      .finally(() => {
        setDeleteShow(false);
      });
  };

  const handleInfo = (data) => {
    navigate(`/customers/stock/${data?.customer?.id}`, {
      state: { title: 'Customers' },
    });
  };

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'Customer',
        accessor: 'customer.name',
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <>
                <Box
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => handleInfo(row?.original)}>
                  {value} ({row?.original?.customer?.code}) -{' '}
                  {row?.original?.customer?.territory?.name}
                </Box>
              </>
            );
          }
          return '-';
        },
      },
      {
        Header: 'Claim Time',
        accessor: 'claimedAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Claimed Amount',
        accessor: 'claimedAmount',
      },

      {
        Header: 'Approved Amount',
        accessor: 'approvedAmount',
      },

      {
        Header: `Approved`,
        accessor: 'isApproved',
        Cell: ({ value, row }) => {
          if (value) {
            return <CheckCircleIcon color="success" />;
          }
          return <HighlightOffIcon color="error" />;
        },
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          if (role !== 'Admin' && ['Super Admin', ...Admins].includes(role)) {
            return (
              <>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <IconButton
                    onClick={() => handleEditShow(row?.original)}
                    className="Table_icon_btn">
                    <BorderColorIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => handleDeleteShow(row?.original?.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </>
            );
          }

          return null;
        },
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data?.data?.data || []}
        filename="Damages"
        expandRow={ExpandRow}
        expandRowProps={{ refetch }}
        addButtonTooltip="Add a damage"
        pendingCount={unApprove}
        hideAddIcon
        isLoading={isLoading || isFetching}
        fullHeight={true}
      />

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
      {showModal && (
        <DamClaimModal
          refetch={refetch}
          editinfo={editData}
          showmodal={showModal}
          setshowmodal={setShowModal}
        />
      )}
    </>
  );
}

export default Damages;
