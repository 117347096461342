import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';
import useAuth from '../hooks/useAuth';
import {
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { AllLoginProfileBox } from '../assets/style/Header/DropMenu';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ProfileCard from './ProfileCard';
import useHeader from '../hooks/useHeader';

export default function Header() {
  // const { backDrop, setBackDrop, left, setLeft } = useLayout();
  const { header } = useHeader();
  const { role, avatar, user, name } = useAuth();
  // const matches = useMediaQuery('(max-width:899px)');
  const navigate = useNavigate();
  const location = useLocation();
  const pathLength = location.pathname.split('/').length;
  const logout = useLogout();

  // const [value, setValue] = React.useState('Reports');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [logInfo, setLogInfo] = React.useState(
    JSON.parse(localStorage.getItem('LoginInfo')) || '',
  );

  const handleAllLogout = async () => {
    await logout();
    localStorage.clear();
    window.location.reload();
  };

  const handleLogout = async () => {
    const newLists = { ...logInfo };
    delete newLists[user?.phone];
    localStorage.setItem('LoginInfo', JSON.stringify(newLists));
    setLogInfo(newLists);
    await logout();
    navigate('/login');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleMobileMenu = () => {
  //   setBackDrop(!backDrop);
  //   if (left === -1000) {
  //     setLeft(0);
  //   } else {
  //     setLeft(-1000);
  //   }
  // };

  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          width: 1,
          zIndex: 150,
          bgcolor: 'primary.main',
          color: '#fff',
        }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* <Box sx={{ display: { xs: 'none', sm: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMobileMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
          </Box> */}
          {pathLength > 2 && (
            <Box sx={{ display: { md: 'none' } }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackIcon color="white" />
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              display: 'flex',
            }}>
            {/* <Box
              sx={{
                display: { md: 'none', xs: 'flex' },
                height: '54px',
                width: '54px',
                background: (theme) => theme.palette.primary.main,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <img
                src={mobileLogo}
                alt=""
                style={{
                  minWidth: '100%',
                  width: '100%',
                  padding: '10px 15px 10px 5px',
                }}
              />
            </Box> */}

            <Typography sx={{ fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
              {header?.title}
            </Typography>
          </Box>
          <Stack direction={'row'} gap={1}>
            <Box sx={{ display: { md: 'block', xs: 'none' } }}>
              <Typography>{user?.employee?.name}</Typography>
              <Typography fontSize={'12px'}>
                {user?.employee?.role?.employeeRole}
              </Typography>
            </Box>
            <Box>
              <Tooltip title="Open settings">
                <IconButton
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ p: 0 }}>
                  <Avatar src={avatar} />
                </IconButton>
              </Tooltip>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}>
                <Box>
                  <Box pl={{ sm: 2, xs: 1 }} pr={{ sm: 2, xs: 1 }}>
                    <ListItem disablePadding>
                      <ListItemButton disableRipple>
                        <ListItemIcon>
                          <Avatar src={avatar} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <>
                              {name}
                              <Typography
                                sx={{ display: 'inline', fontSize: 12 }}>
                                ({role})
                              </Typography>
                            </>
                          }
                          secondary={
                            <>
                              <Typography sx={{ fontSize: 12 }}>
                                <LocationOnIcon
                                  sx={{
                                    position: 'relative',
                                    top: 1,
                                    fontSize: 13,
                                    marginRight: 1,
                                  }}
                                />
                                {
                                  user?.employee?.territory?.territoryType
                                    ?.territoryType
                                }
                                : {user?.employee?.territory?.name}
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                <EmailIcon
                                  sx={{
                                    position: 'relative',
                                    top: 2,
                                    fontSize: 13,
                                    marginRight: 1,
                                  }}
                                />
                                {user?.email}
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                    <Button
                      component={Link}
                      to="/profile"
                      state={{
                        title: 'Profile',
                      }}>
                      <PersonIcon color="primary" fontSize="small" />
                      <Typography
                        ml={1}
                        sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                        fontWeight={600}
                        color="primary">
                        Profile
                      </Typography>
                    </Button>
                    <Button onClick={handleLogout}>
                      <LogoutIcon color="error" fontSize="small" />
                      <Typography
                        ml={1}
                        sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                        fontWeight={600}
                        color="error">
                        Logout
                      </Typography>
                    </Button>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display:
                        Object.keys(logInfo).length > 1 ? 'block' : 'none',
                    }}>
                    <AllLoginProfileBox p={{ sm: 2, xs: 1 }} mt={1}>
                      <ProfileCard />
                    </AllLoginProfileBox>
                    <Divider />
                  </Box>
                  <Stack alignItems="flex-start" p={{ sm: 2, xs: 1 }}>
                    <Button
                      component={Stack}
                      direction="row"
                      gap={1}
                      onClick={() => navigate('/login')}>
                      <PersonAddAlt1Icon
                        color="pr
                      ary"
                        fontSize="small"
                      />
                      <Typography
                        sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                        fontWeight={600}
                        color="primary">
                        Add Another Account
                      </Typography>
                    </Button>
                    {Object.keys(logInfo).length > 1 ? (
                      <Button
                        component={Stack}
                        direction="row"
                        gap={1}
                        onClick={handleAllLogout}>
                        <LogoutIcon color="error" fontSize="small" />
                        <Typography
                          sx={{ fontSize: { sm: '16px', xs: '13px' } }}
                          fontWeight={600}
                          color="error">
                          Logout From All
                        </Typography>
                      </Button>
                    ) : null}
                  </Stack>
                </Box>
              </Menu>
            </Box>
          </Stack>
        </Toolbar>
      </Box>
      {/* {matches && <Box sx={{ minHeight: '55px' }}></Box>} */}
    </>
  );
}
