import { ClientJS } from 'clientjs';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

async function generateRequestId() {
  const client = new ClientJS();
  const fp = await getCurrentBrowserFingerPrint();

  const fingerprint = client.getCustomFingerprint(
    fp,
    client.getCanvasPrint(),
    client.getColorDepth(),
    // client.getCurrentResolution(),
    client.getAvailableResolution(),
    client.getDeviceXDPI(),
    client.getDeviceYDPI(),
    client.getPlugins(),
    client.getFonts(),
    client.getDevice(),
    client.getDeviceType(),
    client.getDeviceVendor(),
    client.getOS(),
    client.getOSVersion(),
    client.getCPU(),
    client.getLanguage(),
    client.getSystemLanguage(),
    client.getTimeZone(),
    client.getEngine(),
    // client.getEngineVersion(),
    // client.getBrowserVersion(),
    client.getMimeTypes(),
  );

  return `10${fingerprint}`;
}

export default generateRequestId;
