import React, { createContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'react-use-cookie';
import generateRequestId from '../utils/generateRequestId';
const RequestIdContext = createContext({});

export const RequestIdProvider = ({ children }) => {
  const [requestId, setRequestId] = useState('');
  const cookieFP = getCookie('request_id');

  useEffect(() => {
    if (!cookieFP) {
      generateRequestId().then((fp) => {
        setRequestId(fp);
        setCookie('request_id', fp, {
          days: 365,
          path: '/',
          SameSite: 'Strict',
          Secure: true,
        });
      });
    } else {
      setRequestId(cookieFP);
    }
  }, [cookieFP]);

  return (
    <RequestIdContext.Provider value={{ requestId }}>
      {children}
    </RequestIdContext.Provider>
  );
};

export default RequestIdContext;
