import React, { useEffect, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Button from '../../../component/Button';
import Modals from '../../../component/Modals';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

function EditModal({
  showModal,
  setShowModal,
  editData,
  setEditData,
  refetch,
}) {
  const axiosPrivate = useAxiosPrivate();

  const [employee, setEmployee] = useState({});
  const [allEmployees, setAllEmployees] = useState([]);

  // get all employees
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/employee?populate=yes&limit=none&isActive=true')
      .then((res) => {
        setAllEmployees(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // set employee
  useEffect(() => {
    setEmployee(editData?.employee);
  }, [editData?.employee?.id]);

  // close modal handler
  const handleClose = () => {
    setEmployee({});
    setEditData({});
    setShowModal(false);
  };

  // update handler
  const handleSubmit = () => {
    axiosPrivate
      .post(`/api/v1/user/${editData?.id}`, {
        userType: 'employee',
        employee: employee?.id,
      })
      .then((res) => {
        refetch();
        handleClose();
        toast.success(res?.data?.message);
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  return (
    <Modals
      title="Set Employee Profile For User"
      show={showModal}
      onHide={handleClose}
      form>
      <Stack direction="column" gap={2}>
        <Typography
          sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
          User's login info
        </Typography>
        <table className="upTable">
          <tr>
            <th>DB_ID</th>
            <th>:&nbsp;</th>
            <td>{editData?.id}</td>
          </tr>
          <tr>
            <th style={{ minWidth: 68 }}>Phone</th>
            <th>:&nbsp;</th>
            <td>{editData?.phone}</td>
          </tr>
          <tr>
            <th>Email</th>
            <th>:&nbsp;</th>
            <td>{editData?.email}</td>
          </tr>
        </table>

        <hr />

        <Typography
          sx={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>
          Employee info
        </Typography>
        <Autocomplete
          fullWidth
          disabled={allEmployees.length < 1}
          options={allEmployees}
          value={employee}
          isOptionEqualToValue={(option, value) => value?.id === option?.id}
          onChange={(event, value) => setEmployee(value)}
          getOptionLabel={(option) =>
            option?.name
              ? `${option?.name} (${option?.code}) - ${option?.territory?.name}`
              : ''
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth label="User's Employee Profile" />
          )}
        />
        <table border={0} className="upTable">
          <tr>
            <th>DB_ID</th>
            <th>:&nbsp;</th>
            <td>{employee?.id}</td>
          </tr>
          <tr>
            <th style={{ minWidth: 68 }}>Name</th>
            <th>:&nbsp;</th>
            <td>{employee?.name}</td>
          </tr>
          <tr>
            <th>Code</th>
            <th>:&nbsp;</th>
            <td>{employee?.code}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <th>:&nbsp;</th>
            <td>{employee?.contactNumber}</td>
          </tr>
          <tr>
            <th>Email</th>
            <th>:&nbsp;</th>
            <td>{employee?.email}</td>
          </tr>
          <tr>
            <th>Role</th>
            <th>:&nbsp;</th>
            <td>{employee?.role?.employeeRole}</td>
          </tr>
          <tr>
            <th>Territory</th>
            <th>:&nbsp;</th>
            <td>{employee?.territory?.nameCode}</td>
          </tr>
        </table>

        <Button type="submit" text="Save" onClick={handleSubmit} />
      </Stack>
    </Modals>
  );
}

export default EditModal;
