import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import logo from '../../assets/images/logo.png';
import Modals from '../../component/Modals';
import useAuth from '../../hooks/useAuth';
import useAxios from '../../hooks/useAxios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function OutletForm() {
  const data = new FormData();
  const { state: x } = useLocation();
  const navigate = useNavigate();
  const axios = useAxios();
  const axiosPrivate = useAxiosPrivate();
  const { role, Admins } = useAuth();

  const [modalShow, setModalShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [outletInfo, setOutletInfo] = useState([]);

  // error states
  const [showModalError, setShowModalError] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [nameError, setNameError] = useState('');
  const [outletNameError, setOutletNameError] = useState('');
  const [outletCategoryError, setOutletCategoryError] = useState('');
  const [outletOwnerNameError, setOutletOwnerNameError] = useState('');
  const [routeBeatError, setRouteBeatError] = useState('');
  const [mobileNoError, setMobileNoError] = useState('');
  const [outletContactError, setOutletContactError] = useState('');
  const [outletOwnerContactError, setOutletOwnerContactError] = useState('');
  const [locationError, setLocationError] = useState('');

  // selection states
  const [regionData, setRegionData] = useState([]);
  const [pointTownData, setPointTownData] = useState([]);
  const [hqData, setHqData] = useState([]);
  const [routeData, setRouteData] = useState([]);

  // form states
  const [image, setImage] = useState(null);
  const [name, setName] = useState(
    x?.name || localStorage.getItem('name') || '',
  );
  const [mobileNo, setMobileNo] = useState(
    x?.mobileNo || localStorage.getItem('mobileNo') || '',
  );
  const [outletName, setOutletName] = useState(x?.outletName || '');
  const [outletCategory, setOutletCategory] = useState(x?.outletCategory || '');
  const [outletContact, setOutletContact] = useState(x?.outletContact || '');
  const [outletOwnerName, setOutletOwnerName] = useState(
    x?.outletOwnerName || '',
  );
  const [outletOwnerContact, setOutletOwnerContact] = useState(
    x?.outletOwnerContact || '',
  );
  const [region, setRegion] = useState(
    x?.region || {
      id: localStorage.getItem('regionId'),
      name: localStorage.getItem('region'),
    },
  );
  const [hq, setHq] = useState(
    x?.hq || {
      id: localStorage.getItem('hqId'),
      name: localStorage.getItem('hq'),
    },
  );
  const [pointTown, setPointTown] = useState(
    x?.pointTown || {
      id: localStorage.getItem('pointTownID'),
      name: localStorage.getItem('pointTown'),
    },
  );
  const [routeBeat, setRouteBeat] = useState(
    x?.routeBeat || localStorage.getItem('routeBeat'),
  );
  const [latitude, setLatitude] = useState(x?.latitude || null);
  const [longitude, setLongitude] = useState(x?.longitude || null);
  const [status, setStatus] = useState(x?.status || 'pending');

  const timer = useRef(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationError(null);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setLocationError(
                'You have to turn on location service and allow the request.',
              );
              break;
            case error.POSITION_UNAVAILABLE:
              setLocationError(
                'Location information is unavailable. Check your internet connection.',
              );
              break;
            case error.TIMEOUT:
              setLocationError('The request to get user location timed out.');
              break;
            case error.UNKNOWN_ERROR:
              setLocationError(
                'An unknown error occurred while getting your location.',
              );
              break;
            default:
              setLocationError(error.message);
              break;
          }
        },
      );
    } else {
      clearInterval(timer.current);
      setLocationError(
        'Geolocation is not supported by this browser. Please use Chrome Browser.',
      );
    }
  };

  useEffect(() => {
    if (!x?.id) {
      timer.current = setInterval(getLocation, 10000);
      getLocation();
    } else {
      clearInterval(timer.current);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [x?.id]);

  useEffect(() => {
    if (locationError) {
      alert(locationError);
    }
  }, [locationError]);

  // get all outlets
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/outlet?populate=yes&limit=none&sort=-createdAt')
      .then((res) => {
        setOutletInfo(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  // get region by region Id
  useEffect(() => {
    axios
      .get(
        '/api/v1/territory?limit=none&isActive=true&territoryType=63aaa3c505e4dc7daa1a85ce',
      )
      .then((res) => {
        setRegionData(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, []);

  // get HQ by it's parent territory id
  useEffect(() => {
    axios
      .get(
        `/api/v1/territory?limit=none&isActive=true&parentTerritory=${region?.id}`,
      )
      .then((res) => {
        setHqData(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [region?.id]);

  // get Point/Town by it's parent territory id
  useEffect(() => {
    axios
      .get(
        `/api/v1/territory?limit=none&isActive=true&parentTerritory=${hq?.id}`,
      )
      .then((res) => {
        setPointTownData(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [hq?.id]);

  useEffect(() => {
    axios
      .get(
        `/api/v1/territory?limit=none&isActive=true&parentTerritory=${pointTown?.id}`,
      )
      .then((res) => {
        setRouteData(res?.data?.data);
      })
      .catch((error) => console.error(error));
  }, [pointTown?.id]);

  useEffect(() => {
    if (!validator.isMobilePhone(mobileNo, 'bn-BD', { strictMode: false })) {
      setMobileNoError('Invalid phone');
    } else {
      setMobileNoError(null);
    }
  }, [mobileNo]);

  useEffect(() => {
    const OC = outletInfo?.filter(
      (item) => item?.outletContact === outletContact,
    );
    if (
      !validator.isMobilePhone(outletContact, 'bn-BD', { strictMode: false })
    ) {
      setOutletContactError('Invalid phone');
    } else if (OC?.length > 0) {
      setOutletContactError('This number already exists');
    } else {
      setOutletContactError(null);
    }
  }, [outletInfo, outletContact]);

  useEffect(() => {
    if (
      outletOwnerContact !== '' &&
      !validator.isMobilePhone(outletOwnerContact, 'bn-BD', {
        strictMode: false,
      })
    ) {
      setOutletOwnerContactError('Invalid phone');
    } else {
      setOutletOwnerContactError(null);
    }
  }, [outletOwnerContact]);

  useEffect(() => {
    if (name.length < 3) {
      setNameError('Valid name is required');
    } else {
      setNameError(null);
    }
  }, [name]);

  useEffect(() => {
    if (outletName.length < 3) {
      setOutletNameError('Valid outlet name is required');
    } else {
      setOutletNameError(null);
    }
  }, [outletName]);

  useEffect(() => {
    if (outletOwnerName.length < 3) {
      setOutletOwnerNameError('Valid owner name is required');
    } else {
      setOutletOwnerNameError(null);
    }
  }, [outletOwnerName]);

  useEffect(() => {
    if (outletCategory.length < 3) {
      setOutletCategoryError('Must select a category');
    } else {
      setOutletCategoryError(null);
    }
  }, [outletCategory]);

  useEffect(() => {
    if (!routeBeat) {
      setRouteBeatError('Valid route is required');
    } else {
      setRouteBeatError(null);
    }
  }, [routeBeat]);

  // open modal
  useEffect(() => {
    if (
      localStorage.getItem('name') &&
      localStorage.getItem('mobileNo') &&
      localStorage.getItem('regionId') &&
      localStorage.getItem('hqId') &&
      localStorage.getItem('pointTownID')
    ) {
      setModalShow(false);
    } else if (x?.id) {
      setModalShow(false);
    } else {
      setModalShow(true);
    }
  }, [x?.id]);

  // edit modal
  const handleEdit = () => {
    setModalShow(true);
  };

  // save information on local storage
  const saveModal = () => {
    setShowModalError(true);
    if (!nameError && !mobileNoError) {
      setShowModalError(false);

      localStorage.setItem('name', name);
      localStorage.setItem('mobileNo', mobileNo);
      localStorage.setItem('region', region.name);
      localStorage.setItem('regionId', region.id);
      localStorage.setItem('hq', hq.name);
      localStorage.setItem('hqId', hq.id);
      localStorage.setItem('pointTown', pointTown.name);
      localStorage.setItem('pointTownID', pointTown.id);

      window.location.reload();
    }
  };

  const handleSubmit = () => {
    setShowFormError(true);

    if (
      !nameError &&
      !outletNameError &&
      !outletCategoryError &&
      !outletOwnerNameError &&
      !mobileNoError &&
      !outletContactError &&
      !outletOwnerContactError &&
      !routeBeatError &&
      !locationError
    ) {
      setShowFormError(false);
      setIsSubmitting(true);

      // save routeBeat on local storage
      localStorage.setItem('routeBeat', routeBeat);

      data.append('name', name);
      data.append('mobileNo', mobileNo);
      data.append('outletName', outletName);
      data.append('outletCategory', outletCategory);
      data.append('outletContact', outletContact);
      data.append('outletOwnerName', outletOwnerName);
      data.append('outletOwnerContact', outletOwnerContact);
      data.append('region', region?.id);
      data.append('hq', hq?.id);
      data.append('pointTown', pointTown?.id);
      data.append('routeBeat', routeBeat);
      if (image instanceof File && image.type.startsWith('image/')) {
        data.append('image', image);
      }

      if (x?.id) {
        // Updating x document
        data.append('status', status);

        axiosPrivate
          .patch(`/api/v1/outlet/${x.id}`, data)
          .then((res) => {
            toast.success(res?.data?.message);
            navigate('/outletinfo', { state: null });
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        // Creating new document
        getLocation();
        data.append('latitude', latitude);
        data.append('longitude', longitude);

        axios
          .post('/api/v1/outlet', data)
          .then((res) => {
            toast.success(res?.data?.message);

            setImage(null);
            setOutletName('');
            setOutletCategory('');
            setOutletContact('');
            setOutletOwnerName('');
            setOutletOwnerContact('');
            setLatitude(null);
            setLongitude(null);
            setRouteBeat('');
            navigate('/outletinfo', { state: null });
          })
          .catch((error) => {
            toast.warn(error?.response?.data?.message);
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    }
  };

  return (
    <>
      <Stack sx={{ maxWidth: '600px', margin: '20px auto' }} gap={2} p={2}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            height={150}
            component={Paper}
            sx={{ borderRadius: '10px', background: '#1976d2', color: '#fff' }}>
            <img src={logo} alt="Logo" style={{ width: 200 }} />

            <Typography sx={{ fontSize: { sm: '28px' } }}>
              Peerless Industries Limited
            </Typography>
          </Stack>
        </Link>

        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          p={3}
          gap={3}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography>Recording Person Information</Typography>
            <Button
              variant="contained"
              onClick={handleEdit}
              startIcon={<BorderColorIcon />}>
              Edit
            </Button>
          </Stack>

          <TextField
            disabled
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showFormError && nameError}
            helperText={showFormError && nameError}
          />

          <TextField
            disabled
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showFormError && mobileNoError}
            helperText={showFormError && mobileNoError}
          />
        </Stack>

        <Stack
          direction="column"
          component={Paper}
          sx={{ borderRadius: '10px' }}
          p={3}
          gap={3}>
          <Typography>Shop Information</Typography>

          <TextField
            label="Outlet name"
            type="text"
            value={outletName}
            onChange={(e) => setOutletName(e.target.value)}
            error={showFormError && outletNameError}
            helperText={showFormError && outletNameError}
          />

          <FormControl fullWidth error={showFormError && outletCategoryError}>
            <InputLabel>Outlet Category</InputLabel>
            <Select
              value={outletCategory}
              label="Outlet Category"
              onChange={(e) => setOutletCategory(e.target.value)}>
              <MenuItem value="A. Departmental Shop">
                A. Departmental Shop
              </MenuItem>
              <MenuItem value="B. Mudi/Grocery/Confectionery Shop">
                B. Mudi/Grocery/Confectionery Shop
              </MenuItem>
              <MenuItem value="C. Tong/Tea Stalls">C. Tong/Tea Stalls</MenuItem>
            </Select>
            {showFormError && (
              <FormHelperText>{outletCategoryError}</FormHelperText>
            )}
          </FormControl>

          <TextField
            label="Outlet Contact Number"
            type="text"
            value={outletContact}
            onChange={(e) => setOutletContact(e.target.value)}
            error={showFormError && outletContactError}
            helperText={showFormError && outletContactError}
          />

          <TextField
            label="Outlet Owner's Name"
            type="text"
            value={outletOwnerName}
            onChange={(e) => setOutletOwnerName(e.target.value)}
            error={showFormError && outletOwnerNameError}
            helperText={showFormError && outletOwnerNameError}
          />

          <TextField
            label="Outlet Owner's Contact Number"
            type="text"
            value={outletOwnerContact}
            onChange={(e) => setOutletOwnerContact(e.target.value)}
            error={showFormError && outletOwnerContactError}
            helperText={showFormError && outletOwnerContactError}
          />

          <Autocomplete
            fullWidth
            disabled
            options={regionData}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled
            options={hqData}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHq(value);
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled
            options={pointTownData}
            value={pointTown}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPointTown(value);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />

          <Autocomplete
            fullWidth
            autoComplete
            autoHighlight
            autoSelect
            freeSolo
            options={routeData?.map((route) => route?.name)}
            value={routeBeat}
            // isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRouteBeat(value);
            }}
            // getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Select Route/Beat"
                error={showFormError && routeBeatError}
                helperText={showFormError && routeBeatError}
              />
            )}
          />

          {/* <TextField
            disabled={!pointTown.id}
            label="Route/Beat"
            type="text"
            value={routeBeat}
            onChange={(e) => setrouteBeat(e.target.value)}
            error={showFormError && routeBeatError}
            helperText={showFormError && routeBeatError}
          /> */}

          {x?.id && ['Super Admin'].includes(role) ? (
            <FormControl>
              <FormLabel id="outlet-status-label">
                Verification Status
              </FormLabel>
              <RadioGroup
                aria-labelledby="outlet-status-label"
                name="outlet-status"
                value={status}
                defaultValue="pending"
                onChange={(e) => {
                  setStatus(e.target.value);
                }}>
                <FormControlLabel
                  value="pending"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Pending&nbsp;
                      <HelpOutlineIcon color="primary" fontSize="small" />
                    </Box>
                  }
                />
                <FormControlLabel
                  value="verified"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Verified&nbsp;
                      <CheckCircleIcon color="success" fontSize="small" />
                    </Box>
                  }
                />
                <FormControlLabel
                  value="failed"
                  control={<Radio />}
                  label={
                    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      Failed&nbsp;
                      <CancelIcon color="error" fontSize="small" />
                    </Box>
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {locationError ? (
            <p
              style={{
                fontSize: 'large',
                fontWeight: 'bold',
                color: 'red',
                textAlign: 'center',
              }}>
              {locationError}
            </p>
          ) : (
            <table width={200} style={{ margin: 'auto' }}>
              <tr>
                <th colSpan={2}>Location</th>
              </tr>
              <tr>
                <td>Latitude :</td>
                <td>{latitude}</td>
              </tr>
              <tr>
                <td>Longitude :</td>
                <td>{longitude}</td>
              </tr>
            </table>
          )}

          {showFormError && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              align="center">
              Make sure you have checked all input fields for errors before you
              proceed.
            </Typography>
          )}

          <Button
            disabled={isSubmitting}
            variant="contained"
            onClick={handleSubmit}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Stack>
      </Stack>

      <Modals
        title="Set Recording Person Information"
        show={modalShow}
        width="lg"
        form
        hideicon>
        <Stack direction="column" gap={3}>
          <TextField
            label="Name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={showModalError && nameError}
            helperText={showModalError && nameError}
          />

          <TextField
            label="Mobile Number"
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
            error={showModalError && mobileNoError}
            helperText={showModalError && mobileNoError}
          />

          <Autocomplete
            fullWidth
            disabled={regionData.length < 1}
            options={regionData}
            value={region}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setRegion(value);
              setHq({});
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Region" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!region?.id || hqData.length < 1}
            options={hqData}
            value={hq}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setHq(value);
              setPointTown({});
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select HQ" />
            )}
          />

          <Autocomplete
            fullWidth
            disabled={!hq?.id || pointTownData.length < 1}
            options={pointTownData}
            value={pointTown}
            isOptionEqualToValue={(option, value) => value?.id === option?.id}
            onChange={(event, value) => {
              setPointTown(value);
            }}
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField {...params} fullWidth label="Select Point/Town" />
            )}
          />
        </Stack>

        <Button
          fullWidth
          disabled={
            name && mobileNo && region?.name && hq?.name && pointTown?.name
              ? false
              : true
          }
          variant="contained"
          onClick={saveModal}
          sx={{ mt: 3 }}>
          Save
        </Button>
      </Modals>
    </>
  );
}
