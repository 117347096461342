import { Box, styled } from '@mui/material';

export const CustomMobileNavBox = styled(Box)(({ theme }) => ({
  borderTop: '1px solid grey',
  width: '100%',
  overflow: 'auto',
  position: 'fixed',
  bottom: 0,
  left: 0,
  background: '#fff',
  color: '#585858',
  zIndex: 200,
  '& .MuiList-root': {
    padding: 0,
    display: 'flex',
    // flexWrap: 'wrap',
    justifyContent: 'center',
    '& .MuiListItem-root': {
      maxWidth: '70px',
      width: '-webkit-fill-available',
      color: '#585858',
      '&.active': {
        color: theme.palette.primary.main,
        background: 'rgba(0, 0, 0, 0.04)',
      },
      '&.active .MuiListItemIcon-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiListItemIcon-root': {
        minWidth: '35px',
      },
      '&.active .MuiTypography-root': {
        fontWeight: 500,
      },
      '& .MuiButtonBase-root': {
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiListItemIcon-root': {
          minWidth: '100%',
          justifyContent: 'center',
        },
        '& .MuiListItemText-root': {
          '& .MuiTypography-root': {
            fontSize: 8,
          },
        },
      },
    },
  },
}));
