import {
  Grid,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import HeadingTitle from '../../../component/HeadingTitle';

export default function ModifiedCustomerClass() {
  const axiosPrivate = useAxiosPrivate();
  const [refetch] = useOutletContext();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.customerClassID;

  const [isLoading, setIsLoading] = useState(false);
  //   form state
  const [customerClass, setCustomerClass] = useState('');
  const [discountPercent, setDiscountPercent] = useState(0);
  const [giftDescription, setGiftDescription] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosPrivate
        .get(`/api/v1/customer/class/${id}?populate=yes`)
        .then((res) => {
          const data = res?.data?.data;
          setCustomerClass(data?.customerClass);
          setDiscountPercent(data?.discountPercent);
          setGiftDescription(data?.giftDescription);
          setDescription(data?.description);
          setIsActive(data?.isActive);
        })
        .catch((error) => console.warn(error))
        .finally(() => setIsLoading(false));
    } else {
      setCustomerClass('');
      setDiscountPercent(0);
      setGiftDescription('');
      setDescription('');
      setIsActive(true);
    }
  }, [id, axiosPrivate]);

  const handleSubmit = () => {
    axiosPrivate({
      method: id ? 'patch' : 'post',
      url: id ? `/api/v1/customer/class/${id}` : '/api/v1/customer/class',
      data: {
        customerClass,
        discountPercent,
        giftDescription,
        description,
        isActive,
      },
    })
      .then((res) => {
        toast.success(res.data.message);
        refetch();
        navigate('/settings/customer-class');
      })
      .catch((error) => toast.warn(error.response.data.message));
  };

  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height={1}>
        <CircularProgress />
        <Typography color="text.disabled">Loading ...</Typography>
      </Stack>
    );
  }

  return (
    <>
      <HeadingTitle>{id ? 'Edit' : 'Add'} Customer Class</HeadingTitle>
      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Grade"
            fullWidth
            value={customerClass}
            onChange={(e) => setCustomerClass(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Discount Percent"
            fullWidth
            value={discountPercent}
            onChange={(e) => setDiscountPercent(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            type="text"
            label="Gift Description"
            fullWidth
            value={giftDescription}
            onChange={(e) => setGiftDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows={2}
            type="text"
            label="Description"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label={isActive ? 'Active' : 'Inactive'}
            value="active"
            control={
              <Switch
                color="primary"
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            }
          />
        </Grid>
      </Grid>
      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/settings/customer-class')}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          sx={{ px: 3 }}
          onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </>
  );
}
