import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useContainerDimensions from '../hooks/useContainerDimensions';

/**
 * @typedef {Function} TypeOnChangeFn
 * @param {Number} quantity
 */

/**
 * @typedef {Object} Props
 * @property {( 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' )} color
 * @property {( 'small' | 'medium' )} size
 * @property {( 'outlined' | 'filled' | 'standard' )} variant
 * @property {Boolean} autoFocus
 * @property {Boolean} disabled
 * @property {Boolean} required
 * @property {Boolean} error
 * @property {String} cartonsLabel
 * @property {String} packetsLabel
 * @property {String} inputLabel
 * @property {Number} cartonUnits
 * @property {Number} value
 * @property {TypeOnChangeFn} onChange
 */

/**
 * @param {Props} props
 */
export default function ProductQuantityInput({
  color = 'primary',
  size = 'medium',
  variant = 'outlined',
  autoFocus = false,
  disabled = false,
  required = false,
  error = false,
  cartonsLabel = 'ctn',
  packetsLabel = 'pcs',
  inputLabel = 'Packets',
  cartonUnits = 1,
  value = 0,
  onChange,
}) {
  const [quantityDisplay, setQuantityDisplay] = useState('');

  const [visibleButtons, setVisibleButtons] = useState(4);
  const [isWide, setIsWide] = useState(false);

  const ref = useRef(null);
  const { width } = useContainerDimensions(ref);

  if (error) color = 'error';

  useEffect(() => {
    if (width > 680) {
      setIsWide(true);
      setVisibleButtons(8);
    } else if (width > 608) {
      setIsWide(true);
      setVisibleButtons(7);
    } else if (width > 536) {
      setIsWide(true);
      setVisibleButtons(6);
    } else if (width > 464) {
      setIsWide(true);
      setVisibleButtons(5);
    } else if (width > 402) {
      setIsWide(false);
      setVisibleButtons(6);
    } else if (width > 330) {
      setIsWide(false);
      setVisibleButtons(5);
    } else {
      setIsWide(false);
      setVisibleButtons(4);
    }
  }, [width]);

  useEffect(() => {
    const v = parseInt(value, 10);
    const cn = parseInt(cartonUnits, 10);

    const cartons = Math.floor(v / cn);
    const packets = v % cn;

    let display = `${cartons} ${cartonsLabel}`;
    if (packets) display += ` ${packets} ${packetsLabel}`;

    setQuantityDisplay(display);
  }, [cartonUnits, cartonsLabel, packetsLabel, value]);

  const handleChange = useCallback((q) => {
    if (typeof onChange !== 'function') return;

    if (q > 0) onChange(q);
    else onChange(0);
  }, []);

  const removeButtons = useMemo(
    () => (
      <Stack direction="row-reverse" alignItems="center" gap={1}>
        <IconButton
          aria-label="remove one"
          color={color}
          disabled={disabled}
          size="large"
          onClick={() => {
            const q = parseInt(value, 10) - 1;
            handleChange(q);
          }}>
          <RemoveIcon />
        </IconButton>

        <Button
          sx={btnWidth}
          color={color}
          disabled={disabled}
          size="small"
          variant="text"
          onClick={() => {
            const q = parseInt(value, 10) - parseInt(cartonUnits, 10);
            handleChange(q);
          }}>
          -1 carton
        </Button>

        {visibleButtons >= 6 && (
          <Button
            sx={btnWidth}
            color={color}
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => {
              const q = parseInt(value, 10) - parseInt(cartonUnits, 10) * 10;
              handleChange(q);
            }}>
            -10 cartons
          </Button>
        )}
      </Stack>
    ),
    [cartonUnits, color, disabled, handleChange, value, visibleButtons],
  );

  const addButtons = useMemo(
    () => (
      <Stack direction="row" alignItems="center" gap={1}>
        <IconButton
          aria-label="add one"
          color={color}
          disabled={disabled}
          size="large"
          onClick={() => {
            const q = parseInt(value, 10) + 1;
            handleChange(q);
          }}>
          <AddIcon />
        </IconButton>

        <Button
          sx={btnWidth}
          color={color}
          disabled={disabled}
          size="small"
          variant="text"
          onClick={() => {
            const q = parseInt(value, 10) + parseInt(cartonUnits, 10);
            handleChange(q);
          }}>
          +1 carton
        </Button>

        {visibleButtons >= 5 && (
          <Button
            sx={btnWidth}
            color={color}
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => {
              const q = parseInt(value, 10) + parseInt(cartonUnits, 10) * 10;
              handleChange(q);
            }}>
            +10 cartons
          </Button>
        )}

        {visibleButtons >= 7 && (
          <Button
            sx={btnWidth}
            color={color}
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => {
              const q = parseInt(value, 10) + parseInt(cartonUnits, 10) * 50;
              handleChange(q);
            }}>
            +50 cartons
          </Button>
        )}

        {visibleButtons >= 8 && (
          <Button
            sx={btnWidth}
            color={color}
            disabled={disabled}
            size="small"
            variant="text"
            onClick={() => {
              const q = parseInt(value, 10) + parseInt(cartonUnits, 10) * 100;
              handleChange(q);
            }}>
            +100 cartons
          </Button>
        )}
      </Stack>
    ),
    [cartonUnits, color, disabled, handleChange, value, visibleButtons],
  );

  const inputField = useMemo(
    () => (
      <TextField
        autoFocus={autoFocus}
        color={color}
        disabled={disabled}
        required={required}
        error={error}
        fullWidth={true}
        helperText={quantityDisplay}
        label={inputLabel}
        size={size}
        variant={variant}
        margin="normal"
        type="number"
        value={value.toString()}
        onChange={(e) => {
          const q = parseInt(e.target.value, 10);
          handleChange(q);
        }}
      />
    ),
    [
      autoFocus,
      color,
      disabled,
      error,
      handleChange,
      variant,
      inputLabel,
      quantityDisplay,
      required,
      size,
      value,
    ],
  );

  return (
    <Box ref={ref} sx={refBox}>
      {isWide ? (
        <Stack direction="row" gap={1}>
          {removeButtons}
          {inputField}
          {addButtons}
        </Stack>
      ) : (
        <Stack direction="column">
          {inputField}
          <Stack direction="row" gap={1}>
            {removeButtons}
            <Divider flexItem orientation="vertical" variant="middle" />
            {addButtons}
          </Stack>
        </Stack>
      )}
    </Box>
  );
}

const refBox = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const btnWidth = { maxWidth: 64 };
