import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DvrIcon from '@mui/icons-material/Dvr';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import StoreIcon from '@mui/icons-material/Store';
import { Stack } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import Card from './Card';
import DashTitle from './DashTitle';
import { useMemo } from 'react';

export default function ReportCard() {
  const { role, Admins, SalesManagers, SalesOfficers } = useAuth();

  const CardInfo = useMemo(
    () => [
      {
        title: 'SKU Contribution',
        url: 'report/sku-contribution',
        icon: <LocalMallIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'Customer Activity',
        url: 'report/cust-activity',
        icon: <DvrIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'Customer Ledger',
        url: '/customer-ledger-report',
        icon: <RequestPageIcon sx={iconStyle} />,
        hidden: false,
      },
      {
        title: 'Monthly SD Commission',
        url: '/sd-commission',
        icon: <CopyAllIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'Cust Collection Report M-M',
        url: 'report/cust-collection',
        icon: <AttachMoneyIcon sx={iconStyle} />,
        hidden: false,
      },
      {
        title: 'DO Section',
        url: 'report/do-section',
        icon: <LocalShippingIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'Cust CTN report M-M',
        url: 'report/cust-stock',
        icon: <InventoryIcon sx={iconStyle} />,
        hidden: false,
      },
      {
        title: 'HQ Wise SKU',
        url: 'report/hq-wise-sku',
        icon: <InventoryIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'ASM WISE ACTIVE DEALERS',
        url: 'report/asm-wise-active-customer',
        icon: <StoreIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'Sales Manager Attendance',
        url: 'report/employee-attendance',
        icon: <RecentActorsIcon sx={iconStyle} />,
        hidden: false,
      },
      {
        title: 'Sales Representative Attendance',
        url: '/sr-report-attendance',
        icon: <RecentActorsIcon sx={iconStyle} />,
        hidden: false,
      },
      {
        title: 'National IMS Report',
        url: 'report/national-ims',
        icon: <ShoppingCartCheckoutIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
      {
        title: 'National Audited IMS Report',
        url: 'report/national-audited-ims',
        icon: <SpellcheckIcon sx={iconStyle} />,
        hidden: false,
      },
      /* {
        title: 'Customer Audited IMS Report',
        url: 'customer-audited-ims',
        icon: <SpellcheckIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      }, */
      {
        title: 'Competitor Category Sales',
        url: 'report/company-category-sales',
        icon: <RequestQuoteIcon sx={iconStyle} />,
        hidden: !['Super Admin', ...Admins].includes(role),
      },
    ],
    [role],
  );

  return (
    <>
      <DashTitle title={'Reports'} />
      <Stack
        direction="row"
        justifyContent="center"
        mt={3}
        sx={{ flexWrap: 'wrap', gap: '20px' }}>
        {/* <Card /> */}
        {CardInfo.map((info) => {
          if (info.hidden) return null;
          return <Card key={info.url} info={info} />;
        })}
      </Stack>
    </>
  );
}

const iconStyle = {
  fontSize: '50px',
};
