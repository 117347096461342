import React, { useMemo, useState } from 'react';
import { Link as MuiLink, TextField } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import { MultiSelectColumnFilter } from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { Link } from 'react-router-dom';

export default function NationalIMS() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );

  const { data, isLoading, isError, error } = useQuery(
    ['national-ims', startDate, endDate],

    () =>
      axiosPrivate
        .get(
          `/api/v1/report/national-ims?startDate=${startDate}&endDate=${endDate}`,
        )
        .then((res) => {
          const results = [];

          res?.data?.data?.forEach((d) => {
            let liftingDisplay = '-';
            if (d?.lifting?.total?.quantityC) {
              liftingDisplay = `${d.lifting.total.quantityC} ctn`;
            }
            if (d?.lifting?.total?.quantityP) {
              liftingDisplay += ` ${d.lifting.total.quantityP} pcs`;
            }

            let transferInDisplay = '-';
            if (d?.transferIn?.total?.quantityC) {
              transferInDisplay = `${d.transferIn.total.quantityC} ctn`;
            }
            if (d?.transferIn?.total?.quantityP) {
              transferInDisplay += ` ${d.transferIn.total.quantityP} pcs`;
            }

            let inMarketSaleDisplay = '-';
            if (d?.inMarketSale?.total?.quantityC) {
              inMarketSaleDisplay = `${d.inMarketSale.total.quantityC} ctn`;
            }
            if (d?.inMarketSale?.total?.quantityP) {
              inMarketSaleDisplay += ` ${d.inMarketSale.total.quantityP} pcs`;
            }

            let damageOutDisplay = '-';
            if (d?.damageOut?.total?.quantityC) {
              damageOutDisplay = `${d.damageOut.total.quantityC} ctn`;
            }
            if (d?.damageOut?.total?.quantityP) {
              damageOutDisplay += ` ${d.damageOut.total.quantityP} pcs`;
            }

            let transferOutDisplay = '-';
            if (d?.transferOut?.total?.quantityC) {
              transferOutDisplay = `${d.transferOut.total.quantityC} ctn`;
            }
            if (d?.transferOut?.total?.quantityP) {
              transferOutDisplay += ` ${d.transferOut.total.quantityP} pcs`;
            }

            let stockDisplay = '-';
            if (d?.stock?.total?.quantityC) {
              stockDisplay = `${d.stock.total.quantityC} ctn`;
            }
            if (d?.stock?.total?.quantityP) {
              stockDisplay += ` ${d.stock.total.quantityP} pcs`;
            }

            const result = {
              ...d,
              liftingDisplay,
              transferInDisplay,
              inMarketSaleDisplay,
              damageOutDisplay,
              transferOutDisplay,
              stockDisplay,
            };

            results.push(result);
          });

          return results;
        }),
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'territories.rank-2.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      /* {
        Header: 'Route/Beat',
        accessor: 'territories.rank-5.name',
      }, */
      {
        Header: 'Customer',
        accessor: 'customer.nameCode',
        Cell: ({ row, value }) => (
          <MuiLink
            component={Link}
            color="inherit"
            to={`/customers/transaction/${row?.original?.customer?.id}`}>
            {value}
          </MuiLink>
        ),
      },
      {
        Header: 'Type',
        accessor: 'customer.customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Lifting',
        accessor: 'liftingDisplayCtn',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Transfer In',
        accessor: 'transferInDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'In Market Sale',
        accessor: 'inMarketSaleDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Damage Out',
        accessor: 'damageOutDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Transfer Out',
        accessor: 'transferOutDisplay',
        aggregateCtn: 'sum',
      },
      {
        Header: 'Current Stock',
        accessor: 'stockDisplay',
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Title title="National IMS Report">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="From"
            inputFormat="DD/MM/YYYY"
            value={startDate}
            onChange={(newValue) =>
              setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />

          <DesktopDatePicker
            label="To"
            inputFormat="DD/MM/YYYY"
            value={endDate}
            onChange={(newValue) =>
              setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
            }
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Title>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table columns={COLUMNS} data={data} hideAddIcon noWrap />
      )}
    </>
  );
}
