import React, { useMemo, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Icon, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import CountColorTypography from '../../component/CountColorTypography';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
  SelectColumnFilterBool,
  SelectFilterSrReport,
  SelectHaveValue,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import Title from '../../component/report/Title';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import { Link } from 'react-router-dom';

export default function CustomerActivity() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD'),
  );

  const [filteredRows, setFilteredRows] = useState([]);

  const { data, isLoading, isError, error } = useQuery(
    ['customer-activity', startDate, endDate],

    () =>
      axiosPrivate
        .get(
          `/api/v1/report/customer-activity?startDate=${startDate}&endDate=${endDate}&isActive=true`,
        )
        .then((res) => {
          const results = [];

          res?.data?.data?.forEach((d) => {
            const result = {
              ...d,
              customerType: d?.customertypes?.[0]?.customerType,
              nameCode: `${d?.name} (${d?.code})`,
              activityCount: 0,
              orderCount: 0,
              deliveryCount: 0,
              transactionSentCount: d?.transactionsSent?.length || 0,
              stockhistoriesCount: d?.stockhistories?.length || 0,
              ledgersCount: d?.ledgers?.length || 0,
              travelallowancesCount: d?.travelallowances?.length || 0,
              workWithCount: 0,
              dealersCount: d?.dealers?.length || 0,
              outletsCount: d?.outlets?.length || 0,
              exdealersCount: d?.exdealers?.length || 0,
              salesactivitiesCount: d?.salesactivities?.length || 0,
              totalTargetDisplay: '-',
              territories: {},
            };

            d?.locations?.forEach((t) => {
              result.territories[`rank-${t?.territoryType?.rank}`] =
                t?.territory?.name || '';
            });

            d?.transactions?.forEach((t) => {
              if (!t?.deliveredAt) {
                result.orderCount += 1;
              } else {
                result.deliveryCount += 1;
              }
            });

            if (d?.totalTarget?.cartons) {
              result.totalTargetDisplay = `${d?.totalTarget?.cartons} ctn`;
            }
            if (d?.totalTarget?.pieces) {
              result.totalTargetDisplay += ` ${d?.totalTarget?.pieces} pcs`;
            }

            d?.travelallowances?.forEach((t) => {
              if (t?.reasonForTravel?.includes('Work with SO/SR')) {
                result.workWithCount += 1;
              }
            });

            result.activityCount =
              d?.totalTarget?.cartons +
              d?.totalTarget?.pieces +
              result.orderCount +
              result.deliveryCount +
              result.transactionSentCount +
              result.stockhistoriesCount +
              result.ledgersCount +
              result.exdealersCount;

            results.push(result);
          });

          return results;
        }),
  );

  if (isError) {
    expired(error);
  }

  // console.log('dsd', data);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Region',
        accessor: 'territories.rank-2',
        Filter: MultiSelectColumnFilter,
        // Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'territories.rank-3',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'territories.rank-4',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Customer Name & Code',
        accessor: 'nameCode',
        Cell: ({ value, row }) => (
          <CountColorTypography
            component={Link}
            to={`/customers/transaction/${row?.original?._id}`}
            count={row?.original?.activityCount}
            error={0}
            variant="body2"
            sx={{ '&:hover': { textDecoration: 'underline' } }}>
            {value}&nbsp;
            <Icon sx={{ fontSize: 14 }}>open_in_new</Icon>
          </CountColorTypography>
        ),
      },
      {
        Header: 'Type',
        accessor: 'customerType',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Starting Date',
        accessor: 'dateOfStarting',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Closing Date',
        accessor: 'dateOfClosing',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : ''),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) =>
          value ? (
            <CheckCircleIcon color="success" />
          ) : (
            <HighlightOffIcon color="error" />
          ),
        Filter: SelectColumnFilterBool,
      },
      {
        Header: 'Approved Collections',
        accessor: 'ledgersCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            success={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Deliveries Received',
        accessor: 'deliveryCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            success={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Pending Orders',
        accessor: 'orderCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Deliveries Sent',
        accessor: 'transactionSentCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Physical Stocks',
        accessor: 'stockhistoriesCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={2}
            success={3}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),

        Filter: MultiSelectColumnFilter,
        filter: 'includesNumber',
      },
      {
        Header: 'TADA',
        accessor: 'travelallowancesCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Work With ',
        accessor: 'workWithCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Dealer Visits',
        accessor: 'exdealersCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Outlets',
        accessor: 'outletsCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'Dealers Searched',
        accessor: 'dealersCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
      },
      {
        Header: 'SR Reports',
        accessor: 'salesactivitiesCount',
        Cell: ({ value }) => (
          <CountColorTypography
            count={value}
            error={0}
            normal={1}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
        Filter: SelectFilterSrReport,
        filter: 'between',
      },
      {
        Header: 'Monthly Target',
        accessor: 'totalTargetDisplay',
        Cell: ({ value, row }) => (
          <CountColorTypography
            count={row?.original?.totalTarget?.cartons}
            error={0}
            normal={400}
            success={800}
            align="center"
            variant="body2">
            {value}
          </CountColorTypography>
        ),
        Filter: SelectHaveValue,
        filter: 'hasValue',
      },
    ],
    [],
  );

  const SummaryView = useMemo(() => {
    let collectionCount = 0;
    let targetCount = 0;

    filteredRows.forEach((row) => {
      // console.log(row);
    });

    return <Typography></Typography>;
  }, [filteredRows]);

  return (
    <>
      <Title title="Customer Activity">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" gap={1}>
            <DesktopDatePicker
              label="From"
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) =>
                setStartDate(dayjs(newValue).format('YYYY-MM-DD'))
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />

            <DesktopDatePicker
              label="To"
              inputFormat="DD/MM/YYYY"
              value={endDate}
              onChange={(newValue) =>
                setEndDate(dayjs(newValue).format('YYYY-MM-DD'))
              }
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>
        </LocalizationProvider>
      </Title>

      {SummaryView}

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={COLUMNS || []}
          data={data}
          setFilteredRows={setFilteredRows}
          hideAddIcon
          noWrap
        />
      )}
    </>
  );
}

// const includeSomeFilter = (rows, id, filterValues) => {
//   // console.log('Rows: ', rows);
//   // console.log('id: ', id);
//   // console.log('filterValues', filterValues);
//   if (filterValues.length === 0) {
//     return rows;
//   } else {
//     return rows.filter((row) => filterValues.includes(row.values[id]));
//   }
// };
