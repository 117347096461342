import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography, TypographyProps } from '@mui/material';
import { toast } from 'react-toastify';

/**
 *
 * @param {Object} props Props
 * @param {String} props.text The text that will be copied
 * @param {String} props.toastText Toast message to show after copying
 * @param {String} props.title Title is shown in tooltip
 * @param {TypographyProps} props.typographyProps Typography Props
 * @returns
 */
function CopyClipboard({ children, text, toastText, title, typographyProps }) {
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => toast(toastText || `Copied ${text} to clipboard`)}>
      <Typography
        {...typographyProps}
        title={title || `Click to copy ${text} to clipboard`}
        sx={{
          cursor: 'pointer',
          '&:hover': { textDecoration: 'underline' },
          ...typographyProps.sx,
        }}>
        {children}
      </Typography>
    </CopyToClipboard>
  );
}

export default CopyClipboard;
