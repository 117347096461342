import { Typography } from '@mui/material';

export default function HeadingTitle({ children, variant }) {
  const styleType = () => {
    switch (variant) {
      case 0:
        return defaultStyle;
      case 1:
        return styleOne;

      default:
        return defaultStyle;
    }
  };
  return <Typography sx={styleType}>{children}</Typography>;
}

const defaultStyle = {
  color: '#4d4d4d',
  fontWeight: '700',
  fontSize: { md: 28, xs: 22 },
  position: 'relative',
  display: 'inline-block',
  '&::before': {
    content: '""',
    position: 'absolute',
    width: '30%',
    height: '4px',
    left: '0%',
    bottom: '-2px',
    background: (theme) => theme.palette.primary.main,
  },
};

const styleOne = {
  color: (theme) => theme.palette.primary.main,
  fontWeight: '700',
  fontSize: { md: 25, xs: 20 },
  position: 'relative',
  display: 'inline-block',
  zIndex: 1,
  // '&::after': {
  //   content: '""',
  //   position: 'absolute',
  //   width: '50%',
  //   height: '100%',
  //   top: '0%',
  //   left: '-2px',
  //   background: (theme) => `${theme.palette.primary.main}30`,
  //   zIndex: -1,
  // },
};
