import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      pt={2}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ labels, bodys }) {
  const logInfo = JSON.parse(localStorage.getItem('LoginInfo')) || '';

  const [value, setValue] = React.useState(
    Object.keys(logInfo).length < 1 ? 1 : 0,
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {labels?.map((item, i) => (
            <Tab
              key={i}
              label={item}
              {...a11yProps(i)}
              disabled={Object.keys(logInfo).length < 1 && i === 0}
            />
          ))}
        </Tabs>
      </Box>

      {bodys?.map((item, i) => (
        <CustomTabPanel key={i} value={value} index={i}>
          {item}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
