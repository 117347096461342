import React from "react";
import style from "../assets/css/login.module.css";

function FloatCIrcle() {
  return (
    <>
      <div className={`${style.circle_main}`}>
        <div className={`${style.circle} ${style.circle_1}`}></div>
        <div className={`${style.circle} ${style.circle_2}`}></div>
        <div className={`${style.circle} ${style.circle_3}`}></div>
        <div className={`${style.circle} ${style.circle_4}`}></div>
      </div>
    </>
  );
}

export default FloatCIrcle;
