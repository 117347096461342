/**
 * Round number to certain digits
 * @param {( String | Number )} number
 * @param {Number} digits
 * @returns {Number}
 */
export default function roundToDigit(number, digits = 2) {
  let digitsString = '100';
  let d = 100;

  if (digits > 2) {
    for (let i = 0; i < digits - 2; i++) {
      digitsString += '0';
    }
    d = parseInt(digitsString, 10);
  }

  const result = Math.round((parseFloat(number) + Number.EPSILON) * d) / d;

  if (Number.isNaN(result)) return 0;
  return result;
}
