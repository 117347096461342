import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';

const calculateTimeAgo = (date) => {
  if (date === undefined) {
    return '';
  } else {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return interval === 1 ? '1 year ago' : interval + ' years ago';
    }

    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval === 1 ? '1 month ago' : interval + ' months ago';
    }

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval === 1 ? '1 day ago' : interval + ' days ago';
    }

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval === 1 ? '1 hour ago' : interval + ' hours ago';
    }

    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval === 1 ? '1 minute ago' : interval + ' minutes ago';
    }

    return 'Just now';
  }
};

const LastActive = ({ lastActive, text, ...props }) => {
  const [activeTime, setActiveTime] = useState(calculateTimeAgo(lastActive));

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTime(calculateTimeAgo(lastActive));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [lastActive]);

  return (
    <Typography {...props}>
      {text} {activeTime}
    </Typography>
  );
};

export default LastActive;
