import React, { useEffect } from 'react';
import Layout from '../component/Layout';
// import DashCard from '../component/dashBoard/DashCard';
import AllForms from '../component/dashBoard/AllForms';
import ChartsCard from '../component/dashBoard/ChartsCard';
import InfoCard from '../component/dashBoard/InfoCard';
import ReportCard from '../component/dashBoard/ReportCard';
import useAuth from '../hooks/useAuth';
import useHeader from '../hooks/useHeader';

export default function Home() {
  const { role, Admins, SalesManagers, SalesOfficers } = useAuth();
  const { setHeader } = useHeader();

  useEffect(() => {
    setHeader({ title: 'Dashboard' });
  }, []);

  return (
    <Layout>
      {['Super Admin', ...Admins, 'DO', ...SalesManagers].includes(role) ? (
        <ChartsCard />
      ) : null}

      {['Super Admin', ...Admins, 'Accounts', 'DO', ...SalesManagers].includes(
        role,
      ) ? (
        <ReportCard />
      ) : null}

      <br />

      {['Super Admin', ...Admins, ...SalesManagers, ...SalesOfficers].includes(
        role,
      ) ? (
        <AllForms />
      ) : null}

      {['Super Admin', ...Admins, ...SalesManagers, ...SalesOfficers].includes(
        role,
      ) ? (
        <InfoCard />
      ) : null}

      <br />
      <br />
      <br />
    </Layout>
  );
}
