import { TableBody, TableCell, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import useAuth from '../../hooks/useAuth';

export default function PrintTbody({ data, filename }) {
  const { role } = useAuth();
  switch (filename) {
    case 'All_Collection':
      return (
        <TableBody sx={style}>
          {data?.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v?.shortId}</TableCell>
              <TableCell>
                {dayjs(v?.dateOfDeposit).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>
                {dayjs(v?.dateOfEntry).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>
                {v?.depositorName ? v?.depositorName : null}
              </TableCell>
              <TableCell>{v?.postUnder?.nameCode}</TableCell>
              <TableCell>
                {role === 'Accounts'
                  ? v?.customer?.territory?.name
                  : v?.customer?.territory?.code}
              </TableCell>
              <TableCell align="center">{v?.entryAmount}</TableCell>
              <TableCell align="center">{v?.approvedAmount}</TableCell>
              <TableCell align="center">{v?.costAmount}</TableCell>
              <TableCell align="center">{v?.bonusAmount}</TableCell>
              <TableCell>{v?.bankNameX ? v?.bankNameX : v?.bankName}</TableCell>
              <TableCell>{v?.transactionID}</TableCell>
              <TableCell>{'                    '}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    case 'Lifting_Plan_Details':
      return (
        <TableBody sx={style}>
          {data?.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v?.employee?.nameCode}</TableCell>
              <TableCell>
                {v?.createdAt
                  ? `${dayjs(v?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                  : null}
              </TableCell>
              <TableCell>
                {v?.timeStart
                  ? `${dayjs(v?.timeStart).format('DD/MM/YYYY')}`
                  : null}
              </TableCell>
              <TableCell>
                {v?.timeEnd ? `${dayjs(v?.timeEnd).format('MMM YYYY')}` : null}
              </TableCell>
              <TableCell>
                {v?.customer?.territories?.['rank-2']?.name}
              </TableCell>
              <TableCell>
                {v?.customer?.territories?.['rank-3']?.name}
              </TableCell>
              <TableCell>
                {v?.customer?.territories?.['rank-4']?.name}
              </TableCell>
              <TableCell>{v?.customer?.nameCode}</TableCell>
              <TableCell>{v?.commitmentAmount}</TableCell>
              <TableCell>{v?.totalCollection}</TableCell>
              <TableCell>{v?.achievement}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    case 'Sr_Report':
      return (
        <TableBody sx={style}>
          {data?.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v?.recordedBy?.nameCode}</TableCell>
              <TableCell>
                {v?.createdAt
                  ? `${dayjs(v?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                  : null}
              </TableCell>
              <TableCell>
                {v?.reportDate
                  ? `${dayjs(v?.reportDate).format('DD/MM/YYYY')}`
                  : null}
              </TableCell>
              <TableCell>{v?.employee?.nameCode}</TableCell>
              <TableCell>
                {v?.employee?.territory?.parentTerritory?.name}
              </TableCell>
              <TableCell>{v?.employee?.territory?.name}</TableCell>
              <TableCell>{v?.route}</TableCell>
              <TableCell>{v?.outletCount}</TableCell>
              <TableCell>{v?.orderQuantity} ctn</TableCell>
              <TableCell>{v?.orderValue} tk</TableCell>
              <TableCell>{v?.memoCount}</TableCell>
              <TableCell>{v?.deliveryQuantity} ctn</TableCell>
              <TableCell>{v?.deliveryValue} tk</TableCell>
              <TableCell>
                {v?.liftingQuantity ? `${v?.liftingQuantity} ctn` : null}
              </TableCell>
              <TableCell>
                {v?.stockQuantity ? `${v?.stockQuantity} ctn` : null}
              </TableCell>
              <TableCell>{v?.aveSkuValuePerMemo}</TableCell>
              <TableCell>{v?.orderValuePerMemo}</TableCell>
              <TableCell>{v?.linePerCall}</TableCell>
              <TableCell>
                {v?.strikeRate ? `${v?.strikeRate}%` : null}
              </TableCell>
              <TableCell>
                {v?.orderExecutionRate ? `${v?.orderExecutionRate}%` : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    case 'payroll_report':
      return (
        <TableBody sx={style}>
          {data?.map((v, i) => (
            <TableRow key={i}>
              <TableCell>{v?.employee?.nameCode}</TableCell>
              <TableCell>{v?.employee?.role?.employeeRole}</TableCell>
              <TableCell>
                {v?.employee?.territories?.['rank-2']?.name}
              </TableCell>
              <TableCell>
                {v?.employee?.territories?.['rank-3']?.name}
              </TableCell>
              <TableCell>
                {v?.employee?.territories?.['rank-4']?.name}
              </TableCell>
              <TableCell>
                {v?.employee?.dateOfJoin
                  ? dayjs(v?.employee?.dateOfJoin).format('DD/MM/YYYY')
                  : null}
              </TableCell>
              <TableCell>
                {v?.employee?.dateOfResign
                  ? dayjs(v?.employee?.dateOfResign).format('DD/MM/YYYY')
                  : null}
              </TableCell>
              <TableCell>{v?.totalAttendance}</TableCell>
              <TableCell>{v?.totalWorkWith}</TableCell>
              <TableCell>{v?.totalCollection}</TableCell>
              <TableCell>{v?.totalLifting}</TableCell>
              <TableCell>{v?.totalInMarketSalesQuantity}</TableCell>
              <TableCell>{v?.totalInMarketSalesValue}</TableCell>
              <TableCell>{v?.salaryBasic}</TableCell>
              <TableCell>{v?.totalDearnessAllowance}</TableCell>
              <TableCell>{v?.totalTravelAllowance}</TableCell>
              <TableCell>{v?.totalHotelBill}</TableCell>
              <TableCell>{v?.totalOtherBill}</TableCell>
              <TableCell>{v?.salaryMobile}</TableCell>
              <TableCell>{v?.salaryInternet}</TableCell>
              <TableCell>{v?.totalTicketsPlus}</TableCell>
              <TableCell>{v?.totalTicketsMinus}</TableCell>
              <TableCell>{v?.previousDues}</TableCell>
              <TableCell>{v?.totalPayable}</TableCell>
              <TableCell>{v?.totalPaid}</TableCell>
              {/* <TableCell>{v?.totalPaidRemarks}</TableCell>
              <TableCell>{v?.totalDue}</TableCell>
              <TableCell>{v?.salaryCollectionPercent}</TableCell>
              <TableCell>{v?.salaryInMarketSalesPercent}</TableCell>
              <TableCell>{v?.travelAllowanceCollectionPercent}</TableCell>
              <TableCell>{v?.travelAllowanceInMarketSalesPercent}</TableCell>
              <TableCell>{v?.totalPayCollectionPercent}</TableCell>
              <TableCell>{v?.totalPayInMarketSalesPercent}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      );
    default:
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={5}>Not found</TableCell>
          </TableRow>
        </TableBody>
      );
  }
}

const style = {
  td: {
    border: '1px solid grey',
    whiteSpace: 'nowrap',
  },
};
