import React from 'react';

function Button(props) {
  return (
    <button
      disabled={props.disabled || false}
      type={props.type}
      className={`__btn btn_blue ${props.bg_color} ${props.fullWidth} c-pointer`}
      {...props}>
      {props.icon} <span className={props.display}>{props.text}</span>
    </button>
  );
}

export default Button;
