function getRandomColorName() {
  const colorNames = [
    'crimson',
    'purple',
    'green',
    'darkgoldenrod',
    'rebeccapurple',
    'chocolate',
    'seagreen',
    'steelblue',
    'olive',
    'darkorange',
    'teal',
    'deeppink',
    'dodgerblue',
    'indianred',
    'darkorchid',
    'yellowgreen',
    'navy',
  ];

  const randomIndex = Math.floor(Math.random() * colorNames.length);

  return colorNames[randomIndex];
}

export default getRandomColorName;
