import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmModal from '../../component/ConfirmModal';
import Modal from '../../component/Modals';
import TableSkeleton from '../../component/Skeleton/TableSkeleton';
import CustomSpeedDial from '../../component/SpeedDial/CustomSpeedDial';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import Table from '../../component/Table/Table';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useExpiredSession from '../../hooks/useExpiredSession';
import useHeader from '../../hooks/useHeader';
import getRandomColorName from '../../utils/getRandomColorName';

function OutletInfo() {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { role, Admins } = useAuth();
  const { setHeader } = useHeader();

  const [verifyShow, setVerifyShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const [status, setStatus] = useState('pending');
  const [phoneCount, setPhoneCount] = useState({});

  const [verifyData, setVerifyData] = useState({});
  const [deleteId, setDeleteId] = useState('');

  const [selectedValue, setSelectedValue] = useState([]);

  const handleEditShow = (data) => {
    navigate('/form', { state: data });
  };

  const handleVerifyShow = (data) => {
    setStatus(data.status);
    setVerifyData(data);
    setVerifyShow(true);
  };

  const handleDeleteShow = (id) => {
    setDeleteId(id);
    setDeleteShow(true);
  };

  const handleSelectionModal = () => {
    if (selectedValue?.length > 0) {
      setVerifyShow(true);
    } else {
      toast.warn('Please select a row first');
    }
  };

  const handleVerify = useCallback(() => {
    if (selectedValue?.length > 0) {
      const updates = selectedValue.map((item) => ({
        _id: item.original.id,
        status,
      }));

      axiosPrivate
        .patch('/api/v1/outlet/approve', updates)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Updated successfully');
            refetch();
            setVerifyShow(false);
          }
        })
        .catch((error) => {
          toast.warn(error?.response?.data?.message);
        });
    } else {
      axiosPrivate
        .patch(`/api/v1/outlet/${verifyData.id}`, { status })
        .then((res) => {
          if (res.status === 200) {
            toast.success('Updated successfully');
            refetch();
            setVerifyShow(false);
          }
        })
        .catch((error) => {
          toast.warn(error?.response?.data?.message);
        });
    }
  }, [verifyData.id, selectedValue.length, status]);

  const handleDelete = () => {
    axiosPrivate
      .delete(`/api/v1/outlet/${deleteId}`)
      .then((res) => {
        if (res.status === 204) {
          toast.success('Document was deleted');
          refetch();
          setDeleteShow(false);
        }
      })
      .catch((error) => {
        toast.warn(error?.response?.data?.message);
      });
  };

  // get product category
  const { data, isLoading, isError, error, refetch } = useQuery(
    'outletInfo',
    () => {
      if (!['Super Admin', ...Admins, 'IMS'].includes(role)) {
        return axiosPrivate.get(
          `/api/v1/outlet?populate=yes&limit=none&sort=-createdAt&hq=${localStorage.getItem(
            'hqId',
          )}`,
        );
      }
      return axiosPrivate.get(
        '/api/v1/outlet?populate=yes&limit=none&sort=-createdAt',
      );
    },
    { refetchInterval: 30000 },
  );
  const d = data?.data?.data;

  if (isError) {
    expired(error);
  }

  useEffect(() => {
    setHeader({ title: 'Outlet Info' });
  }, []);

  // find duplicate phone count
  useEffect(() => {
    if (Array.isArray(d)) {
      const pCount = {};
      d?.forEach((d) => {
        if (pCount[d.outletContact]?.count) {
          pCount[d.outletContact].count += 1;
        } else {
          pCount[d.outletContact] = {
            count: 1,
            color: getRandomColorName(),
          };
        }
      });
      setPhoneCount(pCount);
    }
  }, [d]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: 'Entry Time',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: "Recoder's Name",
        accessor: 'name',
      },
      {
        Header: "Recoder's Mobile No.",
        accessor: 'mobileNo',
      },
      {
        Header: 'Outlet Name',
        accessor: 'outletName',
      },
      {
        Header: 'Outlet Category',
        accessor: 'outletCategory',
      },
      {
        Header: 'Outlet Contact',
        accessor: 'outletContact',
        Cell: ({ value }) => {
          if (phoneCount[value]?.count > 1) {
            return (
              <span style={{ color: phoneCount[value].color }}>
                <span
                  style={{
                    borderRadius: 8,
                    color: 'white',
                    backgroundColor: phoneCount[value].color,
                  }}>
                  &nbsp;{phoneCount[value].count}&nbsp;
                </span>
                &nbsp;
                <a
                  href={`tel:${value}`}
                  style={{
                    color: `${phoneCount[value].color}`,
                    textDecoration: 'underline',
                  }}>
                  {value}
                </a>
              </span>
            );
          }
          return (
            <a
              href={`tel:${value}`}
              style={{ color: '#000', textDecoration: 'underline' }}>
              {value}
            </a>
          );
        },
      },
      {
        Header: 'Owner Name',
        accessor: 'outletOwnerName',
      },
      {
        Header: 'Owner Contact',
        accessor: 'outletOwnerContact',
      },
      {
        Header: 'Region',
        accessor: 'region.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'hq.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'pointTown.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Route/Beat',
        accessor: 'routeBeat',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Geo Location',
        Cell: ({ row }) => {
          if (!row?.original?.latitude || !row?.original?.longitude)
            return <Typography color="error">NO DATA</Typography>;
          return (
            <Link
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${row?.original?.latitude},${row?.original?.longitude}`,
              )}
              rel="noopener noreferrer"
              target="_blank"
              underline="hover"
              title="Open in Google Map">
              <Icon sx={{ fontSize: 14 }}>location_on</Icon>
              {` ${row?.original?.latitude}, ${row?.original?.longitude}`}
            </Link>
          );
        },
      },
      {
        Header: 'Status',
        id: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          if (value === 'verified')
            return (
              <Tooltip title="Verified">
                <CheckCircleIcon color="success" />
              </Tooltip>
            );
          if (value === 'failed')
            return (
              <Tooltip title="Failed">
                <CancelIcon color="error" />
              </Tooltip>
            );
          return (
            <Tooltip title="Pending">
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          );
        },
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Action',
        id: 'Action',
        Cell: ({ row }) => {
          return (
            <Stack direction="row" alignItems="center" spacing={3}>
              {['Super Admin', ...Admins].includes(role) ? (
                <Tooltip title="Update verification status">
                  <IconButton
                    onClick={() => handleVerifyShow(row?.original)}
                    className="Table_icon_btn">
                    <BeenhereIcon />
                  </IconButton>
                </Tooltip>
              ) : null}

              <Tooltip title="Update shop information">
                <IconButton
                  onClick={() => handleEditShow(row?.original)}
                  className="Table_icon_btn">
                  <BorderColorIcon />
                </IconButton>
              </Tooltip>

              {['Super Admin', ...Admins].includes(role) ? (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDeleteShow(row?.original?.id)}
                    className="Table_icon_btn delete">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          );
        },
      },
    ],
    [phoneCount, role],
  );

  const ApproveModal = useMemo(
    () => (
      <Modal
        title="Update verification status"
        show={verifyShow}
        onSave={handleVerify}
        onHide={() => setVerifyShow(false)}
        width="sm"
        form>
        <Stack direction="column" gap={3}>
          <Typography>
            Set verification status for:
            {selectedValue?.length > 0 ? (
              selectedValue?.map((item, i) => (
                <>
                  <br />
                  <strong key={item?.original?.id}>
                    {item?.original?.outletName}
                  </strong>
                </>
              ))
            ) : (
              <>
                <br />
                <strong>{verifyData?.outletName}</strong>
              </>
            )}
          </Typography>

          <FormControl>
            <FormLabel id="outlet-status-label">Verification Status</FormLabel>
            <RadioGroup
              aria-labelledby="outlet-status-label"
              name="outlet-status"
              value={status}
              defaultValue="pending"
              onChange={(e) => setStatus(e.target.value)}>
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Pending&nbsp;
                    <HelpOutlineIcon color="primary" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="verified"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Verified&nbsp;
                    <CheckCircleIcon color="success" fontSize="small" />
                  </Box>
                }
              />
              <FormControlLabel
                value="failed"
                control={<Radio />}
                label={
                  <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    Failed&nbsp;
                    <CancelIcon color="error" fontSize="small" />
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Button variant="contained" onClick={handleVerify}>
            Update
          </Button>
        </Stack>
      </Modal>
    ),
    [
      handleVerify,
      verifyData?.outletName,
      selectedValue.length,
      status,
      verifyShow,
    ],
  );

  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <Box>
      <Table
        columns={COLUMNS}
        data={d}
        filename="OutletInfo"
        addButtonTooltip="Add Outlet"
        onAddButtonClick={() => navigate('/form')}
        fullHeight
        rowSelection
        setSelectionValues={setSelectedValue}
      />

      {selectedValue?.length > 0 ? (
        <CustomSpeedDial
          actions={[
            {
              icon: <BeenhereIcon />,
              name: 'Approve',
              onEvent: handleSelectionModal,
            },
          ]}
        />
      ) : null}

      {ApproveModal}

      <ConfirmModal
        title="Are you sure?"
        subtitle="You won't be able to revert this!"
        show={deleteShow}
        onSave={() => handleDelete()}
        onHide={() => setDeleteShow(false)}
        actionButtonLabel="Delete"
        actionButtonColor="error"
      />
    </Box>
  );
}

export default OutletInfo;
