export const offerTypeLabels = {
  free: 'Free',
  gift: 'Gift',
  none: 'None',
};

export const unitLabels = {
  pcs: 'Pieces',
  pkt: 'Packets',
  btls: 'Bottles',
  mg: 'Milligrams',
  g: 'Grams',
  kg: 'Kilograms',
  oz: 'Ounces',
  lb: 'Pounds',
  ml: 'Milliliters',
  l: 'Litres',
  gal: 'Gallons',
  mm: 'Millimeters',
  cm: 'Centimeters',
  m: 'Meters',
  feet: 'Feet',
  inch: 'Inches',
};
