import React from 'react';
import logo from '../../assets/images/logo_blue.svg'; // LIVE site logo
// import logo from '../../assets/images/placeholder-logo.png'; // TEST site logo
import { Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
export default function Logo() {
  return (
    <Link to="/">
      <Avatar src={logo} sx={avatarStyles} />
    </Link>
  );
}

const avatarStyles = {
  borderRadius: '0',
  width: '100%',
  height: '100%',
  overflow: 'visible',
  img: {
    width: { s_md: '300px', xs: '200px' },
    height: 'auto',
  },
};
