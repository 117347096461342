import React, { useMemo } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../../../component/Table/SelectColumnFilter';
import Table from '../../../../component/Table/Table';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import useExpiredSession from '../../../../hooks/useExpiredSession';
import roundToDigit from '../../../../utils/roundToDigit';
import shortMongoID from '../../../../utils/shortMongoID';
import ExpandRow from '../../../Collections/ExpandRow';
import CustomerProfileHeader from '../CustomerProfileHeader/CustomerProfileHeader';

function Ledger({ LedgerReport, customerID }) {
  const param = useParams();
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();

  const cID = useMemo(
    () => (customerID ? customerID : param?.customerID),
    [customerID, param?.customerID],
  );

  const { data, isLoading, isError, error } = useQuery(
    ['customer-ledger', cID],
    () => {
      const urlLedger = `/api/v1/customer/${cID}/ledger`;
      const urlCommission = `/api/v1/report/sd-commission?&customer=${cID}`;
      return Promise.all([
        axiosPrivate.get(urlLedger),
        axiosPrivate.get(urlCommission),
      ]).then((results) => {
        const ledgerEntries = results?.[0]?.data?.data;
        const commissionEntries = results?.[1]?.data?.data;
        // const dueCommission = results?.[1]?.data?.totalDuePayment;

        const newData = [];

        function calculateEntry(i) {
          if (i?.approvedAmount > 0) {
            i.debit = roundToDigit(i?.approvedAmount);
          } else {
            i.credit = roundToDigit(i?.approvedAmount * -1);
          }

          if (['collection', 'bank charge', 'incentive'].includes(i?.type)) {
            i.collectionShortID = shortMongoID(i?.id);
          }
          if (i?.transactionRef) {
            i.transactionShortID = shortMongoID(i?.transactionRef);
          }
          if (i?.type === 'commission') {
            if (i?.transactionRef) {
              i.type = 'commission paid as products';
            } else {
              i.type = 'commission paid as cash';
            }
          }

          newData.push(i);
        }

        ledgerEntries?.forEach((i) => {
          calculateEntry(i);

          if (i?.totalAmount !== i?.approvedAmount) {
            if (i?.costAmount) {
              calculateEntry({
                ...i,
                type: 'bank charge',
                entryAmount: i?.costAmount,
                approvedAmount: i?.costAmount,
                bonusAmount: 0,
                costAmount: 0,
                totalAmount: i?.costAmount,
              });
            }
            if (i?.bonusAmount) {
              calculateEntry({
                ...i,
                type: 'incentive',
                entryAmount: i?.bonusAmount,
                approvedAmount: i?.bonusAmount,
                bonusAmount: 0,
                costAmount: 0,
                totalAmount: i?.bonusAmount,
              });
            }
          }
        });

        commissionEntries?.forEach((i) => {
          const amount =
            parseFloat(i?.commission) - parseFloat(i?.paidAsProduct);

          const date = dayjs(i?.monthKey, 'MMM YYYY', true)
            .endOf('month')
            .startOf('minute')
            .toDate();

          if (amount) {
            calculateEntry({
              dateOfDeposit: date,
              type: 'SD COMMISSION',
              entryAmount: amount,
              approvedAmount: amount,
              bonusAmount: 0,
              costAmount: 0,
              totalAmount: amount,
            });
          }
        });

        /* if (dueCommission) {
          calculateEntry({
            dateOfDeposit: new Date(),
            type: 'SD COMMISSION',
            entryAmount: dueCommission,
            approvedAmount: dueCommission,
            bonusAmount: 0,
            costAmount: 0,
            totalAmount: dueCommission,
          });
        } */

        const sorted = newData.sort((a, b) => {
          return (
            dayjs(a?.dateOfDeposit).valueOf() -
            dayjs(b?.dateOfDeposit).valueOf()
          );
        });

        let previousSum = 0;

        return sorted
          .map((i) => {
            previousSum += i?.approvedAmount;
            i.balance = roundToDigit(previousSum);

            console.log('entry ===>\n', i);

            return i;
          })
          .reverse();
      });
    },
  );

  const COLUMNS = useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({ row }) => {
          return (
            <>
              {row.isExpanded ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'id',
        hidden: true,
      },
      {
        id: 'ID',
        Header: 'Transaction Ref',
        accessor: 'transactionRef',
        hidden: true,
      },
      {
        Header: 'Time',
        accessor: 'dateOfDeposit',
        Cell: ({ value }) => {
          if (value) return dayjs(value).format('DD/MM/YYYY hh:mm A');
          return value;
        },
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
        Cell: ({ value }) => (
          <span style={{ textTransform: 'capitalize' }}>{value}</span>
        ),
      },
      {
        Header: 'Col. ID',
        accessor: 'collectionShortID',
      },
      {
        Header: 'Trx. ID',
        accessor: 'transactionShortID',
      },
      {
        Header: 'Depositor',
        accessor: 'customer.nameCode',
      },
      {
        Header: 'Post Under',
        accessor: 'postUnder.nameCode',
      },
      {
        Header: 'Details',
        accessor: 'remarks',
        Cell: ({ row }) => (
          <>
            <span>Reference: {row?.original?.reference}</span>
            <br />
            <span>Remarks: {row?.original?.remarks}</span>
          </>
        ),
      },
      {
        Header: 'Debit',
        accessor: 'debit',
        aggregate: 'sum',
      },
      {
        Header: 'Credit',
        accessor: 'credit',
        aggregate: 'sum',
      },
      {
        Header: 'Balance',
        accessor: 'balance',
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      {!LedgerReport ? <CustomerProfileHeader cID={cID} /> : null}

      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        filename="Ledger"
        noWrap
        hideAddIcon
        expandRow={ExpandRow}
      />
    </>
  );
}

export default Ledger;
