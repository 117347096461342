const CompanyNames = [
  'RIDISHA',
  'DANISH',
  'OLYMPIC',
  'HAQUE',
  'GOLD MARK',
  'ROMANIA',
  'KOOKIEJAR',
  'COCOLA',
  'CARE NUTRITION',
  'GREEN 9',
  'NEXT FOOD',
  'IFAD',
  'NICE FOOD',
  'MASUD GROUP',
  'ALIBABA ',
  'BENGAL',
  'BONOFUL',
  'KISHWAN',
  'AKIJ',
  'DEKKO',
  'ACI FOODS',
  'STAR LINE',
  'NEW LIFE',
  'BD FOOD',
  'ARKU FOODS',
  'FRESH',
  'PRAN',
  'PUSHTI',
  'BENGAL',
  'PROME',
  'SHAD',
  'PRINCE',
  'QUANTUM',
  'WELL FOOD',
  'FINE FOOD',
  'VEGAN FOOD',
  'AL MANAR AGRO & FOODS',
  'NMC FOOD ',
  'SMC',
  'FULKOLI',
  'JABED GROUP',
  'SIX SEASONS',
  'ENA FOOD',
  'ALIN FOOD',
  'TK FOOD',
];

/*
  "RIDISHA",
  "DANISH",
  "OLYMPIC",
  "HAQUE",
  "GOLD MARK B",
  "ROMANIA",
  "KOOKIEJAR",
  "COCOLA",
  "CARE",
  "GREEN 9",
  "NEXT FOOD",
  "IFAD",
  "NICE FOOD",
  "MASUD",
  "ALIBABA BARISAL",
  "BENGAL",
  "MASUD GROUP A",
  "MASUD GENERAL",
  "MASUD GROUP B",
  "KOOKIEJAR PROPOSAL",
  "BANOFUL & CO.",
  "RANI FOOD IND LTD",
  "AKIJ BEKARS LTD",
  "ACI FOODS",
  "DEKKO FOODS",
  "STAR LINE",
  "BD FOOD",
  "ARKU FOODS",
  "FRESH",
  "PRAN",
  "PUSHTI",
  "BENGAL",
  "BONOFUL",
  "BRITANNIA INDIA",
  "SUNFEAST INDIA",
  "ANMOL INDIA",
  "BISKFARM INDIA",
  "SOBISCO INDIA",
  "RAJA INDIA",
  "PARLE INDIA",
  "PROME",
  "SHAD",
  "PRINCE",
  "QUANTUM",
  "WELL FOOD",
  "FINE FOOD",
  "VEGAN FOOD",
  "AL MANAR AGRO & FOODS",
  "NMC FOOD",
  "SMC",
  "FULKOLI",
  "JABED GROUP",
  "SIX SEASONS",
  "ENA FOOD",
  "ALIN FOOD",
  "TK FOOD",
  "SHAPLA",
  "AKIJ FOOD AND BEVERAGE",
  "ABUL KHAIR",
  "SAVE FOOD",
*/

export default CompanyNames;
