import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import dayjs from 'dayjs';
import styles from '../../assets/css/profile.module.css';
import useAuth from '../../hooks/useAuth';
import useExpiredSession from '../../hooks/useExpiredSession';
import { useState } from 'react';
import UpdateEmailModal from './UpdateEmailModal';
import UpdatePhoneModal from './UpdatePhoneModal';
import UpdatePasswordModal from './UpdatePasswordModal';

function ViewProfile({ user, isloading, iserror, error }) {
  const { role } = useAuth();
  const matches = useMediaQuery('(min-width:900px)');
  const expired = useExpiredSession();
  // states
  const [updateEmailModal, setUpdateEmailModal] = useState(false);
  const [updatePhoneModal, setUpdatePhoneModal] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);

  const total =
    user?.employee?.salaryBasic +
    user?.employee?.salaryInternet +
    user?.employee?.salaryTravel +
    user?.employee?.salaryMobile;

  if (isloading) {
    return 'lodaing....';
  }
  if (iserror) {
    expired(error);
  }

  return (
    <>
      <Box>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          gap={{ xs: 1, sm: 5 }}>
          <Box>
            <Avatar
              sx={{ width: { sm: 200, xs: 100 }, height: { sm: 200, xs: 100 } }}
              src={user?.employee?.avatar}
              alt={user?.employee?.name}
            />
          </Box>
          <Box item sm={9} xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: { md: 40, sm: 30, xs: 25 },
                  marginLeft: '-3px',
                  fontWeight: 600,
                }}
                color="grey.800">
                {user?.employee?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: { sm: 20, xs: 17 },
                  color: '#000000b8',
                  fontWeight: 500,
                }}>
                {user?.employee?.role?.employeeRole}
              </Typography>

              <Stack
                color="grey"
                direction="row"
                gap={2}
                mt={1}
                flexWrap="wrap">
                {user?.employee?.territory?.nameCode ? (
                  <Typography>
                    <LocationOnIcon
                      fontSize="small"
                      sx={{ position: 'relative', top: 2, marginRight: 1 }}
                    />
                    {user?.employee?.territory?.nameCode}
                  </Typography>
                ) : null}
                {user?.employee?.contactNumber ? (
                  <Typography>
                    <SmartphoneIcon
                      fontSize="small"
                      sx={{ position: 'relative', top: 1, marginRight: 1 }}
                    />
                    {user?.employee?.contactNumber}
                  </Typography>
                ) : null}

                {user?.employee?.email ? (
                  <Typography>
                    <EmailIcon
                      fontSize="small"
                      sx={{ position: 'relative', top: 4, marginRight: 1 }}
                    />
                    {user?.employee?.email}
                  </Typography>
                ) : null}
              </Stack>

              {/* <Stack direction="row" sx={{ marginLeft: '-10px' }}>
                <Tooltip title="Facebook">
                  <Link
                    href="https://www.facebook.com/mahibumasud/"
                    target="_blank">
                    <IconButton>
                      <FacebookIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Twitter">
                  <IconButton>
                    <TwitterIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="LinkedIn">
                  <IconButton>
                    <LinkedInIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="What's App">
                  <IconButton>
                    <WhatsAppIcon />
                  </IconButton>
                </Tooltip>
              </Stack> */}
            </Box>
          </Box>
        </Stack>

        <Divider sx={{ margin: '40px 0px' }} />
        {/* upadte auth */}
        <Stack
          direction="row"
          justifyContent="flex-end"
          gap={2}
          flexWrap="wrap"
          mb={2}>
          <Button
            sx={{ flexGrow: { sm: 0, xs: 1 } }}
            variant="contained"
            color="secondary"
            onClick={() => setUpdateEmailModal(true)}>
            Change Login Email
          </Button>
          <Button
            sx={{ flexGrow: { sm: 0, xs: 1 } }}
            variant="contained"
            color="info"
            onClick={() => setUpdatePhoneModal(true)}>
            Change Login Phone
          </Button>
          <Button
            sx={{ flexGrow: { sm: 0, xs: 1 } }}
            variant="contained"
            color="error"
            onClick={() => setUpdatePasswordModal(true)}>
            Change Password
          </Button>
        </Stack>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ width: { md: '600px' } }}>
          <Grid item xs={12}>
            <Typography
              sx={{ typography: { sm: 'h4', xs: 'h5' } }}
              color="primary.main">
              Basic Information
            </Typography>
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Name</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Name' : ''}
              fullWidth
              value={user?.employee?.name ? user?.employee?.name : ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Email</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Email' : ''}
              fullWidth
              value={user?.employee?.email ? user?.employee?.email : ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>NID</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'NID' : ''}
              fullWidth
              value={
                user?.employee?.nationalID ? user?.employee?.nationalID : ''
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Date Of Birth</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Date Of Birth' : ''}
              fullWidth
              value={
                user?.employee?.dateOfBirth
                  ? dayjs(user?.employee?.dateOfBirth).format('DD/MM/YYYY')
                  : null
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Educational Qualification</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Education Qualification' : ''}
              fullWidth
              value={
                user?.employee?.educationalQualification
                  ? user?.employee?.educationalQualification
                  : ''
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Contact Number</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Contact Number' : ''}
              fullWidth
              value={
                user?.employee?.contactNumber
                  ? user?.employee?.contactNumber
                  : ''
              }
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          {/* <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Secondary Number</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Secondary Number' : ''}
              fullWidth
              value={secondaryNo}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
            <p className={styles.levelName}>Referral Number</p>
          </Grid>
          <Grid item md={7} sm={12}>
            <TextField
              size="small"
              label={!matches ? 'Referral Number' : ''}
              fullWidth
              value={referralNo}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid> */}
        </Grid>

        <Divider sx={{ margin: '40px 0px' }} />
        {role === 'Super Admin' && (
          <Grid
            container
            spacing={2}
            alignItems="center"
            sx={{ width: { md: '600px' } }}>
            <Grid item xs={12}>
              <Typography
                sx={{ typography: { sm: 'h4', xs: 'h5' } }}
                color="primary.main">
                Salary Information
              </Typography>
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Bank Name</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Bank Name' : ''}
                fullWidth
                value={user?.employee?.bankName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Account No</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Account No' : ''}
                fullWidth
                value={user?.employee?.bankAccountNumber}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Basic Salary</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Salary(Basic)' : ''}
                fullWidth
                value={user?.employee?.salaryBasic}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Salary(Internet)</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Salary(Internet)' : ''}
                fullWidth
                value={user?.employee?.salaryInternet}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Salary(Mobile)</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Salary(Mobile)' : ''}
                fullWidth
                value={user?.employee?.salaryMobile}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Salary(Travel)</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Salary(Travel)' : ''}
                fullWidth
                value={user?.employee?.salaryTravel}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              <p className={styles.levelName}>Total</p>
            </Grid>
            <Grid item md={7} sm={12}>
              <TextField
                size="small"
                label={!matches ? 'Total' : ''}
                fullWidth
                value={total}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      {updateEmailModal && (
        <UpdateEmailModal
          show={updateEmailModal}
          onSave={() => setUpdateEmailModal(false)}
          onHide={() => setUpdateEmailModal(false)}
        />
      )}
      {updatePhoneModal && (
        <UpdatePhoneModal
          show={updatePhoneModal}
          onSave={() => setUpdatePhoneModal(false)}
          onHide={() => setUpdatePhoneModal(false)}
        />
      )}
      {updatePasswordModal && (
        <UpdatePasswordModal
          show={updatePasswordModal}
          onSave={() => setUpdatePasswordModal(false)}
          onHide={() => setUpdatePasswordModal(false)}
        />
      )}
    </>
  );
}

export default ViewProfile;
