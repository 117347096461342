import {
  DateRangeColumnFilter,
  MultiSelectColumnFilter,
} from '../../component/Table/SelectColumnFilter';
import React from 'react';
import useHeader from '../../hooks/useHeader';
import useExpiredSession from '../../hooks/useExpiredSession';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Table from '../../component/Table/Table';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

function CompanyCategorySales() {
  const axiosPrivate = useAxiosPrivate();
  const expired = useExpiredSession();
  const { setHeader } = useHeader();

  React.useEffect(() => {
    setHeader({ title: 'Competitor Category Sales' });
  }, []);

  const { data, isLoading, error, isError } = useQuery(
    'company-category-sales',
    () =>
      axiosPrivate
        .get('/api/v1/report/company-category-sales')
        .then((res) => res?.data?.data),
  );

  const COLUMNS = React.useMemo(
    () => [
      {
        Header: 'Company Name',
        accessor: 'companies.companyName',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Product Category',
        accessor: 'companies.productCat',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Sales Amount',
        accessor: 'companies.amount',
        aggregate: 'sum',
      },
      {
        Header: 'Region',
        accessor: 'region.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'HQ',
        accessor: 'hq.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Point/Town',
        accessor: 'pointTown.name',
        Filter: MultiSelectColumnFilter,
        filter: 'includesSome',
      },
      {
        Header: 'Dealer name',
        accessor: 'dealerName',
      },
      {
        Header: 'Entered by',
        accessor: 'name',
      },
      {
        Header: 'Entry date',
        accessor: 'visitDate',
        Cell: ({ value }) => (value ? dayjs(value).format('DD/MM/YYYY') : null),
        Filter: DateRangeColumnFilter,
        filter: 'dateBetween',
      },
    ],
    [],
  );

  if (isError) {
    expired(error);
  }

  return (
    <>
      <Table
        columns={COLUMNS}
        data={data || []}
        isLoading={isLoading}
        noWrap
        hideAddIcon
      />
    </>
  );
}

export default CompanyCategorySales;
